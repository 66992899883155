<template>
  <el-collapse
    :value="activePanel"
    @change="(value) => {
      $emit('saveCounselingProfile');
      $emit('setActivePanel', value);
    }"
    accordion
  >
    <el-collapse-item title="UC values" name="ucValues">
      <el-form>
        <div>
          <p>Now that we’ve learned a little bit about you, we want to tell you about what’s important to us — our community and values.</p>
          <h3>Community principles</h3>
          <p>We are a community of people from diverse backgrounds, with diverse identities, abilities, and needs. Everything we do is supported and enriched by an atmosphere of positive engagement and a spirit of cooperation and inquiry.</p>
          <p>Each UC campus has committed to Principles of Community that reaffirm each individual’s right to dignity and civil discourse. We ask you to agree that, if you become part of the UC community, you will seek to maintain a climate of civility and mutual respect.</p>
          <p>The following principles serve as a guide and reflect our passion for critical inquiry and our deep commitment to contributing to a better world:</p>
          <ul>
            <li>We place honesty and integrity in our teaching, learning, research and administration at the highest level.</li>
            <li>We respect the differences as well as the commonalities that bring us together.</li>
            <li>We reject acts of discrimination based on race, ethnicity, sex, gender identity, age, disability, sexual orientation, religion, and political beliefs.</li>
            <li>We are committed to ensuring freedom of expression. We celebrate the expression of individuality and diversity within the bounds of courtesy, sensitivity, and respect.</li>
          </ul>
        </div>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.california_app]"
        >
          <div style="display: inline-block; width: 100%;">
            <el-checkbox
              true-label="Y"
              false-label="N"
              :value="counselingProfile.accepted_us_values"
              @input="
                accepted_us_values =>
                  setCounselingProfile({ accepted_us_values })
              "
            >
              I acknowledge, value, and agree with these guiding principles.
            </el-checkbox>
          </div>
        </CounselingFormItem>
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
    <el-collapse-item title="Term & level" name="termLevel">
      <el-form>
        <div>
          <hr />
          <p>Before you tell us which campuses you're applying to, make sure the information below is correct. You've already answered these questions at the beginning of the application, but now's your chance to check one more time.</p>
          <hr />
          <h5>I plan to start at the University of California in:</h5>
          <br />
          <h6>Fall Quarter/Semester 2022</h6>
          <hr />
          <h5>My application level is: </h5>
        </div>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.california_app]"
        >
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.apply_level"
            @input="(apply_level) => setCounselingProfile({ apply_level })"
          >
            <div><el-radio label="1">Freshman</el-radio></div>
            <div class="radioPrompt">
              <p>I'm currently in high school.</p>
              <p>- OR -</p>
              <p>I've graduated from high school, but have not enrolled in a regular session (non-summer) at a college or university. </p>
            </div>
            <div><el-radio label="2">Transfer</el-radio></div>
            <div class="radioPrompt">
              <p>I've graduated from high school and am enrolled in a regular session (fall, winter or spring) at a college or university. </p>
            </div>
            <div><el-radio label="3">Other</el-radio></div>
            <div class="radioPrompt">
              <p>I already have a degree (B.A. or B.S.) or I'm not working toward a UC degree.</p>
            </div>
          </el-radio-group>
        </CounselingFormItem>
        <CounselingFormItem
          v-if="applyLevelIsTransfer"
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.california_app]"
        >
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.entering_level"
            @input="(entering_level) => setCounselingProfile({ entering_level })"
          >
            <template slot="label">
              Please select what type of transfer applicant you are based on the number of college/university units you plan to complete before you enroll at UC:
            </template>
            <div><el-radio label="3">Junior-level transfer</el-radio></div>
            <div class="radioPrompt">
              <p>I will complete 60-89 semester units or 90-134 quarter units by the time I enter UC.</p>
            </div>
            <div><el-radio label="2">Sophomore-level transfer</el-radio></div>
            <div class="radioPrompt">
              <p>I will complete fewer than 60 semester units/90 quarter units by the time I enter UC. (There are limited campuses/programs available at this level.)</p>
            </div>
            <div><el-radio label="4">Senior-level transfer</el-radio></div>
            <div class="radioPrompt">
              <p>I will complete 90 semester units/135 quarter units or more by the time I enter UC. (There are limited campuses/programs available at this level.)</p>
            </div>
          </el-radio-group>
        </CounselingFormItem>
        <CounselingFormItem
          v-if="applyLevelIsOther"
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.california_app]"
        >
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.entering_level"
            @input="(entering_level) => setCounselingProfile({ entering_level })"
          >
            <div><el-radio label="5">Second Baccalaureate</el-radio></div>
            <div class="radioPrompt">
              <p>I already have a B.A. or B.S.</p>
            </div>
            <div><el-radio label="6">Limited status</el-radio></div>
            <div class="radioPrompt">
              <p>I am not working toward a UC degree.</p>
            </div>
          </el-radio-group>
        </CounselingFormItem>
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import { thirdAppTypeDict } from "@/class/ThirdApp";
import CounselingFormItem from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/CounselingFormItem";

export default {
  props: {
    currentApplicationType: {
      type: String,
      default: ""
    },
    activePanel: {
      type: String,
      default: ""
    }
  },
  components: {
    CounselingFormItem
  },
  computed: {
    thirdAppTypes() {
      return thirdAppTypeDict;
    },
    counselingProfile() {
      return this.$store.getters["counselingProfile/counselingProfile"];
    },
    applyLevelIsTransfer() {
      return this.counselingProfile.apply_level === "2";
    },
    applyLevelIsOther() {
      return this.counselingProfile.apply_level === "3";
    }
  },
  data() {
    return {};
  },
  methods: {
    setCounselingProfile(data) {
      this.$store.commit("counselingProfile/setCounselingProfile", { ...data });
    }
  }
};
</script>

<style scoped src="@/views/private/Counseling/ThirdApp/CounselingProfileForm/index.css"></style>
<style scoped>
.radioPrompt {
  font-size: 14px;
  margin: 0px 0px 20px 32px;
  color: #606266;
}
</style>
