<template>
  <el-collapse
    :value="activePanel"
    @change="(value) => {
      // $emit('saveCounselingProfile');
      $emit('setActivePanel', value);
    }"
    accordion
  >
    <CounselingFormAlert
      :currentApplication="currentApplicationType"
      :applicationsNeedToVisible="[thirdAppTypes.california_app]"
    >
      <template slot="title">
        {{ $t('counselingProfile.i18n10') }}
        <a
          target="_blank"
          href="https://apply.universityofcalifornia.edu/my-application/apply-scholarships"
        >
          {{ $t('counselingProfile.i18n11') }}
        </a>
        {{ $t('counselingProfile.i18n12') }}
      </template>
    </CounselingFormAlert>
    <!--
    <el-collapse-item title="Apply for scholarships" name="applyForScholarships">
      <el-form>
        <div>
          <p>You can apply to a number of UC scholarships (that means free money) right here in the application. The scholarships listed below are awarded to students with specific backgrounds, academic interests or career objectives. If you don't see any categories that are relevant to you, don't worry. In addition to these scholarships, each campus and many alumni associations award a variety of scholarships that recognize students' achievement and potential.</p>
          <p>You'll also need to file a <a href="https://fafsa.ed.gov/">Free Application for Federal Student Aid (FAFSA)</a> opens in new tab or  <a href="http://www.csac.ca.gov/dream_act.asp">a California Dream Act Applicationopens</a> in new tab (if you're an undocumented student) to be considered for every type of financial aid possible, including the scholarships listed below. International students should not file these.</p>
        </div>
        <CounselingFormItem
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.california_app]"
        >
          <template slot="label">
            Click on a category to view the list of scholarships and check the boxes for the scholarships that you'd like to apply to.
          </template>
          <hr />
          <div :key="scholarship.label" v-for="(scholarship, index) in scholarships">
            <div @click="() => toggleOpenStatus(index)" class="panelTitle">
              <span v-if="scholarship.opened" key="open">
                <i class="fas fa-chevron-down" />
              </span>
              <span v-else key="close">
                <i class="fas fa-chevron-right" />
              </span>
              <span>{{ scholarship.label }}</span>
            </div>
            <div
              class="panelContent"
              v-if="scholarship.opened"
            >
              <el-checkbox-group
                :value="counselingProfile.scholarships"
                @input="(scholarships) => setCounselingProfile({ scholarships })"
              >
                <div :key="item.value" v-for="item in scholarship.items">
                  <el-checkbox :label="item.value">
                    {{ item.label }}
                  </el-checkbox>
                </div>
              </el-checkbox-group>
            </div>
            <hr />
          </div>
        </CounselingFormItem>
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
    <el-collapse-item title="Support programs" name="supportPrograms">
      <el-form>
        <div>
          <p>UC offers an array of programs and services designed to help every student have a fulfilling college experience.</p>
          <p>While we encourage you to explore all of our programs, you have an opportunity to express interest in one of our programs, the Educational Opportunity Program (EOP), right here in the application.</p>
        </div>
        <CounselingFormItem
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.california_app]"
        >
          <template slot="label">
            If you're interested in EOP, check the box below and answer the follow-up questions.
          </template>
          <el-checkbox
            true-label="Y"
            false-label="N"
            :value="counselingProfile.eop_selected"
            @input="(eop_selected) => setCounselingProfile({ eop_selected })"
          >
            Educational Opportunity Program (EOP)
          </el-checkbox>
          <template slot="belowPromptText">
            EOP and similar campus programs provide assistance through mentorship, academic programs, financial assistance, counseling/advising, and other campus support services to those who are first-generation college students, and/or from low-income and educationally disadvantaged backgrounds. EOP serves students from all ethnic backgrounds, and is open only to California residents and to American Indians from any state.
          </template>
        </CounselingFormItem>
        <CounselingFormItem
          v-if="counselingProfile.eop_selected === 'Y'"
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.california_app]"
        >
          <template slot="label">
            Why are you interested in EOP?
          </template>
          <el-input
            type="textarea"
            :rows="5"
            maxlength="240"
            show-word-limit
            :value="counselingProfile.eop_reason"
            @input="eop_reason => setCounselingProfile({ eop_reason })"
          />
        </CounselingFormItem>
      </el-form>
    </el-collapse-item>
    -->
  </el-collapse>
</template>

<script>
import { thirdAppTypeDict } from "@/class/ThirdApp";
import CounselingFormAlert from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/CounselingFormAlert";

export default {
  props: {
    currentApplicationType: {
      type: String,
      default: ""
    },
    activePanel: {
      type: String,
      default: ""
    }
  },
  components: {
    CounselingFormAlert
  },
  computed: {
    thirdAppTypes() {
      return thirdAppTypeDict;
    },
    counselingProfile() {
      return this.$store.getters["counselingProfile/counselingProfile"];
    }
  },
  data() {
    return {
      scholarships: [
        {
          label: "Academic major or interest",
          opened: false,
          items: [
            {
              value: 1,
              label: "Agribusiness economics"
            },
            {
              value: 2,
              label: "Agricultural economics"
            },
            {
              value: 3,
              label: "Agricultural engineering"
            },
            {
              value: 4,
              label: "Agricultural production and business"
            },
            {
              value: 5,
              label: "Agronomy"
            },
            {
              value: 6,
              label: "American Indian language or Chumash studies"
            },
            {
              value: 7,
              label: "American literature"
            },
            {
              value: 8,
              label: "Business major/future business major"
            },
            {
              value: 9,
              label: "Chemistry"
            },
            {
              value: 10,
              label: "Dairying"
            },
            {
              value: 11,
              label: "Dance"
            },
            {
              value: 12,
              label: "Dry land farming"
            },
            {
              value: 13,
              label: "Early childhood studies"
            },
            {
              value: 14,
              label: "Earth Science major"
            },
            {
              value: 15,
              label: "Education"
            },
            {
              value: 16,
              label: "Engineering"
            },
            {
              value: 17,
              label: "Environment and natural resources"
            },
            {
              value: 18,
              label: "Fine arts or liberal arts major, including librarianship, who does not smoke or drinks alcoholic beverages"
            },
            {
              value: 19,
              label: "History of the United States"
            },
            {
              value: 20,
              label: "Humanities discipline"
            },
            {
              value: 21,
              label: "Humanities, liberal arts or fine arts"
            },
            {
              value: 22,
              label: "Life sciences"
            },
            {
              value: 23,
              label: "Literature, English, creative writing or journalism"
            },
            {
              value: 24,
              label: "Major field of interest or major in fine art, ceramics or sculpture"
            },
            {
              value: 25,
              label: "Marine Science minor"
            },
            {
              value: 26,
              label: "Mathematics, biological or physical sciences"
            },
            {
              value: 27,
              label: "Mineral industry"
            },
            {
              value: 28,
              label: "Mining"
            },
            {
              value: 29,
              label: "Music"
            },
            {
              value: 30,
              label: "Music, with interest in Vocal performance"
            },
            {
              value: 31,
              label: "Physical science discipline"
            },
            {
              value: 32,
              label: "Physics"
            },
            {
              value: 33,
              label: "Plant science"
            },
            {
              value: 34,
              label: "Political science"
            },
            {
              value: 35,
              label: "Pomology"
            },
            {
              value: 36,
              label: "Portuguese"
            },
            {
              value: 37,
              label: "Pre-med"
            },
            {
              value: 38,
              label: "Second-, third- or fourth-year student in mathematics, business administration, personnel, insurance or areas related to the insurance industry"
            },
            {
              value: 39,
              label: "Social science major who neither smokes nor drinks"
            },
            {
              value: 40,
              label: "Social sciences discipline"
            },
            {
              value: 41,
              label: "Special education"
            },
            {
              value: 42,
              label: "Structural engineering emphasis and demonstrating desire for a career path in this field"
            },
            {
              value: 43,
              label: "Students that matriculated from a California High School or Community College and are pursuing a degree in the STEM disciplines—science, technology, engineering, or mathematics"
            },
            {
              value: 44,
              label: "Studying Middle Eastern and/or Iranian art history and humanities"
            },
            {
              value: 45,
              label: "Woman major in English literature, American literature or classic Latin literature"
            }
          ]
        },
        {
          label: "Affiliation with group, program or organization",
          opened: false,
          items: [
            {
              value: 46,
              label: "Adversely affected by a family member's military service (e.g. spouse or parent killed in military action)"
            },
            {
              value: 47,
              label: "Affiliation with Alpha Gamma Rho"
            },
            {
              value: 48,
              label: "Affiliation with Alpha Zeta"
            },
            {
              value: 49,
              label: "Attended USA Freestyle Martial Arts for at least two years and earned a minimum of a Red Belt"
            },
            {
              value: 50,
              label: "Has a grandparent, parent, aunt or uncle, or sibling who is a UCI Alumni, UCI retiree, or is a current UCI staff member"
            },
            {
              value: 51,
              label: "Involvement in organizations benefitting women"
            },
            {
              value: 52,
              label: "Involvement with the East Oakland Youth Development Center"
            },
            {
              value: 53,
              label: "Member of Hillel"
            },
            {
              value: 54,
              label: "Member of high school character-building youth group, especially Boys Club of America"
            },
            {
              value: 55,
              label: "Member of the First Church of Christ Scientist in Boston, MA"
            },
            {
              value: 56,
              label: "Member or former member of FFA or 4-H"
            },
            {
              value: 57,
              label: "Participated in Berkeley Youth Alternatives"
            },
            {
              value: 58,
              label: "Participated in College Bound Brotherhood"
            },
            {
              value: 59,
              label: "Participated in College Bound Hayward Senior Academy"
            },
            {
              value: 60,
              label: "Participated in College Bound Oceanside"
            },
            {
              value: 61,
              label: "Participated in College Bound San Diego"
            },
            {
              value: 62,
              label: "Participated in College Track Program"
            },
            {
              value: 63,
              label: "Participated in Early Academic Outreach Program (EAOP) in high school"
            },
            {
              value: 64,
              label: "Participated in First Star Bruin Guardian Scholars Academy"
            },
            {
              value: 65,
              label: "Participated in Guardian Scholars Program San Diego"
            },
            {
              value: 66,
              label: "Participated in Jack and Jill of America, Inc."
            },
            {
              value: 67,
              label: "Participated in Reality Changers College Apps Academy, San Diego"
            },
            {
              value: 68,
              label: "Participated in The Links, Inc."
            },
            {
              value: 69,
              label: "Participated in any Foster Youth college preparatory program"
            },
            {
              value: 70,
              label: "Participated in any SMASH program"
            },
            {
              value: 71,
              label: "Participated in the Oakland Promise Program while in high school"
            },
            {
              value: 72,
              label: "Participated in the Saturday Academy in Mathematics (SAM)"
            },
            {
              value: 73,
              label: "Participated in the UCSD UniversityLink program"
            },
            {
              value: 74,
              label: "Participated in the Young Black Scholars Program"
            },
            {
              value: 75,
              label: "Phi Alpha Phi Fraternity member"
            },
            {
              value: 76,
              label: "Phi Kappa Sigma fraternity member"
            },
            {
              value: 77,
              label: "Serving in a leadership position in Kappa Sigma Fraternity or in a Kappa Sigma Fraternity alumni organization"
            },
            {
              value: 78,
              label: "U.S. Armed Forces as enlisted or non-commissioned officer"
            },
            {
              value: 79,
              label: "Veteran of the Armed Forces of the United States"
            }
          ]
        },
        {
          label: "Ancestry and descendants",
          opened: false,
          items: [
            {
              value: 80,
              label: "Child of S.F. police officer or S.F. firefighter"
            },
            {
              value: 81,
              label: "Child of a UCSD employee"
            },
            {
              value: 82,
              label: "Child of a farmworker; not child of landowner"
            },
            {
              value: 83,
              label: "Child of a member or prior member of the Navy or Marine Corps"
            },
            {
              value: 84,
              label: "Child of a public servant in the federal government or in the service of the state of California (includes military and judiciary as well as civil service)"
            },
            {
              value: 85,
              label: "Child of an incarcerated or previously incarcerated parent"
            },
            {
              value: 86,
              label: "Child of employee of H. J. Heinz Company or an affiliated grower"
            },
            {
              value: 87,
              label: "Child or grandchild of an Antioch, California, classroom teacher"
            },
            {
              value: 88,
              label: "Child/grandchild of a graduate of University High School, Oakland"
            },
            {
              value: 89,
              label: "Daughter or other female dependent of California public school educators"
            },
            {
              value: 90,
              label: "Descendant of Alice Mara Tibbits, Elede Prince Morris or Rose Humann Rogers"
            },
            {
              value: 91,
              label: "Descendant of Arthur Arlett"
            },
            {
              value: 92,
              label: "Descendant of Capt. and Mrs. Wm. James Gray and/or Mr. and Mrs. Adolph Braese"
            },
            {
              value: 93,
              label: "Descendant of Charles Cole"
            },
            {
              value: 94,
              label: "Descendant of Rev. Robert T. Miller"
            },
            {
              value: 95,
              label: "Descendant of Theodore Grady Sr."
            },
            {
              value: 96,
              label: "Descendant of UC Davis alumni member of Omicron Triton Chapter of Phi Sigma Kappa, Zeta Xi or Capon Club"
            },
            {
              value: 97,
              label: "Descendant of Union veteran of Civil War"
            },
            {
              value: 98,
              label: "Descendant of Violet E. Wilson"
            },
            {
              value: 99,
              label: "Descendant of Walter Henry Magee and Dorothy Ethel Magee"
            },
            {
              value: 100,
              label: "Descendant of World War I veteran with four months service prior to November 11, 1918"
            },
            {
              value: 101,
              label: "Descendant of a UC Davis alumni member of the Agricultural Fraternity of Calpha"
            },
            {
              value: 102,
              label: "Descendant of a person subjected to the institution of slavery"
            },
            {
              value: 103,
              label: "Descendant of a person who attended school in Hong Kong"
            },
            {
              value: 104,
              label: "Descendant of employee of Warner Brothers"
            },
            {
              value: 105,
              label: "Descendant of honorably discharged WWII U.S. veteran and spouse"
            },
            {
              value: 106,
              label: "Descendant or spouse of current or retired UCLA Faculty or Staff"
            },
            {
              value: 107,
              label: "Descendants of Napa Seminary students"
            },
            {
              value: 108,
              label: "Direct descendant of member of Free Masons"
            },
            {
              value: 109,
              label: "Foster child"
            },
            {
              value: 110,
              label: "General Motors employee or spouse or offspring of an employee"
            },
            {
              value: 111,
              label: "Mother of minor children"
            },
            {
              value: 112,
              label: "Orphan or foster child before the age of 15"
            },
            {
              value: 113,
              label: "Parent is a member of UC faculty"
            },
            {
              value: 114,
              label: "Raised by a single mother"
            },
            {
              value: 115,
              label: "Raised by a single parent"
            },
            {
              value: 116,
              label: "Son of a widow"
            },
            {
              value: 117,
              label: "Son or daughter of a former Sigma Phi Epsilon or Sister of the Golden Heart"
            },
            {
              value: 118,
              label: "Student who has a relative directly or indirectly connected with the beauty industry"
            },
            {
              value: 119,
              label: "Student who has a relative that is an alumni/current staff/retiree from UC Irvine"
            },
            {
              value: 120,
              label: "Student who is Native American or Alaska Native descent"
            },
            {
              value: 121,
              label: "Student with parent employed by Frontier"
            }
          ]
        },
        {
          label: "Career plans",
          opened: false,
          items: [
            {
              value: 122,
              label: "Accounting"
            },
            {
              value: 123,
              label: "Agriculture"
            },
            {
              value: 124,
              label: "Banking/finance"
            },
            {
              value: 125,
              label: "Business"
            },
            {
              value: 126,
              label: "Career interests in teaching, research, or public service"
            },
            {
              value: 127,
              label: "Communications"
            },
            {
              value: 128,
              label: "Dentistry"
            },
            {
              value: 129,
              label: "Ecology"
            },
            {
              value: 130,
              label: "Economics"
            },
            {
              value: 131,
              label: "Education"
            },
            {
              value: 132,
              label: "Environment and natural resources"
            },
            {
              value: 133,
              label: "Environmental protection"
            },
            {
              value: 134,
              label: "Food processing and marketing"
            },
            {
              value: 135,
              label: "Health and human services"
            },
            {
              value: 136,
              label: "Health care industry, including research"
            },
            {
              value: 137,
              label: "Information technology"
            },
            {
              value: 138,
              label: "Interest in developing local food systems"
            },
            {
              value: 139,
              label: "Interest in food activism around social justice and/or issues of food equity"
            },
            {
              value: 140,
              label: "Interest in school nutrition programs or consumer food education"
            },
            {
              value: 141,
              label: "Interest in sustainable farming or artisanal food production"
            },
            {
              value: 142,
              label: "Interest in sustainable food system"
            },
            {
              value: 143,
              label: "Interest in teaching high school physics"
            },
            {
              value: 144,
              label: "Interest in wildlife habitat conservation and restoration"
            },
            {
              value: 145,
              label: "Interested in a career that combines Arts and STEM"
            },
            {
              value: 146,
              label: "Interested in a career working with companion or captive animals"
            },
            {
              value: 147,
              label: "Interested in a career working with rice production"
            },
            {
              value: 148,
              label: "Interested in civic leadership"
            },
            {
              value: 149,
              label: "Interested in global leadership"
            },
            {
              value: 150,
              label: "Interested in social justice"
            },
            {
              value: 151,
              label: "Interested in working to build communities and address poverty in California"
            },
            {
              value: 152,
              label: "Journalism"
            },
            {
              value: 153,
              label: "Law, humanities, and social welfare"
            },
            {
              value: 154,
              label: "Manuela and Randy Sosa: Financial need, 3.0 or higher GPA. Spanish fluency preferred. Commitment to work in inland empire"
            },
            {
              value: 155,
              label: "Marketing/merchandising"
            },
            {
              value: 156,
              label: "Medical science/nursing/veterinary science/health science"
            },
            {
              value: 157,
              label: "Medicine"
            },
            {
              value: 158,
              label: "Municipal or quasi-public employment in recreation"
            },
            {
              value: 159,
              label: "Music"
            },
            {
              value: 160,
              label: "Nursing"
            },
            {
              value: 161,
              label: "Optometry"
            },
            {
              value: 162,
              label: "Pharmacy"
            },
            {
              value: 163,
              label: "Physician"
            },
            {
              value: 164,
              label: "Physician in clinical practice"
            },
            {
              value: 165,
              label: "Planning to teach in California elementary or secondary school system"
            },
            {
              value: 166,
              label: "Public sector"
            },
            {
              value: 167,
              label: "Public service government"
            },
            {
              value: 168,
              label: "Pursue entrepreneurship upon graduation"
            },
            {
              value: 169,
              label: "School administration"
            },
            {
              value: 170,
              label: "Social services or health science"
            },
            {
              value: 171,
              label: "Student counseling"
            },
            {
              value: 172,
              label: "Teaching"
            },
            {
              value: 173,
              label: "Teaching English"
            },
            {
              value: 174,
              label: "Teaching at primary level"
            },
            {
              value: 175,
              label: "Visual arts"
            },
            {
              value: 176,
              label: "Woman from S.F. area planning a career in a humanitarian field (teaching, social service, etc.)"
            },
            {
              value: 177,
              label: "Working in the California food processing industry or an allied area"
            }
          ]
        },
        {
          label: "Ethnicity, national origin or religion",
          opened: false,
          items: [
            {
              value: 178,
              label: "Active in a campus Christian organization"
            },
            {
              value: 179,
              label: "Armenian descent"
            },
            {
              value: 180,
              label: "At least 50% Japanese descent"
            },
            {
              value: 181,
              label: "At least third-generation U.S. citizen"
            },
            {
              value: 182,
              label: "Brazilian or of Brazilian heritage"
            },
            {
              value: 183,
              label: "Caucasian, not of Polynesian blood and residing in Hawaii"
            },
            {
              value: 184,
              label: "Christian"
            },
            {
              value: 185,
              label: "Descendent of Italian immigrant"
            },
            {
              value: 186,
              label: "European descent"
            },
            {
              value: 187,
              label: "Female of English birth or descent"
            },
            {
              value: 188,
              label: "First generation citizens of the United States of America (including first generation naturalized citizens of the United States of America)"
            },
            {
              value: 189,
              label: "First- or second-generation Asian American"
            },
            {
              value: 190,
              label: "First- or second-generation immigrant from Latin America"
            },
            {
              value: 191,
              label: "First-generation American"
            },
            {
              value: 192,
              label: "First-generation European immigrant"
            },
            {
              value: 193,
              label: "From Bosnia or Herzegovina"
            },
            {
              value: 194,
              label: "From China or Hong Kong"
            },
            {
              value: 195,
              label: "From Sweden"
            },
            {
              value: 196,
              label: "Immigrant to U.S.A. who left country of origin under adverse circumstances"
            },
            {
              value: 197,
              label: "Immigrant to the United States"
            },
            {
              value: 198,
              label: "Iranian descent"
            },
            {
              value: 199,
              label: "Irish descent"
            },
            {
              value: 200,
              label: "Italian immigrant"
            },
            {
              value: 201,
              label: "Japanese descent"
            },
            {
              value: 202,
              label: "Jewish"
            },
            {
              value: 203,
              label: "Jewish orphan studying aeronautical engineering"
            },
            {
              value: 204,
              label: "Latvian heritage and pursuing a degree in a STEM field (except medical or computer science)"
            },
            {
              value: 205,
              label: "Member in the Greek Orthodox Church or in some recognized American/Hellenic fraternal organization"
            },
            {
              value: 206,
              label: "Mexican descent"
            },
            {
              value: 207,
              label: "Native American descent with tribal affiliation and active in their Native communities"
            },
            {
              value: 208,
              label: "Of Afghan descent"
            },
            {
              value: 209,
              label: "Originally from Colombia or other Latin American country"
            },
            {
              value: 210,
              label: "Portuguese"
            },
            {
              value: 211,
              label: "Portuguese descent"
            },
            {
              value: 212,
              label: "Portuguese immigrant, son of Portuguese immigrant or student from Portugal"
            },
            {
              value: 213,
              label: "Practicing Catholic"
            },
            {
              value: 214,
              label: "Protestant"
            },
            {
              value: 215,
              label: "Refugee"
            },
            {
              value: 216,
              label: "Romanian"
            },
            {
              value: 217,
              label: "Student born in Iran or with at least one parent born in Iran"
            },
            {
              value: 218,
              label: "Student who identifies as African American or Black and has been involved in an African American or Black community, organization, or program"
            },
            {
              value: 219,
              label: "Student who is New York born"
            },
            {
              value: 220,
              label: "Student who is Undocumented"
            },
            {
              value: 221,
              label: "Student who is a U.S. citizen of Mexican descent, bears a Spanish surname, and has average ability to speak and understand the Spanish language"
            },
            {
              value: 222,
              label: "Student's ancestors from Pon Yup, China"
            },
            {
              value: 223,
              label: "Students from Southeast Los Angeles"
            },
            {
              value: 224,
              label: "Students from developing countries as defined by the United Nations, who are pursuing a degree in science, mathematics or engineering and express an interest in returning to their country or origin"
            },
            {
              value: 225,
              label: "Students who plan to return to Mexico after completing their academics at UCLA"
            },
            {
              value: 226,
              label: "Students whose parents immigrated to the United States and come from middle income family"
            },
            {
              value: 227,
              label: "Taiwanese descent"
            }
          ]
        },
        {
          label: "Extracurricular activities & work experience",
          opened: false,
          items: [
            {
              value: 228,
              label: "Demonstrated commitment to create and foster an environment in which African/Black students excel, reflecting the mission of the African American Resource & Cultural Center."
            },
            {
              value: 229,
              label: "Extensive community service involvement or volunteer experience"
            },
            {
              value: 230,
              label: "Gay or lesbian student who has made a contribution to the gay and lesbian community"
            },
            {
              value: 231,
              label: "Interest in educating youth about infrastructure issues and needs to promote interest in the field of civil and environmental engineering"
            },
            {
              value: 232,
              label: "Interest in helping with the handicapped"
            },
            {
              value: 233,
              label: "Involved in independent study to address complex problems related to agricultural interests"
            },
            {
              value: 234,
              label: "Involvement in advancement of Asian American affairs"
            },
            {
              value: 235,
              label: "Involvement in athletics"
            },
            {
              value: 236,
              label: "Participates in extracurricular activities"
            },
            {
              value: 237,
              label: "Student has demonstrated academic emphasis in community education"
            },
            {
              value: 238,
              label: "Student who dropped out of school to work for some period of time"
            },
            {
              value: 239,
              label: "Student who has demonstrated academic excellence as well as leadership in extracurricular activities"
            },
            {
              value: 240,
              label: "Student who has demonstrated leadership in a campus organization"
            },
            {
              value: 241,
              label: "Student who has worked as a registered nurse"
            },
            {
              value: 242,
              label: "Student who is interested in gay rights"
            },
            {
              value: 243,
              label: "Student who is not now and does not plan to become a member of a social fraternity or sorority, other than an honor society"
            },
            {
              value: 244,
              label: "Students who plan to participate in an entrepreneurial group on campus"
            },
            {
              value: 245,
              label: "Will work while in college"
            },
            {
              value: 246,
              label: "Working part-time"
            }
          ]
        },
        {
          label: "Family circumstances and relationships",
          opened: false,
          items: [
            {
              value: 247,
              label: "Bilingual in Chinese and English"
            },
            {
              value: 248,
              label: "Death of parent from cancer, or cancer affecting immediate family member"
            },
            {
              value: 249,
              label: "Experienced mental illness or homelessness personally or through experiences of a family member"
            },
            {
              value: 250,
              label: "First family member to attend college"
            },
            {
              value: 251,
              label: "First-generation college student"
            },
            {
              value: 252,
              label: "Gay or lesbian student"
            },
            {
              value: 253,
              label: "Single parent"
            },
            {
              value: 254,
              label: "Student interested in pursuing a career in economics, accounting or business administration with immigration to the U.S. and first generation"
            },
            {
              value: 255,
              label: "Student is a parent with dependent children"
            },
            {
              value: 256,
              label: "Student is enrolled in a U.S. federally recognized tribe or nation"
            },
            {
              value: 257,
              label: "Student who has experienced any form of incarceration as a youth or an adult"
            },
            {
              value: 258,
              label: "Student whose first language is not English"
            },
            {
              value: 259,
              label: "Student whose parent was killed or disabled while engaged in law enforcement or firefighting activities"
            },
            {
              value: 260,
              label: "Students who are first to attend college and come from a middle income family"
            }
          ]
        },
        {
          label: "Health / disabilities",
          opened: false,
          items: [
            {
              value: 261,
              label: "Blind"
            },
            {
              value: 262,
              label: "Blind or serious visual impairment with a functional limitation in an academic arena"
            },
            {
              value: 263,
              label: "Diabetic"
            },
            {
              value: 264,
              label: "Diagnosed with cancer"
            },
            {
              value: 265,
              label: "Hard of hearing or adventitiously (noncongenitally) deaf (documentation required to establish eligibility)"
            },
            {
              value: 266,
              label: "Student diagnosed with a Learning Disability (LD) and/or Attention Deficit Hyperactivity Disorder (ADHD)"
            },
            {
              value: 267,
              label: "Student diagnosed with cancer, acquired immune deficiency syndrome (AIDS), AIDS-related condition (ARC) or student with physical disabilities"
            },
            {
              value: 268,
              label: "Student who has experienced a major illness such as cancer, heart disease, cystic fibrosis, etc. with first priority to cancer patient"
            },
            {
              value: 269,
              label: "Student who has experienced leukemia or cancer during adolescence"
            },
            {
              value: 270,
              label: "Student who persevered through difficult life circumstances to achieve educational goals"
            },
            {
              value: 271,
              label: "Student with physical handicap or disability"
            }
          ]
        },
        {
          label: "School or geographic affiliation",
          opened: false,
          items: [
            {
              value: 272,
              label: "A high school or community college graduate from Santa Cruz, Monterey, Santa Clara or San Benito who is/was disadvantaged due to their background or environment"
            },
            {
              value: 273,
              label: "Attended Lincoln Elementary School in Escondido, CA"
            },
            {
              value: 274,
              label: "Attended New Highland Academy, Brookfield Village or other elementary school in Oakland ZIP codes 94621 or 94603"
            },
            {
              value: 275,
              label: "Attended Secondary School in China"
            },
            {
              value: 276,
              label: "Attended Toussaint Academy of Arts and Sciences, San Diego, California"
            },
            {
              value: 277,
              label: "Attended school in Hong Kong"
            },
            {
              value: 278,
              label: "California born"
            },
            {
              value: 279,
              label: "California community college transfer"
            },
            {
              value: 280,
              label: "Calladay/Swaney-Graduate of Corona Senior High School or Laguna Beach High School"
            },
            {
              value: 281,
              label: "Disadvantaged minority student, preferably from Berkeley High School"
            },
            {
              value: 282,
              label: "From Sacramento or Yolo County and interested in a major in agricultural and environmental sciences"
            },
            {
              value: 283,
              label: "From a small town or the open country"
            },
            {
              value: 284,
              label: "Graduate from California High School, San Ramon Valley High School, Monte Vista High School, Dougherty Valley High School, or Venture school in the San Ramon Valley High School District in Danville"
            },
            {
              value: 285,
              label: "Graduate of a California high school in Butte, Nevada, Placer, Plumas, Sierra, Sutter or Yuba County"
            },
            {
              value: 286,
              label: "Graduate of a California high school in Calaveras, Fresno, Inyo, Kern, Kings, Madera, Mariposa, Merced, Mono, San Joaquin, Stanislaus, Tulare or Tuolumne County"
            },
            {
              value: 287,
              label: "Graduate of a high school in the greater San Diego area"
            },
            {
              value: 288,
              label: "Graduate of any Bakersfield high school"
            },
            {
              value: 289,
              label: "Grew up on a farm or child of farmers"
            },
            {
              value: 290,
              label: "KIPP High School matriculant"
            },
            {
              value: 291,
              label: "Participant in athletics at College of Marin or Marin County high school or prep school"
            },
            {
              value: 292,
              label: "Permanent resident of Santa Cruz County pursuing an academic program in health sciences"
            },
            {
              value: 293,
              label: "Played on basketball team at Butte or Yuba Community Colleges"
            },
            {
              value: 294,
              label: "Resident of Beijing, Shanghai, Hangzhou, Shenzhen or Guangzhou"
            },
            {
              value: 295,
              label: "Resident of the Niles area in the city of Fremont on date of high school graduation"
            },
            {
              value: 296,
              label: "Small-farm farmer or small-town mechanic"
            },
            {
              value: 297,
              label: "Student from Marin County"
            },
            {
              value: 298,
              label: "Student from Sacramento, El Dorado, or Nevada counties and who is a descendant of two generations of California parentage"
            },
            {
              value: 299,
              label: "Student transferring from a California community college that is located in Calaveras, Fresno, Inyo, Kern, Kings, Madera, Mariposa, Merced, Mono, San Joaquin, Stanislaus, Tulare or Tuolumne County"
            },
            {
              value: 300,
              label: "Student who has received a majority of his/her K-12 education in San Diego County and/or Imperial County (ideally born and raised in one of these two counties)"
            },
            {
              value: 301,
              label: "Student who has resided in Brisbane, California, while attending high school"
            },
            {
              value: 302,
              label: "Student who has resided within Alta-Dutch, Blue Canyon, Colfax, Emigrant Gap or Gold Run School District, located in Placer County"
            },
            {
              value: 303,
              label: "Students from Los Angeles County; preference Crossroads School in Santa Monica, California"
            },
            {
              value: 304,
              label: "Students from San Diego County;Encinitas (including Cardiff by the Sea, Olivenhain, and Leucadia), Carlsbad and Solana Beach"
            },
            {
              value: 305,
              label: "Upper Division student who is resident of San Diego or Imperial County and is studying communications or visual arts with a media emphasis"
            },
            {
              value: 306,
              label: "Woman resident of Los Angeles area for two years or more"
            }
          ]
        },
        {
          label: "Transfer students",
          opened: false,
          items: [
            {
              value: 307,
              label: "Ages 25-50 years, who have had at least a 5 year break in higher education"
            },
            {
              value: 308,
              label: "Community college transfer student who has overcome adverse socioeconomic conditions, demonstrated exceptional academic achievement and exhibited altruistic involvement in extracurricular activities that improve the lives of others"
            },
            {
              value: 309,
              label: "Mature person returning to college after absence of at least five years"
            },
            {
              value: 310,
              label: "Osher Foundation Scholarship for students transferring from a California Community College, who show academic promise and a commitment to obtaining a baccalaureate degree"
            },
            {
              value: 311,
              label: "Re-entry student"
            },
            {
              value: 312,
              label: "Woman planning to obtain a graduate degree"
            }
          ]
        }
      ]
    };
  },
  methods: {
    setCounselingProfile(data) {
      this.$store.commit("counselingProfile/setCounselingProfile", { ...data });
    },
    toggleOpenStatus(targetIndex) {
      this.scholarships = this.scholarships.map((scholarship,index) => {
        if (index === targetIndex) {
          return {
            ...scholarship,
            opened: !scholarship.opened
          };
        } else {
          return { ...scholarship };
        }
      });
      
    }
  }
};
</script>

<style scoped src="@/views/private/Counseling/ThirdApp/CounselingProfileForm/index.css"></style>
