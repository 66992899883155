<template>
  <el-select
    style="width: 100%"
    filterable
    :value="value"
    @change="changeValue"
    placeholder
  >
    <el-option
      v-for="item in data"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    />
  </el-select>
</template>

<script>
export default {
  name: "HighestLevelOfEducationsSelector",
  props: ["value"],
  computed: {
    data() {
      return [
        {
          value: "{\"value\":\"Some High School Or Less\",\"code\":\"HSNO\",\"label\":\"Some High School Or Less\"}",
          label: "Some High School Or Less"
        },
        {
          value: "{\"value\":\"High School Diploma/GED\",\"code\":\"HS\",\"label\":\"High School Diploma/GED\"}",
          label: "High School Diploma/GED"
        },
        {
          value: "{\"value\":\"Some College, No Degree Earned\",\"code\":\"SOME\",\"label\":\"Some College, No Degree Earned\"}",
          label: "Some College, No Degree Earned"
        },
        {
          value: "{\"value\":\"Two-year Associate's Degree\",\"code\":\"ASSOC\",\"label\":\"Two-year Associate's Degree\"}",
          label: "Two-year Associate's Degree"
        },
        {
          value: "{\"value\":\"Bachelor's Degree\",\"code\":\"BACH\",\"label\":\"Bachelor's Degree\"}",
          label: "Bachelor's Degree"
        },
        {
          value: "{\"value\":\"Master's Degree\",\"code\":\"MAS\",\"label\":\"Master's Degree\"}",
          label: "Master's Degree"
        },
        {
          value: "{\"value\":\"Professional Degree (MBA, MD, JD, etc.)\",\"code\":\"PRO\",\"label\":\"Professional Degree (MBA, MD, JD, etc.)\"}",
          label: "Professional Degree (MBA, MD, JD, etc.)"
        },
        {
          value: "{\"value\":\"Doctorate Degree or Higher\",\"code\":\"PHD\",\"label\":\"Doctorate Degree or Higher\"}",
          label: "Doctorate Degree or Higher"
        },
        {
          value: "{\"value\":\"Unknown\",\"code\":\"UNK\",\"label\":\"Unknown\"}",
          label: "Unknown"
        }
      ];
    }
  },
  methods: {
    changeValue(value) {
      this.$emit("change", value);
    }
  }
};
</script>
