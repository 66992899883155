<template>
  <el-select
    filterable
    multiple
    :value="value"
    @change="changeValue"
    :size="size"
    placeholder
  >
    <el-option
      v-for="item in data"
      :key="item.id"
      :label="item.title"
      :value="item.id"
    />
  </el-select>
</template>

<script>
export default {
  name: "Citizenship",
  props: {
    value: {
      type: Array,
      default: () => []
    },
    size: {
      type: String,
      default: ""
    }
  },
  computed: {
    data() {
      return this.$store.getters["counselingProfile/citizenshipOptions"];
    }
  },
  created() {
    this.$store.dispatch(
      "counselingProfile/fetchCitizenshipsOfCounselingProfile"
    );
  },
  methods: {
    changeValue(value) {
      this.$emit("change", value);
    }
  }
};
</script>
