<template>
  <div class="activityOrAwardContent">
    <div v-if="activitiesOrAward.category === 'Award'">
      <div class="activityOrAwardRow">
        <b>Name of the award or honor</b>
        <div>{{ activitiesOrAward.award_name }}</div>
      </div>
      <div class="activityOrAwardRow">
        <b>Level of Recognition</b>
        <div
          :key="is_award_level"
          v-for="is_award_level in activitiesOrAward.is_award_levels"
        >
          {{ getAwardLevelName(is_award_level) }}
        </div>
      </div>
      <div class="activityOrAwardRow">
        <b>Type of Award</b>
        <div v-if="activitiesOrAward.award_type === 'A'">Academic</div>
        <div v-if="activitiesOrAward.award_type === 'O'">Non-academic</div>
      </div>
      <div class="activityOrAwardRow">
        <b>When you did it</b>
        <div
          :key="award_grade"
          v-for="award_grade in activitiesOrAward.award_grades"
        >
          {{ getAwardGradeName(award_grade) }}
        </div>
      </div>
      <div class="activityOrAwardRow">
        <b>Requirements for the award</b>
        <div>{{ activitiesOrAward.award_requirements }}</div>
      </div>
      <div class="activityOrAwardRow">
        <b>What you did</b>
        <div>{{ activitiesOrAward.award_achievement }}</div>
      </div>
    </div>
    <div v-else-if="activitiesOrAward.category === 'EdPrep'">
      <div class="activityOrAwardRow">
        <b>Name of the program</b>
        <div>{{ getEducationalPrepName(activitiesOrAward) }}</div>
      </div>
      <div class="activityOrAwardRow">
        <b>Description</b>
        <div>{{ activitiesOrAward.ed_prep_description }}</div>
      </div>
      <div class="activityOrAwardRow">
        <b>When you did it</b>
        <div
          :key="ed_prep_grade"
          v-for="ed_prep_grade in activitiesOrAward.ed_prep_grades"
        >
          {{ getAwardGradeName(ed_prep_grade) }}
          <span v-if="ed_prep_grade === 5">
            {{ `for ${activitiesOrAward.ed_prep_years_after12 > 10 ? 'over 10' : activitiesOrAward.ed_prep_years_after12} year${activitiesOrAward.ed_prep_years_after12 > 1 ? 's' : ''} ${activitiesOrAward.ed_prep_months_after12} month${activitiesOrAward.ed_prep_months_after12 > 1 ? 's' : ''}` }}
          </span>
        </div>
      </div>
      <div class="activityOrAwardRow">
        <b>Time you spent</b>
        <div>{{ `${activitiesOrAward.ed_prep_hours_per_week} hours per week` }}</div>
        <div>{{ `${activitiesOrAward.ed_prep_weeks_per_year} weeks per year` }}</div>
      </div>
    </div>
    <div v-else-if="activitiesOrAward.category === 'ExtraCurr'">
      <div class="activityOrAwardRow">
        <b>Name of the activity</b>
        <div>{{ activitiesOrAward.extra_curr_name }}</div>
      </div>
      <div class="activityOrAwardRow">
        <b>What you did</b>
        <div>{{ activitiesOrAward.extra_curr_description }}</div>
      </div>
      <div class="activityOrAwardRow">
        <b>When you did it</b>
        <div
          :key="extra_curr_grade"
          v-for="extra_curr_grade in activitiesOrAward.extra_curr_grades"
        >
          {{ getAwardGradeName(extra_curr_grade) }}
          <span v-if="extra_curr_grade === 5">
            {{ `for ${activitiesOrAward.extra_curr_years_after12 > 10 ? 'over 10' : activitiesOrAward.extra_curr_years_after12} year${activitiesOrAward.extra_curr_years_after12 > 1 ? 's' : ''} ${activitiesOrAward.extra_curr_months_after12} month${activitiesOrAward.extra_curr_months_after12 > 1 ? 's' : ''}` }}
          </span>
        </div>
      </div>
      <div class="activityOrAwardRow">
        <b>Time you spent</b>
        <div>{{ `${activitiesOrAward.extra_curr_hours_per_week} hours per week` }}</div>
        <div>{{ `${activitiesOrAward.extra_curr_weeks_per_year} weeks per year` }}</div>
      </div>
    </div>
    <div v-else-if="activitiesOrAward.category === 'OtherCourse'">
      <div class="activityOrAwardRow">
        <b>Name of the course</b>
        <div>{{ activitiesOrAward.other_course_name }}</div>
      </div>
      <div class="activityOrAwardRow">
        <b>What you did</b>
        <div>{{ activitiesOrAward.other_course_description }}</div>
      </div>
      <div class="activityOrAwardRow">
        <b>When you did it</b>
        <div
          :key="other_course_grade"
          v-for="other_course_grade in activitiesOrAward.other_course_grades"
        >
          {{ getAwardGradeName(other_course_grade) }}
          <span v-if="other_course_grade === 5">
            {{ `for ${activitiesOrAward.other_course_years_after12 > 10 ? 'over 10' : activitiesOrAward.other_course_years_after12} year${activitiesOrAward.other_course_years_after12 > 1 ? 's' : ''} ${activitiesOrAward.other_course_months_after12} month${activitiesOrAward.other_course_months_after12 > 1 ? 's' : ''}` }}
          </span>
        </div>
      </div>
      <div class="activityOrAwardRow">
        <b>Time you spent</b>
        <div>{{ `${activitiesOrAward.other_course_hours_per_week} hours per week` }}</div>
        <div>{{ `${activitiesOrAward.other_course_weeks_per_year} weeks per year` }}</div>
      </div>
    </div>
    <div v-else-if="activitiesOrAward.category === 'Volunteer'">
      <div class="activityOrAwardRow">
        <b>Name of the organization</b>
        <div>{{ activitiesOrAward.volunteer_org }}</div>
      </div>
      <div class="activityOrAwardRow">
        <b>What is this organization</b>
        <div>{{ activitiesOrAward.volunteer_org_description }}</div>
      </div>
      <div class="activityOrAwardRow">
        <b>What you did</b>
        <div>{{ activitiesOrAward.volunteer_description }}</div>
      </div>
      <div class="activityOrAwardRow">
        <b>When you did it</b>
        <div
          :key="volunteer_grade"
          v-for="volunteer_grade in activitiesOrAward.volunteer_grades"
        >
          {{ getAwardGradeName(volunteer_grade) }}
          <span v-if="volunteer_grade === 5">
            {{ `for ${activitiesOrAward.volunteer_years_after12 > 10 ? 'over 10' : activitiesOrAward.volunteer_years_after12} year${activitiesOrAward.volunteer_years_after12 > 1 ? 's' : ''} ${activitiesOrAward.volunteer_months_after12} month${activitiesOrAward.volunteer_months_after12 > 1 ? 's' : ''}` }}
          </span>
        </div>
      </div>
      <div class="activityOrAwardRow">
        <b>Time you spent</b>
        <div>{{ `${activitiesOrAward.volunteer_hours_per_week} hours per week` }}</div>
        <div>{{ `${activitiesOrAward.volunteer_weeks_per_year} weeks per year` }}</div>
      </div>
    </div>
    <div v-else-if="activitiesOrAward.category === 'WorkExp'">
      <div class="activityOrAwardRow">
        <b>Where you worked</b>
        <div>{{ activitiesOrAward.work_exp_name }}</div>
      </div>
      <div class="activityOrAwardRow">
        <b>What is this organization</b>
        <div>{{ activitiesOrAward.work_exp_desc }}</div>
      </div>
      <div class="activityOrAwardRow">
        <b>What was your position</b>
        <div>{{ activitiesOrAward.work_exp_pos_title }}</div>
      </div>
      <div class="activityOrAwardRow">
        <b>What you did</b>
        <div>{{ activitiesOrAward.work_exp_pos_desc }}</div>
      </div>
      <div class="activityOrAwardRow">
        <b>When you did it and time spent</b>
        <div
          :key="work_exp_grade"
          v-for="(work_exp_grade) in activitiesOrAward.work_exp_grades"
        >
          <div>
            {{ getAwardGradeName(work_exp_grade) }}
            <span v-if="work_exp_grade === 5">
              {{ `for ${activitiesOrAward.work_exp_years_after12 > 10 ? 'over 10' : activitiesOrAward.work_exp_years_after12} year${activitiesOrAward.work_exp_years_after12 > 1 ? 's' : ''} ${activitiesOrAward.work_exp_months_after12} month${activitiesOrAward.work_exp_months_after12 > 1 ? 's' : ''}` }}
            </span>
          </div>
          <template v-if="work_exp_grade === 5">
            <div v-if="activitiesOrAward.is_work_exp_school_after12 === 'Y'">
              {{ `During school year ${activitiesOrAward.work_exp_after12_school_hours_per_week} hours per week` }}
            </div>
            <div v-if="activitiesOrAward.is_work_exp_summer_after12 === 'Y'">
              {{ `During summer ${activitiesOrAward.work_exp_after12_summer_hours_per_week} hours per week` }}
            </div>
          </template>
          <template v-else>
            <div v-if="activitiesOrAward[`is_work_exp_school${8 + work_exp_grade}`] === 'Y'">
              {{ `During school year ${activitiesOrAward[`work_exp${8 + work_exp_grade}_school_hours_per_week`]} hours per week` }}
            </div>
            <div v-if="activitiesOrAward[`is_work_exp_summer${8 + work_exp_grade}`] === 'Y'">
              {{ `During summer ${activitiesOrAward[`work_exp${8 + work_exp_grade}_summer_hours_per_week`]} hours per week` }}
            </div>
          </template>
        </div>
      </div>
      <div class="activityOrAwardRow">
        <b>Still working at this job</b>
        <div>{{ activitiesOrAward.is_work_exp_curr_working === "Y" ? "Yes" : "No" }}</div>
        <div>{{ `Start Date: : ${activitiesOrAward.work_exp_start}` }}</div>
        <div v-if="activitiesOrAward.is_work_exp_curr_working === 'N'">
          {{ `End Date: : ${activitiesOrAward.work_exp_end}` }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activitiesOrAward: {
      type: Object,
      default: () => ({})
    },
    educationalPrepPrograms: {
      type: Array,
      default: () => ([])
    }
  },
  computed: {
    awardLevels() {
      return this.$store.getters["counselingProfile/awardLevelOptions"];
    },
    awardGrades() {
      return this.$store.getters["counselingProfile/awardGradeOptions"];
    }
  },
  methods: {
    getEducationalPrepName(educationalPrep) {
      const OTHER = "18";
      if (educationalPrep.ed_prep_id === OTHER) {
        return educationalPrep.ed_prep_other_name;
      } else {
        return this.educationalPrepPrograms.find((educationalPrepProgram) => (
          educationalPrepProgram.value === educationalPrep.ed_prep_id
        )).label;
      }
    },
    getAwardLevelName(awardLevelValue) {
      return this.awardLevels.find((awardLevel) => awardLevel.value === awardLevelValue).label;
    },
    getAwardGradeName(awardGradeValue) {
      return this.awardGrades.find((awardGrade) => awardGrade.value === awardGradeValue).label;
    }
  }
};
</script>
<style scoped>
.activityOrAwardContent {
  margin-left: 44px;
}

.activityOrAwardRow {
  margin-top: 8px;
}
</style>