<template>
  <el-dialog
    :title="`ADD ${year}TH GRADE CLASSES`"
    :visible.sync="visible"
    :before-close="() => $emit('closeDialog')"
  >
    <el-form>
      <CounselingFormItem
        required
        :currentApplication="currentApplicationType"
        :applicationsNeedToFilling="[thirdAppTypes.california_app]"
      >
        <template slot="label">
          Subject Area/Course Category
        </template>
        <el-select style="width: 100%" v-model="subjectArea">
          <el-option-group
            v-for="subjectAreaGroup in subjectAreaGroups"
            :key="subjectAreaGroup.label"
            :label="subjectAreaGroup.label"
          >
            <el-option
              v-for="item in subjectAreaGroup.options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-option-group>
        </el-select>
      </CounselingFormItem>
      <CounselingFormItem
        required
        :currentApplication="currentApplicationType"
        :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
      >
        <template slot="label">
          Type of Class
        </template>
        <el-select style="width: 100%" v-model="type">
          <el-option
            v-for="classType in classTypes"
            :key="classType.value"
            :label="classType.label"
            :value="classType.value"
          />
        </el-select>
      </CounselingFormItem>
      <CounselingFormItem
        v-if="typeOfClassIsOther"
        required
        :currentApplication="currentApplicationType"
        :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
      >
        <template slot="label">
          Please specify the type of course.
        </template>
        <el-input v-model="otherType" />
      </CounselingFormItem>
      <CounselingFormItem
        required
        :currentApplication="currentApplicationType"
        :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
      >
        <template slot="label">
          Subject Area
        </template>
        <el-select style="width: 100%" :value="subject" @change="setSubject">
          <el-option
            v-for="subjectArea in subjectAreas"
            :key="subjectArea.value"
            :label="subjectArea.label"
            :value="subjectArea.value"
          />
        </el-select>
      </CounselingFormItem>
      <CounselingFormItem
        v-if="!subjectIsArtOrOther"
        required
        :currentApplication="currentApplicationType"
        :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
      >
        <template slot="label">
          Class
        </template>
        <el-select
          style="width: 100%"
          v-model="theClass"
        >
          <el-option
            v-for="theClass in classes"
            :key="theClass.value"
            :label="theClass.label"
            :value="theClass.value"
          />
        </el-select>
      </CounselingFormItem>
      <CounselingFormItem
        v-if="classOrSubjectIsOther || thirdAppTypes[currentApplicationType] === thirdAppTypes.california_app"
        required
        :currentApplication="currentApplicationType"
        :applicationsNeedToFilling="[thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
      >
        <template slot="label">
          Class Name on Transcript
        </template>
        <el-input v-model="transcriptName" />
      </CounselingFormItem>
      <CounselingFormAlert
        :currentApplication="currentApplicationType"
        :applicationsNeedToVisible="[thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
      >
        <template slot="title">
          <ul class="promptList">
            <li>{{ $t('counselingProfile.i18n7') }}</li>
            <li>{{ $t('counselingProfile.i18n8') }}</li>
          </ul>
        </template>
      </CounselingFormAlert>
      <CounselingFormItem
        required
        checkApplications
        :value="gradeScale"
        getterProp="gradingScaleOptions"
        :currentApplication="currentApplicationType"
        :applicationsNeedToFilling="[thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
      >
        <template slot="label">
          Grading Scale
        </template>
        <GradingScaleRadio
          :value="gradeScale"
          @input="setGradeScale"
        />
      </CounselingFormItem>
      <CounselingFormItem
        v-if="gradingScaleIsOther"
        required
        :currentApplication="currentApplicationType"
        :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
      >
        <template slot="label">
          Other Grading Scale
        </template>
        <el-input v-model="otherGradeScale" />
      </CounselingFormItem>
      <template v-if="gradingScaleIsNumbers">
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
        >
          <template slot="label">
            What is the lowest possible number grade at this school?
          </template>
          <el-input-number
            class="inputNumber"
            controls-position="right"
            :min="0"
            :max="numGradeMax"
            v-model="numGradeMin"
          />
        </CounselingFormItem>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
        >
          <template slot="label">
            What is the highest possible number grade at this school?
          </template>
          <el-input-number
            class="inputNumber"
            controls-position="right"
            :min="0"
            :max="200"
            v-model="numGradeMax"
          />
        </CounselingFormItem>
      </template>
      <CounselingFormItem
        required
        checkApplications
        :value="gradeFrequency"
        getterProp="classScheduledOptions"
        :currentApplication="currentApplicationType"
        :applicationsNeedToFilling="[thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
      >
        <template slot="label">
          How was this class scheduled?
        </template>
        <ClassesScheduledRadio
          :value="gradeFrequency"
          @input="setGradeFrequency"
        />
      </CounselingFormItem>
      <CounselingFormItem
        v-if="gradeFrequency && !classesScheduledIsFullYear"
        required
        :currentApplication="currentApplicationType"
        :applicationsNeedToFilling="[thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
      >
        <template slot="label">
          {{ `During which ${classScheduledLabel}(s) did you take this class?` }}
        </template>
        <div style="display: inline-block; width: 100%;">
          <el-checkbox-group :value="terms" @input="setTerms">
            <template
              v-if="
                classesScheduledIsSemesters ||
                  classesScheduledIsTrimesters ||
                  classesScheduledIsQuarters ||
                  classesScheduledIsQuinmesters
              "
            >
              <div>
                <el-checkbox :label="termOptions[0].value">
                  {{ termOptions[0].label }}
                </el-checkbox>
              </div>
              <div>
                <el-checkbox :label="termOptions[1].value">
                  {{ termOptions[1].label }}
                </el-checkbox>
              </div>
            </template>
            <div
              v-if="
                  classesScheduledIsTrimesters ||
                  classesScheduledIsQuarters ||
                  classesScheduledIsQuinmesters
              "
            >
              <el-checkbox :label="termOptions[2].value">
                {{ termOptions[2].label }}
              </el-checkbox>
            </div>
            <div
              v-if="classesScheduledIsQuarters || classesScheduledIsQuinmesters"
            >
              <el-checkbox :label="termOptions[3].value">
                {{ termOptions[3].label }}
              </el-checkbox>
            </div>
            <div v-if="classesScheduledIsQuinmesters">
              <el-checkbox :label="termOptions[4].value">
                {{ termOptions[4].label }}
              </el-checkbox>
            </div>
          </el-checkbox-group>
        </div>
      </CounselingFormItem>
      <CounselingFormAlert
        :currentApplication="currentApplicationType"
        :applicationsNeedToVisible="[thirdAppTypes.california_app]"
      >
        <template slot="title">
          {{ $t('counselingProfile.i18n6') }}
        </template>
      </CounselingFormAlert>
      <CounselingFormItem
        v-if="!schoolDoesNotProvideGrades"
        required
        :currentApplication="currentApplicationType"
        :applicationsNeedToFilling="[thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
      >
        <template slot="label">
          How is the grade for this class reported on your transcript? 
        </template>
        <el-radio-group style="width: 100%" :value="gradeCt" @input="setGradeCt">
          <div :key="gradeCt.value" v-for="gradeCt in gradeCts">
            <el-radio :label="gradeCt.value">
              {{ gradeCt.label }}
            </el-radio>
          </div>
        </el-radio-group>
      </CounselingFormItem>
      <template v-if="!schoolDoesNotProvideGrades && gradeCtsNeedGradeForPerTerm">
        <div :key="gradeIndex" v-for="(grade, gradeIndex) in grades">
          <CounselingFormItem
            v-if="hasTermGrade(gradeIndex)"
            required
            :checkApplications="gradeScaleIsLetter"
            :value="grades[gradeIndex]"
            getterProp="letterGradingOptions"
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              {{ `${word.toUpperFirstLetter(classScheduledLabel)} ${gradeIndex + 1} Grade` }}
            </template>
            <el-select
              style="width: 100%"
              :value="grades[gradeIndex]"
              @change="(newGrade) => setPerTermGrades(gradeIndex, newGrade)"
            >
              <el-option
                v-for="grade in gradeOptions"
                :key="grade.value"
                :label="grade.label"
                :value="grade.value"
              />
            </el-select>
          </CounselingFormItem>
          <CounselingFormItem
            v-if="hasTermGrade(gradeIndex) && termGradeIsNumber(gradeIndex)"
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              Number grade
            </template>
            <el-input-number
              class="inputNumber"
              controls-position="right"
              :min="numGradeMin"
              :max="numGradeMax"
              v-model="gradesNumber[gradeIndex]"
            />
          </CounselingFormItem>
          <CounselingFormItem
            v-if="hasTermGrade(gradeIndex) && termGradeIsOther(gradeIndex)"
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              Other grade
            </template>
            <el-input v-model="otherGrades[gradeIndex]" />
          </CounselingFormItem>
        </div>
      </template>
      <CounselingFormItem
        v-if="
          !schoolDoesNotProvideGrades && (
            classesScheduledIsFullYear|| gradeCtsNeedFinalGrade
          )
        "
        required
        :checkApplications="gradeScaleIsLetter"
        :value="finalGrade"
        getterProp="letterGradingOptions"
        :currentApplication="currentApplicationType"
        :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
      >
        <template slot="label">
          Final Grade
        </template>
        <el-select
          style="width: 100%"
          :value="finalGrade"
          @change="(newFinalGrade) => finalGrade = newFinalGrade"
        >
          <el-option
            v-for="grade in gradeOptions"
            :key="grade.value"
            :label="grade.label"
            :value="grade.value"
          />
        </el-select>
      </CounselingFormItem>
      <CounselingFormItem
        v-if="finalGradeIsNumber"
        required
        :currentApplication="currentApplicationType"
        :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
      >
        <template slot="label">
          Number grade
        </template>
        <el-input-number
          class="inputNumber"
          controls-position="right"
          :min="numGradeMin"
          :max="numGradeMax"
          v-model="finalGradeNumber"
        />
      </CounselingFormItem>
      <CounselingFormItem
        v-if="finalGradeIsOther"
        required
        :currentApplication="currentApplicationType"
        :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
      >
        <template slot="label">
          Other grade
        </template>
        <el-input v-model="otherFinalGrade" />
      </CounselingFormItem>
      <CounselingFormItem
        :currentApplication="currentApplicationType"
        :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
      >
        <el-checkbox v-model="blockSched">
          Addison High School used block scheduling for this class.
        </el-checkbox>
      </CounselingFormItem>
      <CounselingFormItem
        required
        :currentApplication="currentApplicationType"
        :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
      >
        <template slot="label">
          This class occurred during the
        </template>
        <el-radio-group style="width: 100%" v-model="session">
          <div><el-radio label="REG">Regular school year</el-radio></div>
          <div><el-radio label="SUM">Summer</el-radio></div>
        </el-radio-group>
      </CounselingFormItem>
      <CounselingFormItem
        required
        :currentApplication="currentApplicationType"
        :applicationsNeedToFilling="[thirdAppTypes.california_app]"
      >
        <template slot="label">
          Honors Status
        </template>
        <el-select style="width: 100%" v-model="honorStatusCode">
          <el-option label="Advanced Placement" value="AP" />
          <el-option label="UC-approved honors level" value="HL" />
          <el-option label="International Baccalaureate" value="IB" />
          <el-option label="Not honors" value="NH" />
        </el-select>
      </CounselingFormItem>
      <el-form-item required align="right">
        <el-button @click="() => $emit('closeDialog')">CANCEL</el-button>
        <el-button type="primary" @click="saveClassAndClose">
          SAVE CLASS & CLOSE
        </el-button>
        <el-button v-if="!isEditing" type="primary" @click="saveClassAndInitState">
          SAVE & ADD ANOTHER CLASS
        </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { word } from "@ivy-way/material";
import { thirdAppTypeDict } from "@/class/ThirdApp";
import CounselingFormAlert from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/CounselingFormAlert";
import CounselingFormItem from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/CounselingFormItem";
import GradingScaleRadio from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/EducationForm/GradingScaleRadio";
import ClassesScheduledRadio from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/EducationForm/ClassesScheduledRadio";

export default {
  name: "HighSchoolGradeCourseworkDialog",
  props: {
    currentApplicationType: {
      type: String,
      default: ""
    },
    visible: {
      type: Boolean,
      default: false
    },
    year: {
      type: String,
      default: ""
    },
    isEditing: {
      type: Boolean,
      default: false
    },
    editingCoursework: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    CounselingFormAlert,
    CounselingFormItem,
    GradingScaleRadio,
    ClassesScheduledRadio
  },
  computed: {
    word() {
      return word;
    },
    thirdAppTypes() {
      return thirdAppTypeDict;
    },
    classTypes() {
      return [
        { value: "{\"code\":\"REG\",\"value\":\"Regular\"}", label: "Regular"},
        { value: "{\"code\":\"AH\",\"value\":\"Advanced / Honors\"}", label: "Advanced / Honors"},
        { value: "{\"code\":\"AP\",\"value\":\"AP (Advanced Placement)\"}", label: "AP (Advanced Placement)"},
        { value: "{\"code\":\"AS-LVL\",\"value\":\"Cambridge Advanced (AS Level)\"}", label: "Cambridge Advanced (AS Level)"},
        { value: "{\"code\":\"A-LVL\",\"value\":\"Cambridge Advanced (A Level)\"}", label: "Cambridge Advanced (A Level)"},
        { value: "{\"code\":\"IGCSE\",\"value\":\"Cambridge Pre-Advanced (IGCSE)\"}", label: "Cambridge Pre-Advanced (IGCSE)"},
        { value: "{\"code\":\"IBHL\",\"value\":\"IB HL (Higher Level)\"}", label: "IB HL (Higher Level)"},
        { value: "{\"code\":\"IBSL\",\"value\":\"IB SL (Standard Level)\"}", label: "IB SL (Standard Level)"},
        { value: "{\"code\":\"REGENT\",\"value\":\"Regents\"}", label: "Regents"},
        { value: "{\"code\":\"RS\",\"value\":\"Running Start\"}", label: "Running Start"},
        { value: "{\"code\":\"OTHER\",\"value\":\"Other\"}", label: "Other"}
      ];
    },
    subjectAreas() {
      return [
        { value: "{\"code\":\"B\",\"value\":\"English\"}", label: "English"},
        { value: "{\"code\":\"A\",\"value\":\"History/Social Science\"}", label: "History/Social Science"},
        { value: "{\"code\":\"D\",\"value\":\"Laboratory Science\"}", label: "Laboratory Science"},
        { value: "{\"code\":\"E\",\"value\":\"Language Other Than English\"}", label: "Language Other Than English"},
        { value: "{\"code\":\"C\",\"value\":\"Math\"}", label: "Math"},
        { value: "{\"code\":\"F\",\"value\":\"Visual and Performing Arts\"}", label: "Visual and Performing Arts"},
        { value: "{\"code\":\"H\",\"value\":\"Other\"}", label: "Other"}
      ];
    },
    typeOfClassIsOther() {
      return this.type === "{\"code\":\"OTHER\",\"value\":\"Other\"}";
    },
    subjectIsArtOrOther() {
      return [
        "{\"code\":\"F\",\"value\":\"Visual and Performing Arts\"}",
        "{\"code\":\"H\",\"value\":\"Other\"}"
      ].includes(this.subject);
    },
    subjectAreaGroups() {
      return [
        {
          label: "History/Social Science",
          options: [
            {
              label: "Civics/American Government",
              value: "3"
            },
            {
              label: "U.S. History",
              value: "2"
            },
            {
              label: "World History/Cultures/Hist. Geography",
              value: "1"
            }
          ]
        },
        {
          label: "English",
          options: [
            {
              label: "English",
              value: "4"
            },
            {
              label: "English-ESL/ELD",
              value: "5"
            }
          ]
        },
        {
          label: "Mathematics",
          options: [
            {
              label: "Algebra I",
              value: "6"
            },
            {
              label: "Geometry",
              value: "7"
            },
            {
              label: "Algebra II",
              value: "8"
            },
            {
              label: "Mathematics I (Integrated)",
              value: "10"
            },
            {
              label: "Mathematics II (Integrated)",
              value: "11"
            },
            {
              label: "Mathematics III (Integrated)",
              value: "12"
            },
            {
              label: "Algebra II/Trigonometry",
              value: "9"
            },
            {
              label: "Statistics",
              value: "15"
            },
            {
              label: "Calculus",
              value: "14"
            },
            {
              label: "Advanced Mathematics",
              value: "13"
            },
            {
              label: "Computer Science",
              value: "16"
            }
          ]
        },
        {
          label: "Laboratory Science",
          options: [
            {
              label: "Biology/Life Sciences",
              value: "17"
            },
            {
              label: "Biology/Earth &amp; Space Sciences",
              value: "43"
            },
            {
              label: "Chemistry",
              value: "18"
            },
            {
              label: "Chemistry/Earth &amp; Space Sciences",
              value: "44"
            },
            {
              label: "Physics",
              value: "19"
            },
            {
              label: "Physics/Earth &amp; Space Sciences",
              value: "45"
            },
            {
              label: "Integrated Science 1",
              value: "46"
            },
            {
              label: "Integrated Science 2",
              value: "47"
            },
            {
              label: "Integrated Science 3",
              value: "48"
            },
            {
              label: "Applied Science",
              value: "49"
            },
            {
              label: "Computer Science",
              value: "50"
            },
            {
              label: "Earth and Space Sciences",
              value: "22"
            },
            {
              label: "Engineering",
              value: "51"
            },
            {
              label: "Interdisciplinary Sciences",
              value: "20"
            }
          ]
        },
        {
          label: "Language Other Than English",
          options: [
            {
              label: "Yr 1 Lang Other Than English",
              value: "23"
            },
            {
              label: "Yr 2 Lang Other Than English",
              value: "24"
            },
            {
              label: "Yr 3 Lang Other Than English",
              value: "25"
            },
            {
              label: "Yr 4+ Lang Other Than English",
              value: "26"
            }
          ]
        },
        {
          label: "Visual and Performing Arts",
          options: [
            {
              label: "Dance",
              value: "27"
            },
            {
              label: "Music",
              value: "28"
            },
            {
              label: "Theater",
              value: "29"
            },
            {
              label: "Visual Arts",
              value: "30"
            },
            {
              label: "Interdisciplinary Arts",
              value: "31"
            }
          ]
        },
        {
          label: "College-Prep Electives",
          options: [
            {
              label: "Elective -English",
              value: "33"
            },
            {
              label: "Elective -English - ESL/ELD",
              value: "34"
            },
            {
              label: "Elective -History/Social Science",
              value: "32"
            },
            {
              label: "Elective -Interdisciplinary",
              value: "41"
            },
            {
              label: "Elective -Language Other Than English ",
              value: "39"
            },
            {
              label: "Elective -Mathematics",
              value: "35"
            },
            {
              label: "Elective -Math -Computer Science",
              value: "42"
            },
            {
              label: "Elective -Science-Biology/Life Sciences",
              value: "36"
            },
            {
              label: "Elective -Science-Integrated Science",
              value: "38"
            },
            {
              label: "Elective -Science-Physical Sciences",
              value: "37"
            },
            {
              label: "Elective -Visual &amp; Performing Arts",
              value: "40"
            }
          ]
        }
      ];
    },
    classes() {
      switch (this.subject) {
        case "{\"code\":\"B\",\"value\":\"English\"}":
          return [
            {value: "{\"scedCode\":\"01155\",\"name\":\"Communications\"}", label: "Communications "},
            {value: "{\"scedCode\":\"01001\",\"name\":\"English/Language Arts I\"}", label: "English/Language Arts I"},
            {value: "{\"scedCode\":\"01002\",\"name\":\"English/Language Arts II\"}", label: "English/Language Arts II"},
            {value: "{\"scedCode\":\"01003\",\"name\":\"English/Language Arts III\"}", label: "English/Language Arts III"},
            {value: "{\"scedCode\":\"01004\",\"name\":\"English/Language Arts IV\"}", label: "English/Language Arts IV"},
            {value: "{\"scedCode\":\"01151\",\"name\":\"Public Speaking\"}", label: "Public Speaking "},
            {value: "{\"scedCode\":\"01999\",\"name\":\"English-Other\"}", label: "English-Other"}
          ];
        case "{\"code\":\"A\",\"value\":\"History/Social Science\"}":
          return [
            { value: "{\"scedCode\":\"04058\",\"name\":\"Ancient Civilizations\"}", label: "Ancient Civilizations "},
            { value: "{\"scedCode\":\"04060\",\"name\":\"Ancient and Medieval History\"}", label: "Ancient and Medieval History "},
            { value: "{\"scedCode\":\"04251\",\"name\":\"Anthropology\"}", label: "Anthropology "},
            { value: "{\"scedCode\":\"04164\",\"name\":\"Business Law\"}", label: "Business Law "},
            { value: "{\"scedCode\":\"04161\",\"name\":\"Civics\"}", label: "Civics "},
            { value: "{\"scedCode\":\"04154\",\"name\":\"Comparative Government\"}", label: "Comparative Government "},
            { value: "{\"scedCode\":\"04163\",\"name\":\"Consumer Law\"}", label: "Consumer Law "},
            { value: "{\"scedCode\":\"04201\",\"name\":\"Economics\"}", label: "Economics "},
            { value: "{\"scedCode\":\"04155\",\"name\":\"International Relations\"}", label: "International Relations "},
            { value: "{\"scedCode\":\"04162\",\"name\":\"Law Studies\"}", label: "Law Studies "},
            { value: "{\"scedCode\":\"04165\",\"name\":\"Legal System\"}", label: "Legal System "},
            { value: "{\"scedCode\":\"04209\",\"name\":\"Macroeconomics\"}", label: "Macroeconomics"},
            { value: "{\"scedCode\":\"04059\",\"name\":\"Medieval European History\"}", label: "Medieval European History "},
            { value: "{\"scedCode\":\"04208\",\"name\":\"Microeconomics\"}", label: "Microeconomics"},
            { value: "{\"scedCode\":\"04055\",\"name\":\"Modern European History\"}", label: "Modern European History "},
            { value: "{\"scedCode\":\"04306\",\"name\":\"Philosophy\"}", label: "Philosophy "},
            { value: "{\"scedCode\":\"04153\",\"name\":\"Political Science\"}", label: "Political Science "},
            { value: "{\"scedCode\":\"04254\",\"name\":\"Psychology\"}", label: "Psychology "},
            { value: "{\"scedCode\":\"04258\",\"name\":\"Sociology\"}", label: "Sociology "},
            { value: "{\"scedCode\":\"04105\",\"name\":\"State-Specific Studies\"}", label: "State-Specific Studies "},
            { value: "{\"scedCode\":\"04107\",\"name\":\"U.S. Ethnic Studies\"}", label: "U.S. Ethnic Studies"},
            { value: "{\"scedCode\":\"04108\",\"name\":\"U.S. Gender Studies\"}", label: "U.S. Gender Studies "},
            { value: "{\"scedCode\":\"04005\",\"name\":\"U.S. Geography\"}", label: "U.S. Geography"},
            { value: "{\"scedCode\":\"04151\",\"name\":\"U.S. Government\"}", label: "U.S. Government"},
            { value: "{\"scedCode\":\"04101\",\"name\":\"U.S. History\"}", label: "U.S. History"},
            { value: "{\"scedCode\":\"04156\",\"name\":\"United States and World Affairs\"}", label: "United States and World Affairs "},
            { value: "{\"scedCode\":\"04063\",\"name\":\"Western Civilization\"}", label: "Western Civilization "},
            { value: "{\"scedCode\":\"04001\",\"name\":\"World Geography\"}", label: "World Geography "},
            { value: "{\"scedCode\":\"04051\",\"name\":\"World History\"}", label: "World History"},
            { value: "{\"scedCode\":\"04999\",\"name\":\"History/Social Science-Other\"}", label: "History/Social Science-Other"}
          ];
        case "{\"code\":\"D\",\"value\":\"Laboratory Science\"}":
          return [
            { value: "{\"scedCode\":\"03209\",\"name\":\"Aerospace\"}", label: "Aerospace "},
            { value: "{\"scedCode\":\"03054\",\"name\":\"Anatomy\"}", label: "Anatomy "},
            { value: "{\"scedCode\":\"03004\",\"name\":\"Astronomy\"}", label: "Astronomy "},
            { value: "{\"scedCode\":\"03051\",\"name\":\"Biology\"}", label: "Biology "},
            { value: "{\"scedCode\":\"03058\",\"name\":\"Botany\"}", label: "Botany "},
            { value: "{\"scedCode\":\"03101\",\"name\":\"Chemistry\"}", label: "Chemistry "},
            { value: "{\"scedCode\":\"03001\",\"name\":\"Earth Science\"}", label: "Earth Science "},
            { value: "{\"scedCode\":\"03003\",\"name\":\"Environmental Science\"}", label: "Environmental Science "},
            { value: "{\"scedCode\":\"03059\",\"name\":\"Genetics\"}", label: "Genetics "},
            { value: "{\"scedCode\":\"03002\",\"name\":\"Geology\"}", label: "Geology "},
            { value: "{\"scedCode\":\"03201\",\"name\":\"Integrated Science\"}", label: "Integrated Science "},
            { value: "{\"scedCode\":\"03158\",\"name\":\"Life Science\"}", label: "Life Science"},
            { value: "{\"scedCode\":\"03005\",\"name\":\"Marine Science\"}", label: "Marine Science "},
            { value: "{\"scedCode\":\"03006\",\"name\":\"Meteorology\"}", label: "Meteorology "},
            { value: "{\"scedCode\":\"03060\",\"name\":\"Microbiology\"}", label: "Microbiology "},
            { value: "{\"scedCode\":\"03069\",\"name\":\"Nutrition Science\"}", label: "Nutrition Science"},
            { value: "{\"scedCode\":\"03103\",\"name\":\"Organic Chemistry\"}", label: "Organic Chemistry "},
            { value: "{\"scedCode\":\"03104\",\"name\":\"Physical Chemistry\"}", label: "Physical Chemistry "},
            { value: "{\"scedCode\":\"03007\",\"name\":\"Physical Geography\"}", label: "Physical Geography "},
            { value: "{\"scedCode\":\"03159\",\"name\":\"Physical Science\"}", label: "Physical Science "},
            { value: "{\"scedCode\":\"03151\",\"name\":\"Physics\"}", label: "Physics "},
            { value: "{\"scedCode\":\"03055\",\"name\":\"Physiology\"}", label: "Physiology "},
            { value: "{\"scedCode\":\"03061\",\"name\":\"Zoology\"}", label: "Zoology "},
            { value: "{\"scedCode\":\"03999\",\"name\":\"Laboratory Science-Other\"}", label: "Laboratory Science-Other"}
          ];
        case "{\"code\":\"E\",\"value\":\"Language Other Than English\"}":
          return [
            { value: "{\"scedCode\":\"24902\",\"name\":\"American Indian Language I\"}", label: "American Indian Language I "},
            { value: "{\"scedCode\":\"24903\",\"name\":\"American Indian Language II\"}", label: "American Indian Language II "},
            { value: "{\"scedCode\":\"24904\",\"name\":\"American Indian Language III\"}", label: "American Indian Language III "},
            { value: "{\"scedCode\":\"24905\",\"name\":\"American Indian Language IV\"}", label: "American Indian Language IV "},
            { value: "{\"scedCode\":\"24906\",\"name\":\"American Indian Language V\"}", label: "American Indian Language V "},
            { value: "{\"scedCode\":\"24910\",\"name\":\"American Indian Literature\"}", label: "American Indian Literature "},
            { value: "{\"scedCode\":\"24852\",\"name\":\"American Sign Language I\"}", label: "American Sign Language I "},
            { value: "{\"scedCode\":\"24853\",\"name\":\"American Sign Language II\"}", label: "American Sign Language II "},
            { value: "{\"scedCode\":\"24854\",\"name\":\"American Sign Language III\"}", label: "American Sign Language III "},
            { value: "{\"scedCode\":\"24855\",\"name\":\"American Sign Language IV\"}", label: "American Sign Language IV "},
            { value: "{\"scedCode\":\"24856\",\"name\":\"American Sign Language V\"}", label: "American Sign Language V "},
            { value: "{\"scedCode\":\"24752\",\"name\":\"Arabic I\"}", label: "Arabic I "},
            { value: "{\"scedCode\":\"24753\",\"name\":\"Arabic II\"}", label: "Arabic II "},
            { value: "{\"scedCode\":\"24754\",\"name\":\"Arabic III\"}", label: "Arabic III "},
            { value: "{\"scedCode\":\"24755\",\"name\":\"Arabic IV\"}", label: "Arabic IV "},
            { value: "{\"scedCode\":\"24756\",\"name\":\"Arabic V\"}", label: "Arabic V "},
            { value: "{\"scedCode\":\"24760\",\"name\":\"Arabic Literature\"}", label: "Arabic Literature "},
            { value: "{\"scedCode\":\"24041\",\"name\":\"Braille\"}", label: "Braille"},
            { value: "{\"scedCode\":\"24402\",\"name\":\"Chinese I\"}", label: "Chinese I "},
            { value: "{\"scedCode\":\"24403\",\"name\":\"Chinese II\"}", label: "Chinese II "},
            { value: "{\"scedCode\":\"24404\",\"name\":\"Chinese III\"}", label: "Chinese III "},
            { value: "{\"scedCode\":\"24405\",\"name\":\"Chinese IV\"}", label: "Chinese IV "},
            { value: "{\"scedCode\":\"24406\",\"name\":\"Chinese V\"}", label: "Chinese V "},
            { value: "{\"scedCode\":\"24410\",\"name\":\"Chinese Literature\"}", label: "Chinese Literature "},
            { value: "{\"scedCode\":\"24372\",\"name\":\"Classical Greek I\"}", label: "Classical Greek I "},
            { value: "{\"scedCode\":\"24373\",\"name\":\"Classical Greek II\"}", label: "Classical Greek II "},
            { value: "{\"scedCode\":\"24374\",\"name\":\"Classical Greek III\"}", label: "Classical Greek III "},
            { value: "{\"scedCode\":\"24375\",\"name\":\"Classical Greek IV\"}", label: "Classical Greek IV "},
            { value: "{\"scedCode\":\"24376\",\"name\":\"Classical Greek V\"}", label: "Classical Greek V "},
            { value: "{\"scedCode\":\"24391\",\"name\":\"Classical Hebrew I\"}", label: "Classical Hebrew I "},
            { value: "{\"scedCode\":\"24392\",\"name\":\"Classical Hebrew II\"}", label: "Classical Hebrew II "},
            { value: "{\"scedCode\":\"24393\",\"name\":\"Classical Hebrew III\"}", label: "Classical Hebrew III "},
            { value: "{\"scedCode\":\"24394\",\"name\":\"Classical Hebrew IV\"}", label: "Classical Hebrew IV "},
            { value: "{\"scedCode\":\"24395\",\"name\":\"Classical Hebrew V\"}", label: "Classical Hebrew V "},
            { value: "{\"scedCode\":\"24602\",\"name\":\"Filipino I\"}", label: "Filipino I "},
            { value: "{\"scedCode\":\"24603\",\"name\":\"Filipino II\"}", label: "Filipino II "},
            { value: "{\"scedCode\":\"24604\",\"name\":\"Filipino III\"}", label: "Filipino III "},
            { value: "{\"scedCode\":\"24605\",\"name\":\"Filipino IV\"}", label: "Filipino IV "},
            { value: "{\"scedCode\":\"24606\",\"name\":\"Filipino V\"}", label: "Filipino V "},
            { value: "{\"scedCode\":\"24610\",\"name\":\"Filipino Literature\"}", label: "Filipino Literature "},
            { value: "{\"scedCode\":\"24102\",\"name\":\"French I\"}", label: "French I "},
            { value: "{\"scedCode\":\"24103\",\"name\":\"French II\"}", label: "French II "},
            { value: "{\"scedCode\":\"24104\",\"name\":\"French III\"}", label: "French III "},
            { value: "{\"scedCode\":\"24105\",\"name\":\"French IV\"}", label: "French IV "},
            { value: "{\"scedCode\":\"24106\",\"name\":\"French V\"}", label: "French V "},
            { value: "{\"scedCode\":\"24110\",\"name\":\"French Literature\"}", label: "French Literature "},
            { value: "{\"scedCode\":\"24252\",\"name\":\"German I\"}", label: "German I "},
            { value: "{\"scedCode\":\"24253\",\"name\":\"German II\"}", label: "German II "},
            { value: "{\"scedCode\":\"24254\",\"name\":\"German III\"}", label: "German III "},
            { value: "{\"scedCode\":\"24255\",\"name\":\"German IV\"}", label: "German IV "},
            { value: "{\"scedCode\":\"24256\",\"name\":\"German V\"}", label: "German V "},
            { value: "{\"scedCode\":\"24260\",\"name\":\"German Literature\"}", label: "German Literature "},
            { value: "{\"scedCode\":\"24302\",\"name\":\"Greek I\"}", label: "Greek I "},
            { value: "{\"scedCode\":\"24303\",\"name\":\"Greek II\"}", label: "Greek II "},
            { value: "{\"scedCode\":\"24304\",\"name\":\"Greek III\"}", label: "Greek III "},
            { value: "{\"scedCode\":\"24305\",\"name\":\"Greek IV\"}", label: "Greek IV "},
            { value: "{\"scedCode\":\"24306\",\"name\":\"Greek V\"}", label: "Greek V "},
            { value: "{\"scedCode\":\"24310\",\"name\":\"Greek Literature\"}", label: "Greek Literature "},
            { value: "{\"scedCode\":\"24702\",\"name\":\"Hebrew I\"}", label: "Hebrew I "},
            { value: "{\"scedCode\":\"24703\",\"name\":\"Hebrew II\"}", label: "Hebrew II "},
            { value: "{\"scedCode\":\"24704\",\"name\":\"Hebrew III\"}", label: "Hebrew III "},
            { value: "{\"scedCode\":\"24705\",\"name\":\"Hebrew IV\"}", label: "Hebrew IV "},
            { value: "{\"scedCode\":\"24706\",\"name\":\"Hebrew V\"}", label: "Hebrew V "},
            { value: "{\"scedCode\":\"24710\",\"name\":\"Hebrew Literature\"}", label: "Hebrew Literature "},
            { value: "{\"scedCode\":\"24152\",\"name\":\"Italian I\"}", label: "Italian I "},
            { value: "{\"scedCode\":\"24153\",\"name\":\"Italian II\"}", label: "Italian II "},
            { value: "{\"scedCode\":\"24154\",\"name\":\"Italian III\"}", label: "Italian III "},
            { value: "{\"scedCode\":\"24155\",\"name\":\"Italian IV\"}", label: "Italian IV "},
            { value: "{\"scedCode\":\"24156\",\"name\":\"Italian V\"}", label: "Italian V "},
            { value: "{\"scedCode\":\"24160\",\"name\":\"Italian Literature\"}", label: "Italian Literature "},
            { value: "{\"scedCode\":\"24452\",\"name\":\"Japanese I\"}", label: "Japanese I "},
            { value: "{\"scedCode\":\"24453\",\"name\":\"Japanese II\"}", label: "Japanese II "},
            { value: "{\"scedCode\":\"24454\",\"name\":\"Japanese III\"}", label: "Japanese III "},
            { value: "{\"scedCode\":\"24455\",\"name\":\"Japanese IV\"}", label: "Japanese IV "},
            { value: "{\"scedCode\":\"24456\",\"name\":\"Japanese V\"}", label: "Japanese V "},
            { value: "{\"scedCode\":\"24460\",\"name\":\"Japanese Literature\"}", label: "Japanese Literature "},
            { value: "{\"scedCode\":\"24502\",\"name\":\"Korean I\"}", label: "Korean I "},
            { value: "{\"scedCode\":\"24503\",\"name\":\"Korean II\"}", label: "Korean II "},
            { value: "{\"scedCode\":\"24504\",\"name\":\"Korean III\"}", label: "Korean III "},
            { value: "{\"scedCode\":\"24505\",\"name\":\"Korean IV\"}", label: "Korean IV "},
            { value: "{\"scedCode\":\"24506\",\"name\":\"Korean V\"}", label: "Korean V "},
            { value: "{\"scedCode\":\"24510\",\"name\":\"Korean Literature\"}", label: "Korean Literature "},
            { value: "{\"scedCode\":\"24342\",\"name\":\"Latin I\"}", label: "Latin I "},
            { value: "{\"scedCode\":\"24343\",\"name\":\"Latin II\"}", label: "Latin II "},
            { value: "{\"scedCode\":\"24344\",\"name\":\"Latin III\"}", label: "Latin III "},
            { value: "{\"scedCode\":\"24345\",\"name\":\"Latin IV\"}", label: "Latin IV "},
            { value: "{\"scedCode\":\"24346\",\"name\":\"Latin V\"}", label: "Latin V "},
            { value: "{\"scedCode\":\"24202\",\"name\":\"Portuguese I\"}", label: "Portuguese I "},
            { value: "{\"scedCode\":\"24203\",\"name\":\"Portuguese II\"}", label: "Portuguese II "},
            { value: "{\"scedCode\":\"24204\",\"name\":\"Portuguese III\"}", label: "Portuguese III "},
            { value: "{\"scedCode\":\"24205\",\"name\":\"Portuguese IV\"}", label: "Portuguese IV "},
            { value: "{\"scedCode\":\"24206\",\"name\":\"Portuguese V\"}", label: "Portuguese V "},
            { value: "{\"scedCode\":\"24210\",\"name\":\"Portuguese Literature\"}", label: "Portuguese Literature "},
            { value: "{\"scedCode\":\"24652\",\"name\":\"Russian I\"}", label: "Russian I "},
            { value: "{\"scedCode\":\"24653\",\"name\":\"Russian II\"}", label: "Russian II "},
            { value: "{\"scedCode\":\"24654\",\"name\":\"Russian III\"}", label: "Russian III "},
            { value: "{\"scedCode\":\"24655\",\"name\":\"Russian IV\"}", label: "Russian IV "},
            { value: "{\"scedCode\":\"24656\",\"name\":\"Russian V\"}", label: "Russian V "},
            { value: "{\"scedCode\":\"24660\",\"name\":\"Russian Literature\"}", label: "Russian Literature "},
            { value: "{\"scedCode\":\"24052\",\"name\":\"Spanish I\"}", label: "Spanish I "},
            { value: "{\"scedCode\":\"24053\",\"name\":\"Spanish II\"}", label: "Spanish II "},
            { value: "{\"scedCode\":\"24054\",\"name\":\"Spanish III\"}", label: "Spanish III "},
            { value: "{\"scedCode\":\"24055\",\"name\":\"Spanish IV\"}", label: "Spanish IV "},
            { value: "{\"scedCode\":\"24056\",\"name\":\"Spanish V\"}", label: "Spanish V "},
            { value: "{\"scedCode\":\"24060\",\"name\":\"Spanish Literature\"}", label: "Spanish Literature "},
            { value: "{\"scedCode\":\"24802\",\"name\":\"Swahili I\"}", label: "Swahili I "},
            { value: "{\"scedCode\":\"24803\",\"name\":\"Swahili II\"}", label: "Swahili II "},
            { value: "{\"scedCode\":\"24804\",\"name\":\"Swahili III\"}", label: "Swahili III "},
            { value: "{\"scedCode\":\"24805\",\"name\":\"Swahili IV\"}", label: "Swahili IV "},
            { value: "{\"scedCode\":\"24806\",\"name\":\"Swahili V\"}", label: "Swahili V "},
            { value: "{\"scedCode\":\"24810\",\"name\":\"Swahili Literature\"}", label: "Swahili Literature "},
            { value: "{\"scedCode\":\"24552\",\"name\":\"Vietnamese I\"}", label: "Vietnamese I "},
            { value: "{\"scedCode\":\"24553\",\"name\":\"Vietnamese II\"}", label: "Vietnamese II "},
            { value: "{\"scedCode\":\"24554\",\"name\":\"Vietnamese III\"}", label: "Vietnamese III "},
            { value: "{\"scedCode\":\"24555\",\"name\":\"Vietnamese IV\"}", label: "Vietnamese IV "},
            { value: "{\"scedCode\":\"24556\",\"name\":\"Vietnamese V\"}", label: "Vietnamese V "},
            { value: "{\"scedCode\":\"24560\",\"name\":\"Vietnamese Literature\"}", label: "Vietnamese Literature "},
            { value: "{\"scedCode\":\"24999\",\"name\":\"Language Other Than English-Other\"}", label: "Language Other Than English-Other"}
          ];
        case "{\"code\":\"C\",\"value\":\"Math\"}":
          return [
            { value: "{\"scedCode\":\"02052\",\"name\":\"Algebra I\"}", label: "Algebra I"},
            { value: "{\"scedCode\":\"02056\",\"name\":\"Algebra II \"}", label: "Algebra II "},
            { value: "{\"scedCode\":\"02057\",\"name\":\"Algebra III \"}", label: "Algebra III "},
            { value: "{\"scedCode\":\"02302\",\"name\":\"Analysis and Approaches\"}", label: "Analysis and Approaches"},
            { value: "{\"scedCode\":\"02303\",\"name\":\"Applications and Interpretation\"}", label: "Applications and Interpretation"},
            { value: "{\"scedCode\":\"02121\",\"name\":\"Calculus \"}", label: "Calculus "},
            { value: "{\"scedCode\":\"02124\",\"name\":\"Calculus AB\"}", label: "Calculus AB"},
            { value: "{\"scedCode\":\"02125\",\"name\":\"Calculus BC\"}", label: "Calculus BC"},
            { value: "{\"scedCode\":\"02123\",\"name\":\"Differential Calculus \"}", label: "Differential Calculus "},
            { value: "{\"scedCode\":\"02102\",\"name\":\"Discrete Mathematics \"}", label: "Discrete Mathematics "},
            { value: "{\"scedCode\":\"02136\",\"name\":\"Finite Mathematics\"}", label: "Finite Mathematics"},
            { value: "{\"scedCode\":\"02072\",\"name\":\"Geometry \"}", label: "Geometry "},
            { value: "{\"scedCode\":\"02062\",\"name\":\"Integrated Mathematics I\"}", label: "Integrated Mathematics I"},
            { value: "{\"scedCode\":\"02063\",\"name\":\"Integrated Mathematics II\"}", label: "Integrated Mathematics II"},
            { value: "{\"scedCode\":\"02064\",\"name\":\"Integrated Mathematics III\"}", label: "Integrated Mathematics III"},
            { value: "{\"scedCode\":\"02065\",\"name\":\"Integrated Mathematics IV\"}", label: "Integrated Mathematics IV"},
            { value: "{\"scedCode\":\"02111\",\"name\":\"Linear Algebra \"}", label: "Linear Algebra "},
            { value: "{\"scedCode\":\"02122\",\"name\":\"Multivariate Calculus \"}", label: "Multivariate Calculus "},
            { value: "{\"scedCode\":\"02051\",\"name\":\"Pre-Algebra \"}", label: "Pre-Algebra "},
            { value: "{\"scedCode\":\"02110\",\"name\":\"Pre-Calculus \"}", label: "Pre-Calculus "},
            { value: "{\"scedCode\":\"02205\",\"name\":\"Statistics\"}", label: "Statistics"},
            { value: "{\"scedCode\":\"02103\",\"name\":\"Trigonometry \"}", label: "Trigonometry "},
            { value: "{\"scedCode\":\"02999\",\"name\":\"Math-Other\"}", label: "Math-Other"}
          ];
        default:
          return [];
      }
    },
    gradeScaleIsLetter() {
      const LETTER = 126739;
      return this.gradeScale === LETTER;
    },
    gradeOptions() {
      const [INT, LETTER, NUMBER, OTHER] = [126743, 126739, 126740, 126741];
      switch (this.gradeScale) {
        case INT:
          return [
            {
              label: "7",
              value: "007"
            },
            {
              label: "6",
              value: "006"
            },
            {
              label: "5",
              value: "005"
            },
            {
              label: "4",
              value: "004"
            },
            {
              label: "3",
              value: "003"
            },
            {
              label: "2",
              value: "002"
            },
            {
              label: "1",
              value: "001"
            }
          ];
        case LETTER:
          return this.$store.getters["counselingProfile/letterGradingOptions"];
        case NUMBER:
          return [
            {value: "{\"code\":\"NUM\",\"value\":\"Number grade\"}", label: "Number grade"},
            {value: "{\"code\":\"PASS\",\"value\":\"Pass/Fail - Pass\"}", label: "Pass/Fail - Pass"},
            {value: "{\"code\":\"FAIL\",\"value\":\"Pass/Fail - Fail\"}", label: "Pass/Fail - Fail"}
          ];
        case OTHER:
          return [
            {value: "{\"code\":\"PASS\",\"value\":\"Pass/Fail - Pass\"}", label: "Pass/Fail - Pass"},
            {value: "{\"code\":\"FAIL\",\"value\":\"Pass/Fail - Fail\"}", label: "Pass/Fail - Fail"},
            {value: "{\"code\":\"S\",\"value\":\"Satisfactory\"}", label: "Satisfactory"},
            {value: "{\"code\":\"U\",\"value\":\"Unsatisfactory\"}", label: "Unsatisfactory"},
            {value: "{\"code\":\"I\",\"value\":\"Incomplete\"}", label: "Incomplete"},
            {value: "{\"code\":\"W\",\"value\":\"Withdrawn\"}", label: "Withdrawn"},
            {value: "{\"code\":\"O\",\"value\":\"Other\"}", label: "Other"}
          ];
        default:
          return [];
      }
    },
    termOptions() {
      if (this.classesScheduledIsSemesters) {
        return [
          { value: "{\"code\":\"S1\",\"value\":\"First Semester\"}", label: "First Semester"},
          { value: "{\"code\":\"S2\",\"value\":\"Second Semester\"}", label: "Second Semester"}
        ];
      } else if (this.classesScheduledIsTrimesters) {
        return [
          { value: "{\"code\":\"T1\",\"value\":\"First Trimester\"}", label: "First Trimester"},
          { value: "{\"code\":\"T2\",\"value\":\"Second Trimester\"}", label: "Second Trimester"},
          { value: "{\"code\":\"T3\",\"value\":\"Third Trimester\"}", label: "Third Trimester"}
        ];
      } else if (this.classesScheduledIsQuarters) {
        return [
          { value: "{\"code\":\"Q1\",\"value\":\"First Quarter\"}", label: "First Quarter"},
          { value: "{\"code\":\"Q2\",\"value\":\"Second Quarter\"}", label: "Second Quarter"},
          { value: "{\"code\":\"Q3\",\"value\":\"Third Quarter\"}", label: "Third Quarter"},
          { value: "{\"code\":\"Q4\",\"value\":\"Fourth Quarter\"}", label: "Fourth Quarter"}
        ];
      } else if (this.classesScheduledIsQuinmesters) {
        return [
          { value: "{\"code\":\"51\",\"value\":\"First Quinmester\"}", label: "First Quinmester"},
          { value: "{\"code\":\"52\",\"value\":\"Second Quinmester\"}", label: "Second Quinmester"},
          { value: "{\"code\":\"53\",\"value\":\"Third Quinmester\"}", label: "Third Quinmester"},
          { value: "{\"code\":\"54\",\"value\":\"Fourth Quinmester\"}", label: "Fourth Quinmester"},
          { value: "{\"code\":\"55\",\"value\":\"Fifth Quinmester\"}", label: "Fifth Quinmester"}
        ];
      } else {
        return [];
      }
    },
    gradeCts() {
      return [
        { value: "{\"code\":\"ONE\",\"label\":\"One final grade\",\"value\":\"One final grade\"}", label: "One final grade"},
        { value: "{\"code\":\"TERM\",\"label\":\"One grade per term\",\"value\":\"One grade per term\"}", label: "One grade per term"},
        { value: "{\"code\":\"TERMPLUS\",\"label\":\"One grade per term and one final grade\",\"value\":\"One grade per term and one final grade\"}", label: "One grade per term and one final grade"}
      ];
    },
    gradingScaleIsOther() {
      return this.gradeScale === 126741;
    },
    gradingScaleIsNumbers() {
      return this.gradeScale === 126740;
    },
    schoolDoesNotProvideGrades() {
      return this.gradeScale === "{\"code\":\"NA\",\"label\":\"This school does not provide grades\",\"value\":\"This school does not provide grades\"}";
    },
    finalGradeIsNumber() {
      return this.finalGrade === "{\"code\":\"NUM\",\"value\":\"Number grade\"}";
    },
    finalGradeIsOther() {
      return this.finalGrade === "{\"code\":\"O\",\"value\":\"Other\"}";
    },
    classesScheduledIsFullYear() {
      return this.gradeFrequency === 126734;
    },
    classesScheduledIsSemesters() {
      return this.gradeFrequency === 126735;
    },
    classesScheduledIsTrimesters() {
      return this.gradeFrequency === 126736;
    },
    classesScheduledIsQuarters() {
      return this.gradeFrequency === 126737;
    },
    classesScheduledIsQuinmesters() {
      return this.gradeFrequency === 126738;
    },
    classOrSubjectIsOther() {
      return [
        "{\"scedCode\":\"01999\",\"name\":\"English-Other\"}",
        "{\"scedCode\":\"04999\",\"name\":\"History/Social Science-Other\"}",
        "{\"scedCode\":\"03999\",\"name\":\"Laboratory Science-Other\"}",
        "{\"scedCode\":\"24999\",\"name\":\"Language Other Than English-Other\"}",
        "{\"scedCode\":\"02999\",\"name\":\"Math-Other\"}"
      ].includes(this.theClass) || this.subjectIsArtOrOther;
    },
    gradeCtsNeedFinalGrade() {
      return [
        "{\"code\":\"ONE\",\"label\":\"One final grade\",\"value\":\"One final grade\"}",
        "{\"code\":\"TERMPLUS\",\"label\":\"One grade per term and one final grade\",\"value\":\"One grade per term and one final grade\"}"
      ].includes(this.gradeCt);
    },
    gradeCtsNeedGradeForPerTerm() {
      return [
        "{\"code\":\"TERM\",\"label\":\"One grade per term\",\"value\":\"One grade per term\"}",
        "{\"code\":\"TERMPLUS\",\"label\":\"One grade per term and one final grade\",\"value\":\"One grade per term and one final grade\"}"
      ].includes(this.gradeCt);
    },
    hasFirstTermGrade() {
      return this.terms.some(term => (
        [
          "{\"code\":\"S1\",\"value\":\"First Semester\"}",
          "{\"code\":\"T1\",\"value\":\"First Trimester\"}",
          "{\"code\":\"Q1\",\"value\":\"First Quarter\"}",
          "{\"code\":\"51\",\"value\":\"First Quinmester\"}"
        ].includes(term)
      ));
    },
    hasSecondTermGrade() {
      return this.terms.some(term => (
        [
          "{\"code\":\"S2\",\"value\":\"Second Semester\"}",
          "{\"code\":\"T2\",\"value\":\"Second Trimester\"}",
          "{\"code\":\"Q2\",\"value\":\"Second Quarter\"}",
          "{\"code\":\"52\",\"value\":\"Second Quinmester\"}"
        ].includes(term)
      ));
    },
    hasThirdTermGrade() {
      return this.terms.some(term => (
        [
          "{\"code\":\"T3\",\"value\":\"Third Trimester\"}",
          "{\"code\":\"Q3\",\"value\":\"Third Quarter\"}",
          "{\"code\":\"53\",\"value\":\"Third Quinmester\"}"
        ].includes(term)
      ));
    },
    hasFourthTermGrade() {
      return this.terms.some(term => (
        [
          "{\"code\":\"Q4\",\"value\":\"Fourth Quarter\"}",
          "{\"code\":\"54\",\"value\":\"Fourth Quinmester\"}"
        ].includes(term)
      ));
    },
    hasFivethTermGrade() {
      return this.terms.includes("{\"code\":\"55\",\"value\":\"Fifth Quinmester\"}");
    },
    classScheduledLabel() {
      if (this.gradeFrequency === "") {
        return "";
      } else if (this.classesScheduledIsSemesters) {
        return "semester";
      } else if (this.classesScheduledIsTrimesters) {
        return "trimester";
      } else if (this.classesScheduledIsQuarters) {
        return "quarter";
      } else if (this.classesScheduledIsQuinmesters) {
        return "quinmester";
      } else {
        return "";
      }
    }
  },
  data() {
    return {
      type: "",
      otherType: "",
      subjectArea: "",
      subject: "",
      theClass: "",
      transcriptName: "",
      gradeFrequency: "",
      gradeScale: "",
      otherGradeScale: "",
      finalGrade: "",
      finalGradeNumber: 0,
      otherFinalGrade: "",
      grades: [],
      gradesNumber: [],
      otherGrades: [],
      blockSched: false,
      session: "",
      numGradeMax: 0,
      numGradeMin: 0,
      gradeCt: "",
      terms: [],
      honorStatusCode: ""
    };
  },
  created() {
    this.$store.dispatch(
      "counselingProfile/fetchLettersGradingOfCounselingProfile"
    );
    if (this.isEditing) {
      this.fillEditingCourseworkToState();
    }
  },
  methods: {
    initState() {
      this.type = "";
      this.otherType = "";
      this.subjectArea = "";
      this.subject = "";
      this.theClass = "";
      this.transcriptName = "";
      this.gradeFrequency = "";
      this.gradeScale = "";
      this.otherGradeScale = "";
      this.finalGrade = "";
      this.finalGradeNumber = 0;
      this.otherFinalGrade = "";
      this.grades = [];
      this.gradesNumber = [];
      this.otherGrades = [];
      this.blockSched = false;
      this.session = "";
      this.numGradeMax = 0;
      this.numGradeMin = 0;
      this.gradeCt = "";
      this.terms = [];
      this.honorStatusCode = "";
    },
    setSubject(subject) {
      this.subject = subject;
      this.theClass = "";
    },
    setGradeCt(gradeCt) {
      this.gradeCt = gradeCt;
      this.finalGrade = "";
    },
    setGradeScale(gradeScale) {
      this.gradeScale = gradeScale;
      this.initTermGrades();
      this.finalGrade = "";
    },
    setTerms(terms) {
      this.terms = terms;
      this.setTermGradesByTerms();
    },
    setGradeFrequency(gradeFrequency) {
      this.gradeFrequency = gradeFrequency;
      this.terms = [];
    },
    initTermGrades() {
      if (0 < this.terms.length)  {
        this.grades = ["", "", "", "", ""];
        this.gradesNumber = [0, 0, 0, 0, 0];
        this.otherGrades = ["", "", "", "", ""];
      }
    },
    setTermGradesByTerms() {
      if (0 < this.terms.length && 0 === this.grades.length) {
        this.grades = ["", "", "", "", ""];
        this.gradesNumber = [0, 0, 0, 0, 0];
        this.otherGrades = ["", "", "", "", ""];
      }
    },
    hasTermGrade(gradeIndex) {
      switch (gradeIndex) {
        case 0:
          return this.hasFirstTermGrade;
        case 1:
          return this.hasSecondTermGrade;
        case 2:
          return this.hasThirdTermGrade;
        case 3:
          return this.hasFourthTermGrade;
        case 4:
          return this.hasFivethTermGrade;
        default:
          return false;
      }
    },
    termGradeIsNumber(gradeIndex) {
      return this.grades[gradeIndex] === "{\"code\":\"NUM\",\"value\":\"Number grade\"}";
    },
    termGradeIsOther(gradeIndex) {
      return this.grades[gradeIndex] === "{\"code\":\"O\",\"value\":\"Other\"}";
    },
    setPerTermGrades(targetGradeIndex, newGrade) {
      this.grades = this.grades.map((grade, gradeIndex) => {
        if (targetGradeIndex === gradeIndex) {
          return newGrade;
        } else {
          return grade;
        }
      });
    },
    fillEditingCourseworkToState() {
      this.type = this.editingCoursework.type;
      // this.otherType = this.editingCoursework.type;
      this.subjectArea = this.editingCoursework.subject_area;
      this.subject = this.editingCoursework.subject;
      this.theClass = this.editingCoursework.sced;
      this.transcriptName = this.editingCoursework.transcript_name;
      this.gradeFrequency = this.editingCoursework.grade_frequency;
      this.gradeScale = this.editingCoursework.grade_scale;
      this.otherGradeScale = this.editingCoursework.other_grade_scale;
      this.blockSched = this.editingCoursework.block_sched;
      this.session = this.editingCoursework.session;
      this.numGradeMax = this.editingCoursework.num_grade_max;
      this.numGradeMin = this.editingCoursework.num_grade_min;
      this.gradeCt = this.editingCoursework.grade_ct;
      this.terms = this.editingCoursework.terms;
      this.honorStatusCode = this.editingCoursework.honor_status_code;
      this.initTermGrades();
      // 先判斷有沒有 final grade
      const workingGrades = [...this.editingCoursework.grades];
      if (this.gradeCtsNeedFinalGrade) {
        const { grade, gradeNumber, otherGrade } = this.parseGradeToForm(workingGrades.shift());
        this.finalGrade = grade;
        this.finalGradeNumber = gradeNumber;
        this.otherFinalGrade = otherGrade;
      };

      this.grades = this.grades.map((grade, gradeIndex) => {
        let currentGrade = "";
        if (gradeIndex === 0 && this.hasFirstTermGrade) {
          currentGrade = workingGrades.shift();
        }
        if (gradeIndex === 1 && this.hasSecondTermGrade) {
          currentGrade = workingGrades.shift();
        }
        if (gradeIndex === 2 && this.hasThirdTermGrade) {
          currentGrade = workingGrades.shift();
        }
        if (gradeIndex === 3 && this.hasFourthTermGrade) {
          currentGrade = workingGrades.shift();
        }
        if (gradeIndex === 4 && this.hasFivethTermGrade) {
          currentGrade = workingGrades.shift();
        }
        if (currentGrade !== "") {
          const { grade, gradeNumber, otherGrade } = this.parseGradeToForm(currentGrade);
          this.gradesNumber[gradeIndex] = gradeNumber;
          this.otherGrades[gradeIndex] = otherGrade;
          return grade;
        } else {
          return currentGrade;
        }
      });
    },
    parseGradeToForm(grade) {
      const [INT, LETTER, NUMBER, OTHER] = [126743, 126739, 126740, 126741];
      switch (this.gradeScale) {
        case INT:
        case LETTER:
          return { grade, gradeNumber: 0, otherGrade: "" };
        case NUMBER:
          if (JSON.parse(grade).code === "NUM") {
            return {
              grade: "{\"code\":\"NUM\",\"value\":\"Number grade\"}",
              gradeNumber: JSON.parse(grade).value,
              otherGrade: ""
            };
          } else {
            return { grade, gradeNumber: 0, otherGrade: "" };
          }
        case OTHER:
          if (JSON.parse(grade).code === "O") {
            return {
              grade: "{\"code\":\"O\",\"value\":\"Other\"}",
              gradeNumber: 0,
              otherGrade: JSON.parse(grade).value
            };
          } else {
            this.finalGrade = grade;
          }
          break;
        default:
      }
    },
    gradeFormatter(grade, gradeNumber, otherGrade) {
      const [INT, LETTER, NUMBER, OTHER] = [126743, 126739, 126740, 126741];
      switch (this.gradeScale) {
        case INT:
        case LETTER:
          return grade;
        case NUMBER:
          if (grade === "{\"code\":\"NUM\",\"value\":\"Number grade\"}") {
            return `{"code": "NUM", "value": ${gradeNumber}}`;
          } else {
            return grade;
          }
        case OTHER:
          if (grade === "{\"code\":\"O\",\"value\":\"Other\"}") {
            return `{"code": "O", "value":"${otherGrade}"}`;
          } else {
            return grade;
          }
        default:
      }
    },
    getFinalGrade() {
      return this.gradeFormatter(
        this.finalGrade,
        this.finalGradeNumber,
        this.otherFinalGrade
      );
    },
    getTermGrade() {
      let grades = [];
      if (this.hasFirstTermGrade) {
        grades.push(this.gradeFormatter(this.grades[0], this.gradesNumber[0], this.otherGrades[0]));
      }
      if (this.hasSecondTermGrade) {
        grades.push(this.gradeFormatter(this.grades[1], this.gradesNumber[1], this.otherGrades[1]));
      }
      if (this.hasThirdTermGrade) {
        grades.push(this.gradeFormatter(this.grades[2], this.gradesNumber[2], this.otherGrades[2]));
      }
      if (this.hasFourthTermGrade) {
        grades.push(this.gradeFormatter(this.grades[3], this.gradesNumber[3], this.otherGrades[3]));
      }
      if (this.hasFivethTermGrade) {
        grades.push(this.gradeFormatter(this.grades[4], this.gradesNumber[4], this.otherGrades[4]));
      }
      return grades;
    },
    saveClass() {
      let grades = [];
      if (!this.schoolDoesNotProvideGrades) {
        if (this.classesScheduledIsFullYear) {
          grades.push(this.getFinalGrade());
        } else {
          switch (this.gradeCt) {
            case "{\"code\":\"ONE\",\"label\":\"One final grade\",\"value\":\"One final grade\"}":
              grades.push(this.getFinalGrade());
              break;
            case "{\"code\":\"TERM\",\"label\":\"One grade per term\",\"value\":\"One grade per term\"}":
              grades.push(...this.getTermGrade());
              break;
            case "{\"code\":\"TERMPLUS\",\"label\":\"One grade per term and one final grade\",\"value\":\"One grade per term and one final grade\"}":
              grades.push(this.getFinalGrade());
              grades.push(...this.getTermGrade());
              break;
          }
        }
      };

      const coursework = {
        year: `{"code":"${this.year}TH","value":"${this.year}th or earlier"}`,
        type: this.type,
        subject_area: this.subjectArea,
        subject: this.subject,
        sced: this.theClass,
        transcript_name: this.classOrSubjectIsOther ? this.transcriptName : "",
        grade_scale: this.gradeScale,
        other_grade_scale: this.gradingScaleIsOther ? this.otherGradeScale : "",
        grade_frequency: this.gradeFrequency,
        grade_ct: this.gradeCt,
        terms: this.terms,
        honor_status_code: this.honorStatusCode,
        grades,
        session: this.session,
        block_sched: this.blockSched,
        num_grade_max: this.numGradeMax,
        num_grade_min: this.numGradeMin
      };

      if (this.isEditing) {
        coursework.highSchoolIndex = this.editingCoursework.highSchoolIndex;
        coursework.targetYear = this.editingCoursework.targetYear;
        coursework.courseIndex = this.editingCoursework.courseIndex;
      }

      this.$emit("submitGradeCoursework", coursework);
    },
    saveClassAndClose() {
      this.saveClass();
      this.initState();
      this.$emit("closeDialog");
    },
    saveClassAndInitState() {
      this.saveClass();
      this.initState();
    }
  }
};
</script>

