<template>
  <el-select
    filterable
    :value="value"
    @change="changeValue"
    placeholder="Type in the school name to search for your high school."
    clearable
    remote
    :remote-method="fetchHighSchools"
  >
    <el-option
      v-for="item in data"
      :key="item.id"
      :label="item.title"
      :value="item.id"
    />
    <el-option label="Other" value="other" />
  </el-select>
</template>

<script>
export default {
  name: "HighSchool",
  props: ["value", "stateType", "filterState", "filterCountry"],
  computed: {
    data() {
      return this.$store.getters["counselingProfile/highSchoolOptions"];
    }
  },
  methods: {
    fetchHighSchools(searchText) {
      let filterState = "";
      let filterCountry = "";
      switch (this.stateType) {
        case "california":
          filterState = "CA";
          break;
        case "inTheUs":
          filterState = this.filterState;
          break;
        case "outsideTheUs":
          filterState = this.filterCountry;
          break;
        default:
      }
      this.$store.dispatch(
        "counselingProfile/fetchHighSchoolsOfCounselingProfile", {
          searchText,
          filterState,
          filterCountry
        }
      );
    },
    changeValue(value) {
      if (value === "other") {
        this.$emit("change", { id: "other" });
      } else {
        const highSchool = this.data.find(({ id }) => id === value);
        this.$emit("change", highSchool);
      }
    }
  }
};
</script>
