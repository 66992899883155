<template>
  <el-select
    style="width: 100%"
    filterable
    :value="value"
    @change="changeValue"
    placeholder
  >
    <el-option
      v-for="item in data"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    />
  </el-select>
</template>

<script>
export default {
  name: "AcademicYearSelector",
  props: ["value", "startDate", "endDate"],
  data() {
    return {
      data: []
    };
  },
  created() {
    const startYear = Number(this.startDate.split("/")[1]);
    const endYear = Number(this.endDate.split("/")[1]);
    let options = [];

    for (let i = startYear; i <= endYear; i += 1) {
      options.push({
        label: `${i}-${i + 1}`,
        value: `${String(i).slice(2)}-${String(i + 1).slice(2)}`
      });
    }
    this.data = [...options];
  },
  methods: {
    changeValue(value) {
      this.$emit("change", value);
    }
  }
};
</script>
