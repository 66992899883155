<template>
  <el-select
    style="width: 100%"
    filterable
    :value="value"
    @change="changeValue"
    placeholder
  >
    <el-option
      v-for="item in data"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    />
  </el-select>
</template>

<script>
export default {
  name: "IbScoreSelector",
  props: ["value"],
  computed: {
    data() {
      const options = [];
      const SCORE_START_POINT = 1;
      const MAX_SCORE = 7;
      for (let score = MAX_SCORE; score >= SCORE_START_POINT; score--) {
        options.push({ label: String(score), value: String(score) });
      }
      return options;
    }
  },
  methods: {
    changeValue(value) {
      this.$emit("change", value);
    }
  }
};
</script>
