<template>
  <el-collapse
    :value="activePanel"
    @change="(value) => {
      $emit('saveCounselingProfile');
      $emit('setActivePanel', value);
    }"
    accordion
  >
    <el-collapse-item title="Tests Taken" name="testsTaken">
      <el-form>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
        >
          <template slot="label">
            In addition to sending official score reports as required by colleges, do you wish to self-report scores or future test dates for any of the following standardized tests: ACT, SAT/SAT Subject, AP, IB, TOEFL, PTE Academic, and IELTS?
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.wish_to_report_tests"
            @input="wish_to_report_tests => setCounselingProfile({ wish_to_report_tests })"
          >
            <div>
              <el-radio :label="0">Yes</el-radio>
            </div>
            <div>
              <el-radio :label="1">No</el-radio>
            </div>
          </el-radio-group>
        </CounselingFormItem>
        <CounselingFormAlert
          :currentApplication="currentApplicationType"
          :applicationsNeedToVisible="[thirdAppTypes.california_app]"
        >
          <template slot="title">
            {{ $t('counselingProfile.i18n9') }}
          </template>
        </CounselingFormAlert>
        <CounselingFormItem
          v-if="wishReportTest"
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
        >
          <template slot="label">
            Indicate all tests you wish to report. Be sure to include tests you expect to take in addition to tests you have already taken.
          </template>
          <el-select
            style="width: 100%"
            multiple
            :value="counselingProfile.tests_taken"
            @change="tests_taken => setCounselingProfile({ tests_taken })"
          >
            <el-option
              v-for="testType in testTypes"
              :key="testType.value"
              :value="testType.value"
              :label="testType.label"
            />
          </el-select>
        </CounselingFormItem>
        <CounselingFormAlert
          :currentApplication="currentApplicationType"
          :applicationsNeedToVisible="[thirdAppTypes.california_app]"
        >
          <template slot="title">
            {{ $t('counselingProfile.i18n5') }}
            <ul class="promptList">
              <li
                :key="internationalExam"
                v-for="internationalExam in internationalExams"
              >
                {{ internationalExam }}
              </li>
            </ul>
          </template>
        </CounselingFormAlert>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            <b>International applicants:</b> Is promotion within your educational system based upon standard leaving examinations given at the end of lower and/or senior secondary school by a state or national leaving examinations board? (Students studying in the US typically answer no to this question.)
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.gave_leaving_exams"
            @input="gave_leaving_exams => setCounselingProfile({ gave_leaving_exams })"
          >
            <div>
              <el-radio :label="0">Yes</el-radio>
            </div>
            <div>
              <el-radio :label="1">No</el-radio>
            </div>
          </el-radio-group>
        </CounselingFormItem>
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
    <el-collapse-item
      v-if="testTypeAlreadyTaken('ACT Tests')"
      title="ACT Tests"
      name="actTests"
    >
      <el-form>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            Number of past ACT scores you wish to report
          </template>
          <el-select
            style="width: 100%"
            :value="counselingProfile.acts_number"
            @change="acts_number => setCounselingProfile({ acts_number })"
          >
            <el-option value="0" label="0" />
            <el-option
              v-for="reportNum in MAX_ACT_TEST_REPORT_COUNT"
              :key="reportNum"
              :value="reportNum"
              :label="reportNum"
            />
          </el-select>
        </CounselingFormItem>
        <CounselingFormItem
          v-if="reportActNumMoreThanZero"
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            Have you taken the ACT Plus Writing test?
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.took_act_plus_writing_test"
            @input="took_act_plus_writing_test => setCounselingProfile({ took_act_plus_writing_test })"
          >
            <div>
              <el-radio :label="0">Yes</el-radio>
            </div>
            <div>
              <el-radio :label="1">No</el-radio>
            </div>
          </el-radio-group>
        </CounselingFormItem>
        <CounselingFormAlert
          :currentApplication="currentApplicationType"
          :applicationsNeedToVisible="[thirdAppTypes.coalition_app]"
        >
          <template slot="title">
            {{ $t('counselingProfile.coalitionAppJustCanFillOneDateOfSatAndAct') }}
          </template>
        </CounselingFormAlert>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            Number of future ACT sittings you expect
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.future_act_sitting_months_length"
            @input="future_act_sitting_months_length => setCounselingProfile({ future_act_sitting_months_length })"
          >
            <el-radio :label="0">0</el-radio>
            <div
              :key="futureActSittingNum"
              v-for="futureActSittingNum in MAX_FUTURE_ACT_SITTING_COUNT"
            >
              <el-radio :label="futureActSittingNum">{{
                futureActSittingNum
              }}</el-radio>
            </div>
          </el-radio-group>
        </CounselingFormItem>
        <div
          :key="future_act_sitting_months_number"
          v-for="(future_act_sitting_months_number, index) in counselingProfile.future_act_sitting_months_length"
        >
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
          >
            <template slot="label">
              {{ `Future testing date ${future_act_sitting_months_number}` }}
            </template>
            <el-date-picker
              style="width: 100%"
              value-format="MM/yyyy"
              type="date"
              :value="counselingProfile.future_act_sitting_months[index]"
              @input="
                future_act_sitting_month =>
                  setFutureActSittingMonth(index, future_act_sitting_month)
              "
            />
          </CounselingFormItem>
        </div>
        <div v-if="reportActNumMoreThanOne">
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
          >
            <template slot="promptText">
              Provide the Superscore calculated by ACT from your score report; do not calculate your own Superscore.
            </template>
            <template slot="label">
              Superscore Composite
            </template>
            <ActScoreSelector
              :value="counselingProfile.acts_super_score"
              @change="
                acts_super_score =>
                  setCounselingProfile({ acts_super_score })
              "
            />
          </CounselingFormItem>
        </div>
        <div v-if="reportActNumMoreThanZero">
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              Highest composite score
            </template>
            <ActScoreSelector
              :value="counselingProfile.acts_highest_composite_score"
              @change="
                acts_highest_composite_score =>
                  setCounselingProfile({ acts_highest_composite_score })
              "
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.california_app]"
          >
            <template slot="promptText">
              The ACT ID is an eight-digit number beginning with a dash that you receive when you register for the ACT. You can also find it on your score report.
            </template>
            <template slot="label">
              ACT ID number 
            </template>
            <el-input-number
              class="inputNumber"
              controls-position="right"
              :max="99999999"
              :value="counselingProfile.act_id_number"
              @input="
                act_id_number => setCounselingProfile({ act_id_number })
              "
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              Composite date
            </template>
            <el-date-picker
              style="width: 100%"
              value-format="dd/MM/yyyy"
              type="date"
              :value="counselingProfile.acts_composite_date"
              @input="
                acts_composite_date =>
                  setCounselingProfile({ acts_composite_date })
              "
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              Highest english score
            </template>
            <ActScoreSelector
              :value="counselingProfile.acts_highest_english_score"
              @change="
                acts_highest_english_score =>
                  setCounselingProfile({ acts_highest_english_score })
              "
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
          >
            <template slot="label">
              English date
            </template>
            <el-date-picker
              style="width: 100%"
              value-format="dd/MM/yyyy"
              type="date"
              :value="counselingProfile.acts_english_date"
              @input="
                acts_english_date =>
                  setCounselingProfile({ acts_english_date })
              "
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              Highest math score
            </template>
            <ActScoreSelector
              :value="counselingProfile.acts_highest_math_score"
              @change="
                acts_highest_math_score =>
                  setCounselingProfile({ acts_highest_math_score })
              "
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
          >
            <template slot="label">
              Math date
            </template>
            <el-date-picker
              style="width: 100%"
              value-format="dd/MM/yyyy"
              type="date"
              :value="counselingProfile.acts_math_date"
              @input="
                acts_math_date =>
                  setCounselingProfile({ acts_math_date })
              "
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              Highest reading score
            </template>
            <ActScoreSelector
              :value="counselingProfile.acts_highest_reading_score"
              @change="
                acts_highest_reading_score =>
                  setCounselingProfile({ acts_highest_reading_score })
              "
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
          >
            <template slot="label">
              Reading date
            </template>
            <el-date-picker
              style="width: 100%"
              value-format="dd/MM/yyyy"
              type="date"
              :value="counselingProfile.acts_reading_date"
              @input="
                acts_reading_date =>
                  setCounselingProfile({ acts_reading_date })
              "
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              Highest science score
            </template>
            <ActScoreSelector
              :value="counselingProfile.acts_highest_science_score"
              @change="
                acts_highest_science_score =>
                  setCounselingProfile({ acts_highest_science_score })
              "
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
          >
            <template slot="label">
              Science date
            </template>
            <el-date-picker
              style="width: 100%"
              value-format="dd/MM/yyyy"
              type="date"
              :value="counselingProfile.acts_science_date"
              @input="
                acts_science_date =>
                  setCounselingProfile({ acts_science_date })
              "
            />
          </CounselingFormItem>
          <div v-if="plusActWritingScore">
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
            >
              <template slot="label">
                Highest writing score
              </template>
              <ActScoreSelector
                :value="counselingProfile.acts_highest_writing_score"
                @change="
                  acts_highest_writing_score =>
                    setCounselingProfile({ acts_highest_writing_score })
                "
              />
            </CounselingFormItem>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
            >
              <template slot="label">
                Writing date
              </template>
              <el-date-picker
                style="width: 100%"
                value-format="dd/MM/yyyy"
                type="date"
                :value="counselingProfile.acts_writing_date"
                @input="
                  acts_writing_date =>
                    setCounselingProfile({ acts_writing_date })
                "
              />
            </CounselingFormItem>
          </div>
        </div>
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
    <el-collapse-item
      v-if="testTypeAlreadyTaken('SAT')"
      title="SAT"
      name="sat"
    >
      <el-form>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            Number of past SAT scores you wish to report
          </template>
          <el-select
            style="width: 100%"
            :value="counselingProfile.sats_after_march_number"
            @change="sats_after_march_number => setCounselingProfile({ sats_after_march_number })"
          >
            <el-option value="0" label="0" />
            <el-option
              v-for="reportNum in MAX_SAT_AFTER_MARCH_2016_TEST_REPORT_COUNT"
              :key="reportNum"
              :value="reportNum"
              :label="reportNum"
            />
          </el-select>
        </CounselingFormItem>
        <CounselingFormItem
          v-if="reportSatAfterMarch2016NumMoreThanZero"
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            Have you taken the SAT essay?
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.took_sat_essay"
            @input="took_sat_essay => setCounselingProfile({ took_sat_essay })"
          >
            <div>
              <el-radio :label="0">Yes</el-radio>
            </div>
            <div>
              <el-radio :label="1">No</el-radio>
            </div>
          </el-radio-group>
        </CounselingFormItem>
        <CounselingFormAlert
          :currentApplication="currentApplicationType"
          :applicationsNeedToVisible="[thirdAppTypes.coalition_app]"
        >
          <template slot="title">
            {{ $t('counselingProfile.coalitionAppJustCanFillOneDateOfSatAndAct') }}
          </template>
        </CounselingFormAlert>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            Number of future SAT sittings you expect
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.future_sat_sitting_months_length"
            @input="future_sat_sitting_months_length => setCounselingProfile({ future_sat_sitting_months_length })"
          >
            <el-radio :label="0">0</el-radio>
            <div
              :key="futureSatSittingNum"
              v-for="futureSatSittingNum in MAX_FUTURE_SAT_SITTING_COUNT"
            >
              <el-radio :label="futureSatSittingNum">{{
                futureSatSittingNum
              }}</el-radio>
            </div>
          </el-radio-group>
        </CounselingFormItem>
        <div
          :key="future_sat_sitting_months_number"
          v-for="(future_sat_sitting_months_number, index) in counselingProfile.future_sat_sitting_months_length"
        >
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
          >
            <template slot="label">
              {{ `Future testing date ${future_sat_sitting_months_number}` }}
            </template>
            <el-date-picker
              style="width: 100%"
              value-format="MM/yyyy"
              type="date"
              :value="counselingProfile.future_sat_sitting_months[index]"
              @input="
                future_sat_sitting_month =>
                  setFutureSatSittingMonth(index, future_sat_sitting_month)
              "
            />
          </CounselingFormItem>
        </div>
        <div v-if="reportSatAfterMarch2016NumMoreThanZero">
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
          >
            <template slot="label">
              Highest total score
            </template>
            <SatTotalScoreSelector
              :value="counselingProfile.sats_after_march_highest_total_score"
              @change="
                sats_after_march_highest_total_score =>
                  setCounselingProfile({ sats_after_march_highest_total_score })
              "
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              When did you take the SAT
            </template>
            <el-date-picker
              style="width: 100%"
              value-format="dd/MM/yyyy"
              type="date"
              :picker-options="satAfterMarch2016pickerOptions"
              :value="counselingProfile.sats_after_march_total_date"
              @input="
                sats_after_march_total_date =>
                  setCounselingProfile({ sats_after_march_total_date })
              "
            />
          </CounselingFormItem>
          <h4>Section scores (200-800)</h4>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              Highest evidence-based reading and writing score
            </template>
            <SatScoreSelector
              :value="counselingProfile.sats_after_march_highest_reading_and_writing_score"
              @change="
                sats_after_march_highest_reading_and_writing_score =>
                  setCounselingProfile({ sats_after_march_highest_reading_and_writing_score })
              "
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
          >
            <template slot="label">
              Evidence-based reading and writing date
            </template>
            <el-date-picker
              style="width: 100%"
              value-format="dd/MM/yyyy"
              type="date"
              :picker-options="satAfterMarch2016pickerOptions"
              :value="counselingProfile.sats_after_march_reading_and_writing_date"
              @input="
                sats_after_march_reading_and_writing_date =>
                  setCounselingProfile({ sats_after_march_reading_and_writing_date })
              "
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              Highest math score
            </template>
            <SatScoreSelector
              :value="counselingProfile.sats_after_march_highest_math_score"
              @change="
                sats_after_march_highest_math_score =>
                  setCounselingProfile({ sats_after_march_highest_math_score })
              "
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
          >
            <template slot="label">
              Math date
            </template>
            <el-date-picker
              style="width: 100%"
              value-format="dd/MM/yyyy"
              type="date"
              :picker-options="satAfterMarch2016pickerOptions"
              :value="counselingProfile.sats_after_march_math_date"
              @input="
                sats_after_march_math_date =>
                  setCounselingProfile({ sats_after_march_math_date })
              "
            />
          </CounselingFormItem>
          <h4 v-if="[thirdAppTypes.california_app].includes(thirdAppTypes[currentApplicationType])">
            Test scores
          </h4>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.california_app]"
          >
            <template slot="label">
              Reading score
            </template>
            <SatReadingWritingLanguageScoreSelector
              :value="counselingProfile.sats_after_march_highest_sub_reading_score"
              @change="
                sats_after_march_highest_sub_reading_score =>
                  setCounselingProfile({ sats_after_march_highest_sub_reading_score })
              "
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.california_app]"
          >
            <template slot="label">
              Writing & Language
            </template>
            <SatReadingWritingLanguageScoreSelector
              :value="counselingProfile.sats_after_march_highest_sub_writing_score"
              @change="
                sats_after_march_highest_sub_writing_score =>
                  setCounselingProfile({ sats_after_march_highest_sub_writing_score })
              "
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.california_app]"
          >
            <template slot="label">
              Math
            </template>
            <SatMathScoreSelector
              :value="counselingProfile.sats_after_march_highest_sub_math_score"
              @change="
                sats_after_march_highest_sub_math_score =>
                  setCounselingProfile({ sats_after_march_highest_sub_math_score })
              "
            />
          </CounselingFormItem>
          <div v-if="plusSatEssayScore">
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app]"
            >
              <template slot="label">
                Highest combined essay score
              </template>
              <SatEssayScoreSelector
                :value="counselingProfile.sats_after_march_highest_essay_score"
                @change="
                  sats_after_march_highest_essay_score =>
                    setCounselingProfile({ sats_after_march_highest_essay_score })
                "
              />
            </CounselingFormItem>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app]"
            >
              <template slot="label">
                Combined essay date
              </template>
              <el-date-picker
                style="width: 100%"
                value-format="dd/MM/yyyy"
                type="date"
                :picker-options="satAfterMarch2016pickerOptions"
                :value="counselingProfile.sats_after_march_essay_date"
                @input="
                  sats_after_march_essay_date =>
                    setCounselingProfile({ sats_after_march_essay_date })
                "
              />
            </CounselingFormItem>
          </div>
        </div>
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
    <el-collapse-item
      v-if="
        testTypeAlreadyTaken('SAT Subject Tests')  &&
          [thirdAppTypes.common_app, thirdAppTypes.california_app].includes(thirdAppTypes[currentApplicationType])
      "
      title="SAT Subject Tests"
      name="satSubjectTests"
    >
      <el-form>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.california_app]"
        >
          <template slot="label">
            Number of SAT Subject Tests you wish to report, including tests you expect to take
          </template>
          <el-select
            style="width: 100%"
            :value="counselingProfile.sats_number"
            @change="sats_number => setCounselingProfile({ sats_number })"
          >
            <el-option value="0" label="0" />
            <el-option
              v-for="reportNum in MAX_SAT_SUBJECT_REPORT_COUNT"
              :key="reportNum"
              :value="reportNum"
              :label="reportNum"
            />
          </el-select>
        </CounselingFormItem>
        <div
          :key="satSubjectNum"
          v-for="(satSubjectNum, satSubjectIndex) in counselingProfile.sats_number"
        >
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              Date taken or planned
            </template>
            <el-date-picker
              style="width: 100%"
              value-format="MM/yyyy"
              type="date"
              :value="counselingProfile.sats[satSubjectIndex].taken_month"
              @input="
                taken_month =>
                  setSatSubject(satSubjectIndex, { taken_month })
              "
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            checkApplications
            :value="counselingProfile.sats[satSubjectIndex].subject"
            getterProp="suffixOptions"
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              Subject
            </template>
            <SatSubjectSelector
              :value="counselingProfile.sats[satSubjectIndex].subject"
              @change="subject => setSatSubject(satSubjectIndex, { subject })"
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              Score
            </template>
            <SatScoreSelector
              :value="counselingProfile.sats[satSubjectIndex].score"
              @change="score => setSatSubject(satSubjectIndex, { score })"
            />
          </CounselingFormItem>
        </div>
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
    <el-collapse-item
      v-if="testTypeAlreadyTaken('AP Subject Tests')"
      title="AP Subject Tests"
      name="apSubjectTests"
    >
      <CounselingFormAlert
        :currentApplication="currentApplicationType"
        :applicationsNeedToVisible="[thirdAppTypes.coalition_app]"
      >
        <template slot="title">
          {{ $t('counselingProfile.coalitionAppJustCanFillEightApTests') }}
        </template>
      </CounselingFormAlert>
      <el-form>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
        >
          <template slot="label">
            Number of AP Tests you wish to report, including tests you expect to take
          </template>
          <el-select
            style="width: 100%"
            :value="counselingProfile.aps_number"
            @change="aps_number => setCounselingProfile({ aps_number })"
          >
            <el-option value="0" label="0" />
            <el-option
              v-for="reportNum in MAX_AP_SUBJECT_REPORT_COUNT"
              :key="reportNum"
              :value="reportNum"
              :label="reportNum"
            />
          </el-select>
        </CounselingFormItem>
        <div
          :key="apSubjectNum"
          v-for="(apSubjectNum, apSubjectIndex) in counselingProfile.aps_number"
        >
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.california_app]"
          >
            <template slot="label">
              Is this exam completed or planned?
            </template>
            <el-radio-group
              style="width: 100%"
              :value="counselingProfile.aps[apSubjectIndex].is_completed"
              @input="is_completed => setApSubject(apSubjectIndex, {
                is_completed,
                has_received_score: ''
              })"
            >
              <div>
                <el-radio label="Y">Completed</el-radio>
              </div>
              <div>
                <el-radio label="N">Planned</el-radio>
              </div>
            </el-radio-group>
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              Date taken or planned
            </template>
            <el-date-picker
              style="width: 100%"
              value-format="MM/yyyy"
              type="date"
              :value="counselingProfile.aps[apSubjectIndex].taken_date"
              @input="
                taken_date =>
                  setApSubject(apSubjectIndex, { taken_date })
              "
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            checkApplications
            :value="counselingProfile.aps[apSubjectIndex].subject"
            getterProp="apSubjectOptions"
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              Subject
            </template>
            <ApSubjectSelector
              :value="counselingProfile.aps[apSubjectIndex].subject"
              @change="subject => setApSubject(apSubjectIndex, { subject })"
            />
          </CounselingFormItem>
          <CounselingFormItem
            v-show="counselingProfile.aps[apSubjectIndex].is_completed === 'Y'"
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.california_app]"
          >
            <template slot="label">
              Have you received your score yet? 
            </template>
            <el-radio-group
              style="width: 100%"
              :value="counselingProfile.aps[apSubjectIndex].has_received_score"
              @input="has_received_score => setApSubject(apSubjectIndex, { has_received_score })"
            >
              <div>
                <el-radio label="Y">Yes</el-radio>
              </div>
              <div>
                <el-radio label="N">No</el-radio>
              </div>
            </el-radio-group>
          </CounselingFormItem>
          <CounselingFormItem
            v-show="(
              thirdAppTypes[currentApplicationType] === thirdAppTypes.california_app &&
              counselingProfile.aps[apSubjectIndex].has_received_score==='Y'
            ) || (thirdAppTypes[currentApplicationType] !== thirdAppTypes.california_app)"
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              Score
            </template>
            <ApScoreSelector
              :value="counselingProfile.aps[apSubjectIndex].score"
              @change="score => setApSubject(apSubjectIndex, { score })"
            />
          </CounselingFormItem>
        </div>
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
    <el-collapse-item
      v-if="testTypeAlreadyTaken('IB Subject Tests')"
      title="IB Subject Tests"
      name="ibSubjectTests"
    >
      <CounselingFormAlert
        :currentApplication="currentApplicationType"
        :applicationsNeedToVisible="[thirdAppTypes.coalition_app]"
      >
        <template slot="title">
          {{ $t('counselingProfile.coalitionAppJustCanFillSevenIbTests') }}
        </template>
      </CounselingFormAlert>
      <el-form>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
        >
          <template slot="label">
            Number of IB Tests you wish to report, including tests you expect to take
          </template>
          <el-select
            style="width: 100%"
            :value="counselingProfile.ibs_number"
            @change="ibs_number => setCounselingProfile({ ibs_number })"
          >
            <el-option value="0" label="0" />
            <el-option
              v-for="reportNum in MAX_IB_SUBJECT_REPORT_COUNT"
              :key="reportNum"
              :value="reportNum"
              :label="reportNum"
            />
          </el-select>
        </CounselingFormItem>
        <div
          :key="ibSubjectNum"
          v-for="(ibSubjectNum, ibSubjectIndex) in counselingProfile.ibs_number"
        >
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.california_app]"
          >
            <template slot="label">
              Is this exam completed or planned?
            </template>
            <el-radio-group
              style="width: 100%"
              :value="counselingProfile.ibs[ibSubjectIndex].is_completed"
              @input="is_completed => setIbSubject(ibSubjectIndex, {
                is_completed,
                has_received_score: ''
              })"
            >
              <div>
                <el-radio label="Y">Completed</el-radio>
              </div>
              <div>
                <el-radio label="N">Planned</el-radio>
              </div>
            </el-radio-group>
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              Date taken or planned
            </template>
            <el-date-picker
              style="width: 100%"
              value-format="MM/yyyy"
              type="date"
              :value="counselingProfile.ibs[ibSubjectIndex].taken_date"
              @input="
                taken_date =>
                  setIbSubject(ibSubjectIndex, { taken_date })
              "
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.california_app]"
          >
            <template slot="label">
              IB exam subject area
            </template>
            <IbSubjectAreaSelector
              :value="counselingProfile.ibs[ibSubjectIndex].subject_area"
              @change="subject_area => setIbSubject(ibSubjectIndex, { subject_area })"
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            checkApplications
            :value="counselingProfile.ibs[ibSubjectIndex].subject"
            getterProp="ibSubjectOptions"
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              Subject
            </template>
            <IbSubjectSelector
              :value="counselingProfile.ibs[ibSubjectIndex].subject"
              @change="subject => setIbSubject(ibSubjectIndex, { subject })"
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              Level (select the one that applies)
            </template>
           <el-radio-group
              style="width: 100%"
              :value="counselingProfile.ibs[ibSubjectIndex].level"
              @input="level => setIbSubject(ibSubjectIndex, { level })"
            >
              <div>
                <el-radio :label="1">Higher level (HL)</el-radio>
              </div>
              <div>
                <el-radio :label="0">Standard level (SL)</el-radio>
              </div>
            </el-radio-group>
          </CounselingFormItem>
          <CounselingFormItem
            v-show="counselingProfile.ibs[ibSubjectIndex].is_completed === 'Y'"
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.california_app]"
          >
            <template slot="label">
              Have you received your score yet? 
            </template>
            <el-radio-group
              style="width: 100%"
              :value="counselingProfile.ibs[ibSubjectIndex].has_received_score"
              @input="has_received_score => setIbSubject(ibSubjectIndex, { has_received_score })"
            >
              <div>
                <el-radio label="Y">Yes</el-radio>
              </div>
              <div>
                <el-radio label="N">No</el-radio>
              </div>
            </el-radio-group>
          </CounselingFormItem>
          <CounselingFormItem
            v-show="(thirdAppTypes[currentApplicationType] === thirdAppTypes.california_app && counselingProfile.ibs[ibSubjectIndex].has_received_score === 'Y') || (thirdAppTypes[currentApplicationType] !== thirdAppTypes.california_app)"
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              Score
            </template>
           <IbScoreSelector
              :value="counselingProfile.ibs[ibSubjectIndex].score"
              @change="score => setIbSubject(ibSubjectIndex, { score })"
            />
          </CounselingFormItem>
        </div>
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
    <el-collapse-item
      v-if="testTypeAlreadyTaken('TOEFL iBT')"
      title="TOEFL iBT"
      name="toeflIBt"
    >
      <el-form>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
        >
          <template slot="label">
            Number of times you have already taken the TOEFL iBT
          </template>
          <el-select
            style="width: 100%"
            :value="counselingProfile.toefls_number"
            @change="toefls_number => setCounselingProfile({ toefls_number })"
          >
            <el-option value="0" label="0" />
            <el-option
              v-for="reportNum in MAX_TOEFL_IBT_TEST_REPORT_COUNT"
              :key="reportNum"
              :value="reportNum"
              :label="reportNum"
            />
          </el-select>
        </CounselingFormItem>
        <CounselingFormAlert
          :currentApplication="currentApplicationType"
          :applicationsNeedToVisible="[thirdAppTypes.coalition_app]"
        >
          <template slot="title">
            {{ $t('counselingProfile.coalitionAppJustCanFillOneDate') }}
          </template>
        </CounselingFormAlert>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.california_app]"
        >
          <template slot="label">
            Number of future TOEFL iBT sittings you expect
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.future_toefls_sitting_months_length"
            @input="future_toefls_sitting_months_length => setCounselingProfile({ future_toefls_sitting_months_length })"
          >
            <el-radio :label="0">0</el-radio>
            <div
              :key="futureActSittingNum"
              v-for="futureActSittingNum in MAX_FUTURE_TOEFL_IBT_SITTING_COUNT"
            >
              <el-radio :label="futureActSittingNum">{{
                futureActSittingNum
              }}</el-radio>
            </div>
          </el-radio-group>
        </CounselingFormItem>
        <div
          :key="future_toefls_sitting_months_number"
          v-for="(future_toefls_sitting_months_number, index) in counselingProfile.future_toefls_sitting_months_length"
        >
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              Future testing date {{future_toefls_sitting_months_number}}
            </template>
            <el-date-picker
              style="width: 100%"
              value-format="MM/yyyy"
              type="date"
              :value="counselingProfile.future_toefls_sitting_months[index]"
              @input="
                future_toefls_sitting_month =>
                  setFutureToeflIbtSittingMonth(index, future_toefls_sitting_month)
              "
            />
          </CounselingFormItem>
        </div>
        <div v-if="reportToeflsIbsNumMoreThanZero">
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
          >
            <template slot="label">
              My best total score
            </template>
            <el-input-number
              class="inputNumber"
              controls-position="right"
              :max="120"
              :value="counselingProfile.toefls_best_score"
              @input="
                toefls_best_score => setCounselingProfile({ toefls_best_score })
              "
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.california_app]"
          >
            <template slot="label">
              Is this test completed or planned?
            </template>
            <el-radio-group
              style="width: 100%"
              :value="counselingProfile.toefls_is_completed"
              @input="toefls_is_completed => setCounselingProfile({ toefls_is_completed })"
            >
              <div>
                <el-radio label="Y">Completed</el-radio>
              </div>
              <div>
                <el-radio label="N">Planned</el-radio>
              </div>
            </el-radio-group>
          </CounselingFormItem>
          <template v-if="counselingProfile.toefls_is_completed === 'Y'">
            <CounselingFormItem
                required
                :currentApplication="currentApplicationType"
                :applicationsNeedToFilling="[thirdAppTypes.california_app]"
              >
                <template slot="label">
                  Registration number
                </template>
                <el-input-number
                  class="inputNumber"
                  controls-position="right"
                  :value="counselingProfile.toefls_reg_number"
                  @input="
                    toefls_reg_number => setCounselingProfile({ toefls_reg_number })
                  "
                />
            </CounselingFormItem>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.california_app]"
            >
              <template slot="label">
                Have you received your score yet? 
              </template>
              <el-radio-group
                style="width: 100%"
                :value="counselingProfile.toefls_has_received_score"
                @input="toefls_has_received_score => setCounselingProfile({ toefls_has_received_score })"
              >
                <div>
                  <el-radio label="Y">Yes</el-radio>
                </div>
                <div>
                  <el-radio label="N">No</el-radio>
                </div>
              </el-radio-group>
            </CounselingFormItem>
          </template>
          <template v-if="(thirdAppTypes[currentApplicationType] === thirdAppTypes.california_app && counselingProfile.toefls_has_received_score==='Y' && counselingProfile.toefls_is_completed==='Y') || (thirdAppTypes[currentApplicationType] !== thirdAppTypes.california_app)"> 
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
            >
              <template slot="label">
                Highest reading score
              </template>
              <ToeflScoreSelector
                :value="counselingProfile.toefls_highest_reading_score"
                @change="
                  toefls_highest_reading_score =>
                    setCounselingProfile({ toefls_highest_reading_score })
                "
              />
            </CounselingFormItem>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
            >
              <template slot="label">
                Reading score date
              </template>
              <el-date-picker
                style="width: 100%"
                value-format="dd/MM/yyyy"
                type="date"
                :value="counselingProfile.toefls_reading_date"
                @input="
                  toefls_reading_date =>
                    setCounselingProfile({ toefls_reading_date })
                "
              />
            </CounselingFormItem>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
            >
              <template slot="label">
                Highest speaking score
              </template>
              <ToeflScoreSelector
                :value="counselingProfile.toefls_highest_speaking_score"
                @change="
                  toefls_highest_speaking_score =>
                    setCounselingProfile({ toefls_highest_speaking_score })
                "
              />
            </CounselingFormItem>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
            > 
              <template slot="label">
                Speaking score date
              </template>
              <el-date-picker
                style="width: 100%"
                value-format="dd/MM/yyyy"
                type="date"
                :value="counselingProfile.toefls_speaking_date"
                @input="
                  toefls_speaking_date =>
                    setCounselingProfile({ toefls_speaking_date })
                "
              />
            </CounselingFormItem>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
            > 
              <template slot="label">
                Highest listening score
              </template>
              <ToeflScoreSelector
                :value="counselingProfile.toefls_highest_listening_score"
                @change="
                  toefls_highest_listening_score =>
                    setCounselingProfile({ toefls_highest_listening_score })
                "
              />
            </CounselingFormItem>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
            > 
              <template slot="label">
                Listening score date
              </template>
              <el-date-picker
                style="width: 100%"
                value-format="dd/MM/yyyy"
                type="date"
                :value="counselingProfile.toefls_listening_date"
                @input="
                  toefls_listening_date =>
                    setCounselingProfile({ toefls_listening_date })
                "
              />
            
            </CounselingFormItem>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
            > 
              <template slot="label">
                Highest writing score
              </template>
              <ToeflScoreSelector
                :value="counselingProfile.toefls_highest_writing_score"
                @change="
                  toefls_highest_writing_score =>
                    setCounselingProfile({ toefls_highest_writing_score })
                "
              />
            
            </CounselingFormItem>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
            > 
              <template slot="label">
                Writing score date
              </template>
              <el-date-picker
                style="width: 100%"
                value-format="dd/MM/yyyy"
                type="date"
                :value="counselingProfile.toefls_writing_date"
                @input="
                  toefls_writing_date =>
                    setCounselingProfile({ toefls_writing_date })
                "
              />
            
            </CounselingFormItem>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.california_app]"
            > 
              <template slot="label">
                Highest TOEFL iBT total score
              </template>
              <ToeflScoreSelector
                :value="counselingProfile.toefls_total_score"
                @change="
                  toefls_total_score =>
                    setCounselingProfile({ toefls_total_score })
                "
              />
            </CounselingFormItem>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.california_app]"
            > 
              <template slot="label">
                TOEFL iBT total score date
              </template>
              <el-date-picker
                style="width: 100%"
                value-format="dd/MM/yyyy"
                type="date"
                :value="counselingProfile.toefls_total_score_date"
                @input="
                  toefls_total_score_date =>
                    setCounselingProfile({ toefls_total_score_date })
                "
              />
            </CounselingFormItem>
          </template>
        </div>
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
    <el-collapse-item
      v-if="testTypeAlreadyTaken('TOEFL Paper') && [thirdAppTypes.common_app, thirdAppTypes.california_app].includes(thirdAppTypes[currentApplicationType])"
      title="TOEFL Paper"
      name="toeflPaper"
    >
      <el-form>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.california_app]"
        > 
          <template slot="label">
            Number of times you have already taken the TOEFL Paper
          </template>
          <el-select
            style="width: 100%"
            :value="counselingProfile.toefl_papers_number"
            @change="toefl_papers_number => setCounselingProfile({ toefl_papers_number })"
          >
            <el-option value="0" label="0" />
            <el-option
              v-for="reportNum in MAX_TOEFL_PAPER_TEST_REPORT_COUNT"
              :key="reportNum"
              :value="reportNum"
              :label="reportNum"
            />
          </el-select>
        </CounselingFormItem>
        <CounselingFormAlert
          :currentApplication="currentApplicationType"
          :applicationsNeedToVisible="[thirdAppTypes.coalition_app]"
        >
          <template slot="title">
            {{ $t('counselingProfile.coalitionAppJustCanFillOneDate') }}
          </template>
        </CounselingFormAlert>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.california_app]"
        > 
          <template slot="label">
            Number of future TOEFL Paper sittings you expect
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.future_toefl_papers_sitting_months_length"
            @input="future_toefl_papers_sitting_months_length => setCounselingProfile({ future_toefl_papers_sitting_months_length })"
          >
            <el-radio :label="0">0</el-radio>
            <div
              :key="futureToeflPaperSittingNum"
              v-for="futureToeflPaperSittingNum in MAX_FUTURE_TOEFL_PAPER_SITTING_COUNT"
            >
              <el-radio :label="futureToeflPaperSittingNum">{{
                futureToeflPaperSittingNum
              }}</el-radio>
            </div>
          </el-radio-group>
        </CounselingFormItem>

        <div
          :key="future_toefl_papers_sitting_months_number"
          v-for="(future_toefl_papers_sitting_months_number, index) in counselingProfile.future_toefl_papers_sitting_months_length"
        >
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.california_app]"
          > 
            <template slot="label">
              Future testing date {{future_toefl_papers_sitting_months_number}}
            </template>
            <el-date-picker
              style="width: 100%"
              value-format="MM/yyyy"
              type="date"
              :value="counselingProfile.future_toefl_papers_sitting_months[index]"
              @input="
                future_toefl_papers_sitting_month =>
                  setFutureToeflPapersSittingMonth(index, future_toefl_papers_sitting_month)
              "
            />
          </CounselingFormItem>
        </div>

        <div v-if="reportToeflsPapersNumMoreThanZero">
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.california_app]"
          >
            <template slot="label">
              Is this test completed or planned?
            </template>
            <el-radio-group
              style="width: 100%"
              :value="counselingProfile.toefl_papers_is_completed"
              @input="toefl_papers_is_completed => setCounselingProfile({ toefl_papers_is_completed })"
            >
              <div>
                <el-radio label="Y">Completed</el-radio>
              </div>
              <div>
                <el-radio label="N">Planned</el-radio>
              </div>
            </el-radio-group>
          </CounselingFormItem>
          <template v-if="counselingProfile.toefl_papers_is_completed === 'Y'">
            <CounselingFormItem
                required
                :currentApplication="currentApplicationType"
                :applicationsNeedToFilling="[thirdAppTypes.california_app]"
              >
                <template slot="label">
                  Registration number
                </template>
                <el-input-number
                  class="inputNumber"
                  controls-position="right"
                  :value="counselingProfile.toefl_papers_reg_number"
                  @input="
                    toefl_papers_reg_number => setCounselingProfile({ toefl_papers_reg_number })
                  "
                />
            </CounselingFormItem>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.california_app]"
            >
              <template slot="label">
                Have you received your score yet? 
              </template>
              <el-radio-group
                style="width: 100%"
                :value="counselingProfile.toefl_papers_has_received_score"
                @input="toefl_papers_has_received_score => setCounselingProfile({ toefl_papers_has_received_score })"
              >
                <div>
                  <el-radio label="Y">Yes</el-radio>
                </div>
                <div>
                  <el-radio label="N">No</el-radio>
                </div>
              </el-radio-group>
            </CounselingFormItem>
          </template>
          <template v-if="(thirdAppTypes[currentApplicationType] === thirdAppTypes.california_app && counselingProfile.toefl_papers_has_received_score==='Y' && counselingProfile.toefl_papers_is_completed==='Y') || (thirdAppTypes[currentApplicationType] !== thirdAppTypes.california_app)">
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.california_app]"
            > 
              <template slot="label">
                Highest listening score
              </template>
              <el-input-number
                class="inputNumber"
                controls-position="right"
                :min="31"
                :max="68"
                :value="counselingProfile.toefl_papers_highest_listening_score"
                @input="
                  toefl_papers_highest_listening_score =>
                    setCounselingProfile({ toefl_papers_highest_listening_score })
                "
              />
            </CounselingFormItem>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app]"
            > 
              <template slot="label">
                Listening score date
              </template>
              <el-date-picker
                style="width: 100%"
                value-format="dd/MM/yyyy"
                type="date"
                :value="counselingProfile.toefl_papers_listening_date"
                @input="
                  toefl_papers_listening_date =>
                    setCounselingProfile({ toefl_papers_listening_date })
                "
              />
            </CounselingFormItem>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.california_app]"
            > 
              <template slot="label">
                Highest reading score
              </template>
              <el-input-number
                class="inputNumber"
                controls-position="right"
                :min="31"
                :max="67"
                :value="counselingProfile.toefl_papers_highest_reading_score"
                @input="
                  toefl_papers_highest_reading_score =>
                    setCounselingProfile({ toefl_papers_highest_reading_score })
                "
              />
            </CounselingFormItem>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app]"
            > 
              <template slot="label">
                Reading score date
              </template>
              <el-date-picker
                style="width: 100%"
                value-format="dd/MM/yyyy"
                type="date"
                :value="counselingProfile.toefl_papers_reading_date"
                @input="
                  toefl_papers_reading_date =>
                    setCounselingProfile({ toefl_papers_reading_date })
                "
              />
            </CounselingFormItem>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.california_app]"
            > 
              <template slot="label">
                Highest written expression/writing score
              </template>
              <el-input-number
                class="inputNumber"
                controls-position="right"
                :min="0"
                :max="30"
                :value="counselingProfile.toefl_papers_highest_writing_score"
                @input="
                  toefl_papers_highest_writing_score =>
                    setCounselingProfile({ toefl_papers_highest_writing_score })
                "
              />
            </CounselingFormItem>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app]"
            > 
              <template slot="label">
                Written expression/writing date
              </template>
              <el-date-picker
                style="width: 100%"
                value-format="dd/MM/yyyy"
                type="date"
                :value="counselingProfile.toefl_papers_writing_date"
                @input="
                  toefl_papers_writing_date =>
                    setCounselingProfile({ toefl_papers_writing_date })
                "
              />
            </CounselingFormItem>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app]"
            > 
              <template slot="label">
                Highest TWE score
              </template>
              <el-select
                style="width: 100%"
                :value="counselingProfile.toefl_papers_highest_twe_score"
                @change="
                  toefl_papers_highest_twe_score =>
                    setCounselingProfile({ toefl_papers_highest_twe_score })
                "
              >
                <el-option
                  :key="toeflPaperTweScore"
                  v-for="toeflPaperTweScore in MAX_TOEFL_PAPRE_TWE_SCORE"
                  :label="toeflPaperTweScore"
                  :value="toeflPaperTweScore"
                />
              </el-select>
            </CounselingFormItem>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app]"
            > 
              <template slot="label">
                TWE score date
              </template>
              <el-date-picker
                style="width: 100%"
                value-format="dd/MM/yyyy"
                type="date"
                :value="counselingProfile.toefl_papers_twe_date"
                @input="
                  toefl_papers_twe_date =>
                    setCounselingProfile({ toefl_papers_twe_date })
                "
              />
            </CounselingFormItem>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.california_app]"
            > 
              <template slot="label">
                Highest TOEFL Paper total score
              </template>
              <el-input-number
                class="inputNumber"
                controls-position="right"
                :min="310"
                :max="667"
                :value="counselingProfile.toefl_papers_total_score"
                @input="
                  toefl_papers_total_score =>
                    setCounselingProfile({ toefl_papers_total_score })
                "
              />
            </CounselingFormItem>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app]"
            > 
              <template slot="label">
                TOEFL Paper total score date
              </template>
              <el-date-picker
                style="width: 100%"
                value-format="dd/MM/yyyy"
                type="date"
                :value="counselingProfile.toefl_papers_total_score_date"
                @input="
                  toefl_papers_total_score_date =>
                    setCounselingProfile({ toefl_papers_total_score_date })
                "
              />
            </CounselingFormItem>
          </template>
        </div>
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
    <el-collapse-item
      v-if="testTypeAlreadyTaken('PTE Academic Tests') && thirdAppTypes[currentApplicationType] === thirdAppTypes.common_app"
      title="PTE Academic Tests"
      name="pteAcademicTests"
    >
      <el-form>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        > 
          <template slot="label">
            Number of times you have already taken the PTE Academic Test
          </template>
          <el-select
            style="width: 100%"
            :value="counselingProfile.ptes_number"
            @change="ptes_number => setCounselingProfile({ ptes_number })"
          >
            <el-option value="0" label="0" />
            <el-option
              v-for="reportNum in MAX_PTES_TEST_REPORT_COUNT"
              :key="reportNum"
              :value="reportNum"
              :label="reportNum"
            />
          </el-select>
        </CounselingFormItem>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        > 
          <template slot="label">
            Number of future PTE Academic Test sittings you expect
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.future_ptes_sitting_months_length"
            @input="future_ptes_sitting_months_length => setCounselingProfile({ future_ptes_sitting_months_length })"
          >
            <el-radio :label="0">0</el-radio>
            <div
              :key="futurePtesSittingNum"
              v-for="futurePtesSittingNum in MAX_FUTURE_PTES_SITTING_COUNT"
            >
              <el-radio :label="futurePtesSittingNum">{{
                futurePtesSittingNum
              }}</el-radio>
            </div>
          </el-radio-group>
        </CounselingFormItem>
        <div
          :key="future_pte_sitting_months_number"
          v-for="(future_pte_sitting_months_number, index) in counselingProfile.future_ptes_sitting_months_length"
        >
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          > 
            <template slot="label">
              Future testing date {{future_pte_sitting_months_number}}
            </template>
            <el-date-picker
              style="width: 100%"
              value-format="MM/yyyy"
              type="date"
              :value="counselingProfile.future_ptes_sitting_months[index]"
              @input="
                future_ptes_sitting_month =>
                  setFuturePtesPapersSittingMonth(index, future_ptes_sitting_month)
              "
            />
          </CounselingFormItem>
        </div>
        <div v-if="reportPtesNumMoreThanZero">
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          > 
            <template slot="label">
              Highest listening score
            </template>
            <PteScoreSelector
              :value="counselingProfile.ptes_highest_listening_score"
              @change="
                ptes_highest_listening_score =>
                  setCounselingProfile({ ptes_highest_listening_score })
              "
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          > 
            <template slot="label">
              Listening score date
            </template>
            <el-date-picker
              style="width: 100%"
              value-format="dd/MM/yyyy"
              type="date"
              :value="counselingProfile.ptes_listening_date"
              @input="
                ptes_listening_date =>
                  setCounselingProfile({ ptes_listening_date })
              "
            />
          </CounselingFormItem>

          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          > 
            <template slot="label">
              Highest reading score
            </template>
            <PteScoreSelector
              :value="counselingProfile.ptes_highest_reading_score"
              @change="
                ptes_highest_reading_score =>
                  setCounselingProfile({ ptes_highest_reading_score })
              "
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          > 
            <template slot="label">
              Reading score date
            </template>
            <el-date-picker
              style="width: 100%"
              value-format="dd/MM/yyyy"
              type="date"
              :value="counselingProfile.ptes_reading_date"
              @input="
                ptes_reading_date => setCounselingProfile({ ptes_reading_date })
              "
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          > 
            <template slot="label">
              Highest speaking score
            </template>
            <PteScoreSelector
              :value="counselingProfile.ptes_highest_speaking_score"
              @change="
                ptes_highest_speaking_score =>
                  setCounselingProfile({ ptes_highest_speaking_score })
              "
            />
          </CounselingFormItem>

          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          > 
            <template slot="label">
              Speaking score date
            </template>
            <el-date-picker
              style="width: 100%"
              value-format="dd/MM/yyyy"
              type="date"
              :value="counselingProfile.ptes_speaking_date"
              @input="
                ptes_speaking_date =>
                  setCounselingProfile({ ptes_speaking_date })
              "
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          > 
            <template slot="label">
              Highest writing score
            </template>
            <PteScoreSelector
              :value="counselingProfile.ptes_highest_writing_score"
              @change="
                ptes_highest_writing_score =>
                  setCounselingProfile({ ptes_highest_writing_score })
              "
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          > 
            <template slot="label">
              Writing score date
            </template>
            <el-date-picker
              style="width: 100%"
              value-format="dd/MM/yyyy"
              type="date"
              :value="counselingProfile.ptes_writing_date"
              @input="
                ptes_writing_date =>
                  setCounselingProfile({ ptes_writing_date })
              "
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          > 
            <template slot="label">
              Highest grammar score
            </template>
            <PteScoreSelector
              :value="counselingProfile.ptes_highest_grammar_score"
              @change="
                ptes_highest_grammar_score =>
                  setCounselingProfile({ ptes_highest_grammar_score })
              "
            />
          </CounselingFormItem>

          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          > 
            <template slot="label">
              Grammar score date
            </template>
            <el-date-picker
              style="width: 100%"
              value-format="dd/MM/yyyy"
              type="date"
              :value="counselingProfile.ptes_grammar_date"
              @input="
                ptes_grammar_date =>
                  setCounselingProfile({ ptes_grammar_date })
              "
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          > 
            <template slot="label">
              Highest oral fluency score
            </template>
            <PteScoreSelector
              :value="counselingProfile.ptes_highest_oral_fluency_score"
              @change="
                ptes_highest_oral_fluency_score =>
                  setCounselingProfile({ ptes_highest_oral_fluency_score })
              "
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          > 
            <template slot="label">
              Oral fluency score date
            </template>
            <el-date-picker
              style="width: 100%"
              value-format="dd/MM/yyyy"
              type="date"
              :value="counselingProfile.ptes_oral_fluency_date"
              @input="
                ptes_oral_fluency_date =>
                  setCounselingProfile({ ptes_oral_fluency_date })
              "
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          > 
            <template slot="label">
              Highest pronunciation score
            </template>
            <PteScoreSelector
              :value="counselingProfile.ptes_highest_pronunciation_score"
              @change="
                ptes_highest_pronunciation_score =>
                  setCounselingProfile({ ptes_highest_pronunciation_score })
              "
            />
          </CounselingFormItem>

          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          > 
            <template slot="label">
              Pronunciation score date
            </template>
            <el-date-picker
              style="width: 100%"
              value-format="dd/MM/yyyy"
              type="date"
              :value="counselingProfile.ptes_pronunciation_date"
              @input="
                ptes_pronunciation_date =>
                  setCounselingProfile({ ptes_pronunciation_date })
              "
            />
          </CounselingFormItem>

          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          > 
            <template slot="label">
              Highest spelling score
            </template>
           <PteScoreSelector
              :value="counselingProfile.ptes_highest_spelling_score"
              @change="
                ptes_highest_spelling_score =>
                  setCounselingProfile({ ptes_highest_spelling_score })
              "
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          > 
            <template slot="label">
              Spelling score date
            </template>
            <el-date-picker
              style="width: 100%"
              value-format="dd/MM/yyyy"
              type="date"
              :value="counselingProfile.ptes_spelling_date"
              @input="
                ptes_spelling_date =>
                  setCounselingProfile({ ptes_spelling_date })
              "
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          > 
            <template slot="label">
              Highest vocabulary score
            </template>
            <PteScoreSelector
              :value="counselingProfile.ptes_highest_vocabulary_score"
              @change="
                ptes_highest_vocabulary_score =>
                  setCounselingProfile({ ptes_highest_vocabulary_score })
              "
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          > 
            <template slot="label">
              Vocabulary score date
            </template>
            <el-date-picker
              style="width: 100%"
              value-format="dd/MM/yyyy"
              type="date"
              :value="counselingProfile.ptes_vocabulary_date"
              @input="
                ptes_vocabulary_date =>
                  setCounselingProfile({ ptes_vocabulary_date })
              "
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          > 
            <template slot="label">
              Highest written discourse score
            </template>
            <PteScoreSelector
              :value="counselingProfile.ptes_highest_discourse_score"
              @change="
                ptes_highest_discourse_score =>
                  setCounselingProfile({ ptes_highest_discourse_score })
              "
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          > 
            <template slot="label">
              Written discourse score date
            </template>
            <el-date-picker
              style="width: 100%"
              value-format="dd/MM/yyyy"
              type="date"
              :value="counselingProfile.ptes_discourse_date"
              @input="
                ptes_discourse_date =>
                  setCounselingProfile({ ptes_discourse_date })
              "
            />
          </CounselingFormItem>
        </div>
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
    <el-collapse-item
      v-if="testTypeAlreadyTaken('IELTS')"
      title="IELTS"
      name="ieltes"
    >
      <el-form>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
        > 
          <template slot="label">
            Number of times you have already taken the IELTS
          </template>
          <el-select
            style="width: 100%"
            :value="counselingProfile.ielts_number"
            @change="ielts_number => setCounselingProfile({ ielts_number })"
          >
            <el-option value="0" label="0" />
            <el-option
              v-for="reportNum in MAX_IELTS_TEST_REPORT_COUNT"
              :key="reportNum"
              :value="reportNum"
              :label="reportNum"
            />
          </el-select>
        </CounselingFormItem>
        <CounselingFormAlert
          :currentApplication="currentApplicationType"
          :applicationsNeedToVisible="[thirdAppTypes.coalition_app]"
        >
          <template slot="title">
            {{ $t('counselingProfile.coalitionAppJustCanFillOneDate') }}
          </template>
        </CounselingFormAlert>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.california_app]"
        > 
          <template slot="label">
            Number of future IELTS sittings you expect
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.future_ielts_sitting_months_length"
            @input="future_ielts_sitting_months_length => setCounselingProfile({ future_ielts_sitting_months_length })"
          >
            <el-radio :label="0">0</el-radio>
            <div
              :key="futureIeltsSittingNum"
              v-for="futureIeltsSittingNum in MAX_FUTURE_IELTS_SITTING_COUNT"
            >
              <el-radio :label="futureIeltsSittingNum">{{
                futureIeltsSittingNum
              }}</el-radio>
            </div>
          </el-radio-group>
        </CounselingFormItem>
        <div
          :key="future_ielts_sitting_months_number"
          v-for="(future_ielts_sitting_months_number, index) in counselingProfile.future_ielts_sitting_months_length"
        >
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.california_app]"
          > 
            <template slot="label">
              Future testing date {{future_ielts_sitting_months_number}}
            </template>
            <el-date-picker
              style="width: 100%"
              value-format="MM/yyyy"
              type="date"
              :value="counselingProfile.future_ielts_sitting_months[index]"
              @input="
                future_ielts_sitting_month =>
                  setFutureIeltsPapersSittingMonth(index, future_ielts_sitting_month)
              "
            />
          </CounselingFormItem>
        </div>
        <div v-if="reportIeltsNumMoreThanZero">
          <div>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.california_app]"
            >
              <template slot="label">
                Is this test completed or planned?
              </template>
              <el-radio-group
                style="width: 100%"
                :value="counselingProfile.ielts_is_completed"
                @input="ielts_is_completed => setCounselingProfile({ ielts_is_completed })"
              >
                <div>
                  <el-radio label="Y">Completed</el-radio>
                </div>
                <div>
                  <el-radio label="N">Planned</el-radio>
                </div>
              </el-radio-group>
            </CounselingFormItem>
            <template v-if="counselingProfile.ielts_is_completed === 'Y'">
              <CounselingFormItem
                required
                :currentApplication="currentApplicationType"
                :applicationsNeedToFilling="[thirdAppTypes.california_app]"
              >
                <template slot="label">
                  Test report form number
                </template>
                <el-input-number
                  class="inputNumber"
                  controls-position="right"
                  :value="counselingProfile.ielts_form_number"
                  @input="
                    ielts_form_number => setCounselingProfile({ ielts_form_number })
                  "
                />
              </CounselingFormItem>
              <CounselingFormItem
                required
                :currentApplication="currentApplicationType"
                :applicationsNeedToFilling="[thirdAppTypes.california_app]"
              >
                <template slot="label">
                  Have you received your score yet? 
                </template>
                <el-radio-group
                  style="width: 100%"
                  :value="counselingProfile.ielts_has_received_score"
                  @input="ielts_has_received_score => setCounselingProfile({ ielts_has_received_score })"
                >
                  <div>
                    <el-radio label="Y">Yes</el-radio>
                  </div>
                  <div>
                    <el-radio label="N">No</el-radio>
                  </div>
                </el-radio-group>
              </CounselingFormItem>
            </template>
            <template v-if="(thirdAppTypes[currentApplicationType] === thirdAppTypes.california_app && counselingProfile.ielts_has_received_score==='Y' && counselingProfile.ielts_is_completed==='Y') || (thirdAppTypes[currentApplicationType] !== thirdAppTypes.california_app)">
              <CounselingFormItem
                required
                :currentApplication="currentApplicationType"
                :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
              > 
                <template slot="label">
                  Highest listening score
                </template>
                <IeltsScoreSelector
                  :value="counselingProfile.ielts_highest_listening_score"
                  @change="
                    ielts_highest_listening_score =>
                      setCounselingProfile({ ielts_highest_listening_score })
                  "
                />
              </CounselingFormItem>
              <CounselingFormItem
                required
                :currentApplication="currentApplicationType"
                :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
              > 
                <template slot="label">
                  Listening score date
                </template>
                <el-date-picker
                  style="width: 100%"
                  value-format="dd/MM/yyyy"
                  type="date"
                  :value="counselingProfile.ielts_listening_date"
                  @input="
                    ielts_listening_date =>
                      setCounselingProfile({ ielts_listening_date })
                  "
                />
              </CounselingFormItem>
              <CounselingFormItem
                required
                :currentApplication="currentApplicationType"
                :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
              > 
                <template slot="label">
                  Highest reading score
                </template>
                <IeltsScoreSelector
                  :value="counselingProfile.ielts_highest_reading_score"
                  @change="
                    ielts_highest_reading_score =>
                      setCounselingProfile({ ielts_highest_reading_score })
                  "
                />
              </CounselingFormItem>
              <CounselingFormItem
                required
                :currentApplication="currentApplicationType"
                :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
              > 
                <template slot="label">
                  Reading score date
                </template>
                <el-date-picker
                  style="width: 100%"
                  value-format="dd/MM/yyyy"
                  type="date"
                  :value="counselingProfile.ielts_reading_date"
                  @input="
                    ielts_reading_date => setCounselingProfile({ ielts_reading_date })
                  "
                />
              </CounselingFormItem>
              <CounselingFormItem
                required
                :currentApplication="currentApplicationType"
                :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
              > 
                <template slot="label">
                  Highest writing score
                </template>
                <IeltsScoreSelector
                  :value="counselingProfile.ielts_highest_writing_score"
                  @change="
                    ielts_highest_writing_score =>
                      setCounselingProfile({ ielts_highest_writing_score })
                  "
                />
              </CounselingFormItem>
              <CounselingFormItem
                required
                :currentApplication="currentApplicationType"
                :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
              > 
                <template slot="label">
                  Writing score date
                </template>
                <el-date-picker
                  style="width: 100%"
                  value-format="dd/MM/yyyy"
                  type="date"
                  :value="counselingProfile.ielts_writing_date"
                  @input="
                    ielts_writing_date =>
                      setCounselingProfile({ ielts_writing_date })
                  "
                />
              </CounselingFormItem>
              <CounselingFormItem
                required
                :currentApplication="currentApplicationType"
                :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
              > 
                <template slot="label">
                  Highest speaking score
                </template>
                <IeltsScoreSelector
                  :value="counselingProfile.ielts_highest_speaking_score"
                  @change="
                    ielts_highest_speaking_score =>
                      setCounselingProfile({ ielts_highest_speaking_score })
                  "
                />
              </CounselingFormItem>
              <CounselingFormItem
                required
                :currentApplication="currentApplicationType"
                :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
              > 
                <template slot="label">
                  Speaking score date
                </template>
                <el-date-picker
                  style="width: 100%"
                  value-format="dd/MM/yyyy"
                  type="date"
                  :value="counselingProfile.ielts_speaking_date"
                  @input="
                    ielts_speaking_date =>
                      setCounselingProfile({ ielts_speaking_date })
                  "
                />
              </CounselingFormItem>
              <CounselingFormItem
                required
                :currentApplication="currentApplicationType"
                :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
              > 
                <template slot="label">
                  Highest IELTS overall band score
                </template>
                <IeltsScoreSelector
                  :value="counselingProfile.ielts_overall_score"
                  @change="
                    ielts_overall_score =>
                      setCounselingProfile({ ielts_overall_score })
                  "
                />
              </CounselingFormItem>
              <CounselingFormItem
                required
                :currentApplication="currentApplicationType"
                :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
              > 
                <template slot="label">
                  IELTS overall band score date
                </template>
                <el-date-picker
                  style="width: 100%"
                  value-format="dd/MM/yyyy"
                  type="date"
                  :value="counselingProfile.ielts_overall_score_date"
                  @input="
                    ielts_overall_score_date =>
                      setCounselingProfile({ ielts_overall_score_date })
                  "
                />
              </CounselingFormItem>
            </template>
          </div>
        </div>
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
    <el-collapse-item
      v-if="testTypeAlreadyTaken('A Level') && thirdAppTypes[currentApplicationType] === thirdAppTypes.coalition_app"
      title="A Level"
      name="aLevelTests"
    >
      <el-form>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
        > 
          <template slot="label">
            Number of A Level Tests you wish to report, including tests you expect to take
          </template>
          <el-select
            style="width: 100%"
            :value="counselingProfile.a_levels_number"
            @change="a_levels_number => setCounselingProfile({ a_levels_number })"
          >
            <el-option value="0" label="0" />
            <el-option
              v-for="reportNum in MAX_A_LEVEL_REPORT_COUNT"
              :key="reportNum"
              :value="reportNum"
              :label="reportNum"
            />
          </el-select>
        </CounselingFormItem>

        <div
          :key="aLevelNum"
          v-for="(aLevelNum, aLevelIndex) in counselingProfile.a_levels_number"
        >
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
          > 
            <template slot="label">
              Date taken
            </template>
            <el-date-picker
              style="width: 100%"
              value-format="MM/yyyy"
              type="date"
              :value="counselingProfile.a_levels[aLevelIndex].taken_date"
              @input="
                taken_date =>
                  setALevelSubject(aLevelIndex, { taken_date })
              "
            />
          </CounselingFormItem>

          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
          > 
            <template slot="label">
              A Level Exam
            </template>
            <el-input
              :value="counselingProfile.a_levels[aLevelIndex].title"
              @input="title => setALevelSubject(aLevelIndex, { title })"
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
          > 
            <template slot="label">
              Score
            </template>
            <ALevelScoreSelector
              :value="counselingProfile.a_levels[aLevelIndex].score"
              @change="score => setALevelSubject(aLevelIndex, { score })"
            />
          </CounselingFormItem>
        </div>
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
    <el-collapse-item
      v-if="testTypeAlreadyTaken('Duolingo')"
      title="Duolingo"
      name="duolingo"
    >
      <CounselingFormAlert
        :currentApplication="currentApplicationType"
        :applicationsNeedToVisible="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
      >
        <template slot="title">
          <ul class="promptList">
            <li>{{ $t('counselingProfile.commappDuoHighest') }}</li>
            <li>{{ $t('counselingProfile.coalition4EslScore') }}</li>
            <li>{{ $t('counselingProfile.i18n4') }}</li>
          </ul>
        </template>
      </CounselingFormAlert>
      <el-form>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
        > 
          <template slot="label">
            Number of Duolingo Tests you wish to report, including tests you expect to take
          </template>
          <el-select
            style="width: 100%"
            :value="counselingProfile.duolingoes_number"
            @change="duolingoes_number => setCounselingProfile({ duolingoes_number })"
          >
            <el-option value="0" label="0" />
            <el-option
              v-for="reportNum in MAX_DUOLINGO_REPORT_COUNT"
              :key="reportNum"
              :value="reportNum"
              :label="reportNum"
            />
          </el-select>
        </CounselingFormItem>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            Number of future duolingoes sittings you expect
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.future_duolingoes_sitting_months_length"
            @input="future_duolingoes_sitting_months_length => setCounselingProfile({ future_duolingoes_sitting_months_length })"
          >
            <el-radio :label="0">0</el-radio>
            <div
              :key="futureDuolingoSittingNum"
              v-for="futureDuolingoSittingNum in MAX_FUTURE_DUOLINGOES_SITTING_COUNT"
            >
              <el-radio :label="futureDuolingoSittingNum">{{
                futureDuolingoSittingNum
              }}</el-radio>
            </div>
          </el-radio-group>
        </CounselingFormItem>
        <div
          :key="future_duolingoes_sitting_months_number"
          v-for="(future_duolingoes_sitting_months_number, index) in counselingProfile.future_duolingoes_sitting_months_length"
        >
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              Future testing date {{future_duolingoes_sitting_months_number}}
            </template>
            <el-date-picker
              style="width: 100%"
              value-format="MM/yyyy"
              type="date"
              :value="counselingProfile.future_duolingoes_sitting_months[index]"
              @input="
                future_duolingoes_sitting_month =>
                  setFutureDuolingoesSittingMonth(index, future_duolingoes_sitting_month)
              "
            />
          </CounselingFormItem>
        </div>
        <div
          :key="duolingoNum"
          v-for="(duolingoNum, duolingoIndex) in counselingProfile.duolingoes_number"
        >
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.california_app]"
          >
            <template slot="label">
              Is this test completed or planned?
            </template>
            <el-radio-group
              style="width: 100%"
              :value="counselingProfile.duolingoes[duolingoIndex].is_completed"
              @input="is_completed => setDuolingo(duolingoIndex, {
                  is_completed,
                  has_received_score: ''
                })
              "
            >
              <div>
                <el-radio label="Y">Completed</el-radio>
              </div>
              <div>
                <el-radio label="N">Planned</el-radio>
              </div>
            </el-radio-group>
          </CounselingFormItem>
          <template v-if="counselingProfile.duolingoes[duolingoIndex].is_completed === 'Y'">
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.california_app]"
            >
              <template slot="label">
                Have you received your score yet? 
              </template>
              <el-radio-group
                style="width: 100%"
                :value="counselingProfile.duolingoes[duolingoIndex].has_received_score"
                @input="
                  has_received_score =>
                    setDuolingo(duolingoIndex, { has_received_score })
                "
              >
                <div>
                  <el-radio label="Y">Yes</el-radio>
                </div>
                <div>
                  <el-radio label="N">No</el-radio>
                </div>
              </el-radio-group>
            </CounselingFormItem>
          </template>
          <template v-if="(thirdAppTypes[currentApplicationType] === thirdAppTypes.california_app && counselingProfile.duolingoes[duolingoIndex].has_received_score ==='Y' && counselingProfile.duolingoes[duolingoIndex].is_completed ==='Y') || (thirdAppTypes[currentApplicationType] !== thirdAppTypes.california_app)">
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.california_app]"
            > 
              <template slot="label">
                Literacy
              </template>
              <DuolingoScoreSelector
                :value="counselingProfile.duolingoes[duolingoIndex].literacy_score"
                @change="
                  literacy_score =>
                    setDuolingo(duolingoIndex, { literacy_score })
                "
              />
            </CounselingFormItem>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app]"
            >
              <template slot="label">
                Literacy score date
              </template>
              <el-date-picker
                style="width: 100%"
                value-format="dd/MM/yyyy"
                type="date"
                :value="counselingProfile.duolingoes[duolingoIndex].literacy_date"
                @input="literacy_date => setDuolingo(duolingoIndex, { literacy_date })"
              />
            </CounselingFormItem>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.california_app]"
            > 
              <template slot="label">
                Conversation
              </template>
              <DuolingoScoreSelector
                :value="counselingProfile.duolingoes[duolingoIndex].conversation_score"
                @change="
                  conversation_score =>
                    setDuolingo(duolingoIndex, { conversation_score })
                "
              />
            </CounselingFormItem>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app]"
            >
              <template slot="label">
                Conversation score date
              </template>
              <el-date-picker
                style="width: 100%"
                value-format="dd/MM/yyyy"
                type="date"
                :value="counselingProfile.duolingoes[duolingoIndex].conversation_date"
                @input="conversation_date => setDuolingo(duolingoIndex, { conversation_date })"
              />
            </CounselingFormItem>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.california_app]"
            > 
              <template slot="label">
                Comprehension
              </template>
              <DuolingoScoreSelector
                :value="counselingProfile.duolingoes[duolingoIndex].comprehension_score"
                @change="
                  comprehension_score =>
                    setDuolingo(duolingoIndex, { comprehension_score })
                "
              />
            </CounselingFormItem>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app]"
            >
              <template slot="label">
                Comprehension score date
              </template>
              <el-date-picker
                style="width: 100%"
                value-format="dd/MM/yyyy"
                type="date"
                :value="counselingProfile.duolingoes[duolingoIndex].comprehension_date"
                @input="comprehension_date => setDuolingo(duolingoIndex, { comprehension_date })"
              />
            </CounselingFormItem>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.california_app]"
            > 
              <template slot="label">
                Production
              </template>
              <DuolingoScoreSelector
                :value="counselingProfile.duolingoes[duolingoIndex].production_score"
                @change="
                  production_score =>
                    setDuolingo(duolingoIndex, { production_score })
                "
              />
            </CounselingFormItem>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app]"
            >
              <template slot="label">
                Production score date
              </template>
              <el-date-picker
                style="width: 100%"
                value-format="dd/MM/yyyy"
                type="date"
                :value="counselingProfile.duolingoes[duolingoIndex].production_date"
                @input="production_date => setDuolingo(duolingoIndex, { production_date })"
              />
            </CounselingFormItem>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
            >
              <template slot="label">
                Total/Overall Score
              </template>
              <DuolingoScoreSelector
                :value="counselingProfile.duolingoes[duolingoIndex].score"
                @change="
                  score =>
                    setDuolingo(duolingoIndex, { score })
                "
              />
            </CounselingFormItem>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
            >
              <template slot="label">
                Date taken
              </template>
              <el-date-picker
                style="width: 100%"
                value-format="dd/MM/yyyy"
                type="date"
                :value="counselingProfile.duolingoes[duolingoIndex].taken_date"
                @input="taken_date => setDuolingo(duolingoIndex, { taken_date })"
              />
            </CounselingFormItem>
          </template>
        </div>
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
    <el-collapse-item
      v-if="testTypeAlreadyTaken('Other Proficiency Exam') && thirdAppTypes[currentApplicationType] === thirdAppTypes.coalition_app"
      title="Other Proficiency Exam"
      name="otherProficiencyExam"
    >
      <CounselingFormAlert
        :currentApplication="currentApplicationType"
        :applicationsNeedToVisible="[thirdAppTypes.coalition_app]"
      >
        <template slot="title">
          {{ $t('counselingProfile.coalition4EslScore') }}
        </template>
      </CounselingFormAlert>
      <el-form>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
        > 
          <template slot="label">
            Number of other proficiency exams you wish to report, including tests you expect to take
          </template>
          <el-select
            style="width: 100%"
            :value="counselingProfile.other_proficiency_exams_number"
            @change="other_proficiency_exams_number => setCounselingProfile({ other_proficiency_exams_number })"
          >
            <el-option value="0" label="0" />
            <el-option
              v-for="reportNum in MAX_OTHER_PROFICIENCY_EXAMS_REPORT_COUNT"
              :key="reportNum"
              :value="reportNum"
              :label="reportNum"
            />
          </el-select>
        </CounselingFormItem>

        <div
          :key="otherProficiencyExamNum"
          v-for="(otherProficiencyExamNum, otherProficiencyExamIndex) in counselingProfile.other_proficiency_exams_number"
        >
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
          > 
            <template slot="label">
              If other, please specify
            </template>
            <el-input
              :value="counselingProfile.other_proficiency_exams[otherProficiencyExamIndex].title"
              @input="title => setOtherProficiencyExam(otherProficiencyExamIndex, { title })"
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
          > 
            <template slot="label">
              Total/Overall Score
            </template>
            <el-input
              :value="counselingProfile.other_proficiency_exams[otherProficiencyExamIndex].score"
              @input="score => setOtherProficiencyExam(otherProficiencyExamIndex, { score })"
            />
          </CounselingFormItem>

          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
          > 
            <template slot="label">
              Date taken
            </template>
            <el-date-picker
              style="width: 100%"
              value-format="MM/yyyy"
              type="date"
              :value="counselingProfile.other_proficiency_exams[otherProficiencyExamIndex].taken_date"
              @input="taken_date => setOtherProficiencyExam(otherProficiencyExamIndex, { taken_date })"
            />
          </CounselingFormItem>
        </div>
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
    <el-collapse-item
      v-if="gaveLeavingExams "
      title="Senior Secondary Leaving Examinations"
      name="seniorSecondaryLeavingExaminations"
    >
      <el-form>
         <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.california_app]"
        > 
          <template slot="label">
            Number of Senior Secondary Leaving Examinations you have already taken
          </template>
          <el-select
            style="width: 100%"
            :value="counselingProfile.leaving_exams_number"
            @change="leaving_exams_number => setCounselingProfile({ leaving_exams_number })"
          >
            <el-option value="0" label="0" />
            <el-option
              v-for="reportNum in MAX_LEAVING_EXAMS_REPORT_COUNT"
              :key="reportNum"
              :value="reportNum"
              :label="reportNum"
            />
          </el-select>
        </CounselingFormItem>
        <div
          :key="leaving_exam_number"
          v-for="(leaving_exam_number, leaving_exam_index) in counselingProfile.leaving_exams_number"
        >
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.california_app]"
          > 
            <template slot="label">
              Date taken
            </template>
            <el-date-picker
              style="width: 100%"
              value-format="MM/yyyy"
              type="date"
              :value="counselingProfile.leaving_exams[leaving_exam_index].taken_date"
              @input="
                taken_date => setLeavingExam(leaving_exam_index, { taken_date })
              "
            />
          </CounselingFormItem>

          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.california_app]"
          > 
            <template slot="label">
              Examination board
            </template>
            <ExaminationBoardSelector
              :value="counselingProfile.leaving_exams[leaving_exam_index].examination_board"
              @change="
                examination_board => setLeavingExam(leaving_exam_index, { examination_board })
              "
            />
          </CounselingFormItem>

          <CounselingFormItem
            v-if="examinationBoardIsOther(leaving_exam_index)"
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.california_app]"
          > 
            <template slot="label">
              Specify other examination board
            </template>
            <el-input
              :value="counselingProfile.leaving_exams[leaving_exam_index].examination_board_descr"
              @input="
                examination_board_descr => setLeavingExam(leaving_exam_index, { examination_board_descr })
              "
            />
          </CounselingFormItem>

          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.california_app]"
          > 
            <template slot="label">
              Academic subject
            </template>
            <el-input
              :value="counselingProfile.leaving_exams[leaving_exam_index].subject"
              @input="
                subject => setLeavingExam(leaving_exam_index, { subject })
              "
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.california_app]"
          > 
            <template slot="label">
              Score
            </template>
            <el-input-number
              class="inputNumber"
              controls-position="right"
              :value="counselingProfile.leaving_exams[leaving_exam_index].score"
              @input="score => setLeavingExam(leaving_exam_index, { score })"
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          > 
            <template slot="label">
              Score type
            </template>
            <el-radio-group
              style="width: 100%"
              :value="counselingProfile.leaving_exams[leaving_exam_index].score_type"
              @input="score_type => setLeavingExam(leaving_exam_index, { score_type })"
            >
              <div>
                <el-radio :label="0">Actual</el-radio>
              </div>
              <div>
                <el-radio :label="1">Predicted</el-radio>
              </div>
            </el-radio-group>
          </CounselingFormItem>
        </div>
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import moment from "moment";
import { thirdAppTypeDict } from "@/class/ThirdApp";
import CounselingFormAlert from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/CounselingFormAlert";
import CounselingFormItem from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/CounselingFormItem";
import ActScoreSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/TestForm/ActScoreSelector";
import SatScoreSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/TestForm/SatScoreSelector";
import SatTotalScoreSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/TestForm/SatTotalScoreSelector";
import SatEssayScoreSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/TestForm/SatEssayScoreSelector";
import SatReadingWritingLanguageScoreSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/TestForm/SatReadingWritingLanguageScoreSelector";
import SatMathScoreSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/TestForm/SatMathScoreSelector";
import SatSubjectSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/TestForm/SatSubjectSelector";
import ApSubjectSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/TestForm/ApSubjectSelector";
import ApScoreSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/TestForm/ApScoreSelector";
import IbSubjectAreaSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/TestForm/IbSubjectAreaSelector";
import IbSubjectSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/TestForm/IbSubjectSelector";
import IbScoreSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/TestForm/IbScoreSelector";
import ALevelScoreSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/TestForm/ALevelScoreSelector";
import ToeflScoreSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/TestForm/ToeflScoreSelector";
import PteScoreSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/TestForm/PteScoreSelector";
import IeltsScoreSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/TestForm/IeltsScoreSelector";
import DuolingoScoreSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/TestForm/DuolingoScoreSelector";
import ExaminationBoardSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/TestForm/ExaminationBoardSelector";

export default {
  props: {
    currentApplicationType: {
      type: String,
      default: ""
    },
    activePanel: {
      type: String,
      default: ""
    }
  },
  components: {
    CounselingFormAlert,
    CounselingFormItem,
    ActScoreSelector,
    SatScoreSelector,
    SatTotalScoreSelector,
    SatEssayScoreSelector,
    SatReadingWritingLanguageScoreSelector,
    SatMathScoreSelector,
    SatSubjectSelector,
    ApSubjectSelector,
    ApScoreSelector,
    IbSubjectAreaSelector,
    IbSubjectSelector,
    IbScoreSelector,
    ALevelScoreSelector,
    ToeflScoreSelector,
    PteScoreSelector,
    IeltsScoreSelector,
    DuolingoScoreSelector,
    ExaminationBoardSelector
  },
  computed: {
    thirdAppTypes() {
      return thirdAppTypeDict;
    },
    testTypes() {
      return [
        { value: "0", label: "ACT Tests" },
        { value: "9", label: "SAT" },
        { value: "2", label: "SAT Subject Tests" },
        { value: "3", label: "AP Subject Tests" },
        { value: "4", label: "IB Subject Tests" },
        { value: "5", label: "TOEFL iBT" },
        { value: "6", label: "TOEFL Paper" },
        { value: "8", label: "PTE Academic Tests" },
        { value: "7", label: "IELTS" },
        { value: "21", label: "A Level" },
        { value: "22", label: "Duolingo" },
        { value: "23", label: "Other Proficiency Exam" }
      ];
    },
    internationalExams() {
      return [
        "Australia NSW School Certificate/NSW Higher School Certificate",
        "Caribbean Commonwealth CXC/CAPE",
        "GCSE/IGCSE/O-levels",
        "GCE AS-level/A-level",
        "Hong Kong HKCEE/HKALE/HKDSE",
        "India Year X/XII",
        "Indonesia SMA/SMP",
        "Malaysia SPM/STPM",
        "Pakistan HSC/SSC",
        "New Zealand NCEA Level 1/Level 2/Level 3",
        "Scottish Qualifications Certificate (SQC) Intermediate 1/Intermediate 2/Higher Grade/Adv Higher Grade",
        "Singapore O-levels/A-levels H1/A-levels H2",
        "Sri Lanka O-levels/A-levels",
        "Sri Lanka A-levels",
        "West African Examinations Council (WAEC) WASSC"
      ];
    },
    counselingProfile() {
      return this.$store.getters["counselingProfile/counselingProfile"];
    },
    wishReportTest() {
      return this.counselingProfile.wish_to_report_tests === 0;
    },
    gaveLeavingExams() {
      return this.counselingProfile.gave_leaving_exams === 0;
    },
    reportActNumMoreThanZero() {
      return 0 < this.counselingProfile.acts_number;
    },
    reportActNumMoreThanOne() {
      return 1 < this.counselingProfile.acts_number;
    },
    reportSatBeforeMarch2016NumMoreThanZero() {
      return 0 < this.counselingProfile.sats_before_march_number;
    },
    reportSatAfterMarch2016NumMoreThanZero() {
      return 0 < this.counselingProfile.sats_after_march_number;
    },
    reportToeflsIbsNumMoreThanZero() {
      return 0 < this.counselingProfile.toefls_number;
    },
    reportToeflsPapersNumMoreThanZero() {
      return 0 < this.counselingProfile.toefl_papers_number;
    },
    reportPtesNumMoreThanZero() {
      return 0 < this.counselingProfile.ptes_number;
    },
    reportIeltsNumMoreThanZero() {
      return 0 < this.counselingProfile.ielts_number;
    },
    plusActWritingScore() {
      return this.counselingProfile.took_act_plus_writing_test === 0;
    },
    plusSatEssayScore() {
      return this.counselingProfile.took_sat_essay === 0;
    },
    MAX_ACT_TEST_REPORT_COUNT() {
      return 5;
    },
    MAX_FUTURE_ACT_SITTING_COUNT() {
      return 3;
    },
    MAX_FUTURE_SAT_SITTING_COUNT() {
      return 3;
    },
    MAX_FUTURE_TOEFL_IBT_SITTING_COUNT() {
      return 3;
    },
    MAX_FUTURE_TOEFL_PAPER_SITTING_COUNT() {
      return 3;
    },
    MAX_FUTURE_PTES_SITTING_COUNT() {
      return 3;
    },
    MAX_FUTURE_DUOLINGOES_SITTING_COUNT() {
      return 3;
    },
    MAX_FUTURE_IELTS_SITTING_COUNT() {
      return 3;
    },
    MAX_SAT_BEFORE_MARCH_2016_TEST_REPORT_COUNT() {
      return 5;
    },
    MAX_SAT_AFTER_MARCH_2016_TEST_REPORT_COUNT() {
      return 5;
    },
    MAX_SAT_SUBJECT_REPORT_COUNT() {
      return 10;
    },
    MAX_AP_SUBJECT_REPORT_COUNT() {
      return 10;
    },
    MAX_IB_SUBJECT_REPORT_COUNT() {
      return 10;
    },
    MAX_TOEFL_IBT_TEST_REPORT_COUNT() {
      return 5;
    },
    MAX_TOEFL_PAPER_TEST_REPORT_COUNT() {
      return 5;
    },
    MAX_PTES_TEST_REPORT_COUNT() {
      return 5;
    },
    MAX_IELTS_TEST_REPORT_COUNT() {
      return 5;
    },
    MAX_A_LEVEL_REPORT_COUNT() {
      return 6;
    },
    MAX_LEAVING_EXAMS_REPORT_COUNT() {
      return 10;
    },
    MAX_TOEFL_PAPRE_TWE_SCORE() {
      return 7;
    },
    MAX_DUOLINGO_REPORT_COUNT() {
      return 5;
    },
    MAX_OTHER_PROFICIENCY_EXAMS_REPORT_COUNT() {
      return 4;
    }
  },
  data() {
    return {
      satBeforeMarch2016pickerOptions: {
        disabledDate: time => {
          const march2016Date = new moment("2016-02-29");
          const jun2010Date = new moment("2010-01-01");
          const currentTime = new moment(time);
          return (
            march2016Date.isBefore(currentTime) ||
            jun2010Date.isAfter(currentTime)
          );
        }
      },
      satAfterMarch2016pickerOptions: {
        disabledDate: time => {
          const march2016Date = new moment("2016-03-01");
          const currentTime = new moment(time);
          return march2016Date.isAfter(currentTime);
        }
      }
    };
  },
  methods: {
    setCounselingProfile(data) {
      this.$store.commit("counselingProfile/setCounselingProfile", { ...data });
    },
    setFutureActSittingMonth(futureActSittingMonthIndex, futureActSittingMonth) {
      this.$store.commit("counselingProfile/setFutureActSittingMonth", {
        futureActSittingMonthIndex,
        futureActSittingMonth
      });
    },
    setFutureSatSittingMonth(futureSatSittingMonthIndex, futureSatSittingMonth) {
      this.$store.commit("counselingProfile/setFutureSatSittingMonth", {
        futureSatSittingMonthIndex,
        futureSatSittingMonth
      });
    },
    setFutureToeflIbtSittingMonth(futureToeflIbtSittingMonthIndex, futureToeflIbtSittingMonth) {
      this.$store.commit("counselingProfile/setFutureToeflIbtSittingMonth", {
        futureToeflIbtSittingMonthIndex,
        futureToeflIbtSittingMonth
      });
    },
    setFutureToeflPapersSittingMonth(futureToeflPaperSittingMonthIndex, futureToeflPaperSittingMonth) {
      this.$store.commit("counselingProfile/setFutureToeflPaperSittingMonth", {
        futureToeflPaperSittingMonthIndex,
        futureToeflPaperSittingMonth
      });
    },
    setFuturePtesPapersSittingMonth(futurePtesSittingMonthIndex, futurePtesSittingMonth) {
      this.$store.commit("counselingProfile/setFuturePtesPapersSittingMonth", {
        futurePtesSittingMonthIndex,
        futurePtesSittingMonth
      });
    },
    setFutureIeltsPapersSittingMonth(futureIeltsSittingMonthIndex, futureIeltsSittingMonth) {
      this.$store.commit("counselingProfile/setFutureIeltsPapersSittingMonth", {
        futureIeltsSittingMonthIndex,
        futureIeltsSittingMonth
      });
    },
    setFutureDuolingoesSittingMonth(futureDuolingoesSittingMonthIndex, futureDuolingoesSittingMonth) {
      this.$store.commit("counselingProfile/setFutureDuolingoesSittingMonth", {
        futureDuolingoesSittingMonthIndex,
        futureDuolingoesSittingMonth
      });
    },
    setSatSubject(satSubjectIndex, data) {
      this.$store.commit("counselingProfile/setSatSubject", {
        satSubjectIndex,
        data
      });
    },
    setApSubject(apSubjectIndex, data) {
      this.$store.commit("counselingProfile/setApSubject", {
        apSubjectIndex,
        data
      });
    },
    setIbSubject(ibSubjectIndex, data) {
      this.$store.commit("counselingProfile/setIbSubject", {
        ibSubjectIndex,
        data
      });
    },
    setDuolingo(duolingoIndex, data) {
      this.$store.commit("counselingProfile/setDuolingo", {
        duolingoIndex,
        data
      });
    },
    setOtherProficiencyExam(otherProficiencyExamIndex, data) {
      this.$store.commit("counselingProfile/setOtherProficiencyExam", {
        otherProficiencyExamIndex,
        data
      });
    },
    setALevelSubject(aLevelIndex, data) {
      this.$store.commit("counselingProfile/setALevelSubject", {
        aLevelIndex,
        data
      });
    },
    setLeavingExam(leaveExamIndex, data) {
      this.$store.commit("counselingProfile/setLeavingExam", {
        leaveExamIndex,
        data
      });
    },
    testTypeAlreadyTaken(testType) {
      const testValue = this.testTypes.find(({ label }) => label === testType).value;
      return this.counselingProfile.tests_taken.includes(testValue);
    },
    examinationBoardIsOther(leavingExamIndex) {
      const OTHER = "24";
      return String(this.counselingProfile.leaving_exams[leavingExamIndex].examination_board) === OTHER;
    }
  }
};
</script>

<style scoped src="@/views/private/Counseling/ThirdApp/CounselingProfileForm/index.css"></style>
