<template>
  <el-select
    style="width: 100%"
    filterable
    :value="value"
    @change="changeValue"
    placeholder
  >
    <el-option
      v-for="item in data"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    />
  </el-select>
</template>

<script>
export default {
  name: "SatScoreSelector",
  props: ["value"],
  computed: {
    data() {
      const options = [];
      const SCORE_START_POINT = 200;
      const MAX_SCORE = 800;
      const SCORE_GAP = 10;
      for (let score = SCORE_START_POINT; score <= MAX_SCORE; score += SCORE_GAP) {
        options.push({ label: String(score), value: String(score) });
      }
      return options;
    }
  },
  methods: {
    changeValue(value) {
      this.$emit("change", value);
    }
  }
};
</script>
