<template>
  <el-dialog
    :title="$t('message.notice')"
    :visible.sync="isVisible"
    width="300px"
  >
    <strong>
      <span class="action-icon">
        <i class="fas fa-check" />
      </span>
      {{ $t('application.dialogs.successfullyLoggedIn') }}
    </strong>
    <br/>
    <strong>
      <span class="action-icon">
        <i class="el-icon-loading" />
      </span>
      {{ $t('application.dialogs.loadingData') }}
    </strong>
  </el-dialog>
</template>

<script>

export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped src="@/views/private/Counseling/ThirdApp/Dialog/index.css"></style>
