<template>
  <el-form>
    <CounselingFormItem
      required
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
    >
      <template slot="label">
        {{ `Parent ${parentNum} type` }}
      </template>
      <el-radio-group
        style="width: 100%"
        :value="parent.type"
        @input="(type) => $emit('setParent', { type })"
      >
        <div>
          <el-radio :label="0">Mother</el-radio>
        </div>
        <div>
          <el-radio :label="1">Father</el-radio>
        </div>
        <div>
          <el-radio :label="2">I have limited information about this parent</el-radio>
        </div>
        <div>
          <el-radio
            v-if="displayDoNotHaveAnotherParentOption"
            :label="3"
          >I do not have another parent to list</el-radio>
        </div>
      </el-radio-group>
    </CounselingFormItem>
    <CounselingFormItem
      required
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
    >
      <template slot="label">
        {{ `Is Parent ${parentNum} living` }}
      </template>
      <el-radio-group
        style="width: 100%"
        :value="parent.is_living"
        @input="(is_living) => $emit('setParent', { is_living })"
      >
        <div>
          <el-radio :label="0">Yes</el-radio>
        </div>
        <div>
          <el-radio :label="1">No</el-radio>
        </div>
      </el-radio-group>
    </CounselingFormItem>
    <CounselingFormItem
      v-if="isParentDeceased"
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
    >
      <template slot="label">
        Date deceased
      </template>
      <el-date-picker
        style="width: 100%"
        value-format="yyyy-MM-dd"
        type="date"
        :value="parent.deceased_date"
        @input="(deceased_date) => $emit('setParent', { deceased_date })"
      />
    </CounselingFormItem>
    <CounselingFormItem
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.common_app]"
    >
      <template slot="label">
        Prefix
      </template>
      <el-radio-group
        style="width: 100%"
        :value="parent.prefix"
        @input="(prefix) => $emit('setParent', { prefix })"
      >
        <div>
          <el-radio :label="0">Dr.</el-radio>
        </div>
        <div>
          <el-radio :label="1">Mr.</el-radio>
        </div>
        <div>
          <el-radio :label="2">Ms.</el-radio>
        </div>
        <div>
          <el-radio :label="3">Mrs.</el-radio>
        </div>
      </el-radio-group>
    </CounselingFormItem>
    <CounselingFormItem
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
    >
      <template slot="label">
        First/given name
      </template>
      <el-input
        :value="parent.first_name"
        @input="(first_name) => $emit('setParent', { first_name })"
      />
    </CounselingFormItem>
    <CounselingFormItem
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.common_app]"
    >
      <template slot="label">
        Middle initial
      </template>
      <el-input
        :value="parent.middle_name"
        @input="(middle_name) => $emit('setParent', { middle_name })"
      />
    </CounselingFormItem>
    <CounselingFormItem
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
    >
      <template slot="label">
        Last/Family/Surname
      </template>
      <el-input
        :value="parent.last_name"
        @input="(last_name) => $emit('setParent', { last_name })"
      />
    </CounselingFormItem>
    <CounselingFormItem
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.common_app]"
    >
      <template slot="label">
        Former last/family/surname (if any)
      </template>
      <el-input
        :value="parent.former_last_name"
        @input="(former_last_name) => $emit('setParent', { former_last_name })"
      />
    </CounselingFormItem>
    <CounselingFormItem
      checkApplications
      :value="parent.suffix"
      getterProp="suffixOptions"
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.common_app]"
    >
      <template slot="label">
        Suffix
      </template>
      <SuffixSelector
        :value="parent.suffix"
        @change="(suffix) => $emit('setParent', { suffix })"
      />
    </CounselingFormItem>
    <CounselingFormItem
      checkApplications
      :value="parent.birth_country"
      getterProp="countryOptions"
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
    >
      <template slot="label">
        Birth country/region/territory
      </template>
      <CountrySelector
        style="width: 100%"
        :value="parent.birth_country"
        @change="(birth_country) => $emit('setParent', { birth_country })"
      />
    </CounselingFormItem>
    <CounselingFormItem
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
    >
      <template slot="label">
        Preferred email
      </template>
      <el-input
        :value="parent.preferred_email"
        @input="(preferred_email) => $emit('setParent', { preferred_email })"
      />
    </CounselingFormItem>
    <CounselingFormItem
      required
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.common_app]"
    >
      <template slot="label">
        Preferred phone
      </template>
      <el-radio-group
        style="width: 100%"
        :value="parent.preferred_phone"
        @input="(preferred_phone) => $emit('setParent', { preferred_phone })"
      >
        <div><el-radio :label="1">Mobile</el-radio></div>
        <div><el-radio :label="0">Home</el-radio></div>
        <div><el-radio :label="2">Other</el-radio></div>
        <div><el-radio :label="3">Work</el-radio></div>
      </el-radio-group>
    </CounselingFormItem>
    <CounselingFormItem
      required
      checkApplications
      :value="parent.preferred_phone_number.country_code"
      getterProp="mobileCountryCodeOptions"
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
    >
      <template slot="label">
        Preferred phone number
      </template>
      <div style="display: inline-block; width: 100%;">
        <div style="display: flex">
          <MobileCountryCodeSelector
            :value="parent.preferred_phone_number.country_code"
            @change="(country_code) => $emit('setPhoneOfParent', { country_code })"
            style="margin-right: 10px"
          />
          <el-input
            :value="parent.preferred_phone_number.phone_number"
            @input="(phone_number) => $emit('setPhoneOfParent', { phone_number })"
            style="width: 400px; margin-right: 10px"
          />
          <el-input
            :value="parent.preferred_phone_number.extension"
            @input="(extension) => $emit('setPhoneOfParent', { extension })"
            style="width: 200px"
          />
        </div>
      </div>
    </CounselingFormItem>
    <CounselingFormItem
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
    >
      <template slot="label">
        Highest Level of Education
      </template>
      <HighestLevelOfEducationsSelector
        :value="parent.highest_level_of_education"
        @change="(highest_level_of_education) => $emit('setParent', { highest_level_of_education })"
      />
    </CounselingFormItem>
    <CounselingFormItem
      required
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
    >
      <template slot="label">
        {{ `Parent ${parentNum} address is` }}
      </template>
      <el-radio-group
        style="width: 100%"
        :value="parent.address_is"
        @input="(address_is) => $emit('setParent', { address_is })"
      >
        <div><el-radio :label="0">the same as my home address</el-radio></div>
        <div><el-radio :label="1">a different address</el-radio></div>
        <div><el-radio :label="2">unknown</el-radio></div>
      </el-radio-group>
    </CounselingFormItem>
    <template v-if="parent.address_is === 1">
      <AddressForm
        :currentApplicationType="currentApplicationType"
        :address="parent.address"
        @setAddress="(data) => $emit('setAddressOfParent', { ...data })"
      />
    </template>
    <CounselingFormItem
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.common_app]"
    >
      <template slot="label">
        Occupation (former occupation, if retired or deceased)
      </template>
      <OccupationSelector
        style="width: 100%"
        :value="parent.occupation"
        @change="(occupation) => $emit('setParent', { occupation })"
      />
    </CounselingFormItem>
    <CounselingFormItem
      v-if="isOtherOccupation"
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.common_app]"
    >
      <template slot="label">
        Other Occupation Details
      </template>
      <el-input
        :value="parent.occupation_descr"
        @input="(occupation_descr) => $emit('setParent', { occupation_descr })"
      />
    </CounselingFormItem>
    <CounselingFormItem
      required
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
    >
      <template slot="label">
        Occupation and Position/Title
      </template>
      <el-input
        :value="parent.position"
        @input="(position) => $emit('setParent', { position })"
      />
    </CounselingFormItem>
    <CounselingFormItem
      required
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
    >
      <template slot="label">
        Place of Work
      </template>
      <el-input
        :value="parent.occupation_place"
        @input="(occupation_place) => $emit('setParent', { occupation_place })"
      />
    </CounselingFormItem>
    <CounselingFormItem
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.common_app]"
    >
      <template slot="label">
        Education level
      </template>
      <EducationLevelSelector
        style="width: 100%"
        :value="parent.education_level"
        @change="(education_level) => $emit('setParent', { education_level })"
      />
    </CounselingFormItem>
    <InstitutionsAttendedForm
      v-if="hasEnoughEducationLevel"
      :currentApplicationType="currentApplicationType"
      :person="parent"
      @setPerson="data => $emit('setParent', { ...data })"
      @setCollege="
        (collegeIndex, value) =>
          $emit('setCollegeOfParent', collegeIndex, { ...value })
      "
      @setDegreeOfCollege="
        (collegeIndex, degreeIndex, value) =>
          $emit('setDegreeOfParentCollege', collegeIndex, degreeIndex, { ...value })
      "
    />
  </el-form>
</template>

<script>
import { thirdAppTypeDict } from "@/class/ThirdApp";
import CounselingFormItem from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/CounselingFormItem";
import AddressForm from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/AddressForm";
import InstitutionsAttendedForm from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/FamilyForm/InstitutionsAttendedForm";
import MobileCountryCodeSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/MobileCountryCodeSelector";
import SuffixSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/SuffixSelector";
import EducationLevelSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/FamilyForm/EducationLevelSelector";
import CountrySelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/CountrySelector";
import OccupationSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/OccupationSelector";
import HighestLevelOfEducationsSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/FamilyForm/HighestLevelOfEducationsSelector";

export default {
  props: {
    currentApplicationType: {
      type: String,
      default: ""
    },
    parentNum: {
      type: String,
      default: ""
    },
    displayDoNotHaveAnotherParentOption: {
      type: Boolean,
      default: false
    },
    parent: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    CounselingFormItem,
    AddressForm,
    SuffixSelector,
    CountrySelector,
    OccupationSelector,
    EducationLevelSelector,
    MobileCountryCodeSelector,
    InstitutionsAttendedForm,
    HighestLevelOfEducationsSelector
  },
  computed: {
    thirdAppTypes() {
      return thirdAppTypeDict;
    },
    isParentDeceased() {
      return this.parent.is_living === 1;
    },
    isOtherOccupation() {
      const OTHER = 44;
      return this.parent.occupation === OTHER;
    },
    hasEnoughEducationLevel() {
      const higherEducationLevels = ["7", "8", "9", "10", "11"];
      return higherEducationLevels.includes(this.parent.education_level);
    }
  }
};
</script>

<style scoped>
::v-deep .el-form-item__label {
  text-align: left;
  line-height: 28px;
}

.promptText {
  width: 400px;
}
</style>
