<template>
  <div style="display: inline-block; width: 100%;">
    <el-checkbox-group :value="value" @input="changeValue">
      <div :key="item.label" v-for="item in data">
        <el-checkbox :label="item.value">
          {{ item.label }}
        </el-checkbox>
      </div>
    </el-checkbox-group>
  </div>
</template>

<script>
export default {
  name: "ActivityOrAwardGradesSelector",
  props: {
    value: {
      type: Array,
      default: () => ([])
    }
  },
  computed: {
    data() {
      return this.$store.getters["counselingProfile/awardGradeOptions"];
    }
  },
  methods: {
    changeValue(value) {
      this.$emit("input", value);
    }
  }
};
</script>
