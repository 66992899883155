<template>
  <el-select
    style="width: 100%"
    filterable
    :value="value"
    @change="changeValue"
    placeholder
  >
    <el-option
      v-for="item in data"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    />
  </el-select>
</template>

<script>
export default {
  name: "LanguageOfCourse78thSelector",
  props: ["value"],
  computed: {
    data() {
      return [
        {
          label: "American Sign Language",
          value: 1
        },
        {
          label: "Arabic",
          value: 2
        },
        {
          label: "Armenian",
          value: 3
        },
        {
          label: "Chinese",
          value: 4
        },
        {
          label: "Farsi",
          value: 81
        },
        {
          label: "Filipino",
          value: 5
        },
        {
          label: "French",
          value: 6
        },
        {
          label: "German",
          value: 7
        },
        {
          label: "Greek",
          value: 82
        },
        {
          label: "Hebrew",
          value: 8
        },
        {
          label: "Hindi",
          value: 83
        },
        {
          label: "Hmong",
          value: 9
        },
        {
          label: "Italian",
          value: 10
        },
        {
          label: "Japanese",
          value: 11
        },
        {
          label: "Khmer",
          value: 84
        },
        {
          label: "Korean",
          value: 12
        },
        {
          label: "Latin",
          value: 13
        },
        {
          label: "Other Language",
          value: 14
        },
        {
          label: "Portuguese",
          value: 85
        },
        {
          label: "Punjabi",
          value: 86
        },
        {
          label: "Russian",
          value: 15
        },
        {
          label: "Spanish",
          value: 16
        },
        {
          label: "Tamil",
          value: 87
        },
        {
          label: "Turkish",
          value: 88
        },
        {
          label: "Vietnamese",
          value: 17
        }
      ];
    }
  },
  methods: {
    changeValue(value) {
      this.$emit("change", value);
    }
  }
};
</script>
