<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="6">Grade</el-col>
      <el-col :span="8">Academic Year selection</el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="6">
        <el-select
          :value="highSchoolAcadYear.grade_level"
          @change="
            grade_level =>
              $emit('setHighSchoolAcadYear', { grade_level })
          "
        >
          <el-option
            :key="highSchoolGrade.value"
            v-for="highSchoolGrade in highSchoolGrades"
            :value="highSchoolGrade.value"
            :label="highSchoolGrade.label"
          />
        </el-select>
      </el-col>
      <el-col :span="8">
        <AcademicYearSelector
          :startDate="highSchoolEntryMonth"
          :endDate="highSchoolExitMonth"
          :value="highSchoolAcadYear.acad_year"
          @change="acad_year => 
            $emit('setHighSchoolAcadYear', { acad_year })
          "
        />
      </el-col>
      <el-col :span="10">
        <el-checkbox
          true-label="Y"
          false-label="N"
          :value="highSchoolAcadYear.has_summer_courses"
          @input="has_summer_courses => 
            $emit('setHighSchoolAcadYear', { has_summer_courses })
          "
        >
          I took summer courses after this grade
        </el-checkbox>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import AcademicYearSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/EducationForm/AcademicYearSelector";

export default {
  name: "HighSchoolAcadYear",
  props: ["highSchoolAcadYear", "highSchoolEntryMonth", "highSchoolExitMonth"],
  components: {
    AcademicYearSelector
  },
  computed: {
    highSchoolGrades() {
      return [
        { value: "09", label: "9th" },
        { value: "10", label: "10th" },
        { value: "11", label: "11th" },
        { value: "12", label: "12th" }
      ];
    }
  }
};
</script>
