<template>
  <div>
    <CounselingFormItem
      required
      checkApplications
      :value="address.country_code"
      getterProp="countryOptions"
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
    >
      <template slot="label">
        Country/Region/Territory
      </template>
      <CountrySelector
        style="width: 100%"
        :value="address.country_code"
        @change="(country_code) => $emit('setAddress', { country_code })"
      />
    </CounselingFormItem>
    <CounselingFormItem
      required
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
    >
      <template slot="label">
        Address Line 1 (Street Name and Number)
      </template>
      <el-input
        :value="address.address_line_1"
        @input="(address_line_1) => $emit('setAddress', { address_line_1 })"
      />
    </CounselingFormItem>
    <CounselingFormItem
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
    >
      <template slot="label">
        Address Line 2
      </template>
      <el-input
        :value="address.address_line_2"
        @input="(address_line_2) => $emit('setAddress', { address_line_2 })"
      />
    </CounselingFormItem>
    <CounselingFormAlert
      :currentApplication="currentApplicationType"
      :applicationsNeedToVisible="[thirdAppTypes.coalition_app]"
    >
      <template slot="title">
        {{ $t('counselingProfile.coalitionAppJustCanFillTwoAddressLines') }}
      </template>
    </CounselingFormAlert>
    <CounselingFormItem
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.common_app]"
    >
      <template slot="label">
        Address Line 3
      </template>
      <el-input
        :value="address.address_line_3"
        @input="(address_line_3) => $emit('setAddress', { address_line_3 })"
      />
    </CounselingFormItem>
    <CounselingFormItem
      required
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
    >
      <template slot="label">
        {{ cityLabelName }}
      </template>
      <el-input
        :value="address.city"
        @input="(city) => $emit('setAddress', { city })"
      />
    </CounselingFormItem>
    <CounselingFormItem
      checkApplications
      :value="address.state_value"
      getterProp="stateOptions"
      :required="needStateOrProvince"
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
    >
      <template slot="label">
        {{ stateLabelName }}
      </template>
      <StateSelector
        v-if="hasStateOptions"
        :value="address.state_value"
        @change="(state) => $emit('setAddress', {
          state_value: state.code,
          state: state.value
        })"
      />
      <el-input
        v-else
        :value="address.state"
        @input="(state) => $emit('setAddress', {
          state_value: state, state: state
        })"
      />
    </CounselingFormItem>
    <CounselingFormItem
      :required="needPostalCode"
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
    >
      <template slot="label">
        {{ zipLabelName }}
      </template>
      <el-input
        :value="address.zip"
        @input="(zip) => $emit('setAddress', { zip })"
      />
    </CounselingFormItem>
  </div>
</template>

<script>
import { thirdAppTypeDict } from "@/class/ThirdApp";
import CounselingFormAlert from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/CounselingFormAlert";
import CounselingFormItem from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/CounselingFormItem";
import CountrySelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/CountrySelector";
import StateSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/StateSelector";

export default {
  props: {
    currentApplicationType: {
      type: String,
      default: ""
    },
    address: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    CounselingFormAlert,
    CounselingFormItem,
    CountrySelector,
    StateSelector
  },
  computed: {
    thirdAppTypes() {
      return thirdAppTypeDict;
    },
    cityLabelName() {
      switch (this.address.country_value) {
        case "United States of America":
        case "Canada":
          return "City";
        default:
          return "City or Town";
      }
    },
    stateLabelName() {
      switch (this.address.country_value) {
        case "United States of America":
          return "State";
        case "Canada":
          return "Province";
        default:
          return "State or Province";
      }
    },
    zipLabelName() {
      switch (this.address.country_value) {
        case "United States of America":
          return "Zip Code";
        default:
          return "Postal Code";
      }
    },
    needStateOrProvince() {
      const needStateOrProvinceCountries = ["United States of America", "Canada"];
      return needStateOrProvinceCountries.includes(this.address.country_value);
    },
    needPostalCode() {
      const needStateOrProvinceCountries = ["United States of America", "Canada"];
      return needStateOrProvinceCountries.includes(this.address.country_value);
    },
    hasStateOptions() {
      const needStateOrProvinceCountries = ["United States of America", "Canada"];
      return needStateOrProvinceCountries.includes(this.address.country_value);
    }
  }
};
</script>
