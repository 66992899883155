<template>
  <el-radio-group style="width: 100%" :value="value" @input="changeValue">
    <div :key="score.value" v-for="score in data">
      <el-radio :label="score.value">{{ score.label }}</el-radio>
    </div>
  </el-radio-group>
</template>

<script>
export default {
  name: "ApScoreSelector",
  props: ["value"],
  computed: {
    data() {
      const options = [];
      const SCORE_START_POINT = 1;
      const MAX_SCORE = 5;
      for (let score = MAX_SCORE; score >= SCORE_START_POINT; score--) {
        options.push({ label: String(score), value: String(score) });
      }
      return options;
    }
  },
  methods: {
    changeValue(value) {
      this.$emit("change", value);
    }
  }
};
</script>
