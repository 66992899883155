<template>
  <el-select
    style="width: 100%"
    filterable
    :value="value"
    @change="changeValue"
    placeholder
  >
    <el-option
      v-for="item in data"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    />
  </el-select>
</template>

<script>
export default {
  name: "ReligiousSelector",
  props: ["value"],
  computed: {
    data() {
      return [
        {
          value: 40,
          label: "Agnostic"
        },
        {
          value: 0,
          label: "Anglican (Episcopal)"
        },
        {
          value: 48,
          label: "Assemblies of God"
        },
        {
          value: 41,
          label: "Atheist"
        },
        {
          value: 2,
          label: "Bahá'í"
        },
        {
          value: 1,
          label: "Baptist"
        },
        {
          value: 3,
          label: "Buddhist"
        },
        {
          value: 4,
          label: "Caodaiist"
        },
        {
          value: 6,
          label: "Cheondoist"
        },
        {
          value: 49,
          label: "Christian (Nondenominational)"
        },
        {
          value: 7,
          label: "Christian Scientist"
        },
        {
          value: 8,
          label: "Church of Christ"
        },
        {
          value: 9,
          label: "Church of World Messianity"
        },
        {
          value: 10,
          label: "Confucianist"
        },
        {
          value: 11,
          label: "Congregationalist (UCC)"
        },
        {
          value: 38,
          label: "Disciples of Christ"
        },
        {
          value: 44,
          label: "Eastern Orthodox"
        },
        {
          value: 43,
          label: "Evangelical Covenant Church"
        },
        {
          value: 12,
          label: "Friend (Quaker)"
        },
        {
          value: 13,
          label: "Hindu"
        },
        {
          value: 14,
          label: "Jain"
        },
        {
          value: 15,
          label: "Jehovah's Witness"
        },
        {
          value: 16,
          label: "Jewish"
        },
        {
          value: 17,
          label: "Latter-day Saint (Mormon)"
        },
        {
          value: 18,
          label: "Lutheran"
        },
        {
          value: 42,
          label: "Mennonite"
        },
        {
          value: 19,
          label: "Methodist"
        },
        {
          value: 20,
          label: "Moravian"
        },
        {
          value: 46,
          label: "Muist"
        },
        {
          value: 21,
          label: "Muslim"
        },
        {
          value: 37,
          label: "None"
        },
        {
          value: 45,
          label: "Oriental Orthodoxy"
        },
        {
          value: 36,
          label: "Other"
        },
        {
          value: 35,
          label: "Other - Christian"
        },
        {
          value: 23,
          label: "Pentecostal"
        },
        {
          value: 24,
          label: "Presbyterian"
        },
        {
          value: 25,
          label: "Rastafari"
        },
        {
          value: 26,
          label: "Reformed"
        },
        {
          value: 5,
          label: "Roman Catholic"
        },
        {
          value: 39,
          label: "Scientology"
        },
        {
          value: 27,
          label: "Seicho-no-Ie-ist"
        },
        {
          value: 28,
          label: "Seventh Day Adventist"
        },
        {
          value: 47,
          label: "Shintoist"
        },
        {
          value: 29,
          label: "Sikh"
        },
        {
          value: 30,
          label: "Taoist"
        },
        {
          value: 31,
          label: "Tenrikyoist"
        },
        {
          value: 32,
          label: "Unitarian Universalist (UU)"
        },
        {
          value: 33,
          label: "Wiccan (Pagan)"
        },
        {
          value: 34,
          label: "Yazidi"
        }
      ];
    }
  },
  methods: {
    changeValue(value) {
      this.$emit("change", value);
    }
  }
};
</script>
