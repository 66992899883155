<template>
  <div>
    <h4>
      {{ $t('counselingProfile.previewEssay') }}
      <el-tooltip
        :content="$t('counselingProfile.editEssay')"
        placement="top"
        popper-class="tooltipColor"
      >
        <a :href="essayUrl" target="blank">
          <span class="action-icon">
            <i class="fas fa-edit" />
          </span>
        </a>
      </el-tooltip>
      <el-tooltip
        :content="$t('counselingProfile.downloadEssayFromGoogle')"
        placement="top"
        popper-class="tooltipColor"
      >
        <span class="action-icon" @click="() => fetchEssayContent()">
          <i class="fas fa-sync"></i>
        </span>
      </el-tooltip>
    </h4>
    <div class="essayPreviewer">
      <a class="editEssayIcon" :href="essayUrl" target="blank">
        <el-tooltip
          :content="$t('counselingProfile.editEssay')"
          placement="top"
          popper-class="tooltipColor"
        >
          <span class="action-icon">
            <i class="fas fa-edit" />
          </span>
        </el-tooltip>
      </a>
      <div class="essayContent" v-html="essayContent" />
    </div>
    <span
      v-if="limitWord"
      :class="isValidWordCount ? 'validCount' : 'invalidCount'"
    >
      {{ `Word count: ${wordCount}/${essay.word_max} ` }}
    </span>
    <span
      v-if="limitLetter"
      :class="isValidLetterCount ? 'validCount' : 'invalidCount'"
    >
      {{ `Letter count: ${letterCount}/${essay.letter_max}` }}
    </span>
  </div>
</template>

<script>
import essayApi from "@/apis/essay";
export default {
  props: {
    essay: {
      type: Object,
      default: () => ({
        student_essay: {}
      })
    }
  },
  components: {},
  computed: {
    studentEssayId() {
      return this.essay.student_essay.id;
    },
    essayUrl() {
      return this.essay.student_essay.essay_url;
    },
    limitTypes() {
      return this.essay.limit_types;
    },
    limitWord() {
      const LIMIT_WORD_TYPE = 1;
      return this.limitTypes.includes(LIMIT_WORD_TYPE);
    },
    limitLetter() {
      const LIMIT_LETTER_TYPE = 2;
      return this.limitTypes.includes(LIMIT_LETTER_TYPE);
    },
    paragraphDivStyle() {
      return "word-wrap: break-word; margin-bottom: 8px";
    },
    isValidWordCount() {
      return (
        this.essay.word_min < this.wordCount &&
        this.wordCount < this.essay.word_max
      );
    },
    isValidLetterCount() {
      return (
        this.essay.letter_min < this.letterCount &&
        this.letterCount < this.essay.letter_max
      );
    }
  },
  data() {
    return {
      wordCount: 0,
      letterCount: 0,
      essayContent: ""
    };
  },
  async created() {
    await this.fetchEssayContent();
    this.$emit("setEssayContent", this.removeStyle(this.essayContent));
    this.computeEssayWordCount();
    this.computeEssayLetterCount();
  },
  methods: {
    removeStyle(essayContent) {
      return essayContent.replaceAll(` style="${this.paragraphDivStyle}"`, "");
    },
    removeHtmlTag(essayContent) {
      let workEssayContent = essayContent;
      const htmlTags = [
        "<u>",
        "</u>",
        "<i>",
        "</i>",
        "<b>",
        "</b>",
        `<div style="${this.paragraphDivStyle}">`,
        "</div>"
      ];
      htmlTags.forEach((htmlTag) => {
        workEssayContent = workEssayContent.replaceAll(htmlTag, "");
      });
      return workEssayContent;
    },
    computeEssayWordCount() {
      let essayContent = this.essayContent;
      essayContent = essayContent.replaceAll(" - ", " ");
      essayContent = essayContent.replaceAll("\n", " ");
      essayContent = this.removeHtmlTag(essayContent);
      const words = essayContent.split(" ").filter((word) => word !== "");
      this.wordCount = words.length;
    },
    computeEssayLetterCount() {
      let essayContent = this.essayContent;
      essayContent = essayContent.replaceAll("\n", "");
      essayContent = this.removeHtmlTag(essayContent);
      this.letterCount = essayContent.length;
    },
    async fetchEssayContent() {
      const {
        doc_content
      } = await essayApi.getThirdApplicationEssayPromptContent(
        this.studentEssayId
      );
      let paragraphs = doc_content
        .filter(({ paragraph }) => paragraph)
        .map(({ paragraph }) => paragraph);

      // 要忽略預設的內容（前三段）
      paragraphs = paragraphs.slice(3);
      this.setEssayContent(paragraphs);
    },
    setEssayContent(paragraphs) {
      let startEssayContent = false;
      let workEssayContent = "";
      for (const paragraph of paragraphs) {
        let essayContent = `<div style="${this.paragraphDivStyle}">`;
        paragraph.elements.forEach((element) => {
          const { textStyle, content } = element.textRun;
          essayContent += this.decorateContent(
            textStyle, content
          );
        });
        essayContent += "</div>";

        if (!startEssayContent) {
          if (essayContent === `<div style="${this.paragraphDivStyle}">\n</div>`) {
            continue;
          } else {
            startEssayContent = true;
          }
        }
        if (startEssayContent && essayContent === `<div style="${this.paragraphDivStyle}">\n</div>`) {
          essayContent = "<br />";
        }
        workEssayContent += essayContent;
      }
      this.essayContent = workEssayContent;
    },
    decorateContent(textStyle, content) {
      let startTag = "";
      let endTag = "";
      for (const textStyleType in textStyle) {
        if (textStyle[textStyleType]) {
          switch (textStyleType) {
            case "underline":
              startTag = `<u>${startTag}`;
              endTag = `${endTag}</u>`;
              break;
            case "bold":
              startTag = `<b>${startTag}`;
              endTag = `${endTag}</b>`;
              break;
            case "italic":
              startTag = `<i>${startTag}`;
              endTag = `${endTag}</i>`;
              break;
            default:
          }
        }
      }

      return `${startTag}${content}${endTag}`;
    }
  }
};
</script>
<style scoped>
.essayPreviewer {
  font-size: 14px;
  border: 1px solid #696969;
  border-radius: 4px;
}

.essayContent {
  padding: 2px 4px;
  border-radius: 4px;
}

.editEssayIcon {
  margin-top: -8px;
  font-size: 26px;
  float: right;
  visibility: hidden;
}

.essayPreviewer:hover > .editEssayIcon {
  visibility: visible;
}

.validCount {
  color: #3e975f;
}

.invalidCount {
  color: red;
}
</style>