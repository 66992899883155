<template>
  <el-select
    style="width: 100%"
    filterable
    :value="value"
    @change="changeValue"
    :size="size"
    placeholder
  >
    <el-option
      v-for="item in data"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    />
  </el-select>
</template>

<script>
export default {
  name: "EducationLevel",
  props: {
    value: {
      type: String,
      default: ""
    },
    size: {
      type: String,
      default: ""
    }
  },
  computed: {
    data() {
      return [
        { label: "None", value: "0" },
        { label: "Some grade/primary school", value: "1" },
        { label: "Completed grade/primary school", value: "2" },
        { label: "Some high/secondary school", value: "3" },
        {
          label: "Graduated from high/secondary school (or equivalent)",
          value: "4"
        },
        { label: "Some trade school or community college", value: "10" },
        {
          label: "Graduated from trade school or community college",
          value: "11"
        },
        { label: "Some college/university", value: "7" },
        { label: "Graduated from college/university", value: "8" },
        { label: "Graduate school", value: "9" }
      ];
    }
  },
  methods: {
    changeValue(value) {
      this.$emit("change", value);
    }
  }
};
</script>
