<template>
  <div>
    <CounselingFormItem
      required
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.california_app]"
    >
      <template slot="label">
        What's the name of the award or honor?
      </template>
      <el-input
        :value="activity.award_name"
        @input="(award_name) => $emit('setActivity', { award_name })"
      />
    </CounselingFormItem>
    <CounselingFormItem
      required
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.california_app]"
    >
      <template slot="label">
        Level of recognition
      </template>
      <div style="display: inline-block; width: 100%;">
        <el-checkbox-group
          :value="activity.is_award_levels"
          @input="(is_award_levels) => $emit('setActivity', { is_award_levels })"
        >
          <div
            :key="awardLevel.label"
            v-for="awardLevel in awardLevels"
          >
            <el-checkbox :label="awardLevel.value">
              {{ awardLevel.label }}
            </el-checkbox>
          </div>
        </el-checkbox-group>
      </div>
    </CounselingFormItem>
    <CounselingFormItem
      required
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.california_app]"
    >
      <template slot="label">
        Type of award or honor
      </template>
      <el-radio-group
        style="width: 100%"
        :value="activity.award_type"
        @input="(award_type) => $emit('setActivity', { award_type })"
      >
        <div><el-radio label="A">Academic (for example: Honor societies, academic competitions & programs, grade-based & department awards)</el-radio></div>
        <div><el-radio label="O">Non-academic (for example: Athletics, leadership, volunteering/community service)</el-radio></div>
      </el-radio-group>
    </CounselingFormItem>
    <CounselingFormItem
      required
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.california_app]"
    >
      <template slot="label">
        When did you receive it?
      </template>
      <template slot="promptText">
        If you received an award during the summer, select the grade you were in before that summer.
      </template>
      <ActivityOrAwardGradesCheckbox
        :value="activity.award_grades"
        @input="(award_grades) => $emit('setActivity', { award_grades })"
      />
    </CounselingFormItem>
    <CounselingFormItem
      required
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.california_app]"
    >
      <template slot="label">
        What are the eligibility requirements for this award or honor?
      </template>
      <template slot="promptText">
        For example: How are award recipients chosen? How many people are selected to receive the award? Is there an application or nomination for the award?
      </template>
      <el-input
        type="textarea"
        :rows="5"
        maxlength="250"
        show-word-limit
        :value="activity.award_requirements"
        @input="(award_requirements) => $emit('setActivity', { award_requirements })"
      />
    </CounselingFormItem>
    <CounselingFormItem
      required
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.california_app]"
    >
      <template slot="label">
        What did you do to achieve this award or honor? 
      </template>
      <template slot="promptText">
        We'd like to understand what it took - on your part - to achieve this award. For instance: Were there multiple competitions that you had to participate in? How much time did you dedicate to winning this award?
      </template>
      <el-input
        type="textarea"
        :rows="5"
        maxlength="250"
        show-word-limit
        :value="activity.award_achievement"
        @input="(award_achievement) => $emit('setActivity', { award_achievement })"
      />
    </CounselingFormItem>
  </div>
</template>

<script>
import { thirdAppTypeDict } from "@/class/ThirdApp";
import CounselingFormItem from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/CounselingFormItem";
import ActivityOrAwardGradesCheckbox from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/ActivityForm/ActivityOrAwardDialog/Components/ActivityOrAwardGradesCheckbox";

export default {
  name: "ActivityOrAwardDialog",
  props: {
    currentApplicationType: {
      type: String,
      default: ""
    },
    activity: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    CounselingFormItem,
    ActivityOrAwardGradesCheckbox
  },
  computed: {
    thirdAppTypes() {
      return thirdAppTypeDict;
    },
    awardLevels() {
      return this.$store.getters["counselingProfile/awardLevelOptions"];
    }
  }
};
</script>

