<template>
  <div class="container">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item v-if="isEnrolledCounseling">
          <router-link
            :to="{
              name: 'counselingClassesDetail',
              params: { id: $route.params.id }
            }"
          >
            <IsOnlineIcon :isOnline="sessionClass.course_session.is_online" />
            {{ sessionClass.title }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item v-else>
          <router-link :to="{ name: 'counseling' }">
            Counseling
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <router-link
            :to="
              isEnrolledCounseling
                ? {
                    name: 'counselingThirdApp',
                    params: { id: $route.params.id }
                  }
                : {
                    name: 'counselingUserApplication',
                    params: { username: $route.params.username }
                  }
            "
          >
            Application Forms
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ pageTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <el-dialog
      :title="$t('message.notice')"
      :visible.sync="thirdAppExtraSchoolsDialogVisible"
      width="1000px"
    >
      <strong>
        {{ `${$t('application.details.syncWillDelete')} ${thirdAppName} ${$t('application.details.collegeMayResultErrorPleaseGoTo')} ${thirdAppName} ${$t('application.details.andDeleteFollowingColleges')}` }}
      </strong>
      <el-table :data="thirdAppExtraSchools">
        <el-table-column label="School">
          <template slot-scope="scope">
            <router-link
              :to="{
                name: 'School',
                params: { schoolId: scope.row.slug, type: 'overview' }
              }"
            >
              <span v-if="lang === 'EN-US'">{{
                JSON.parse(scope.row.name)[langList[lang]]
              }}</span>
              <span v-else
                >{{ JSON.parse(scope.row.name)[langList[lang]] }}&nbsp;({{
                  JSON.parse(scope.row.name)["en"]
                }})</span
              >
            </router-link>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="thirdAppExtraSchoolsDialogVisible = false">
          {{ $t("button.cancel") }}
        </el-button>
        <el-button type="primary" @click="syncCollegesToThirdApp">
          {{ `${$t('application.details.uploadCollegeListTo')} ${thirdAppName} ${$t('application.details.onApplication')}` }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="$t('application.details.synCollegeList')"
      :visible.sync="syncCollegeDialogVisible"
      width="1200px"
    >
      <div class="syncSchoolList">
        <div>
          <h5>{{ `${$t('application.details.inIvyway')}${thirdAppName}${$t('application.details.collegeList')}` }}</h5>
          <el-table :data="thirdApp.schools">
            <el-table-column label="#" type="index" />
            <el-table-column label="School">
              <template slot-scope="scope">
                <router-link
                  :to="{
                    name: 'School',
                    params: { schoolId: scope.row.school.slug, type: 'overview' }
                  }"
                >
                  <span v-if="lang === 'EN-US'">{{
                    scope.row.school.name[langList[lang]]
                  }}</span>
                  <span v-else
                    >{{ scope.row.school.name[langList[lang]] }}&nbsp;({{
                      scope.row.school.name["en"]
                    }})</span
                  >
                </router-link>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="syncBtns">
          <el-tooltip
            popper-class="tooltipColor"
            :content="$t('application.details.downloadCollegeListToIvyway')"
            placement="top"
          >
            <el-button type="primary" @click="syncCollegesToIvyWay">
              <i class="fas fa-arrow-left" />{{ $t('application.details.downloadColleges') }}
            </el-button>
          </el-tooltip>
          <br />
          <el-tooltip
            popper-class="tooltipColor"
            :content="`${$t('application.details.uploadCollegeListTo')}${thirdAppName}${$t('application.details.application')}`"
            placement="top"
          >
            <el-button type="primary" @click="compareThirdAppAndIvyWaySchool">
              <i class="fas fa-arrow-right" />{{ $t('application.details.uploadCollegeList') }}
            </el-button>
          </el-tooltip>
        </div>
        <div>
          <h5>{{ `${$t('application.details.myCollegeListOn')}${thirdAppName}${$t('application.details.onCollegeList')}` }}</h5>
          <el-table :data="schoolsOfThird">
            <el-table-column label="#" type="index" />
            <el-table-column label="School">
              <template slot-scope="scope">
                <router-link
                  :to="{
                    name: 'School',
                    params: { schoolId: scope.row.slug, type: 'overview' }
                  }"
                >
                  <span v-if="lang === 'EN-US'">{{
                    JSON.parse(scope.row.name)[langList[lang]]
                  }}</span>
                  <span v-else
                    >{{ JSON.parse(scope.row.name)[langList[lang]] }}&nbsp;({{
                      JSON.parse(scope.row.name)["en"]
                    }})</span
                  >
                </router-link>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="() => toggleSyncCollegeDialogVisible(false)">
          {{ $t("button.cancel") }}
        </el-button>
      </span>
    </el-dialog>
    <div class="title">
      <template v-if="pageIsAllApplication">
        <el-alert
          v-if="thirdAppsNotSetAccoundInfoYet.length === 0"
          :title="$t('application.details.yourAccountIsConnected')"
          type="info"
          show-icon
          :closable="false"
        />
        <el-alert
          :key="thirdAppNotSetAccoundInfoYet.thirdServiceType"
          v-for="thirdAppNotSetAccoundInfoYet in thirdAppsNotSetAccoundInfoYet"
          type="info"
          show-icon
          :closable="false"
        >
          <template slot="title">
            {{ `${$t('application.details.pleaseGoTo')}` }}
            <a target="_blank" :href="applicationUrl(thirdAppNotSetAccoundInfoYet.thirdServiceType)">
              {{ thirdAppTypeDict[thirdAppNotSetAccoundInfoYet.thirdServiceType] }}
            </a>
            {{ `${$t('application.details.toRegisterAndEnterLoginInfo')}` }}
          </template>
        </el-alert>
      </template>
      <template v-else>
        <el-alert
          v-if="thirdApp.account"
          :title="$t('application.details.yourAccountIsConnected')"
          type="info"
          show-icon
          :closable="false"
        />
        <el-alert
          v-else
          type="info"
          show-icon
          :closable="false"
        >
          <template slot="title">
            {{ `${$t('application.details.pleaseGoTo')}` }}
            <a target="_blank" :href="applicationUrl($route.params.thirdAppType)">{{ pageTitle }}</a>
            {{ `${$t('application.details.toRegisterAndEnterLoginInfo')}` }}
          </template>
        </el-alert>
      </template>
      <div class="applicationHeader">
        <h1>
          Application Forms - 
        </h1>
        <el-select
          :value="$route.params.thirdAppType"
          @change="goToChoiceApplicationDetailPage"
        >
          <el-option value="all" :label="$t('application.allApplications')" />
          <el-option
            :value="thirdAppType"
            :label="thirdAppTypeDict[thirdAppType]"
            :key="thirdAppType"
            v-for="thirdAppType in Object.keys(thirdAppTypeDict)"
          />
        </el-select>
      </div>
    </div>
    <el-tabs v-model="activeTabTitle" type="card">
      <el-tab-pane :label="$t('application.details.myApplication')">
        <h2>
          {{ $t('application.details.myApplication') }}
          <el-tooltip
            popper-class="tooltipColor"
            :content="$t('application.details.uploadApplication')"
            placement="top"
          >
            <span
              class="action-icon"
              @click="() => syncCounselingProfileToThirdApp()"
            >
              <i class="fas fa-upload" />
            </span>
          </el-tooltip>
        </h2>
        <CounselingProfileForm :studentUserId="studentUserId" />
      </el-tab-pane>
      <el-tab-pane :label="collegeTabTitle">
        <h2>
          {{ collegeTabTitle }}
          <router-link
            v-if="isEnrolledCounseling"
            :to="{
              name: 'addCounselingThirdAppSchool',
              params: {
                counselingId: $route.params.id,
                thirdAppType: $route.params.thirdAppType
              }
            }"
          >
            <i class="fas fa-plus action-icon" />
          </router-link>
          <router-link v-else :to="{ name: 'SchoolsList' }">
            <i class="fas fa-plus action-icon" />
          </router-link>
          <el-tooltip
            popper-class="tooltipColor"
            :content="$t('application.details.uploadCollegeList')"
            placement="top"
          >
            <span
              class="action-icon"
              @click="() => toggleSyncCollegeDialogVisible(true)"
            >
              <i class="fas fa-upload" />
            </span>
          </el-tooltip>
        </h2>
        <template v-if="pageIsAllApplication">
          <CollectCollegeTable
            :enableFilterBtns="false"
            :counselingId="Number($route.params.id)"
            :collectedCounselingSchools="allThirdAppSchools"
            @removeSchoolFromFavorite="
              relatedSchoolId => $emit('removeSchoolFromFavorite', relatedSchoolId)
            "
          />
        </template>
        <template v-else>
          <CollectCollegeTable
            :enableFilterBtns="false"
            :counselingId="Number($route.params.id)"
            :collectedCounselingSchools="thirdApp.schools"
            @removeSchoolFromFavorite="
              relatedSchoolId => $emit('removeSchoolFromFavorite', relatedSchoolId)
            "
          />
        </template>
      </el-tab-pane>
      <el-tab-pane label="Essays">
        <hr class="separate-line" />
        <ApplicationEssays
          :isEnrolledCounseling="isEnrolledCounseling"
          :studentId="studentUserId"
          :studentUsername="$route.params.username"
          :sessionClass="sessionClass"
          :filterThirdApplication="$route.params.thirdAppType === 'all' ? null : $route.params.thirdAppType"
        />
        <hr class="separate-line" />
        <SchoolEssays
          :isEnrolledCounseling="isEnrolledCounseling"
          :studentId="studentUserId"
          :sessionClass="sessionClass"
          :filterThirdApplication="$route.params.thirdAppType === 'all' ? null : $route.params.thirdAppType"
        />
      </el-tab-pane>
      <el-tab-pane name="loginInfo" :label="$t('application.details.loginInfo')">
        <template v-if="pageIsAllApplication">
          <ThirdAppLoginInformation
            :key="thirdAppDetail.thirdServiceType"
            v-for="thirdAppDetail in thirdAppsDetail"
            :pageTitle="`${thirdAppTypeDict[thirdAppDetail.thirdServiceType]}`"
            :thirdApp="thirdAppDetail"
            :studentUserId="studentUserId"
            :thirdAppName="thirdAppTypeDict[thirdAppDetail.thirdServiceType]"
            :editMode="Boolean($route.query.isEditLoginInfo)"
            @fetchThirdAccount="fetchThirdAccountsDetail"
          />
        </template>
        <template v-else>
          <ThirdAppLoginInformation
            :pageTitle="$t('application.details.loginInfo')"
            :thirdApp="thirdApp"
            :studentUserId="studentUserId"
            :thirdAppName="thirdAppName"
            :editMode="Boolean($route.query.isEditLoginInfo)"
            @fetchThirdAccount="fetchThirdAccount"
          />
        </template>
      </el-tab-pane>
    </el-tabs>
    <!--這些一定要在最後面，不然會被擋住-->
    <LoggingInDialog :isVisible="loggingInDialogVisible" />
    <FetchingThirdAppSchoolsDialog :isVisible="fetchingThirdAppSchoolsDialogVisible" />
    <AsyncSchoolsToThirdAppDialog :isVisible="asyncSchoolsToThirdAppDialogVisible" />
    <LoginFailedDialog
      :isVisible="accountInfoIsWrongDialogVisible"
      :thirdAppTypeName="thirdAppName"
      @onCancel="() => accountInfoIsWrongDialogVisible = false"
      @onInputAccountInfo="() => accountInfoIsWrongDialogVisible = false"
    />
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import IsOnlineIcon from "@/components/icon/IsOnline";
import CounselingProfileForm from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/CounselingProfileForm";
import CollectCollegeTable from "@/components/school/CollectCollegeTable";
import ThirdAppLoginInformation from "@/views/private/Counseling/ThirdApp/ThirdAppLoginInformation";
import ApplicationEssays from "@/views/private/Counseling/EssayComponets/ApplicationEssays";
import SchoolEssays from "@/views/private/Counseling/EssayComponets/SchoolEssays";
import LoggingInDialog from "@/views/private/Counseling/ThirdApp/Dialog/LoggingIn";
import FetchingThirdAppSchoolsDialog from "@/views/private/Counseling/ThirdApp/Dialog/FetchingThirdAppSchools";
import AsyncSchoolsToThirdAppDialog from "@/views/private/Counseling/ThirdApp/Dialog/AsyncSchoolsToThirdApp";
import LoginFailedDialog from "@/views/private/Counseling/ThirdApp/Dialog/LoginFailed";
import privateApi from "@/apis/private";
import profileApi from "@/apis/profile";
import { thirdAppTypeDict } from "@/class/ThirdApp";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },
  components: {
    Breadcrumb,
    IsOnlineIcon,
    CounselingProfileForm,
    CollectCollegeTable,
    LoggingInDialog,
    FetchingThirdAppSchoolsDialog,
    AsyncSchoolsToThirdAppDialog,
    LoginFailedDialog,
    ThirdAppLoginInformation,
    ApplicationEssays,
    SchoolEssays
  },
  computed: {
    pageTitle() {
      return this.thirdAppName;
    },
    thirdAppTypeDict() {
      return thirdAppTypeDict;
    },
    thirdAppName() {
      if (this.$route.params.thirdAppType === "all") {
        return this.$t("application.allApplications");
      } else {
        return thirdAppTypeDict[this.$route.params.thirdAppType];
      }
    },
    thirdApp() {
      return this.$store.getters["thirdApp/thirdApp"];
    },
    thirdAppsDetail() {
      return this.$store.getters["thirdApp/thirdAppsDetail"];
    },
    thirdAppsNotSetAccoundInfoYet() {
      return this.thirdAppsDetail.filter(({ account }) => !account);
    },
    pageIsAllApplication() {
      return this.$route.params.thirdAppType === "all";
    },
    collegeTabTitle() {
      return this.pageIsAllApplication ?
        this.$t("application.details.myCollegeList") :
        `${this.$t("application.details.myCollegeList1")}${this.thirdAppName}${this.$t("application.details.myCollegeList2")}`;
    },
    allThirdAppSchools() {
      let thirdAppSchools = [];
      for (const thirdAppDetail of this.thirdAppsDetail) {
        thirdAppSchools = [...thirdAppSchools, ...thirdAppDetail.schools];
      }
      return thirdAppSchools;
    },
    schoolsOfThird() {
      return this.$store.getters["thirdApp/schoolsOfThird"];
    },
    lang() {
      return this.$store.getters["user/langValue"];
    },
    langList() {
      return {
        "ZH-TW": "tw",
        "EN-US": "en",
        "ZH-CN": "cn"
      };
    },
    isEnrolledCounseling() {
      return this.$route.name === "counselingThirdAppDetail";
    }
  },
  data() {
    return {
      studentUserId: null,
      syncCollegeDialogVisible: false,
      thirdAppExtraSchoolsDialogVisible: false,
      fetchingThirdAppSchoolsDialogVisible: false,
      asyncSchoolsToThirdAppDialogVisible: false,
      loggingInDialogVisible: false,
      accountInfoIsWrongDialogVisible: false,
      thirdAppExtraSchools: [],
      sessionClass: {
        course_session: {}
      },
      activeTabTitle: "0"
    };
  },
  async created() {
    if (this.isEnrolledCounseling) {
      await this.fetchCustomLesson();
    } else {
      const student = await this.fetchUserProfile();
      this.studentUserId = student.id;
    }
    if (this.pageIsAllApplication) {
      this.fetchThirdAccountsDetail();
    } else {
      this.fetchThirdAccount();
    }

    if (this.$route.query.isEditLoginInfo) {
      this.activeTabTitle = "loginInfo";
    }
  },
  methods: {
    applicationUrl(thirdAppType) {
      switch (thirdAppTypeDict[thirdAppType]) {
        case thirdAppTypeDict.common_app:
          return "https://apply.commonapp.org/login?timestamp=1629355478101";
        case thirdAppTypeDict.coalition_app:
          return "https://shibboleth-idp.collegenet.com/idp/profile/SAML2/Redirect/SSO?SAMLRequest=fZJLU8IwFIX%2FSif7Nn3waoYyg7CQGZQORRdunBCukDFNam5q5d%2FbAipu2GWSL%2Bfcc5Ix8lJVbFq7g17DRw3ovK9SaWSng4zUVjPDUSLTvARkTrBi%2BrBkcRCyyhpnhFHEmyKCddLomdFYl2ALsJ9SwNN6mZGDcxUySpumCcqjMFzJDg2M3dP9Oy0Ocrs1CtwhQDS0U49pvio2xJu340jNO%2FpPBn95X%2B6qoPVXsAcNrl2WtN2i7VxvUsFFag07aUE4WhQr4i3mGXndpkMeiX6vJ4aJSPo8FQlPeRqF4WAEu0HSYog1LDQ6rl1G4jCO%2FHDkx%2BEmClkyYnH6Qrz8Ev9O6p3U%2B9tdbc8QsvvNJvfP8Z7B4ilaC5DJuGucnYzt1RvcluU%2FxZPJrZqvOsOKjumV1dm3Yo%2Bt9mKeGyXF0ZsqZZqZBe4gIxGhk%2FOV%2Fx9l8g0%3D&RelayState=https%3A%2F%2Fwww.mycoalition.org%2Fgk";
        case thirdAppTypeDict.california_app:
          return "https://apply.universityofcalifornia.edu/my-application/login";
        default:
          return "";
      }
    },
    openAccountEditable() {
      this.newThirdApp = { ...this.thirdApp };
      this.accountEditable = true;
    },
    closeAccountEditable() {
      this.accountEditable = false;
    },
    updateAccount() {
      this.checkingAccountInfoDialogVisible = true;
      this.$store.dispatch(
        "thirdApp/storeThirdAccount",
        {
          studentUserId: this.studentUserId,
          thirdApp: this.newThirdApp,
          success: () => {
            this.fetchThirdAccount();
            this.accountEditable = false;
            this.checkingAccountInfoDialogVisible = false;
          },
          error: () => {
            this.checkingAccountInfoDialogVisible = false;
            this.accountInfoIsWrongDialogVisible = true;
          }
        }
      );
    },
    fetchThirdAccount() {
      this.$store.dispatch(
        "thirdApp/getThirdAccount", {
          thirdAppType: this.$route.params.thirdAppType,
          studentUserId: this.studentUserId
        }
      );
    },
    fetchThirdAccountsDetail() {
      this.$store.dispatch(
        "thirdApp/getThirdAccountsDetail", {
          studentUserId: this.studentUserId
        }
      );
    },
    async fetchCustomLesson() {
      const customLesson = await privateApi.getCustomLesson({
        class_id: this.$route.params.id
      });
      this.studentUserId = customLesson.student_class_tickets[0].student_user_id;
      this.sessionClass = {
        ...customLesson.session_class
      };
    },
    async fetchUserProfile() {
      const { basic_info, student: { counseling_schools_type } } = await profileApi.getUserByName(
        this.$route.params.username
      );
      this.student = { ...basic_info, counseling_schools_type };
      return this.student;
    },
    compareThirdAppAndIvyWaySchool() {
      const ivywaySchoolsIdMap = {};
      this.thirdApp.schools.forEach(({ school_id }) => { ivywaySchoolsIdMap[school_id] = true; });

      const extraSchools = this.schoolsOfThird.filter(({ id }) => (
        !ivywaySchoolsIdMap[id]
      ));
      if (extraSchools.length === 0) {
        this.syncCollegesToThirdApp();
      } else {
        this.thirdAppExtraSchools = extraSchools;
        this.thirdAppExtraSchoolsDialogVisible = true;
      }
    },
    syncCollegesToThirdApp() {
      this.$store.dispatch("thirdApp/syncCollegesToThirdApp", {
        thirdServiceType: this.$route.params.thirdAppType,
        studentUserId: this.studentUserId,
        before: () => { this.loggingInDialogVisible = true; },
        running: () => {
          this.loggingInDialogVisible = false;
          this.asyncSchoolsToThirdAppDialogVisible = true;
        },
        after: () => {
          this.loggingInDialogVisible = false;
          this.asyncSchoolsToThirdAppDialogVisible = false;
        },
        error: () => {
          this.loggingInDialogVisible = false;
          this.asyncSchoolsToThirdAppDialogVisible = false;
          this.accountInfoIsWrongDialogVisible = true;
        }
      });
      this.thirdAppExtraSchoolsDialogVisible = false;
    },
    async syncCollegesToIvyWay() {
      const storeSchoolIds = this.schoolsOfThird.map((school) => school.id);
      const removeSchoolsIds = this.$store.getters["thirdApp/thirdApp"].schools
        .map(({ school_id }) => school_id);

      await this.$store.dispatch("collectSchool/syncCollegesToIvyWay", {
        storeSchoolIds,
        removeSchoolsIds,
        thirdServiceType: this.$route.params.thirdAppType,
        studentUserId: this.studentUserId
      });
      this.fetchThirdAccount();
    },
    async syncCounselingProfileToThirdApp() {
      this.$store.dispatch("thirdApp/syncCounselingProfileToThirdApp", {
        thirdServiceType: this.$route.params.thirdAppType,
        studentUserId: this.studentUserId,
        before: () => { this.loggingInDialogVisible = true; },
        running: () => {
          this.loggingInDialogVisible = false;
        },
        after: () => {
          this.loggingInDialogVisible = false;
        },
        error: () => {
          this.loggingInDialogVisible = false;
        }
      });
    },
    goToChoiceApplicationDetailPage(applicationType) {
      this.$router.push({
        name: this.$route.name,
        params: {
          ...this.$route.params,
          thirdAppType: applicationType
        }
      });
    },
    toggleSyncCollegeDialogVisible(status) {
      this.syncCollegeDialogVisible = status;
      if (status) {
        this.$store.dispatch("thirdApp/getThirdColleges", {
          studentUserId: this.studentUserId,
          thirdAppType: this.$route.params.thirdAppType,
          before: () => { this.loggingInDialogVisible = true; },
          running: () => {
            this.loggingInDialogVisible = false;
            this.fetchingThirdAppSchoolsDialogVisible = true;
          },
          after: () => {
            this.loggingInDialogVisible = false;
            this.fetchingThirdAppSchoolsDialogVisible = false;
          },
          error: () => {
            this.loggingInDialogVisible = false;
            this.fetchingThirdAppSchoolsDialogVisible = false;
            this.accountInfoIsWrongDialogVisible = true;
          }
        });
      }
    }
  }
};
</script>

<style scoped>
.applicationHeader {
  display: flex;
  align-items: center;
}

.applicationHeader > h1 {
  margin-right: 8px;
}

.prompt {
  color: #999
}

.syncSchoolList {
  display: grid;
  grid-template-columns: 450px 200px 450px;
}

.syncSchoolList > .syncBtns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

::v-deep .el-alert--info {
  margin: 8px 0px;
}
</style>
