<template>
  <el-select
    filterable
    :value="value"
    @change="changeValue"
    :size="size"
    placeholder=""
  >
    <el-option
      v-for="item in data"
      :key="item.id"
      :label="item.title"
      :value="item.id"
    />
  </el-select>
</template>

<script>
export default {
  name: "Language",
  props: {
    value: {
      type: Number,
      default: null
    },
    size: {
      type: String,
      default: ""
    }
  },
  computed: {
    data() {
      return this.$store.getters["counselingProfile/languageOptions"];
    }
  },
  created() {
    this.$store.dispatch("counselingProfile/fetchLanguagesOfCounselingProfile");
  },
  methods: {
    changeValue(value) {
      this.$emit("change", value);
    }
  }
};
</script>
