<template>
  <el-select
    style="width: 100%"
    filterable
    :value="value"
    @change="changeValue"
    :size="size"
    placeholder
  >
    <el-option
      v-for="item in data"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    />
  </el-select>
</template>

<script>
export default {
  name: "courseLevel",
  props: {
    value: {
      type: String,
      default: ""
    },
    size: {
      type: String,
      default: ""
    }
  },
  computed: {
    data() {
      return [
        { value: "1", label: "Regular/Standard" },
        { value: "0", label: "Accelerated" },
        { value: "9", label: "Advanced" },
        { value: "2", label: "Advanced Placement (AP)" },
        {
          value: "3",
          label: "AS/A-level/International A-level, Cambridge AICE"
        },
        { value: "8", label: "College Prep" },
        { value: "4", label: "Dual Enrollment" },
        { value: "10", label: "Enriched" },
        { value: "16", label: "GCSE, IGCSE" },
        { value: "11", label: "Gifted" },
        { value: "12", label: "High Honors" },
        { value: "5", label: "Honors" },
        { value: "13", label: "Intensive" },
        { value: "6", label: "International Baccalaureate (IB)" },
        { value: "14", label: "Pre-IB" },
        { value: "7", label: "Regents" },
        { value: "15", label: "N/A" }
      ];
    }
  },
  methods: {
    changeValue(value) {
      this.$emit("change", value);
    }
  }
};
</script>
