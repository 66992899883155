<template>
  <el-select
    style="width: 100%"
    filterable
    :value="value"
    @change="changeValue"
    :size="size"
    placeholder
  >
    <el-option
      v-for="item in data"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    />
  </el-select>
</template>

<script>
export default {
  name: "ExaminationBoard",
  props: {
    value: {
      type: String,
      default: ""
    },
    size: {
      type: String,
      default: ""
    }
  },
  computed: {
    data() {
      return [
        { value: "0", label: "IB Diploma" },
        { value: "1", label: "Abitur" },
        { value: "2", label: "Apolytirion" },
        { value: "3", label: "Baccalauréat" },
        { value: "4", label: "Bagrut" },
        { value: "5", label: "CAPE" },
        { value: "6", label: "Center Shiken" },
        { value: "26", label: "CIE Pre-U" },
        { value: "7", label: "Concurso Nacional" },
        { value: "8", label: "ENTER/TER/UAI" },
        { value: "9", label: "European Baccalaureate" },
        { value: "10", label: "Gaokao" },
        { value: "11", label: "GCE A-levels" },
        { value: "12", label: "Higher Level 6" },
        { value: "13", label: "HSC" },
        { value: "14", label: "ISC" },
        { value: "15", label: "KCSE" },
        { value: "16", label: "Leaving Cert" },
        { value: "17", label: "MATSEC" },
        { value: "18", label: "Matura/Maturita" },
        { value: "20", label: "Prueba de Selección Universitaria" },
        { value: "21", label: "STPM" },
        { value: "22", label: "UME" },
        { value: "23", label: "WASSCE" },
        { value: "25", label: "YGS-LYS" },
        { value: "24", label: "Other (specify below)" }
      ];
    }
  },
  methods: {
    changeValue(value) {
      this.$emit("change", value);
    }
  }
};
</script>
