<template>
  <el-select
    style="width: 100%"
    filterable
    :value="value"
    @change="changeValue"
    placeholder
  >
    <el-option
      v-for="item in data"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    />
  </el-select>
</template>

<script>
export default {
  name: "DegreeReceived",
  props: ["value"],
  computed: {
    data() {
      return [
        { value: "0", label: "Associate's (AA, AS)" },
        { value: "1", label: "Bachelor's (BA, BS)" },
        { value: "2", label: "Master's (MA, MS)" },
        { value: "3", label: "Business (MBA, MAcc)" },
        { value: "4", label: "Law (JD, LLM)" },
        { value: "5", label: "Medicine (MD, DO, DVM, DDS)" },
        { value: "6", label: "Doctorate (PhD, EdD, etc)" },
        { value: "7", label: "Other" }
      ];
    }
  },
  methods: {
    changeValue(value) {
      this.$emit("change", value);
    }
  }
};
</script>
