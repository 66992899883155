<template>
  <el-select filterable :value="value" @change="changeValue" placeholder>
    <el-option v-for="item in data" :key="item.value" :label="item.label" :value="item.value" />
  </el-select>
</template>

<script>
export default {
  name: "Occupation",
  props: ["value"],
  computed: {
    data() {
      return [
        { label: "Accountant or actuary", value: 0 },
        { label: "Actor or entertainer", value: 1 },
        { label: "Architect or urban planner", value: 2 },
        { label: "Artist", value: 3 },
        { label: "Business (clerical)", value: 4 },
        { label: "Business executive (management, administrator)", value: 5 },
        { label: "Business owner or proprietor", value: 6 },
        { label: "Business salesperson or buyer", value: 7 },
        { label: "Clergy (minister, priest)", value: 8 },
        { label: "Clergy (other religious)", value: 9 },
        { label: "Clinical psychologist", value: 10 },
        { label: "College administrator/staff", value: 11 },
        { label: "College teacher", value: 12 },
        { label: "Computer programmer or analyst", value: 13 },
        { label: "Conservationist or forester", value: 14 },
        { label: "Dentist (including orthodontist)", value: 15 },
        { label: "Dietitian or nutritionist", value: 16 },
        { label: "Engineer", value: 17 },
        { label: "Farmer or rancher", value: 18 },
        { label: "Foreign service worker (including diplomat)", value: 19 },
        { label: "Homemaker (full-time)", value: 20 },
        { label: "Interior decorator (including designer)", value: 21 },
        { label: "Lab technician or hygienist", value: 22 },
        { label: "Laborer", value: 42 },
        { label: "Law enforcement officer", value: 23 },
        { label: "Lawyer (attorney) or judge", value: 24 },
        { label: "Military service (career)", value: 25 },
        { label: "Musician (performer, composer)", value: 26 },
        { label: "Nurse", value: 27 },
        { label: "Optometrist", value: 28 },
        { label: "Pharmacist", value: 29 },
        { label: "Physician", value: 30 },
        { label: "Policymaker/Government", value: 31 },
        { label: "School counselor", value: 32 },
        { label: "School principal or superintendent", value: 33 },
        { label: "Scientific researcher", value: 34 },
        { label: "Skilled trades", value: 41 },
        { label: "Social, welfare, or recreation worker", value: 35 },
        { label: "Teacher or administrator (elementary)", value: 37 },
        { label: "Teacher or administrator (secondary)", value: 38 },
        { label: "Therapist (physical, occupational, speech)", value: 36 },
        { label: "Veterinarian", value: 39 },
        { label: "Writer or journalist", value: 40 },
        { label: "Other", value: 44 }
      ];
    }
  },
  methods: {
    changeValue(value) {
      this.$emit("change", value);
    }
  }
};
</script>
