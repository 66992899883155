var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('el-radio-group',{on:{"input":_vm.fetchCollectType},model:{value:(_vm.collectType),callback:function ($$v) {_vm.collectType=$$v},expression:"collectType"}},[_c('el-radio-button',{attrs:{"label":null}},[_vm._v(_vm._s(_vm.$t("enrollment.list.all")))]),_vm._l((_vm.collectTypes),function(collectType){return _c('el-radio-button',{key:collectType.name,class:_vm.getClassName(collectType.name),attrs:{"label":collectType.name}},[_vm._v(" "+_vm._s(_vm.$t(`schools.star.${collectType.i18nKey}`))+" ")])})],2)],1),_c('hr',{staticClass:"separate-line"}),_c('el-table',{attrs:{"data":_vm.displayCollectedCounselingSchools}},[_c('el-table-column',{attrs:{"label":"#","type":"index"}}),_c('el-table-column',{attrs:{"width":"320px","label":"School"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":{
            name: 'School',
            params: { schoolId: scope.row.school.slug, type: 'overview' }
          }}},[(_vm.$i18n.locale === 'en')?_c('span',[_vm._v(_vm._s(scope.row.school.name[_vm.$i18n.locale]))]):_c('span',[_vm._v(_vm._s(scope.row.school.name[_vm.$i18n.locale])+" ("+_vm._s(scope.row.school.name["en"])+")")])])]}}])}),_c('el-table-column',{attrs:{"width":"200px","label":_vm.$t('collectedSchool.form.category')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('CollectCollegeTypeTag',{attrs:{"type":scope.row.type}})]}}])}),_c('el-table-column',{attrs:{"width":"110px","label":_vm.$t('collectedSchool.form.applying')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(" "+_vm._s(scope.row.is_applying ? _vm.$t('collectedSchool.form.yes') : _vm.$t('collectedSchool.form.no'))+" ")])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('collectedSchool.form.applicationType'),"prop":"application_type"}}),_c('el-table-column',{attrs:{"label":_vm.$t('collectedSchool.form.applicationSubmissionDate')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.shortageOfApplicationType(scope.row.application_type))?_c('SelectApplicationTypeInfoIcon'):_c('span',[(scope.row.deadline)?_c('span',[_vm._v(" "+_vm._s(_vm.moment(scope.row.deadline).format("MM-DD"))+" ")]):_c('span',[_vm._v("-")])])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('collectedSchool.form.financialAidSubmissionDate')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.shortageOfApplicationType(scope.row.application_type))?_c('SelectApplicationTypeInfoIcon'):_c('span',[(scope.row.finaid_app_final_deadline)?_c('span',[_vm._v(" "+_vm._s(_vm.moment(scope.row.finaid_app_final_deadline).format("MM-DD"))+" ")]):_c('span',[_vm._v("-")])])]}}])}),_c('el-table-column',{attrs:{"width":"120px","label":"Action"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.counselingId)?[_c('router-link',{staticClass:"action-icon",attrs:{"to":{
              name: 'counselingCollectedColleges',
              params: { counselingId: _vm.counselingId, collectedCollegeId: scope.row.id }
            }}},[_c('i',{staticClass:"fa fa-eye"})]),_c('router-link',{staticClass:"action-icon",attrs:{"to":{
              name: 'editCounselingCollectedColleges',
              params: { counselingId: _vm.counselingId, collectedCollegeId: scope.row.id }
            }}},[_c('i',{staticClass:"fa fa-edit"})])]:(_vm.username)?[_c('router-link',{staticClass:"action-icon",attrs:{"to":{
              name: 'userCollectedSchool',
              params: { username: _vm.username, collectedCollegeId: scope.row.id }
            }}},[_c('i',{staticClass:"fa fa-eye"})]),_c('router-link',{staticClass:"action-icon",attrs:{"to":{
              name: 'editCounselingUserSchool',
              params: { username: _vm.username, collectedCollegeId: scope.row.id }
            }}},[_c('i',{staticClass:"fa fa-edit"})])]:_vm._e(),_c('span',{staticClass:"action-icon warning-icon",on:{"click":() => {
              _vm.removeSchoolFromFavorite(scope.row.id);
            }}},[_c('i',{staticClass:"fa fa-trash"})])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }