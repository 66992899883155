<template>
  <div>
    <CounselingFormItem
      required
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.common_app]"
    >
      <template slot="label">
        Total number of institutions attended
      </template>
      <div style="display: inline-block; width: 100%;">
        <el-radio-group
          style="width: 100%"
          :value="person.attended_institutions_number"
          @input="(attended_institutions_number) => $emit('setPerson', { attended_institutions_number })"
        >
          <div :key="num" v-for="num in 5">
            <el-radio :label="num">{{ num }}</el-radio>
          </div>
        </el-radio-group>
      </div>
    </CounselingFormItem>
    <div :key="value" v-for="(value, collegeIndex) in person.attended_institutions_number">
      <CounselingFormItem
        required
        :currentApplication="currentApplicationType"
        :applicationsNeedToFilling="[thirdAppTypes.common_app]"
      >
        <template slot="label">
          College lookup
        </template>
        <div v-if="!editingColleges[collegeIndex]">
          {{ person.colleges[collegeIndex].name }}
          <span
            class="editNameOfColleges"
            @click="() => setEditingColleges(collegeIndex, true)"
          >
            EDIT
          </span>
        </div>
        <div v-else>
          <CollegeSelector
            style="width: 100%"
            :value="person.colleges[collegeIndex].value"
            @change="({ value, title }) => $emit('setCollege', collegeIndex, { value, name: title })"
          />
        </div>
      </CounselingFormItem>
      <div v-if="!hasCollegeInList(collegeIndex)">
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            Name of college/university
          </template>
          <el-input
            :value="person.colleges[collegeIndex].name"
            @input="name => $emit('setCollege', collegeIndex, { name })"
          />
        </CounselingFormItem>
        <AddressForm
          :currentApplicationType="currentApplicationType"
          :address="person.colleges[collegeIndex]"
          @setAddress="data => $emit('setCollege', collegeIndex, { ...data })"
        />
      </div>
      <CounselingFormItem
        required
        :currentApplication="currentApplicationType"
        :applicationsNeedToFilling="[thirdAppTypes.common_app]"
      >
        <template slot="label">
          Number of degrees your parent received from this college
        </template>
        <el-select
          style="width: 100%"
          :value="person.colleges[collegeIndex].degrees_length"
          @change="(degrees_length) => $emit('setCollege', collegeIndex, { degrees_length })"
        >
          <el-option label="No degree" :value="0" />
          <el-option :key="num" v-for="num in 5" :label="num" :value="num" />
        </el-select>
      </CounselingFormItem>
      <div :key="value" v-for="(value, degreeIndex) in person.colleges[collegeIndex].degrees_length">
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            Degree received
          </template>
          <DegreeReceivedSelector
            :value="person.colleges[collegeIndex].degrees[degreeIndex].name"
            @change="(name) => $emit('setDegreeOfCollege', collegeIndex, degreeIndex, { name })"
          />
        </CounselingFormItem>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            Year received
          </template>
          <el-select
            style="width: 100%"
            :value="person.colleges[collegeIndex].degrees[degreeIndex].received_yr"
            @change="(received_yr) => $emit('setDegreeOfCollege', collegeIndex, degreeIndex, { received_yr })"
          >
            <el-option
              :key="receivedYear"
              v-for="receivedYear in receivedYears"
              :label="receivedYear"
              :value="receivedYear"
            />
          </el-select>
        </CounselingFormItem>
      </div>
    </div>
  </div>
</template>

<script>
import { thirdAppTypeDict } from "@/class/ThirdApp";
import CounselingFormItem from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/CounselingFormItem";
import AddressForm from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/AddressForm";
import CollegeSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/CollegeSelector";
import DegreeReceivedSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/FamilyForm/DegreeReceivedSelector";

export default {
  props: {
    currentApplicationType: {
      type: String,
      default: ""
    },
    person: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    CounselingFormItem,
    DegreeReceivedSelector,
    CollegeSelector,
    AddressForm
  },
  computed: {
    thirdAppTypes() {
      return thirdAppTypeDict;
    },
    receivedYears() {
      const nextYear = new Date().getFullYear() + 1;
      const yearPeriod = nextYear - 1900;
      return Array.from(Array(yearPeriod))
        .map((_v, index) => index + 1900)
        .sort((a, b) => b - a);
    },
    hasCollegeInList() {
      const didNotFind = "other";
      return (collegeIndex) => this.person.colleges[collegeIndex].value !== didNotFind;
    }
  },
  data() {
    return {
      editingColleges: []
    };
  },
  async created() {
    this.initEditingCollegesStatus();
  },
  methods: {
    initEditingCollegesStatus() {
      this.editingColleges = Array.from(
        Array(this.person.colleges.length)
      ).map((_, index) => !this.person.colleges[index].value);
    },
    setEditingColleges(collegeIndex, isEditing) {
      this.editingColleges = this.editingColleges.map((editingCollege, index) => {
        if (index === collegeIndex) {
          return isEditing;
        } else {
          return editingCollege;
        }
      });
      this.$emit("setCollege", collegeIndex, { value: "" });
    }
  }
};
</script>

<style scoped>
::v-deep .el-form-item__label {
  text-align: left;
  line-height: 28px;
}

.promptText {
  width: 400px;
}

.editNameOfColleges {
  color: #42a16a;
  cursor: pointer;
  margin-left: 12px;
}
</style>
