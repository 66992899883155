<template>
  <el-select
    style="width: 100%"
    filterable
    :value="value"
    @change="changeValue"
    :size="size"
    placeholder
  >
    <el-option
      v-for="item in data"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    />
  </el-select>
</template>

<script>
export default {
  name: "sport",
  props: {
    value: {
      type: String,
      default: ""
    },
    size: {
      type: String,
      default: ""
    }
  },
  computed: {
    data() {
      return [
        { value: "0", label: "Archery" },
        { value: "1", label: "Badminton" },
        { value: "2", label: "Baseball" },
        { value: "3", label: "Basketball" },
        { value: "4", label: "Bowling" },
        { value: "5", label: "Boxing" },
        { value: "6", label: "Cheerleading" },
        { value: "7", label: "Cricket" },
        { value: "8", label: "Cross-country" },
        { value: "9", label: "Diving" },
        { value: "10", label: "Equestrian" },
        { value: "11", label: "Fencing" },
        { value: "12", label: "Field Hockey" },
        { value: "45", label: "Figure Skating" },
        { value: "13", label: "Football" },
        { value: "14", label: "Football (non-tackle)" },
        { value: "15", label: "Golf" },
        { value: "16", label: "Gymnastics" },
        { value: "17", label: "Handball" },
        { value: "18", label: "Ice Hockey" },
        { value: "19", label: "Indoor Track" },
        { value: "20", label: "Judo" },
        { value: "21", label: "Lacrosse" },
        { value: "44", label: "Other Sport" },
        { value: "22", label: "Outdoor Track" },
        { value: "23", label: "Racquetball" },
        { value: "24", label: "Rifle" },
        { value: "48", label: "Rock climbing, Bouldering" },
        { value: "25", label: "Rodeo" },
        { value: "26", label: "Rowing (Crew)" },
        { value: "27", label: "Rugby" },
        { value: "28", label: "Sailing" },
        { value: "29", label: "Skiing" },
        { value: "30", label: "Skin Diving" },
        { value: "31", label: "Soccer" },
        { value: "32", label: "Softball" },
        { value: "33", label: "Squash" },
        { value: "34", label: "Swim" },
        { value: "35", label: "Sync. swimming" },
        { value: "36", label: "Table Tennis" },
        { value: "37", label: "Tennis" },
        { value: "38", label: "Track and field" },
        { value: "39", label: "Triathlon" },
        { value: "49", label: "Ultimate Frisbee" },
        { value: "40", label: "Volleyball" },
        { value: "41", label: "Water polo" },
        { value: "42", label: "Weight lifting" },
        { value: "43", label: "Wrestling" }
      ];
    }
  },
  methods: {
    changeValue(value) {
      this.$emit("change", value);
    }
  }
};
</script>
