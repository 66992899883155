<template>
  <el-select
    style="width: 100%"
    filterable
    :value="value"
    @change="changeValue"
    placeholder
  >
    <el-option
      v-for="item in data"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    />
  </el-select>
</template>

<script>
export default {
  name: "MathCourse78thSelector",
  props: ["value"],
  computed: {
    data() {
      return [
        {
          label: "Algebra I",
          value: 1
        },
        {
          label: "Geometry",
          value: 2
        },
        {
          label: "Algebra II",
          value: 3
        },
        {
          label: "Algebra II/Trigonometry",
          value: 4
        },
        {
          label: "Advanced Functions",
          value: 5
        },
        {
          label: "Mathematics I",
          value: 6
        },
        {
          label: "Mathematics II",
          value: 7
        },
        {
          label: "Mathematics III",
          value: 8
        },
        {
          label: "Trigonometry",
          value: 9
        },
        {
          label: "Pre-Calculus",
          value: 10
        },
        {
          label: "Statistics",
          value: 11
        },
        {
          label: "Calculus",
          value: 12
        },
        {
          label: "Discrete Math",
          value: 13
        },
        {
          label: "Finite Math",
          value: 14
        },
        {
          label: "Other Advanced Math",
          value: 15
        }
      ];
    }
  },
  methods: {
    changeValue(value) {
      this.$emit("change", value);
    }
  }
};
</script>
