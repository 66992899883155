<template>
  <div>
    <div>
      {{ getCategoryTextByValue(activitiesOrAward.category) }}
    </div>
    <template v-if="!hideActivityOrAwardTitle">
      <div v-if="activitiesOrAward.category === 'Award'">
        {{ activitiesOrAward.award_name }}
      </div>
      <div v-else-if="activitiesOrAward.category === 'EdPrep'">
        {{ getEducationalPrepName(activitiesOrAward) }}
      </div>
      <div v-else-if="activitiesOrAward.category === 'ExtraCurr'">
        {{ activitiesOrAward.extra_curr_name }}
      </div>
      <div v-else-if="activitiesOrAward.category === 'OtherCourse'">
        {{ activitiesOrAward.other_course_name }}
      </div>
      <div v-else-if="activitiesOrAward.category === 'Volunteer'">
        {{ activitiesOrAward.volunteer_org }}
      </div>
      <div v-else-if="activitiesOrAward.category === 'WorkExp'">
        {{ activitiesOrAward.work_exp_name }}
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    hideActivityOrAwardTitle: {
      type: Boolean,
      default: false
    },
    activitiesOrAward: {
      type: Object,
      default: () => ({})
    },
    categories: {
      type: Array,
      default: () => ([])
    },
    educationalPrepPrograms: {
      type: Array,
      default: () => ([])
    }
  },
  methods: {
    getCategoryTextByValue(categoryValue) {
      return this.categories.find((category) => (
        category.value === categoryValue
      )).label;
    },
    getEducationalPrepName(educationalPrep) {
      const OTHER = "18";
      if (educationalPrep.ed_prep_id === OTHER) {
        return educationalPrep.ed_prep_other_name;
      } else {
        return this.educationalPrepPrograms.find((educationalPrepProgram) => (
          educationalPrepProgram.value === educationalPrep.ed_prep_id
        )).label;
      }
    }
  }
};
</script>
