<template>
  <el-collapse
    :value="activePanel"
    @change="(value) => {
      $emit('saveCounselingProfile');
      $emit('setActivePanel', value);
    }"
    accordion
  >
    <el-collapse-item title="Household" name="household">
      <el-form>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
        >
          <template slot="label">
            Who is in your household? (select all that apply)
          </template>
          <div style="display: inline-block; width: 100%;">
            <el-checkbox-group
              :value="counselingProfile.household"
              @input="(household) => setHousehold(household)"
            >
              <div :key="household.label" v-for="household in households">
                <el-checkbox
                  :disabled="householdJustMe && household.label !== 'Just me'"
                  :label="household.value"
                >
                  {{ household.label }}
                </el-checkbox>
              </div>
            </el-checkbox-group>
          </div>
        </CounselingFormItem>
        <CounselingFormItem
          required
          checkApplications
          :value="counselingProfile.parents_marital_status"
          getterProp="parentMaritalStatusOptions"
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            Parents' marital status (relative to each other)
          </template>
          <el-select
            style="width: 100%"
            :value="counselingProfile.parents_marital_status"
            @change="(parents_marital_status) => setCounselingProfile({ parents_marital_status })"
          >
            <el-option
              v-for="parentMaritalStatus in parentMaritalStatuses"
              :key="parentMaritalStatus.id"
              :label="parentMaritalStatus.title"
              :value="parentMaritalStatus.id"
            />
          </el-select>
        </CounselingFormItem>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.california_app]"
        >
          <template slot="label">
            What is the highest level of education earned by any of your parents?
          </template>
          <el-select
            style="width: 100%"
            :value="counselingProfile.highest_parent_ed"
            @change="(highest_parent_ed) => setCounselingProfile({ highest_parent_ed })"
          >
            <el-option
              v-for="highestParentEducation in highestParentEducations"
              :key="highestParentEducation.value"
              :label="highestParentEducation.label"
              :value="highestParentEducation.value"
            />
          </el-select>
        </CounselingFormItem>
        <CounselingFormItem
          v-if="parentsMaritalStatusIsOther"
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            If other, please specify
          </template>
          <el-input
            :value="counselingProfile.other_parent_marital_descr"
            @input="(other_parent_marital_descr) => setCounselingProfile({ other_parent_marital_descr })"
          />
        </CounselingFormItem>
        <CounselingFormItem
          v-if="isMarriedStatusDivorced"
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            Year of divorce
          </template>
          <el-input
            :value="counselingProfile.divorced_yr"
            @input="(divorced_yr) => setCounselingProfile({ divorced_yr })"
          />
        </CounselingFormItem>
        <CounselingFormItem
          required
          checkApplications
          :value="counselingProfile.whom_make_your_permanent_home"
          getterProp="whoYourPermanentHomeOptions"
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            With whom do you make your permanent home?
          </template>
          <el-select
            style="width: 100%"
            :value="counselingProfile.whom_make_your_permanent_home"
            @change="(whom_make_your_permanent_home) => setCounselingProfile({ whom_make_your_permanent_home })"
          >
            <el-option
              v-for="yourPermanent in yourPermanents"
              :key="yourPermanent.id"
              :label="yourPermanent.title"
              :value="yourPermanent.id"
            />
          </el-select>
        </CounselingFormItem>
        <CounselingFormItem
          required
          v-if="isOtherMakeYourPermanentHome"
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            Specify other living situation
          </template>
          <el-input
            :value="counselingProfile.living_situation_descr"
            @input="(living_situation_descr) => setCounselingProfile({ living_situation_descr })"
          />
        </CounselingFormItem>
        <div v-if="hasNoMarried">
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          >
            <template slot="label">
              Do you wish to list any step-parents?
            </template>
            <el-radio-group
              style="width: 100%"
              :value="counselingProfile.want_to_list_step_parents"
              @input="(want_to_list_step_parents) => setCounselingProfile({ want_to_list_step_parents })"
            >
              <div><el-radio :label="0">Yes</el-radio></div>
              <div><el-radio :label="1">No</el-radio></div>
            </el-radio-group>
          </CounselingFormItem>
          <CounselingFormItem
            required
            v-if="wantToListStepParents"
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          >
            <template slot="label">
              How many?
            </template>
            <el-radio-group
              style="width: 100%"
              :value="counselingProfile.step_parents_number"
              @input="(step_parents_number) => setCounselingProfile({ step_parents_number })"
            >
              <div><el-radio :label="1">1</el-radio></div>
              <div><el-radio :label="2">2</el-radio></div>
            </el-radio-group>
          </CounselingFormItem>
        </div>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            Do you have any children?
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.has_children"
            @input="(has_children) => setCounselingProfile({ has_children })"
          >
            <div><el-radio :label="0">Yes</el-radio></div>
            <div><el-radio :label="1">No</el-radio></div>
          </el-radio-group>
        </CounselingFormItem>
        <CounselingFormItem
          required
          v-if="hasChildren"
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            How many?
          </template>
          <el-input
            :value="counselingProfile.children_number"
            @input="(children_number) => setCounselingProfile({ children_number })"
          />
        </CounselingFormItem>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
        >
          <template slot="label">
            Household Size
          </template>
          <el-input-number
            class="inputNumber"
            controls-position="right"
            :min="0"
            :max="20"
            :value="counselingProfile.household_size"
            @input="
              household_size => setCounselingProfile({ household_size })
            "
          />
        </CounselingFormItem>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.california_app]"
        >
          <template slot="promptText">
            We may use this information to help us determine if you're eligible for certain outreach programs, scholarships, and the application fee waiver. This information is not used for financial aid.
          </template>
          <template slot="label">
            What was your total income earned in 2020?(Include your spouse's income, if relevant)
          </template>
          <el-input-number
            class="inputNumber"
            controls-position="right"
            :min="0"
            :value="counselingProfile.family_income_curr_year"
            @input="
              family_income_curr_year => setCounselingProfile({ family_income_curr_year })
            "
          />
        </CounselingFormItem>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.california_app]"
        >
          <template slot="label">
            How many people were supported by this income? 
          </template>
          <el-input-number
            class="inputNumber"
            controls-position="right"
            :min="0"
            :value="counselingProfile.family_size_curr_year"
            @input="
              family_size_curr_year => setCounselingProfile({ family_size_curr_year })
            "
          />
        </CounselingFormItem>
        <CounselingFormAlert
          :currentApplication="currentApplicationType"
          :applicationsNeedToVisible="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
        >
          <template slot="title">
            <ul class="promptList">
              <li>{{ $t('counselingProfile.commonAppJustCanSubmitTwoParentsAndOneLegalGuardian') }}</li>
              <li>{{ $t('counselingProfile.coalitionAppJustCanSubmitFourOfParentsAndLegalGuardian') }}</li>
            </ul>
          </template>
        </CounselingFormAlert>
        <CounselingFormItem
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            How many parents do you have?
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.parents_length"
            @input="(parents_length) => setCounselingProfile({ parents_length })"
          >
            <div><el-radio :label="0">0</el-radio></div>
            <div :key="parentNum" v-for="parentNum in MAX_PARENT_COUNT">
              <el-radio :label="parentNum">
                {{ parentNum }}
              </el-radio>
            </div>
          </el-radio-group>
        </CounselingFormItem>
        <CounselingFormItem
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            How many guardians do you have?
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.legal_guardians_length"
            @input="(legal_guardians_length) => setCounselingProfile({ legal_guardians_length })"
          >
            <div><el-radio :label="0">0</el-radio></div>
            <div
              :key="legalGuardianNum"
              v-for="legalGuardianNum in MAX_LEGAL_GUARDIAN_COUNT"
            >
              <el-radio :label="legalGuardianNum">
                {{ legalGuardianNum }}
              </el-radio>
            </div>
          </el-radio-group>
        </CounselingFormItem>
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
    <el-collapse-item
      :key="`parent${parentNum}`"
      v-for="(parentNum, parentIndex) in counselingProfile.parents_length"
      :title="`Parent ${parentNum}`"
      :name="`parent${parentNum}`"
    >
      <ParentForm
        :currentApplicationType="currentApplicationType"
        :parentNum="String(parentNum)"
        :displayDoNotHaveAnotherParentOption="parentNum > 1"
        :parent="counselingProfile.parents[parentIndex]"
        @setParent="(data) => setParent(parentIndex, { ...data })"
        @setPhoneOfParent="(data) => setPhoneOfParent(parentIndex, { ...data })"
        @setAddressOfParent="(data) => setAddressOfParent(parentIndex, { ...data })"
        @setCollegeOfParent="(collegeIndex, data) => {
          setCollegeOfParent(parentIndex, collegeIndex, data)
        }"
        @setDegreeOfParentCollege="(collegeIndex, degreeIndex, data) => {
          setDegreeOfParentCollege(parentIndex, collegeIndex, degreeIndex, data)
        }"
      />
      <el-form>
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
    <el-collapse-item
      :key="`legalGuardian${legalGuardianNum}`"
      v-for="(legalGuardianNum, legalGuardianIndex) in counselingProfile.legal_guardians_length"
      :title="`Legal Guardian ${legalGuardianNum}`"
      :name="`legalGuardian${legalGuardianNum}`"
    >
      <el-form>
        <CounselingFormItem
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            Prefix
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.legal_guardians[legalGuardianIndex].prefix"
            @input="(prefix) => setLegalGuardian(legalGuardianIndex, { prefix })"
          >
            <div>
              <el-radio :label="0">Dr.</el-radio>
            </div>
            <div>
              <el-radio :label="1">Mr.</el-radio>
            </div>
            <div>
              <el-radio :label="2">Ms.</el-radio>
            </div>
            <div>
              <el-radio :label="3">Mrs.</el-radio>
            </div>
          </el-radio-group>
        </CounselingFormItem>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            First/given name
          </template>
          <el-input
            :value="counselingProfile.legal_guardians[legalGuardianIndex].first_name"
            @input="(first_name) => setLegalGuardian(legalGuardianIndex, { first_name })"
          />
        </CounselingFormItem>
        <CounselingFormItem
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            Middle name
          </template>
          <el-input
            :value="counselingProfile.legal_guardians[legalGuardianIndex].middle_name"
            @input="(middle_name) => setLegalGuardian(legalGuardianIndex, { middle_name })"
          />
        </CounselingFormItem>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            Last/Family/Surname
          </template>
          <el-input
            :value="counselingProfile.legal_guardians[legalGuardianIndex].last_name"
            @input="(last_name) => setLegalGuardian(legalGuardianIndex, { last_name })"
          />
        </CounselingFormItem>
        <CounselingFormItem
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            Former last/family/surname (if any)
          </template>
          <el-input
            :value="counselingProfile.legal_guardians[legalGuardianIndex].former_last_name"
            @input="(former_last_name) => setLegalGuardian(legalGuardianIndex, { former_last_name })"
          />
        </CounselingFormItem>
        <CounselingFormItem
          checkApplications
          :value="counselingProfile.legal_guardians[legalGuardianIndex].suffix"
          getterProp="suffixOptions"
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            Suffix
          </template>
          <SuffixSelector
            :value="counselingProfile.legal_guardians[legalGuardianIndex].suffix"
            @change="(suffix) => setLegalGuardian(legalGuardianIndex, { suffix })"
          />
        </CounselingFormItem>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            Relationship to you
          </template>
          <el-input
            :value="counselingProfile.legal_guardians[legalGuardianIndex].relationship"
            @input="(relationship) => setLegalGuardian(legalGuardianIndex, { relationship })"
          />
        </CounselingFormItem>
        <CounselingFormItem
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            Preferred email
          </template>
          <el-input
            :value="counselingProfile.legal_guardians[legalGuardianIndex].email"
            @input="(email) => setLegalGuardian(legalGuardianIndex, { email })"
          />
        </CounselingFormItem>
        <CounselingFormItem
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            Preferred phone
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.legal_guardians[legalGuardianIndex].preferred_phone"
            @input="(preferred_phone) => setLegalGuardian(legalGuardianIndex, { preferred_phone })"
          >
            <div><el-radio :label="1">Mobile</el-radio></div>
            <div><el-radio :label="0">Home</el-radio></div>
            <div><el-radio :label="2">Other</el-radio></div>
            <div><el-radio :label="3">Work</el-radio></div>
          </el-radio-group>
        </CounselingFormItem>
        <CounselingFormItem
          checkApplications
          :value="counselingProfile.legal_guardians[legalGuardianIndex].preferred_phone_number.country_code"
          getterProp="mobileCountryCodeOptions"
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
        >
          <template slot="promptText">
            Phone includes country code, number, and extension (if applicable).
          </template>
          <template slot="label">
            Preferred phone number
          </template>
          <div style="display: inline-block; width: 100%;">
            <div style="display: flex">
              <MobileCountryCodeSelector
                :value="counselingProfile.legal_guardians[legalGuardianIndex].preferred_phone_number.country_code"
                @change="(country_code) => setPhoneOfLegalGuardian(legalGuardianIndex, { country_code })"
                style="margin-right: 10px"
              />
              <el-input
                :value="counselingProfile.legal_guardians[legalGuardianIndex].preferred_phone_number.phone_number"
                @input="(phone_number) => setPhoneOfLegalGuardian(legalGuardianIndex, { phone_number })"
                style="width: 400px; margin-right: 10px"
              />
              <el-input
                :value="counselingProfile.legal_guardians[legalGuardianIndex].preferred_phone_number.extension"
                @input="(extension) => setPhoneOfLegalGuardian(legalGuardianIndex, { extension })"
                style="width: 200px"
              />
            </div>
          </div>
        </CounselingFormItem>
        <CounselingFormItem
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            Highest Level of Education
          </template>
          <HighestLevelOfEducationsSelector
            :value="counselingProfile.legal_guardians[legalGuardianIndex].highest_level_of_education"
            @input="(highest_level_of_education) => setLegalGuardian(legalGuardianIndex, { highest_level_of_education })"
          />
        </CounselingFormItem>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            Legal Guardian address is
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.legal_guardians[legalGuardianIndex].address_type"
            @input="(address_type) => setLegalGuardian(legalGuardianIndex, { address_type })"
          >
            <div><el-radio :label="0">Same as my home address</el-radio></div>
            <div><el-radio :label="2">Same as my mailing address</el-radio></div>
            <div><el-radio :label="1">other address</el-radio></div>
          </el-radio-group>
        </CounselingFormItem>
        <div v-if="hasDifferentAddress(legalGuardianIndex)">
          <AddressForm
            :currentApplicationType="currentApplicationType"
            :address="counselingProfile.legal_guardians[legalGuardianIndex].address"
            @setAddress="data => setAddressOfLegalGuardian(legalGuardianIndex, { ...data })"
          />
        </div>
        <CounselingFormItem
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            Occupation (former occupation, if retired or deceased)
          </template>
          <OccupationSelector
            style="width: 100%"
            :value="counselingProfile.legal_guardians[legalGuardianIndex].occupation"
            @change="(occupation) => setLegalGuardian(legalGuardianIndex, { occupation })"
          />
        </CounselingFormItem>
        <CounselingFormItem
          v-if="isOtherOccupation(legalGuardianIndex)"
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            Other Occupation Details
          </template>
          <el-input
            :value="counselingProfile.legal_guardians[legalGuardianIndex].occupation_descr"
            @input="(occupation_descr) => setLegalGuardian(legalGuardianIndex, { occupation_descr })"
          />
        </CounselingFormItem>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            Employment status
          </template>
          <el-select
            style="width: 100%"
            :value="counselingProfile.legal_guardians[legalGuardianIndex].employment_status"
            @change="(employment_status) => setLegalGuardian(legalGuardianIndex, { employment_status })"
          >
            <el-option
              v-for="employmentStatus in employmentStatuses"
              :key="employmentStatus.value"
              :label="employmentStatus.label"
              :value="employmentStatus.value"
            />
          </el-select>
        </CounselingFormItem>
        <CounselingFormItem
          v-if="employmentStatusIsEmployedOrRetired(legalGuardianIndex)"
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            Is legal guardian currently employed by or retired from a college or university?
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.legal_guardians[legalGuardianIndex].is_employed_or_retired_college"
            @input="(is_employed_or_retired_college) => setLegalGuardian(legalGuardianIndex, { is_employed_or_retired_college })"
          >
            <div><el-radio :label="1">Currently employed at a college/university</el-radio></div>
            <div><el-radio :label="2">Retired from a college/university</el-radio></div>
            <div><el-radio :label="3">Never employed at a college/university</el-radio></div>
          </el-radio-group>
        </CounselingFormItem>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            Position/Title (former position, if retired)
          </template>
          <el-input
            :value="counselingProfile.legal_guardians[legalGuardianIndex].position"
            @input="(position) => setLegalGuardian(legalGuardianIndex, { position })"
          />
        </CounselingFormItem>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            Place of Work
          </template>
          <el-input
            :value="counselingProfile.legal_guardians[legalGuardianIndex].occupation_place"
            @input="(occupation_place) => setLegalGuardian(legalGuardianIndex, { occupation_place })"
          />
        </CounselingFormItem>
        <div v-if="!neverEmployed(legalGuardianIndex)">
          <AddressForm
            :currentApplicationType="currentApplicationType"
            :address="counselingProfile.legal_guardians[legalGuardianIndex].college_employer"
            @setAddress="data => setLegalGuardianCollegeEmployer(legalGuardianIndex, { ...data })"
          />
        </div>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            Education level
          </template>
          <EducationLevelSelector
            :value="String(counselingProfile.legal_guardians[legalGuardianIndex].education_level)"
            @change="
              education_level => setLegalGuardian(legalGuardianIndex, { education_level })
            "
          />
        </CounselingFormItem>
        <InstitutionsAttendedForm
          v-if="legalGuardianHasEnoughEducationLevel(legalGuardianIndex)"
          :currentApplicationType="currentApplicationType"
          :person="{
            colleges: counselingProfile.legal_guardians[legalGuardianIndex].colleges,
            attended_institutions_number: counselingProfile.legal_guardians[legalGuardianIndex].attended_institutions_number
          }"
          @setPerson="
            data => setLegalGuardian(legalGuardianIndex, { ...data })
          "
          @setCollege="
            (collegeIndex, value) =>
              setCollegeOfLegalGuardian(legalGuardianIndex, collegeIndex, { ...value })
          "
          @setDegreeOfCollege="
            (collegeIndex, degreeIndex, value) =>
              setDegreeOfLegalGuardianCollege(
                legalGuardianIndex,
                collegeIndex,
                degreeIndex,
                { ...value }
              )
          "
        />
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
    <el-collapse-item title="Sibling" name="sibling">
      <CounselingFormAlert
        :currentApplication="currentApplicationType"
        :applicationsNeedToVisible="[thirdAppTypes.coalition_app]"
      >
        <template slot="title">
          {{ $t('counselingProfile.coalitionAppJustCanFillFourSiblings') }}
        </template>
      </CounselingFormAlert>
      <el-form>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            Please specify number of siblings you have
          </template>
          <el-select
            :value="counselingProfile.siblings_number"
            @change="(siblings_number) => {
              setCounselingProfile({ siblings_number });
              initEditingSiblingCollegesStatus();
            }"
            style="width: 100%"
          >
            <el-option
              v-for="(num, index) in MAX_SIBLING_COUNT"
              :key="index"
              :label="index"
              :value="index"
            />
          </el-select>
        </CounselingFormItem>
        <div :key="num" v-for="(num, siblingIndex) in counselingProfile.siblings_number">
          <h6>{{ `Sibling  ${num}` }}</h6>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
          >
            <template slot="label">
              First/given name
            </template>
            <el-input
              :value="counselingProfile.siblings[siblingIndex].first_name"
              @input="(first_name) => setSibling(siblingIndex, { first_name })"
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
          >
            <template slot="label">
              Last/Family/Surname
            </template>
            <el-input
              :value="counselingProfile.siblings[siblingIndex].last_name"
              @input="(last_name) => setSibling(siblingIndex, { last_name })"
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
          >
            <template slot="label">
              Age
            </template>
            <el-input-number
              class="inputNumber"
              controls-position="right"
              :min="0"
              :max="100"
              :value="counselingProfile.siblings[siblingIndex].age"
              @input="(age) => setSibling(siblingIndex, { age })"
            />
          </CounselingFormItem>
          <CounselingFormItem
            checkApplications
            :value="counselingProfile.siblings[siblingIndex].relationship"
            getterProp="siblingRelationshipOptions"
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
          >
            <template slot="label">
              Relationship
            </template>
            <el-select
              style="width: 100%"
              :value="counselingProfile.siblings[siblingIndex].relationship"
              @change="(relationship) => setSibling(siblingIndex, { relationship })"
            >
              <el-option
                v-for="siblingRelationship in siblingRelationships"
                :key="siblingRelationship.id"
                :label="siblingRelationship.title"
                :value="siblingRelationship.id"
              />
            </el-select>
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          >
            <template slot="label">
              Education level
            </template>
            <EducationLevelSelector
              :value="counselingProfile.siblings[siblingIndex].education_level"
              @change="education_level => setSibling(siblingIndex, { education_level })"
            />
          </CounselingFormItem>
          <template v-if="siblingHasEnoughEducationLevel(siblingIndex)">
            <CounselingFormItem
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app]"
            >
              <template slot="label">
                Degree earned or expected
              </template>
              <DegreeReceivedSelector
                :value="counselingProfile.siblings[siblingIndex].college.degree.name"
                @change="name => setDegreeOfSiblingCollege(siblingIndex, { name })"
              />
            </CounselingFormItem>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app]"
            >
              <template slot="label">
                College lookup
              </template>
              <div v-if="!editingSiblingColleges[siblingIndex]">
                {{ counselingProfile.siblings[siblingIndex].college.name }}
                <span
                  class="editNameOfColleges"
                  @click="() => setEditingSiblingColleges(siblingIndex, true)"
                >
                  EDIT
                </span>
              </div>
              <div v-else>
                <CollegeSelector
                  style="width: 100%"
                  :value="counselingProfile.siblings[siblingIndex].college.value"
                  @change="({ value, title }) => setCollegeOfSibling(siblingIndex, { value, name: title })"
                />
              </div>
            </CounselingFormItem>
            <div v-if="!hasCollegeInList(siblingIndex)">
              <CounselingFormItem
                required
                :currentApplication="currentApplicationType"
                :applicationsNeedToFilling="[thirdAppTypes.common_app]"
              >
                <template slot="label">
                  Name of college/university
                </template>
                <el-input
                  :value="counselingProfile.siblings[siblingIndex].college.name"
                  @input="name => setCollegeOfSibling(siblingIndex, { name })"
                />
              </CounselingFormItem>
              <AddressForm
                :currentApplicationType="currentApplicationType"
                :address="counselingProfile.siblings[siblingIndex].college"
                @setAddress="data => setCollegeOfSibling(siblingIndex, { ...data })"
              />
            </div>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app]"
            >
              <template slot="label">
                College start date
              </template>
              <el-date-picker
                style="width: 100%"
                value-format="MM/yyyy"
                type="date"
                :value="counselingProfile.siblings[siblingIndex].college.degree.from_month"
                @input="(from_month) => setDegreeOfSiblingCollege(siblingIndex, { from_month })"
              />
            </CounselingFormItem>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app]"
            >
              <template slot="label">
                College end date
              </template>
              <el-date-picker
                style="width: 100%"
                value-format="MM/yyyy"
                type="date"
                :value="counselingProfile.siblings[siblingIndex].college.degree.to_month"
                @input="(to_month) => setDegreeOfSiblingCollege(siblingIndex, { to_month })"
              />
            </CounselingFormItem>
          </template>
        </div>
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
    <template v-if="hasStepParentCount">
      <el-collapse-item
        :key="stepParentIndex"
        v-for="stepParentIndex in stepParentCount"
        :title="`Step-Parent ${stepParentIndex}`"
        :name="`step-parent${stepParentIndex}`"
      >
        <StepParentForm
        
        />
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-collapse-item>
    </template>
  </el-collapse>
</template>

<script>
import { thirdAppTypeDict } from "@/class/ThirdApp";
import CounselingFormItem from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/CounselingFormItem";
import SuffixSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/SuffixSelector";
import MobileCountryCodeSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/MobileCountryCodeSelector";
import OccupationSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/OccupationSelector";
import EducationLevelSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/FamilyForm/EducationLevelSelector";
import DegreeReceivedSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/FamilyForm/DegreeReceivedSelector";
import CollegeSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/CollegeSelector";
import ParentForm from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/FamilyForm/ParentForm";
import StepParentForm from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/FamilyForm/StepParentForm";
import InstitutionsAttendedForm from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/FamilyForm/InstitutionsAttendedForm";
import AddressForm from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/AddressForm";
import HighestLevelOfEducationsSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/FamilyForm/HighestLevelOfEducationsSelector";
import CounselingFormAlert from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/CounselingFormAlert";

export default {
  props: {
    currentApplicationType: {
      type: String,
      default: ""
    },
    activePanel: {
      type: String,
      default: ""
    }
  },
  components: {
    CounselingFormItem,
    SuffixSelector,
    MobileCountryCodeSelector,
    OccupationSelector,
    CollegeSelector,
    EducationLevelSelector,
    DegreeReceivedSelector,
    ParentForm,
    StepParentForm,
    InstitutionsAttendedForm,
    AddressForm,
    HighestLevelOfEducationsSelector,
    CounselingFormAlert
  },
  computed: {
    thirdAppTypes() {
      return thirdAppTypeDict;
    },
    parentMaritalStatuses() {
      return this.$store.getters["counselingProfile/parentMaritalStatusOptions"];
    },
    yourPermanents() {
      return this.$store.getters["counselingProfile/whoYourPermanentHomeOptions"];
    },
    employmentStatuses() {
      return [
        { value: 0, label: "Employed" },
        { value: 1, label: "Unemployed" },
        { value: 2, label: "Retired" },
        { value: 3, label: "Self-Employed" }
      ];
    },
    highestParentEducations() {
      return [
        {
          label: "No high school",
          value: "01"
        },
        {
          label: "Some high school",
          value: "02"
        },
        {
          label: "High school graduate",
          value: "03"
        },
        {
          label: "Some college/University",
          value: "04"
        },
        {
          label: "Two-year college/University graduate",
          value: "05"
        },
        {
          label: "Four-year college/University graduate",
          value: "06"
        },
        {
          label: "Postgraduate study",
          value: "07"
        }
      ];
    },
    households() {
      return [
        { value: "{\"code\":\"PARENTS\",\"value\":\"Parent(s)/Guardian(s)\"}", label: "Parent(s)/Guardian(s)" },
        { value: "{\"code\":\"SIBS\",\"value\":\"Sibling(s)\"}", label: "Sibling(s)" },
        { value: "{\"code\":\"SPOUSE\",\"value\":\"Spouse\"}", label: "Spouse" },
        { value: "{\"code\":\"CHILD\",\"value\":\"Child(ren)\"}", label: "Child(ren)" },
        { value: "{\"code\":\"ME\",\"value\":\"Just me\"}", label: "Just me" }
      ];
    },
    counselingProfile() {
      return this.$store.getters["counselingProfile/counselingProfile"];
    },
    siblingRelationships() {
      return this.$store.getters["counselingProfile/siblingRelationshipOptions"];
    },
    parentsMaritalStatusIsOther() {
      const OTHER = 6;
      return this.counselingProfile.parents_marital_status === OTHER;
    },
    wantToListStepParents() {
      return this.counselingProfile.want_to_list_step_parents === 0;
    },
    hasChildren() {
      return this.counselingProfile.has_children === 0;
    },
    isMarriedStatusDivorced() {
      const DIVORCED = 2;
      return this.counselingProfile.parents_marital_status === DIVORCED;
    },
    hasNoMarried() {
      const [DIVORCED, NEVER_MARRIED, WIDOWED] = [2, 3, 4];
      return this.counselingProfile.parents_marital_status === DIVORCED || 
      this.counselingProfile.parents_marital_status === NEVER_MARRIED || 
      this.counselingProfile.parents_marital_status === WIDOWED;
    },
    isOtherMakeYourPermanentHome() {
      const OTHER = 4;
      return this.counselingProfile.whom_make_your_permanent_home === OTHER;
    },
    isParentDeceased() {
      return (parentIndex) => {
        return this.counselingProfile.parents[parentIndex].is_living === 0;
      };
    },
    hasDifferentAddress() {
      return (legalGuardianIndex) => {
        return this.counselingProfile.legal_guardians[legalGuardianIndex].address_type === 1;
      };
    },
    isOtherOccupation() {
      const OTHER = 44;
      return (legalGuardianIndex) => {
        return this.counselingProfile.legal_guardians[legalGuardianIndex].occupation === OTHER;
      };
    },
    employmentStatusIsEmployedOrRetired() {
      const [EMPLOYED, RETIRED] = [0, 2];
      return (legalGuardianIndex) => {
        return [EMPLOYED, RETIRED].includes(this.counselingProfile.legal_guardians[legalGuardianIndex].employment_status);
      };
    },
    neverEmployed() {
      const NEVER = 3;
      return (legalGuardianIndex) => {
        return this.counselingProfile.legal_guardians[legalGuardianIndex].is_employed_or_retired_college === NEVER;
      };
    },
    householdJustMe() {
      return this.counselingProfile.household.includes("{\"code\":\"ME\",\"value\":\"Just me\"}");
    },
    ENOUGH_EDUCATION_LEVELS() {
      return ["7", "8", "9", "10", "11"];
    },
    legalGuardianHasEnoughEducationLevel() {
      return (legalGuardianIndex) =>
        this.ENOUGH_EDUCATION_LEVELS.includes(
          this.counselingProfile.legal_guardians[legalGuardianIndex].education_level
        );
    },
    hasCollegeInList() {
      const didNotFind = "other";
      return siblingIndex =>
        this.counselingProfile.siblings[siblingIndex].college.value !==
        didNotFind;
    },
    hasStepParentCount() {
      return this.counselingProfile.want_to_list_step_parents === 0;
    },
    stepParentCount() {
      return this.counselingProfile.step_parents_number;
    },
    MAX_PARENT_COUNT() {
      return 4;
    },
    MAX_LEGAL_GUARDIAN_COUNT() {
      return 4;
    },
    MAX_SIBLING_COUNT() {
      return 6;
    }
  },
  data() {
    return {
      editingSiblingColleges: []
    };
  },
  async created() {
    this.initEditingSiblingCollegesStatus();
    this.$store.dispatch("counselingProfile/fetchCountriesOfCounselingProfile");
    this.$store.dispatch("counselingProfile/fetchSiblingRelationshipsOfCounselingProfile");
    this.$store.dispatch("counselingProfile/fetchParentMaritalStatusesOfCounselingProfile");
    this.$store.dispatch("counselingProfile/fetchWhoYourPermanentHomeOfCounselingProfile");
  },
  methods: {
    setHousehold(household) {
      const JUST_ME = "{\"code\":\"ME\",\"value\":\"Just me\"}";
      const HOUSEHOLD_JUST_ME = household.includes(JUST_ME);
      if (HOUSEHOLD_JUST_ME) {
        this.setCounselingProfile({
          household: household.filter(theHousehold => theHousehold === JUST_ME)
        });
      } else {
        this.setCounselingProfile({ household });
      }
    },
    initEditingSiblingCollegesStatus() {
      this.editingSiblingColleges = Array.from(
        Array(this.counselingProfile.siblings_number)
      ).map((_, index) => !this.counselingProfile.siblings[index].college.value);
    },
    setEditingSiblingColleges(siblingIndex, isEditing) {
      this.editingSiblingColleges = this.editingSiblingColleges.map((editingCollege, index) => {
        if (index === siblingIndex) {
          return isEditing;
        } else {
          return editingCollege;
        }
      });
      this.setCollegeOfSibling(siblingIndex, { value: "" });
    },
    setCounselingProfile(data) {
      this.$store.commit("counselingProfile/setCounselingProfile", { ...data });
    },
    setParent(parentIndex, data) {
      this.$store.commit(
        "counselingProfile/setParent",
        { parentIndex, data: { ...data }}
      );
    },
    setPhoneOfParent(parentIndex, data) {
      this.$store.commit(
        "counselingProfile/setPhoneOfParent",
        { parentIndex, data: { ...data }}
      );
    },
    setAddressOfParent(parentIndex, data) {
      this.$store.commit(
        "counselingProfile/setAddressOfParent",
        { parentIndex, data: { ...data }}
      );
    },
    setCollegeOfParent(parentIndex, collegeIndex, data) {
      this.$store.commit(
        "counselingProfile/setCollegeOfParent",
        { parentIndex, collegeIndex, data: {...data}}
      );
    },
    setDegreeOfParentCollege(parentIndex, collegeIndex, degreeIndex, data) {
      this.$store.commit(
        "counselingProfile/setDegreeOfParentCollege",
        { parentIndex, collegeIndex, degreeIndex, data: {...data}}
      );
    },
    setLegalGuardian(legalGuardianIndex, data) {
      this.$store.commit(
        "counselingProfile/setLegalGuardian",
        { legalGuardianIndex, data: { ...data }}
      );
    },
    setPhoneOfLegalGuardian(legalGuardianIndex, data) {
      this.$store.commit(
        "counselingProfile/setPhoneOfLegalGuardian",
        { legalGuardianIndex, data: {...data}}
      );
    },
    setAddressOfLegalGuardian(legalGuardianIndex, data) {
      this.$store.commit(
        "counselingProfile/setAddressOfLegalGuardian",
        { legalGuardianIndex, data: {...data}}
      );
    },
    setCollegeOfLegalGuardian(legalGuardianIndex, collegeIndex, data) {
      this.$store.commit(
        "counselingProfile/setCollegeOfLegalGuardian",
        { legalGuardianIndex, collegeIndex, data: {...data}}
      );
    },
    setDegreeOfLegalGuardianCollege(legalGuardianIndex, collegeIndex, degreeIndex, data) {
      this.$store.commit(
        "counselingProfile/setDegreeOfLegalGuardianCollege",
        { legalGuardianIndex, collegeIndex, degreeIndex, data: {...data}}
      );
    },
    setLegalGuardianCollegeEmployer(legalGuardianIndex, data) {
      this.$store.commit("counselingProfile/setLegalGuardianCollegeEmployer", {
        legalGuardianIndex, data: {...data}}
      );
    },
    setSibling(siblingIndex, data) {
      this.$store.commit(
        "counselingProfile/setSibling",
        { siblingIndex, data: { ...data }}
      );
    },
    setCollegeOfSibling(siblingIndex, data) {
      this.$store.commit(
        "counselingProfile/setCollegeOfSibling",
        { siblingIndex, data: { ...data }}
      );
    },
    setDegreeOfSiblingCollege(siblingIndex, data) {
      this.$store.commit(
        "counselingProfile/setDegreeOfSiblingCollege",
        { siblingIndex, data: {...data}}
      );
    },
    siblingHasEnoughEducationLevel(siblingIndex) {
      return this.ENOUGH_EDUCATION_LEVELS.includes(
        this.counselingProfile.siblings[siblingIndex].education_level
      );
    }
  }
};
</script>

<style scoped src="@/views/private/Counseling/ThirdApp/CounselingProfileForm/index.css"></style>

<style scoped>
.editNameOfColleges {
  color: #42a16a;
  cursor: pointer;
  margin-left: 12px;
}
</style>