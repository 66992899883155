<template>
  <el-select
    style="width: 100%"
    filterable
    :value="value"
    @change="changeValue"
    placeholder
  >
    <el-option
      v-for="item in data"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    />
  </el-select>
</template>

<script>
export default {
  name: "IbSubject",
  props: ["value"],
  computed: {
    data() {
      return [
        {
          label: "Languages A1",
          value: "A"
        },
        {
          label: "Second Languages A2, B",
          value: "B"
        },
        {
          label: "Individuals and Society",
          value: "I"
        },
        {
          label: "Experimental Sciences",
          value: "E"
        },
        {
          label: "Mathematical and Computer Science",
          value: "M"
        },
        {
          label: "Arts",
          value: "R"
        }
      ];
    }
  },
  methods: {
    changeValue(value) {
      this.$emit("change", value);
    }
  }
};
</script>
