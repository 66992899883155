<template>
  <el-form>
    <el-form-item required :label="`Step-parent ${stepParentNum} is married to`">
      <el-radio-group
        style="width: 100%"
        :value="parent.type"
        @input="(type) => $emit('setStepParent', { type })"
      >
        <div>
          <el-radio :label="0">Mother</el-radio>
        </div>
        <div>
          <el-radio :label="1">Father</el-radio>
        </div>
        <div>
          <el-radio :label="2">I have limited information about this parent</el-radio>
        </div>
        <div>
          <el-radio
            v-if="displayDoNotHaveAnotherParentOption"
            :label="3"
          >I do not have another parent to list</el-radio>
        </div>
      </el-radio-group>
    </el-form-item>
    <el-form-item required :label="`Is Parent ${parentNum} living`">
      <el-radio-group
        style="width: 100%"
        :value="parent.is_living"
        @input="(is_living) => $emit('setStepParent', { is_living })"
      >
        <div>
          <el-radio :label="0">Yes</el-radio>
        </div>
        <div>
          <el-radio :label="1">No</el-radio>
        </div>
      </el-radio-group>
    </el-form-item>
    <el-form-item v-if="isParentDeceased" label="Date deceased">
      <el-date-picker
        style="width: 100%"
        value-format="yyyy-MM-dd"
        type="date"
        :value="parent.deceased_date"
        @input="(deceased_date) => $emit('setStepParent', { deceased_date })"
      />
    </el-form-item>
    <el-form-item label="Prefix">
      <el-radio-group
        style="width: 100%"
        :value="parent.prefix"
        @input="(prefix) => $emit('setStepParent', { prefix })"
      >
        <div>
          <el-radio :label="0">Dr.</el-radio>
        </div>
        <div>
          <el-radio :label="1">Mr.</el-radio>
        </div>
        <div>
          <el-radio :label="2">Ms.</el-radio>
        </div>
        <div>
          <el-radio :label="3">Mrs.</el-radio>
        </div>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="First/given name">
      <el-input
        :value="parent.first_name"
        @input="(first_name) => $emit('setStepParent', { first_name })"
      />
    </el-form-item>
    <el-form-item label="Middle initial">
      <el-input
        :value="parent.middle_name"
        @input="(middle_name) => $emit('setStepParent', { middle_name })"
      />
    </el-form-item>
    <el-form-item label="Last/Family/Surname">
      <el-input
        :value="parent.last_name"
        @input="(last_name) => $emit('setStepParent', { last_name })"
      />
    </el-form-item>
    <el-form-item label="Former last/family/surname (if any)">
      <el-input
        :value="parent.former_last_name"
        @input="(former_last_name) => $emit('setStepParent', { former_last_name })"
      />
    </el-form-item>
    <el-form-item label="Suffix">
      <SuffixSelector
        :value="parent.suffix"
        @change="(suffix) => $emit('setStepParent', { suffix })"
      />
    </el-form-item>
    <el-form-item label="Birth country/region/territory">
      <CountrySelector
        style="width: 100%"
        :value="parent.birth_country"
        @change="(birth_country) => $emit('setStepParent', { birth_country })"
      />
    </el-form-item>
    <el-form-item label="Occupation (former occupation, if retired or deceased)">
      <OccupationSelector
        style="width: 100%"
        :value="parent.occupation"
        @change="(occupation) => $emit('setStepParent', { occupation })"
      />
    </el-form-item>
    <el-form-item v-if="isOtherOccupation" label="Other Occupation Details">
      <el-input
        :value="parent.occupation_descr"
        @input="(occupation_descr) => $emit('setStepParent', { occupation_descr })"
      />
    </el-form-item>
    <el-form-item label="Education level">
      <EducationLevelSelector
        style="width: 100%"
        :value="String(parent.education_level)"
        @change="(education_level) => $emit('setStepParent', { education_level })"
      />
    </el-form-item>
    <InstitutionsAttendedForm
      v-if="hasEnoughEducationLevel"
      :person="parent"
      @setCollege="
        (collegeIndex, value) =>
          $emit('setCollegeOfStepParent', collegeIndex, { ...value })
      "
      @setDegreeOfCollege="
        (collegeIndex, degreeIndex, value) =>
          $emit('setDegreeOfStepParentCollege', collegeIndex, degreeIndex, { ...value })
      "
    />
  </el-form>
</template>

<script>
import InstitutionsAttendedForm from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/FamilyForm/InstitutionsAttendedForm";
import SuffixSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/SuffixSelector";
import EducationLevelSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/FamilyForm/EducationLevelSelector";
import CountrySelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/CountrySelector";
import OccupationSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/OccupationSelector";

export default {
  props: {
    stepParentNum: {
      type: String,
      default: ""
    },
    stepParent: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    SuffixSelector,
    CountrySelector,
    OccupationSelector,
    EducationLevelSelector,
    InstitutionsAttendedForm
  },
  computed: {
    isParentDeceased() {
      return this.parent.is_living === 0;
    },
    isOtherOccupation() {
      const OTHER = 44;
      return this.parent.occupation === OTHER;
    },
    hasEnoughEducationLevel() {
      const higherEducationLevels = ["7", "8", "9", "10", "11"];
      return higherEducationLevels.includes(this.parent.education_level);
    }
  }
};
</script>

<style scoped>
::v-deep .el-form-item__label {
  text-align: left;
  line-height: 28px;
}

.promptText {
  width: 400px;
}
</style>
