<template>
  <el-select
    style="width: 100%"
    filterable
    :value="value"
    @change="changeValue"
    :size="size"
    placeholder
  >
    <el-option
      v-for="item in data"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    />
  </el-select>
</template>

<script>
export default {
  name: "StateOfResidencySelector",
  props: {
    value: {
      type: String,
      default: ""
    },
    size: {
      type: String,
      default: ""
    }
  },
  computed: {
    data() {
      return [
        { value: "N/A", label: "Not Applicable" },
        { value: "US-AA", label: "APO AA" },
        { value: "US-AE", label: "APO AE" },
        { value: "US-AP", label: "APO AP" },
        { value: "US-AL", label: "Alabama" },
        { value: "US-AK", label: "Alaska" },
        { value: "US-AS", label: "American Samoa" },
        { value: "US-AZ", label: "Arizona" },
        { value: "US-AR", label: "Arkansas" },
        { value: "US-CA", label: "California" },
        { value: "US-CO", label: "Colorado" },
        { value: "US-CT", label: "Connecticut" },
        { value: "US-DE", label: "Delaware" },
        { value: "US-DC", label: "District of Columbia" },
        { value: "US-FL", label: "Florida" },
        { value: "US-GA", label: "Georgia" },
        { value: "US-GU", label: "Guam" },
        { value: "US-HI", label: "Hawaii" },
        { value: "US-ID", label: "Idaho" },
        { value: "US-IL", label: "Illinois" },
        { value: "US-IN", label: "Indiana" },
        { value: "US-IA", label: "Iowa" },
        { value: "US-KS", label: "Kansas" },
        { value: "US-KY", label: "Kentucky" },
        { value: "US-LA", label: "Louisiana" },
        { value: "US-ME", label: "Maine" },
        { value: "US-MH", label: "Marshall Islands" },
        { value: "US-MD", label: "Maryland" },
        { value: "US-MA", label: "Massachusetts" },
        { value: "US-MI", label: "Michigan" },
        { value: "US-FM", label: "Micronesia" },
        { value: "US-MN", label: "Minnesota" },
        { value: "US-MS", label: "Mississippi" },
        { value: "US-MO", label: "Missouri" },
        { value: "US-MT", label: "Montana" },
        { value: "US-NE", label: "Nebraska" },
        { value: "US-NV", label: "Nevada" },
        { value: "US-NH", label: "New Hampshire" },
        { value: "US-NJ", label: "New Jersey" },
        { value: "US-NM", label: "New Mexico" },
        { value: "US-NY", label: "New York" },
        { value: "US-NC", label: "North Carolina" },
        { value: "US-ND", label: "North Dakota" },
        { value: "US-MP", label: "Northern Mariana Islands" },
        { value: "US-OH", label: "Ohio" },
        { value: "US-OK", label: "Oklahoma" },
        { value: "US-OR", label: "Oregon" },
        { value: "US-PW", label: "Palau" },
        { value: "US-PA", label: "Pennsylvania" },
        { value: "US-PR", label: "Puerto Rico" },
        { value: "US-RI", label: "Rhode Island" },
        { value: "US-SC", label: "South Carolina" },
        { value: "US-SD", label: "South Dakota" },
        { value: "US-TN", label: "Tennessee" },
        { value: "US-TX", label: "Texas" },
        { value: "US-VI", label: "US Virgin Islands" },
        { value: "US-UT", label: "Utah" },
        { value: "US-VT", label: "Vermont" },
        { value: "US-VA", label: "Virginia" },
        { value: "US-WA", label: "Washington" },
        { value: "US-WV", label: "West Virginia" },
        { value: "US-WI", label: "Wisconsin" },
        { value: "US-WY", label: "Wyoming" }
      ];
    }
  },
  methods: {
    changeValue(value) {
      this.$emit("change", value);
    }
  }
};
</script>
