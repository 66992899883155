<template>
  <el-select
    style="width: 100%"
    filterable
    :value="value"
    @change="changeValue"
    placeholder
  >
    <el-option
      v-for="item in data"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    />
  </el-select>
</template>

<script>
export default {
  name: "LanguageOfInstructionCourse78thSelector",
  props: ["value"],
  computed: {
    data() {
      return [
        {
          label: "Arabic",
          value: "AR"
        },
        {
          label: "Armenian",
          value: "AN"
        },
        {
          label: "Bengali",
          value: "BE"
        },
        {
          label: "Chinese",
          value: "CH"
        },
        {
          label: "English",
          value: "EN"
        },
        {
          label: "French",
          value: "FR"
        },
        {
          label: "German",
          value: "GE"
        },
        {
          label: "Greek",
          value: "GR"
        },
        {
          label: "Hebrew",
          value: "HW"
        },
        {
          label: "Hindi/Urdu",
          value: "HD"
        },
        {
          label: "Hmong",
          value: "HM"
        },
        {
          label: "Hungarian",
          value: "HU"
        },
        {
          label: "Italian",
          value: "IT"
        },
        {
          label: "Japanese",
          value: "JP"
        },
        {
          label: "Korean",
          value: "KO"
        },
        {
          label: "Lao",
          value: "LA"
        },
        {
          label: "Malay",
          value: "ML"
        },
        {
          label: "Mon-Khmer",
          value: "MK"
        },
        {
          label: "Navajo",
          value: "NV"
        },
        {
          label: "Persian (Farsi)",
          value: "PE"
        },
        {
          label: "Polish",
          value: "PL"
        },
        {
          label: "Portuguese",
          value: "PO"
        },
        {
          label: "Punjabi",
          value: "PJ"
        },
        {
          label: "Russian",
          value: "RU"
        },
        {
          label: "Spanish",
          value: "SP"
        },
        {
          label: "Tagalog",
          value: "TG"
        },
        {
          label: "Thai",
          value: "TH"
        },
        {
          label: "Turkish",
          value: "TU"
        },
        {
          label: "Vietnamese",
          value: "VI"
        },
        {
          label: "Other",
          value: "OT"
        }
      ];
    }
  },
  methods: {
    changeValue(value) {
      this.$emit("change", value);
    }
  }
};
</script>
