<template>
  <el-collapse
    :value="activePanel"
    @change="(value) => {
      $emit('saveCounselingProfile');
      $emit('setActivePanel', value);
    }"
    accordion
  >
    <el-collapse-item
      v-if="
        thirdAppTypes[currentApplicationType] === thirdAppTypes.california_app &&
        applyLevelIsNotFreshman
      "
      title="Transfer Admission Planner"
      name="transferAdmissionPlanner"
    >
      <el-form>
        <p>
          If you have entered your school and coursework information in the UC Transfer Admission Planner (TAP) and indicated you plan to apply to UC for Fall Quarter/Semester 2022, your information may be available for import into your UC application.
        </p>
        <p>
          Please enter the login ID and email address used for your TAP account and click on "Import data from TAP" to copy your information. This will delete all previously entered academic history information.
        </p>
        <p>
          Click "Save & continue" if you do not have a TAP account.
        </p>
        <CounselingFormItem
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.california_app]"
        >
          <template slot="label">
            TAP login ID
          </template>
          <el-input
            :value="counselingProfile.tap_login_id"
            @input="
              natap_login_idme => setCounselingProfile({ tap_login_id })
            "
          />
        </CounselingFormItem>
        <CounselingFormItem
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.california_app]"
        >
          <template slot="label">
            TAP email address
          </template>
          <el-input
            :value="counselingProfile.tap_email_addr"
            @input="
              tap_email_addr => setCounselingProfile({ tap_email_addr })
            "
          />
        </CounselingFormItem>
        <el-form-item align="left">
          <el-button type="primary" @click="$emit('sendCounselorInvitation')">
            Import data from TAP
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
    <el-collapse-item
      v-if="
        thirdAppTypes[currentApplicationType] === thirdAppTypes.california_app &&
        !applyLevelIsNotFreshman
      "
      title="7th & 8th grade"
      name="7thAnd8thGrade"
    >
      <el-form>
        <p>
          If you took any high school-level math or a language other than English courses in 7th or 8th grade, you can enter them on this page. We want to make sure we count these courses toward meeting our "a-g" subject requirement, which is one of our admission requirements. (Math and language other than English are the only subject requirements that can be met in middle school and high school).        </p>
        <p>
          You should enter these courses only if you received a grade of C or better.
        </p>
        <p>
          If you took these courses in high school (9th - 12th grade), you'll have the chance to enter them at a later point.
        </p>
        <hr />
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.california_app]"
        >
          <template slot="label">
            Did you take any high school-level math courses in 7th or 8th grade?
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.math_flag_78th"
            @input="(math_flag_78th) => setCounselingProfile({ math_flag_78th })"
          >
            <div><el-radio label="Y">Yes</el-radio></div>
            <div><el-radio label="N">No</el-radio></div>
          </el-radio-group>
        </CounselingFormItem>
        <template v-if="counselingProfile.math_flag_78th === 'Y'">
          <div
            :key="mathCourse78thIndex"
            v-for="(mathCourse, mathCourse78thIndex) in counselingProfile.math_courses_78th"
          >
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.california_app]"
            >
              <template slot="label">
                Course name
              </template>
              <MathCourse78thSelector
                :value="mathCourse.course_name_code"
                @change="
                  course_name_code =>
                    setMathCourse78th(mathCourse78thIndex, { course_name_code })
                "
              />
            </CounselingFormItem>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.california_app]"
            >
              <template slot="label">
                Term period
              </template>
              <el-select
                style="width: 100%"
                :value="mathCourse.semester_count"
                @change="
                  semester_count =>
                    setMathCourse78th(mathCourse78thIndex, { semester_count })
                "
              >
                <el-option
                  v-for="course78thTermPeriod in course78thTermPeriods"
                  :key="course78thTermPeriod.value"
                  :label="course78thTermPeriod.label"
                  :value="course78thTermPeriod.value"
                />
              </el-select>
              <br />
              <span
                class="removeAcademicInterest"
                v-if="1 < counselingProfile.math_courses_78th.length"
                @click="() => removeMathCourse78th(mathCourse78thIndex)"
              >
                REMOVE
              </span>
            </CounselingFormItem>
          </div>
          <el-form-item v-if="mathCourseCountNotMaxYet" align="left">
            <el-button type="primary" @click="() => addMathCourse78th()">
              ADD ANOTHER COURSE
            </el-button>
          </el-form-item>
        </template>
        <hr />
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.california_app]"
        >
          <template slot="label">
            Did you take any courses in a language other than English in 7th or 8th grade?
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.language_flag_78th"
            @input="(language_flag_78th) => setCounselingProfile({ language_flag_78th })"
          >
            <div><el-radio label="Y">Yes</el-radio></div>
            <div><el-radio label="N">No</el-radio></div>
          </el-radio-group>
        </CounselingFormItem>
        <template v-if="counselingProfile.language_flag_78th === 'Y'">
          <div
            :key="languageCourse78thIndex"
            v-for="(languageCourse, languageCourse78thIndex) in counselingProfile.language_courses_78th"
          >
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.california_app]"
            >
              <template slot="label">
                Course name
              </template>
              <el-input
                :value="languageCourse.class_name"
                @input="
                  class_name =>
                    setLanguageCourse78th(languageCourse78thIndex, { class_name })
                "
              />
            </CounselingFormItem>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.california_app]"
            >
              <template slot="label">
                Term period
              </template>
              <el-select
                style="width: 100%"
                :value="languageCourse.semester_count"
                @change="
                  semester_count =>
                    setLanguageCourse78th(languageCourse78thIndex, { semester_count })
                "
              >
                <el-option
                  v-for="course78thTermPeriod in course78thTermPeriods"
                  :key="course78thTermPeriod.value"
                  :label="course78thTermPeriod.label"
                  :value="course78thTermPeriod.value"
                />
              </el-select>
            </CounselingFormItem>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.california_app]"
            >
              <template slot="label">
                Course category
              </template>
              <el-select
                style="width: 100%"
                :value="languageCourse.discipline_code"
                @change="
                  discipline_code =>
                    setLanguageCourse78th(languageCourse78thIndex, { discipline_code })
                "
              >
                <el-option
                  v-for="course78thCourseCategory in course78thCourseCategories"
                  :key="course78thCourseCategory.value"
                  :label="course78thCourseCategory.label"
                  :value="course78thCourseCategory.value"
                />
              </el-select>
            </CounselingFormItem>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.california_app]"
            >
              <template slot="label">
                Language
              </template>
              <LanguageOfCourse78thSelector
                :value="languageCourse.lote_code"
                @change="
                  lote_code =>
                    setLanguageCourse78th(languageCourse78thIndex, { lote_code })
                "
              />
              <br />
              <span
                class="removeAcademicInterest"
                v-if="1 < counselingProfile.math_courses_78th.length"
                @click="() => removeLanguageCourse78th(languageCourse78thIndex)"
              >
                REMOVE
              </span>
            </CounselingFormItem>
          </div>
          <el-form-item v-if="languageCourseCountNotMaxYet" align="left">
            <el-button type="primary" @click="() => addLanguageCourse78th()">
              ADD ANOTHER COURSE
            </el-button>
          </el-form-item>
        </template>
        <hr />
        <h5>International school experience</h5>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.california_app]"
        >
          <template slot="promptText">
            This excludes high school summer sessions, or American International or Department of Defense High School.
          </template>
          <template slot="label">
            Did you attend school outside the United States for any part of 6th through 8th grade?
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.intl_exp_flag_78th"
            @input="(intl_exp_flag_78th) => setCounselingProfile({ intl_exp_flag_78th })"
          >
            <div><el-radio label="Y">Yes</el-radio></div>
            <div><el-radio label="N">No</el-radio></div>
          </el-radio-group>
        </CounselingFormItem>
        <template v-if="counselingProfile.intl_exp_flag_78th === 'Y'">
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.california_app]"
          >
            <template slot="label">
              What was the language of instruction?
            </template>
            <LanguageOfInstructionCourse78thSelector
              :value="counselingProfile.intl_exp_lang_code"
              @change="
                intl_exp_lang_code =>
                  setCounselingProfile({ intl_exp_lang_code })
              "
            />
          </CounselingFormItem>
          <CounselingFormItem
            v-if="intlExpLangIsOther"
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.california_app]"
          >
            <template slot="label">
              Specify the language
            </template>
            <el-input
              :value="counselingProfile.intl_exp_lang_other"
              @input="
                intl_exp_lang_other =>
                  setCounselingProfile({ intl_exp_lang_other })
              "
            />
          </CounselingFormItem>
        </template>
        <hr />
      </el-form>
    </el-collapse-item>
    <el-collapse-item name="highSchool">
      <template slot="title">
        Current or Most Recent Secondary/High School
        <el-tooltip
          class="item"
          content="請填寫畢業學校"
          placement="top"
          popper-class="tooltipColor"
        >
          <span>
            <i class="fas fa-question-circle action-icon" />
          </span>
        </el-tooltip>
      </template>
      <el-form>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            High school type
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.high_school_type"
            @input="high_school_type => setCounselingProfile({ high_school_type })"
          >
            <div
              :key="highSchoolType.value"
              v-for="highSchoolType in highSchoolTypes"
            >
              <el-radio :label="highSchoolType.value">{{
                highSchoolType.label
              }}</el-radio>
            </div>
          </el-radio-group>
        </CounselingFormItem>
        <CounselingFormItem
          v-if="highSchoolTypeIsNonUs"
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
        >
          <el-checkbox
            :value="counselingProfile.high_school_is_exam_based"
            @input="(high_school_is_exam_based) => setCounselingProfile({ high_school_is_exam_based })"
          >
            This is an exam-based school.
          </el-checkbox>
        </CounselingFormItem>
        <CounselingFormItem
          v-if="!editingHighSchool"
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
        >
          <template slot="label">
            Current or most recent secondary/high school
          </template>
          <div>
            {{ counselingProfile.high_school_name }}
            <span
              class="editNameOfOtherHighSchool"
              @click="() => setEditingHighSchool(true)"
            >
              EDIT
            </span>
          </div>
        </CounselingFormItem>
        <template v-else>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              Where did you attend high school?
            </template>
            <el-radio-group
              style="width: 100%"
              :value="counselingProfile.high_school_state_type"
              @input="
                high_school_state_type => setCounselingProfile({ high_school_state_type })
              "
            >
              <div>
                <el-radio label="california">In California</el-radio>
              </div>
              <div>
                <el-radio label="inTheUs">Outside California (in the U.S.)</el-radio>
              </div>
              <div>
                <el-radio label="outsideTheUs">Outside the U.S.</el-radio>
              </div>
            </el-radio-group>
          </CounselingFormItem>
          <CounselingFormItem
            v-if="counselingProfile.high_school_state_type === 'inTheUs'"
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              State/U.S. territory
            </template>
            <div>
              <HighSchoolFilterStateSelector
                style="width: 100%"
                :value="counselingProfile.high_school_filter_state"
                @change="high_school_filter_state => setCounselingProfile({ high_school_filter_state })"
              />
            </div>
          </CounselingFormItem>
          <CounselingFormItem
            v-if="counselingProfile.high_school_state_type === 'outsideTheUs'"
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              Select a country
            </template>
            <div>
              <HighSchoolFilterCountrySelector
                style="width: 100%"
                :value="counselingProfile.high_school_filter_country"
                @change="high_school_filter_country => setCounselingProfile({ high_school_filter_country })"
              />
            </div>
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              Current or most recent secondary/high school
            </template>
            <div>
              <HighSchoolSelector
                style="width: 100%"
                :stateType="counselingProfile.high_school_state_type"
                :filterState="counselingProfile.high_school_filter_state"
                :filterCountry="counselingProfile.high_school_filter_country"
                :value="counselingProfile.high_school_value"
                @change="highSchool => setCounselingProfile({
                  high_school_value: highSchool.id,
                  high_school_name: highSchool.title
                })"
              />
            </div>
          </CounselingFormItem>
        </template>
        <div v-if="choseOtherInHighSchool">
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              Name of college/university
            </template>
            <el-input
              :value="counselingProfile.high_school_name"
              @input="
                high_school_name => setCounselingProfile({ high_school_name })
              "
            />
          </CounselingFormItem>
          <AddressForm
            :currentApplicationType="currentApplicationType"
            :address="counselingProfileHighSchool"
            @setAddress="data => setHighSchool({ ...data })"
          />
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.california_app]"
          >
            <template slot="label">
              School type
            </template>
            <el-select
              style="width: 100%"
              :value="counselingProfile.high_school_type_code"
              @change="(high_school_type_code) => setCounselingProfile({ high_school_type_code })"
            >
              <el-option
                v-for="highSchoolTypeCode in highSchoolTypeCodes"
                :key="highSchoolTypeCode.value"
                :label="highSchoolTypeCode.label"
                :value="highSchoolTypeCode.value"
              />
            </el-select>
          </CounselingFormItem>
        </div>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.california_app]"
        >
          <template slot="label">
            Specialized curriculum (if associated with this school)
          </template>
          <div style="display: inline-block; width: 100%;">
            <el-checkbox-group
              :value="counselingProfile.high_school_specialized_curriculums"
              @input="
                high_school_specialized_curriculums =>
                  setCounselingProfile({ high_school_specialized_curriculums })
              "
            >
              <div
                :key="specializedCurriculum.value"
                v-for="specializedCurriculum in specializedCurriculums"
              >
                <el-checkbox :label="specializedCurriculum.value">
                  {{ specializedCurriculum.label }}
                </el-checkbox>
              </div>
            </el-checkbox-group>
          </div>
        </CounselingFormItem>
        <CounselingFormItem
          v-if="specializedCurriculumIncludesOther"
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.california_app]"
        >
          <template slot="label">
            If you chose Other, please specify
          </template>
          <el-input
            :value="counselingProfile.high_school_specialized_curriculums_descr"
            @input="
              high_school_specialized_curriculums_descr => setCounselingProfile({ high_school_specialized_curriculums_descr })
            "
          />
        </CounselingFormItem>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
        >
          <template slot="label">
            Date of entry
          </template>
          <el-date-picker
            style="width: 100%"
            value-format="MM/yyyy"
            type="date"
            :value="counselingProfile.high_school_entry_month"
            @input="
              high_school_entry_month =>
                setCounselingProfile({ high_school_entry_month })
            "
          />
        </CounselingFormItem>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            Is this a boarding school?
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.high_school_is_boarding_high_school"
            @input="
              high_school_is_boarding_high_school =>
                setCounselingProfile({ high_school_is_boarding_high_school })
            "
          >
            <div>
              <el-radio :label="0">Yes</el-radio>
            </div>
            <div>
              <el-radio :label="1">No</el-radio>
            </div>
          </el-radio-group>
        </CounselingFormItem>
        <CounselingFormItem
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            Do you live on campus?
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.high_school_live_on_campus"
            @input="
              high_school_live_on_campus =>
                setCounselingProfile({ high_school_live_on_campus })
            "
          >
            <div>
              <el-radio :label="0">Yes</el-radio>
            </div>
            <div>
              <el-radio :label="1">No</el-radio>
            </div>
          </el-radio-group>
        </CounselingFormItem>
        <CounselingFormItem
          required
          checkApplications
          :value="counselingProfile.high_school_grade_frequency"
          getterProp="classScheduledOptions"
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
        >
          <template slot="label">
            How are classes scheduled at this school? 
          </template>
          <ClassesScheduledRadio
            :value="counselingProfile.high_school_grade_frequency"
            @input="(high_school_grade_frequency) => setCounselingProfile({ high_school_grade_frequency })"
          />
        </CounselingFormItem>
        <CounselingFormItem
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
        >
          <el-checkbox
            :value="counselingProfile.high_school_block_sched"
            @input="(high_school_block_sched) => setCounselingProfile({ high_school_block_sched })"
          >
            This school uses block scheduling.
          </el-checkbox>
        </CounselingFormItem>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.california_app]"
        >
          <template slot="label">
            What is this school's grading system? 
          </template>
          <el-select
            v-if="counselingProfile.high_school_state_type === 'outsideTheUs'"
            style="width: 100%"
            multiple
            :value="counselingProfile.high_school_grading_systems"
            @change="high_school_grading_systems => setCounselingProfile({ high_school_grading_systems })"
          >
            <el-option
              v-for="gradingSystem in outsideUsaHighSchoolGradingSystems"
              :key="gradingSystem.value"
              :value="gradingSystem.value"
              :label="gradingSystem.label"
            />
          </el-select>
          <div v-else style="display: inline-block; width: 100%;">
            <el-checkbox-group
              :value="counselingProfile.high_school_grading_systems"
              @input="
                high_school_grading_systems =>
                  setCounselingProfile({ high_school_grading_systems })
              "
            >
              <div
                :key="gradingSystem.value"
                v-for="gradingSystem in highSchoolGradingSystems"
              >
                <el-checkbox :label="gradingSystem.value">
                {{ gradingSystem.label }}
                </el-checkbox>
              </div>
            </el-checkbox-group>
          </div>
        </CounselingFormItem>
        <CounselingFormItem
          v-if="highSchoolGradingSystemsIsOther"
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.california_app]"
        >
          <template slot="label">
            If you chose Other, please specify
          </template>
          <el-input
            :value="counselingProfile.high_school_grading_system_descr"
            @input="
              high_school_grading_system_descr => setCounselingProfile({ high_school_grading_system_descr })
            "
          />
        </CounselingFormItem>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
        >
          <template slot="label">
            Degree, diploma, or certification received or to be received. 
          </template>
          <el-select
            style="width: 100%"
            :value="counselingProfile.high_school_degree_code"
            @change="
              high_school_degree_code =>
                setCounselingProfile({ high_school_degree_code })
            "
          >
            <el-option
              v-for="highSchoolDegree in highSchoolDegrees"
              :key="highSchoolDegree.value"
              :label="highSchoolDegree.label"
              :value="highSchoolDegree.value"
            />
          </el-select>
        </CounselingFormItem>
        <CounselingFormItem
          v-if="highSchoolDegreeIsOther"
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.california_app]"
        >
          <template slot="label">
            If you chose Other, please specify
          </template>
          <el-input
            :value="counselingProfile.high_school_degree_descr"
            @input="
              high_school_degree_descr => setCounselingProfile({ high_school_degree_descr })
            "
          />
        </CounselingFormItem>
        <CounselingFormItem
          required
          checkApplications
          :value="counselingProfile.high_school_grade_scale"
          getterProp="gradingScaleOptions"
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            Grading Scale
          </template>
          <GradingScaleRadio
            :value="counselingProfile.high_school_grade_scale"
            @input="(high_school_grade_scale) => setCounselingProfile({ high_school_grade_scale })"
          />
        </CounselingFormItem>
        <CounselingFormItem
          required
          v-if="highSchoolGradingScaleIsOther"
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            Other Grading Scale
          </template>
          <el-input
            :value="counselingProfile.high_school_other_grade_scale"
            @input="
              high_school_other_grade_scale => setCounselingProfile({ high_school_other_grade_scale })
            "
          />
        </CounselingFormItem>
        <template v-if="highSchoolGradingScaleIsNumbers">
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
          >
            <template slot="label">
              What is the lowest possible number grade at this school?
            </template>
            <el-input-number
              class="inputNumber"
              controls-position="right"
              :min="0"
              :max="counselingProfile.high_school_num_grade_max"
              :value="counselingProfile.high_school_num_grade_min"
              @input="
                num_grade_min =>
                  setCounselingProfile({ high_school_num_grade_min })
              "
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
          >
            <template slot="label">
              What is the highest possible number grade at this school?
            </template>
            <el-input-number
              class="inputNumber"
              controls-position="right"
              :min="0"
              :max="200"
              :value="counselingProfile.high_school_num_grade_max"
              @input="
                high_school_num_grade_max =>
                  setCounselingProfile({ high_school_num_grade_max })
              "
            />
          </CounselingFormItem>
        </template>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            Did or will you graduate from this school?
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.high_school_graduate_from_school"
            @input="
              high_school_graduate_from_school =>
                setCounselingProfile({ high_school_graduate_from_school })
            "
          >
            <div>
              <el-radio :label="0">Yes</el-radio>
            </div>
            <div>
              <el-radio :label="1">No</el-radio>
            </div>
          </el-radio-group>
        </CounselingFormItem>
        <template v-if="hasGraduatedFromThisShcool">
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
          >
            <template slot="label">
              Graduation date
            </template>
            <el-date-picker
              style="width: 100%"
              value-format="dd/MM/yyyy"
              type="date"
              :value="counselingProfile.high_school_graduation_month"
              @input="
                high_school_graduation_month =>
                  setCounselingProfile({ high_school_graduation_month })
              "
            />
          </CounselingFormItem>
        </template>
        <template v-else>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              Exit date
            </template>
            <el-date-picker
              style="width: 100%"
              value-format="MM/yyyy"
              type="date"
              :value="counselingProfile.high_school_exit_month"
              @input="
                high_school_exit_month =>
                  setCounselingProfile({ high_school_exit_month })
              "
            />
          </CounselingFormItem>
          <div
            v-if="
              [thirdAppTypes.california_app].includes(thirdAppTypes[currentApplicationType]) &&
              counselingProfile.high_school_entry_month &&
              counselingProfile.high_school_exit_month
            "
          >
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.california_app]"
            >
              <template slot="label">
                What grades did you attend this high school?
              </template>
            </CounselingFormItem>
            <CounselingFormItem
              required
              hiddenTag
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.california_app]"
            >
              <HighSchoolAcadYear
                :key="index"
                v-for="(highSchoolAcadYear, index) in counselingProfile.high_school_acad_years"
                :highSchoolEntryMonth="counselingProfile.high_school_entry_month"
                :highSchoolExitMonth="counselingProfile.high_school_exit_month"
                :highSchoolAcadYear="highSchoolAcadYear"
                @setHighSchoolAcadYear="highSchoolAcadYear => setHighSchoolAcadYear(index, highSchoolAcadYear)"
              />
            </CounselingFormItem>
            <div>
              <el-form-item align="left">
                <el-button
                  type="primary"
                  @click="() => addHighSchoolAnotherAcadYear()"
                >
                  ADD ANOTHER GRADE
                </el-button>
              </el-form-item>
            </div>
          </div>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          >
            <template slot="label">
              Did or will you receive your high school equivalency?
            </template>
            <el-radio-group
              style="width: 100%"
              :value="counselingProfile.high_school_receive_high_school_equivalency"
              @input="
                high_school_receive_high_school_equivalency =>
                  setCounselingProfile({ high_school_receive_high_school_equivalency })
              "
            >
              <div>
                <el-radio :label="0">Yes</el-radio>
              </div>
              <div>
                <el-radio :label="1">No</el-radio>
              </div>
            </el-radio-group>
          </CounselingFormItem>
          <template v-if="hasReceivedSchoolEquivalency">
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app]"
            >
              <template slot="label">
                Date equivalency received
              </template>
              <el-date-picker
                style="width: 100%"
                value-format="MM/yyyy"
                type="date"
                :value="counselingProfile.high_school_receive_high_school_equivalency_month"
                @input="
                  high_school_receive_high_school_equivalency_month =>
                    setCounselingProfile({ high_school_receive_high_school_equivalency_month })
                "
              />
            </CounselingFormItem>
          </template>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          >
            <template slot="label">
              Please provide details about the circumstances under which you did or will not graduate from secondary/high school.
            </template>
            <div class="ckeditor">
              <CounselingEditor
                height="200"
                :value="counselingProfile.high_school_descr"
                @input="
                  high_school_descr =>
                    setCounselingProfile({ high_school_descr })
                "
              />
            </div>
          </CounselingFormItem>
        </template>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            Please indicate if any of these options will have affected your progression through or since secondary/high school. Check all that apply.
          </template>
          <div style="display: inline-block; width: 100%;">
            <el-checkbox-group
              :value="counselingProfile.high_school_progression"
              @input="
                high_school_progression =>
                  setCounselingProfile({ high_school_progression })
              "
            >
              <div>
                <el-checkbox label="1">Did or will graduate early</el-checkbox>
              </div>
              <div>
                <el-checkbox label="2">Did or will graduate late</el-checkbox>
              </div>
              <div>
                <el-checkbox label="4">Did or will take time off</el-checkbox>
              </div>
              <div>
                <el-checkbox label="5">Did or will take gap year</el-checkbox>
              </div>
              <div>
                <el-checkbox label="7">No change in progression</el-checkbox>
              </div>
            </el-checkbox-group>
          </div>
        </CounselingFormItem>
        <template v-if="hasAnyProgression">
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          >
            <template slot="label">
              Please use the space below to provide details about the change in progression through secondary/high school that you indicated above.
            </template>
            <div class="ckeditor">
              <CounselingEditor
                height="200"
                :value="counselingProfile.high_school_progression_descr"
                @input="
                  high_school_progression_descr =>
                    setCounselingProfile({ high_school_progression_descr })
                "
              />
            </div>
          </CounselingFormItem>
        </template>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            Most recent academic year
          </template>
          <el-select
            style="width: 100%"
            :value="counselingProfile.high_school_grade_level"
            @change="(high_school_grade_level) => setCounselingProfile({ high_school_grade_level })"
          >
            <el-option
              v-for="gradeLevel in gradeLevels"
              :key="gradeLevel.value"
              :label="gradeLevel.label"
              :value="gradeLevel.value"
            />
          </el-select>
        </CounselingFormItem>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            Graduating class size (approx.)
          </template>
          <el-input-number
            :min="0"
            :max="99999"
            class="inputNumber"
            controls-position="right"
            :value="counselingProfile.graduating_class_size"
            @input="
              graduating_class_size => setCounselingProfile({ graduating_class_size })
            "
          />
        </CounselingFormItem>
        <CounselingFormItem
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            Class rank reporting
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.class_rank_reporting"
            @input="
              class_rank_reporting => setCounselingProfile({ class_rank_reporting })
            "
          >
            <div
              :key="classRankReporting"
              v-for="classRankReporting in Object.keys(EnumClassRankReportings)"
            >
              <el-radio :label="EnumClassRankReportings[classRankReporting]">
                {{ classRankReporting }}
              </el-radio>
            </div>
          </el-radio-group>
        </CounselingFormItem>
        <CounselingFormItem
          v-if="counselingProfile.class_rank_reporting === EnumClassRankReportings.Exact"
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            Class rank
          </template>
          <el-input-number
            :min="0"
            :max="99999"
            class="inputNumber"
            controls-position="right"
            :value="counselingProfile.exact_class_rank"
            @input="
              exact_class_rank => setCounselingProfile({ exact_class_rank })
            "
          />
        </CounselingFormItem>
        <CounselingFormItem
          v-else-if="counselingProfile.class_rank_reporting === EnumClassRankReportings.Decile"
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            Decile rank
          </template>
          <el-select
            style="width: 100%"
            :value="counselingProfile.decile_rank"
            @change="(decile_rank) => setCounselingProfile({ decile_rank })"
          >
            <el-option
              v-for="decileRank in 10"
              :key="decileRank"
              :label="`Top ${decileRank}0%`"
              :value="decileRank"
            />
          </el-select>
        </CounselingFormItem>
        <CounselingFormItem
          v-else-if="counselingProfile.class_rank_reporting === EnumClassRankReportings.Quintile"
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            Quintile rank
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.quintile_rank"
            @input="
              quintile_rank => setCounselingProfile({ quintile_rank })
            "
          >
            <div><el-radio :label="1">Top 20%</el-radio></div>
            <div><el-radio :label="2">Top 40%</el-radio></div>
            <div><el-radio :label="3">Top 60%</el-radio></div>
            <div><el-radio :label="4">Top 80%</el-radio></div>
            <div><el-radio :label="5">Top 100%</el-radio></div>
          </el-radio-group>
        </CounselingFormItem>
        <CounselingFormItem
          v-else-if="counselingProfile.class_rank_reporting === EnumClassRankReportings.Quartile"
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            Quartile rank
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.quartile_rank"
            @input="
              quartile_rank => setCounselingProfile({ quartile_rank })
            "
          >
            <div><el-radio :label="1">Top 25%</el-radio></div>
            <div><el-radio :label="2">Top 50%</el-radio></div>
            <div><el-radio :label="3">Top 75%</el-radio></div>
            <div><el-radio :label="4">Top 10%</el-radio></div>
          </el-radio-group>
        </CounselingFormItem>
        <CounselingFormItem
          v-if="counselingProfile.class_rank_reporting !== EnumClassRankReportings.None"
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            Rank weighting
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.class_rank_weighting"
            @input="
              class_rank_weighting => setCounselingProfile({ class_rank_weighting })
            "
          >
            <div><el-radio :label="1">Weighted</el-radio></div>
            <div><el-radio :label="2">Unweighted</el-radio></div>
          </el-radio-group>
        </CounselingFormItem>
        <CounselingFormItem
          required
          checkApplications
          :value="counselingProfile.gpa_scale_reporting"
          getterProp="gpaScaleOptions"
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            GPA Scale reporting
          </template>
          <el-select
            style="width: 100%"
            :value="counselingProfile.gpa_scale_reporting"
            @change="(gpa_scale_reporting) => setCounselingProfile({ gpa_scale_reporting })"
          >
            <el-option
              v-for="gpaScale in gpaScales"
              :key="gpaScale.id"
              :label="gpaScale.title"
              :value="gpaScale.id"
            />
          </el-select>
        </CounselingFormItem>
        <CounselingFormItem
          v-if="gpaScaleIsOther"
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            GPA scale other
          </template>
          <el-input
            :value="counselingProfile.high_school_gpa_scale_other"
            @input="
              high_school_gpa_scale_other => setCounselingProfile({ high_school_gpa_scale_other })
            "
          />
        </CounselingFormItem>
        <CounselingFormItem
          :required="!gpaScaleReportingIsNone"
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            Cumulative GPA
          </template>
          <el-input
            :value="counselingProfile.cumulative_gpa"
            @input="
              cumulative_gpa => setCounselingProfile({ cumulative_gpa })
            "
          />
        </CounselingFormItem>
        <CounselingFormItem
          required
          checkApplications
          :value="counselingProfile.gpa_weighting"
          getterProp="gpaTypeOptions"
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            GPA weighting
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.gpa_weighting"
            @input="
              gpa_weighting => setCounselingProfile({ gpa_weighting })
            "
          >
            <div :key="gpaType.id" v-for="gpaType in gpaTypes">
              <el-radio :label="gpaType.id">
                {{ gpaType.title }}
              </el-radio>
            </div>
          </el-radio-group>
        </CounselingFormItem>
        <CounselingFormItem
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
          hiddenTag
        >
          <h3>High School Counselor</h3>
        </CounselingFormItem>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            Counselor's First Name
          </template>
          <el-input
            :value="counselingProfile.counselor_first_name"
            @input="
              counselor_first_name => setCounselingProfile({ counselor_first_name })
            "
          />
        </CounselingFormItem>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            Counselor's Last Name
          </template>
          <el-input
            :value="counselingProfile.counselor_last_name"
            @input="
              counselor_last_name => setCounselingProfile({ counselor_last_name })
            "
          />
        </CounselingFormItem>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            Counselor's Email
          </template>
          <el-input
            :value="counselingProfile.counselor_email"
            @input="
              counselor_email => setCounselingProfile({ counselor_email })
            "
          />
        </CounselingFormItem>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            This is my
          </template>
          <div style="display: inline-block; width: 100%;">
            <el-checkbox-group
              :value="counselingProfile.counselor_relationships"
              @input="
                counselor_relationships =>
                  setCounselingProfile({ counselor_relationships })
              "
            >
              <div
                :key="counselorRelationship.value"
                v-for="counselorRelationship in counselorRelationships"
              >
                <el-checkbox :label="counselorRelationship.value">
                  {{ counselorRelationship.label }}
                </el-checkbox>
              </div>
            </el-checkbox-group>
          </div>
        </CounselingFormItem>
        <CounselingFormItem
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
          hiddenTag
        >
          <el-button type="primary" @click="$emit('sendCounselorInvitation')">
            INVITE
          </el-button>
        </CounselingFormItem>
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
    <el-collapse-item
      title="Other Secondary/High Schools"
      name="otherHighSchools"
    >
      <CounselingFormAlert
        :currentApplication="currentApplicationType"
        :applicationsNeedToVisible="[thirdAppTypes.common_app]"
      >
        <template slot="title">
          {{ $t('counselingProfile.commonAppJustCanFillThreeHighSchools') }}
        </template>
      </CounselingFormAlert>
      <el-form>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
        >
          <template slot="label">
            If you have attended any secondary/high schools not listed in the previous section, please indicate the number of schools.
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.other_high_schools_length"
            @input="
              other_high_schools_length => {
                setCounselingProfile({ other_high_schools_length });
                initEditingOtherHighSchoolsStatus();
              }
            "
          >
            <div><el-radio :label="0">0</el-radio></div>
            <div :key="num" v-for="num in MAX_OTHER_HIGH_SCHOOL_COUNT">
              <el-radio :label="num">{{ num }}</el-radio>
            </div>
          </el-radio-group>
        </CounselingFormItem>
        <CounselingFormItem
          v-if="hasNoOtherHighSchool"
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            Did you earn high school equivalency by taking a test such as the GED or CHSPE?
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.equiv_exam_yn"
            @input="(equiv_exam_yn) => setCounselingProfile({ equiv_exam_yn })"
          >
            <div><el-radio :label="true">Yes</el-radio></div>
            <div><el-radio :label="false">No</el-radio></div>
          </el-radio-group>
        </CounselingFormItem>
        <hr />
        <div
          :key="otherHighSchoolIndex"
          v-for="(otherHighSchoolNum, otherHighSchoolIndex) of counselingProfile.other_high_schools_length"
        >
          <h2>{{ `High School ${otherHighSchoolNum}` }}</h2>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
          >
            <template slot="label">
              {{ `High school ${otherHighSchoolNum} type` }}
            </template>
            <el-radio-group
              style="width: 100%"
              :value="counselingProfile.other_high_schools[otherHighSchoolIndex].type"
              @input="(type) => setOtherHighSchool(otherHighSchoolIndex, { type })"
            >
              <div
                :key="highSchoolType.value"
                v-for="highSchoolType in highSchoolTypes"
              >
                <el-radio :label="highSchoolType.value">{{
                  highSchoolType.label
                }}</el-radio>
              </div>
            </el-radio-group>
          </CounselingFormItem>
          <CounselingFormItem
            v-if="!editingOtherHighSchools[otherHighSchoolIndex]"
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              {{ `Secondary/high school ${otherHighSchoolNum}` }}
            </template>
            <div v-if="!editingOtherHighSchools[otherHighSchoolIndex]">
              {{ counselingProfile.other_high_schools[otherHighSchoolIndex].name }}
              <span
                class="editNameOfOtherHighSchool"
                @click="() => setEditingOtherHighSchools(otherHighSchoolIndex, true)"
              >
                EDIT
              </span>
            </div>
          </CounselingFormItem>
          <template v-else>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
            >
              <template slot="label">
                Where did you attend high school?
              </template>
              <el-radio-group
                style="width: 100%"
                :value="counselingProfile.other_high_schools[otherHighSchoolIndex].state_type"
                @input="
                  state_type => setOtherHighSchool(otherHighSchoolIndex, { state_type })
                "
              >
                <div>
                  <el-radio label="california">In California</el-radio>
                </div>
                <div>
                  <el-radio label="inTheUs">Outside California (in the U.S.)</el-radio>
                </div>
                <div>
                  <el-radio label="outsideTheUs">Outside the U.S.</el-radio>
                </div>
              </el-radio-group>
            </CounselingFormItem>
            <CounselingFormItem
              v-if="counselingProfile.other_high_schools[otherHighSchoolIndex].state_type === 'inTheUs'"
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
            >
              <template slot="label">
                State/U.S. territory
              </template>
              <div>
                <HighSchoolFilterStateSelector
                  style="width: 100%"
                  :value="counselingProfile.high_school_filter_state"
                  @change="high_school_filter_state => setCounselingProfile({ high_school_filter_state })"
                />
              </div>
            </CounselingFormItem>
            <CounselingFormItem
              v-if="counselingProfile.other_high_schools[otherHighSchoolIndex].state_type === 'outsideTheUs'"
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
            >
              <template slot="label">
                Select a country
              </template>
              <div>
                <HighSchoolFilterCountrySelector
                  style="width: 100%"
                  :value="counselingProfile.high_school_filter_country"
                  @change="high_school_filter_country => setCounselingProfile({ high_school_filter_country })"
                />
              </div>
            </CounselingFormItem>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
            >
              <template slot="label">
                {{ `Secondary/high school ${otherHighSchoolNum}` }}
              </template>
              <div>
                <HighSchoolSelector
                  style="width: 100%"
                  :stateType="counselingProfile.other_high_schools[otherHighSchoolIndex].state_type"
                  :filterState="counselingProfile.other_high_schools[otherHighSchoolIndex].filter_state"
                  :filterCountry="counselingProfile.other_high_schools[otherHighSchoolIndex].filter_country"
                  :value="counselingProfile.other_high_schools[otherHighSchoolIndex].value"
                  @change="highSchool => setOtherHighSchool(otherHighSchoolIndex, {
                    value: highSchool.id,
                    name: highSchool.title
                  })"
                />
              </div>
            </CounselingFormItem>
          </template>
          <div v-if="choseOtherInOtherHighSchool(otherHighSchoolIndex)">
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
            >
              <template slot="label">
                High School Name
              </template>
              <el-input
                :value="counselingProfile.other_high_schools[otherHighSchoolIndex].name"
                @input="
                  name => setOtherHighSchool(otherHighSchoolIndex, { name })
                "
              />
            </CounselingFormItem>
            <AddressForm
              :currentApplicationType="currentApplicationType"
              :address="counselingProfile.other_high_schools[otherHighSchoolIndex]"
              @setAddress="data => setOtherHighSchool(otherHighSchoolIndex, { ...data })"
            />
          </div>
          <CounselingFormAlert
            :currentApplication="currentApplicationType"
            :applicationsNeedToVisible="[thirdAppTypes.common_app]"
          >
            <template slot="title">
              {{ $t('counselingProfile.commonAppJustCanSubmitFirstAttendanceDate') }}
            </template>
          </CounselingFormAlert>
          <div
            :key="attendDateIndex"
            v-for="(attendDate, attendDateIndex) in counselingProfile.other_high_schools[otherHighSchoolIndex].attend_dates"
          >
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
            >
              <template slot="label">
                {{ `Secondary/high school ${otherHighSchoolNum} from date` }}
              </template>
              <el-date-picker
                style="width: 100%"
                value-format="MM/yyyy"
                type="date"
                :value="attendDate.from_month"
                @input="
                  from_month =>
                    setAttendDateOfOtherHighSchool(otherHighSchoolIndex, attendDateIndex, { from_month })
                "
              />
            </CounselingFormItem>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
            >
              <template slot="label">
                {{ `Secondary/high school ${otherHighSchoolNum} to date` }}
              </template>
              <el-date-picker
                style="width: 100%"
                value-format="MM/yyyy"
                type="date"
                :value="attendDate.to_month"
                @input="
                  to_month =>
                    setAttendDateOfOtherHighSchool(otherHighSchoolIndex, attendDateIndex, { to_month })
                "
              /><br />
              <span
                class="removeAttendDateOfOtherHighSchool"
                v-if="1 < counselingProfile.other_high_schools[otherHighSchoolIndex].attend_dates.length"
                @click="() => removeOtherHighSchoolAttendDate(otherHighSchoolIndex, attendDateIndex)"
              >
                REMOVE
              </span>
            </CounselingFormItem>
          </div>
          <el-form-item align="left">
            <el-button
              type="primary"
              @click="() => addOtherHighSchoolAttendDate(otherHighSchoolIndex)"
            >
              ADD MORE ATTENDANCE DATES
            </el-button>
          </el-form-item>
          <div
            v-if="
              counselingProfile.other_high_schools[otherHighSchoolIndex]
                .attend_dates[0].from_month &&
                counselingProfile.other_high_schools[otherHighSchoolIndex]
                  .attend_dates[0].to_month
            "
          >
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.california_app]"
            >
              <template slot="label">
                What grades did you attend this high school?
              </template>
            </CounselingFormItem>
            <CounselingFormItem
              required
              hiddenTag
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.california_app]"
            >
              <HighSchoolAcadYear
                :key="index"
                v-for="(highSchoolAcadYear, index) in counselingProfile.other_high_schools[otherHighSchoolIndex].acad_years"
                :highSchoolEntryMonth="counselingProfile.other_high_schools[otherHighSchoolIndex].attend_dates[0].from_month"
                :highSchoolExitMonth="counselingProfile.other_high_schools[otherHighSchoolIndex].attend_dates[0].to_month"
                :highSchoolAcadYear="highSchoolAcadYear"
                @setHighSchoolAcadYear="highSchoolAcadYear => setOtherHighSchoolAcadYear(otherHighSchoolIndex, index, highSchoolAcadYear)"
              />
            </CounselingFormItem>
            <div>
              <el-form-item align="left">
                <el-button
                  type="primary"
                  @click="() => addOtherHighSchoolAnotherAcadYear(otherHighSchoolIndex)"
                >
                  ADD ANOTHER GRADE
                </el-button>
              </el-form-item>
            </div>
          </div>
          <CounselingFormItem
            v-if="otherHighSchoolTypeIsNonUs(otherHighSchoolIndex)"
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
          >
            <el-checkbox
              :value="counselingProfile.other_high_schools[otherHighSchoolIndex].is_exam_based"
              @input="(is_exam_based) => setOtherHighSchool(otherHighSchoolIndex, { is_exam_based })"
            >
              This is an exam-based school.
            </el-checkbox>
          </CounselingFormItem>
          <CounselingFormItem
            required
            checkApplications
            :value="counselingProfile.other_high_schools[otherHighSchoolIndex].grade_frequency"
            getterProp="classScheduledOptions"
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              How are classes scheduled at this school? 
            </template>
            <ClassesScheduledRadio
              :value="counselingProfile.other_high_schools[otherHighSchoolIndex].grade_frequency"
              @input="(grade_frequency) => setOtherHighSchool(otherHighSchoolIndex, { grade_frequency })"
            />
          </CounselingFormItem>
          <CounselingFormItem
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
          >
            <el-checkbox
              :value="counselingProfile.other_high_schools[otherHighSchoolIndex].block_sched"
              @input="(block_sched) => setOtherHighSchool(otherHighSchoolIndex, { block_sched })"
            >
              This school uses block scheduling.
            </el-checkbox>
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.california_app]"
          >
            <template slot="label">
              What is this school's grading system? 
            </template>
            <el-select
              v-if="counselingProfile.other_high_schools[otherHighSchoolIndex].state_type === 'outsideTheUs'"
              style="width: 100%"
              multiple
              :value="counselingProfile.other_high_schools[otherHighSchoolIndex].grading_systems"
              @change="
                grading_systems =>
                  setOtherHighSchool(otherHighSchoolIndex, { grading_systems })
              "
            >
              <el-option
                v-for="gradingSystem in outsideUsaHighSchoolGradingSystems"
                :key="gradingSystem.value"
                :value="gradingSystem.value"
                :label="gradingSystem.label"
              />
            </el-select>
            <div v-else style="display: inline-block; width: 100%;">
            <el-checkbox-group
              :value="counselingProfile.other_high_schools[otherHighSchoolIndex].grading_systems"
              @input="
                grading_systems =>
                  setOtherHighSchool(otherHighSchoolIndex, { grading_systems })
              "
            >
              <div
                :key="gradingSystem.value"
                v-for="gradingSystem in highSchoolGradingSystems"
              >
                <el-checkbox :label="gradingSystem.value">
                  {{ gradingSystem.label }}
                </el-checkbox>
              </div>
            </el-checkbox-group>
            </div>
          </CounselingFormItem>
          <CounselingFormItem
            v-if="otherSchoolGradingSystemIsOther(otherHighSchoolIndex)"
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.california_app]"
          >
            <template slot="label">
              If you chose Other, please specify
            </template>
            <el-input
              :value="counselingProfile.other_high_schools[otherHighSchoolIndex].grading_system_descr"
              @input="
                grading_system_descr => setOtherHighSchool(otherHighSchoolIndex, { grading_system_descr })
              "
            />
          </CounselingFormItem>
          <CounselingFormItem
            checkApplications
            :value="counselingProfile.other_high_schools[otherHighSchoolIndex].grade_scale"
            getterProp="gradingScaleOptions"
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
          >
            <template slot="label">
              Grading Scale
            </template>
            <GradingScaleRadio
              :value="counselingProfile.other_high_schools[otherHighSchoolIndex].grade_scale"
              @input="(grade_scale) => setOtherHighSchool(otherHighSchoolIndex, { grade_scale })"
            />
          </CounselingFormItem>
          <CounselingFormItem
            v-if="otherHighSchoolGradingScaleIsOther(otherHighSchoolIndex)"
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
          >
            <template slot="label">
              Other Grading Scale
            </template>
            <el-input
              :value="counselingProfile.other_high_schools[otherHighSchoolIndex].other_grade_scale"
              @input="
                other_grade_scale => setOtherHighSchool(otherHighSchoolIndex, { other_grade_scale })
              "
            />
          </CounselingFormItem>
          <template v-if="otherHighSchoolGradingScaleIsNumbers(otherHighSchoolIndex)">
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
            >
              <template slot="label">
                What is the lowest possible number grade at this school?
              </template>
              <el-input-number
                class="inputNumber"
                controls-position="right"
                :min="0"
                :max="counselingProfile.other_high_schools[otherHighSchoolIndex].num_grade_max"
                :value="counselingProfile.other_high_schools[otherHighSchoolIndex].num_grade_min"
                @input="
                  num_grade_min =>
                    setOtherHighSchool(otherHighSchoolIndex, { num_grade_min })
                "
              />
            </CounselingFormItem>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
            >
              <template slot="label">
                What is the highest possible number grade at this school?
              </template>
              <el-input-number
                class="inputNumber"
                controls-position="right"
                :min="0"
                :max="200"
                :value="counselingProfile.other_high_schools[otherHighSchoolIndex].num_grade_max"
                @input="
                  num_grade_max =>
                    setOtherHighSchool(otherHighSchoolIndex, { num_grade_max })
                "
              />
            </CounselingFormItem>
          </template>
          <hr />
        </div>
        <template v-if="otherHighSchoolCounsMoreThanZero">
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          >
            <template slot="label">
              Please provide details about why you left each of the above secondary/high schools.
            </template>
            <div class="ckeditor">
              <CounselingEditor
                height="200"
                :value="counselingProfile.why_leave_other_high_school"
                @input="
                  why_leave_other_high_school =>
                    setCounselingProfile({ why_leave_other_high_school })
                "
              />
            </div>
          </CounselingFormItem>
        </template>
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
    <el-collapse-item 
      v-if="[thirdAppTypes.coalition_app, thirdAppTypes.california_app].includes(thirdAppTypes[currentApplicationType])"
      title="9th Grade Coursework"
      name="9thGradeCoursework"
    >
      <el-form>
        <div
          :key="highSchoolIndex"
          v-for="(highSchool, highSchoolIndex) in counselingProfile.high_schools"
        >
          <h3>{{ highSchool.name }}</h3>
          <hr />
          <el-form-item align="left">
            <el-button
              type="primary"
              @click="() => openNinethGradeCourseworkDialog(highSchoolIndex)"
            >
              ADD 9TH GRADE CLASSES
            </el-button>
          </el-form-item>
          <div
            :key="courseIndex"
            v-for="(course, courseIndex) in highSchool.ninethCourses"
            class="course"
          >
            <div>
              <b>{{ course.className }}</b>&nbsp;&nbsp;&nbsp;&nbsp;
              {{ course.subjectName }}
            </div>
            <div>
              <span
                class="editAndDeleteCourseOfOtherHighSchool"
                @click="() => editGradeCourseworkOfHighSchool(highSchoolIndex, 9, courseIndex)"
              >
                EDIT
              </span>
              <span
                class="editAndDeleteCourseOfOtherHighSchool"
                @click="() => deleteGradeCourseworkOfHighSchool(highSchoolIndex, 9, courseIndex)"
              >
                DELETE
              </span>
            </div>
          </div>
        </div>
      </el-form>
    </el-collapse-item>
    <HighSchoolGradeCourseworkDialog
      :currentApplicationType="currentApplicationType"
      year="9"
      :visible="ninethGradeCourseworkDialogVisible"
      @closeDialog="closeNinethGradeCourseworkDialog"
      @submitGradeCoursework="
        coursework => addGradeCourseworkOfOtherHighSchool(coursework)
      "
    />
    <el-collapse-item
      v-if="[thirdAppTypes.coalition_app, thirdAppTypes.california_app].includes(thirdAppTypes[currentApplicationType])"
      title="10th Grade Coursework"
      name="10thGradeCoursework"
    >
      <el-form>
        <div
          :key="highSchoolIndex"
          v-for="(highSchool, highSchoolIndex) in counselingProfile.high_schools"
        >
          <h3>{{ highSchool.name }}</h3>
          <hr />
          <el-form-item align="left">
            <el-button
              type="primary"
              @click="() => openTenthGradeCourseworkDialog(highSchoolIndex)"
            >
              ADD 10TH GRADE CLASSES
            </el-button>
          </el-form-item>
          <div
            :key="courseIndex"
            v-for="(course, courseIndex) in highSchool.tenthCourses"
            class="course"
          >
            <div>
              <b>{{ course.className }}</b>&nbsp;&nbsp;&nbsp;&nbsp;
              {{ course.subjectName }}
            </div>
            <div>
              <span
                class="editAndDeleteCourseOfOtherHighSchool"
                @click="() => editGradeCourseworkOfHighSchool(highSchoolIndex, 10, courseIndex)"
              >
                EDIT
              </span>
              <span
                class="editAndDeleteCourseOfOtherHighSchool"
                @click="() => deleteGradeCourseworkOfHighSchool(highSchoolIndex, 10, courseIndex)"
              >
                DELETE
              </span>
            </div>
          </div>
        </div>
      </el-form>
    </el-collapse-item>
    <HighSchoolGradeCourseworkDialog
      :currentApplicationType="currentApplicationType"
      year="10"
      :visible="tenthGradeCourseworkDialogVisible"
      @closeDialog="closeTenthGradeCourseworkDialog"
      @submitGradeCoursework="
        coursework => addGradeCourseworkOfOtherHighSchool(coursework)
      "
    />
    <el-collapse-item
      v-if="[thirdAppTypes.coalition_app, thirdAppTypes.california_app].includes(thirdAppTypes[currentApplicationType])"
      title="11th Grade Coursework"
      name="11thGradeCoursework"
    >
      <el-form>
        <div
          :key="highSchoolIndex"
          v-for="(highSchool, highSchoolIndex) in counselingProfile.high_schools"
        >
          <h3>{{ highSchool.name }}</h3>
          <hr />
          <el-form-item align="left">
            <el-button
              type="primary"
              @click="() => openEleventhGradeCourseworkDialog(highSchoolIndex)"
            >
              ADD 11TH GRADE CLASSES
            </el-button>
          </el-form-item>
          <div
            :key="courseIndex"
            v-for="(course, courseIndex) in highSchool.eleventhCourses"
            class="course"
          >
            <div>
              <b>{{ course.className }}</b>&nbsp;&nbsp;&nbsp;&nbsp;
              {{ course.subjectName }}
            </div>
            <div>
              <span
                class="editAndDeleteCourseOfOtherHighSchool"
                @click="() => editGradeCourseworkOfHighSchool(highSchoolIndex, 11, courseIndex)"
              >
                EDIT
              </span>
              <span
                class="editAndDeleteCourseOfOtherHighSchool"
                @click="() => deleteGradeCourseworkOfHighSchool(highSchoolIndex, 11, courseIndex)"
              >
                DELETE
              </span>
            </div>
          </div>
        </div>
      </el-form>
    </el-collapse-item>
    <HighSchoolGradeCourseworkDialog
      :currentApplicationType="currentApplicationType"
      year="11"
      :visible="eleventhGradeCourseworkDialogVisible"
      @closeDialog="closeEleventhGradeCourseworkDialog"
      @submitGradeCoursework="
        coursework => addGradeCourseworkOfOtherHighSchool(coursework)
      "
    />
    <el-collapse-item
      v-if="thirdAppTypes[currentApplicationType] === thirdAppTypes.coalition_app"
      title="9-11th Grade Coursework"
      name="910thGradeCoursework"
    >
      <el-form>
        <div>
          <strong>When you've finished adding all of your coursework, use the checkbox below in order to mark your 9th-11th Grade Coursework section complete. </strong>
        </div>
        <CounselingFormItem
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
        >
          <el-checkbox
            :value="counselingProfile.non_senior_complete"
            @input="(non_senior_complete) => setCounselingProfile({ non_senior_complete })"
          >
            I'm finished adding all the 9th-11th grade classes I've taken or plan to take for every high school I've attended. (I can still edit my classes if I need to.)
          </el-checkbox>
        </CounselingFormItem>
        <template
          v-if="
            counselingProfile.non_senior_complete &&
              (highSchoolsNinethCoursesCount < 5 ||
                highSchoolsTenthCoursesCount < 5 ||
                highSchoolsEleventhCoursesCount < 5)
          "
        >
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
          >
            <template slot="label">
              You've entered fewer than five classes for 9th, 10th or 11th grade. Please add more classes or explain below.
            </template>
            <div class="ckeditor">
              <CounselingEditor
                height="200"
                :value="counselingProfile.non_senior_explain"
                @input="
                  non_senior_explain => setCounselingProfile({ non_senior_explain })
                "
              />
            </div>
          </CounselingFormItem>
        </template>
      </el-form>
    </el-collapse-item>
    <el-collapse-item
      v-if="[thirdAppTypes.coalition_app, thirdAppTypes.california_app].includes(thirdAppTypes[currentApplicationType])"
      title="12th Grade Coursework"
      name="12thGradeCoursework"
    >
      <el-form>
        <div
          :key="highSchoolIndex"
          v-for="(highSchool, highSchoolIndex) in counselingProfile.high_schools"
        >
          <h3>{{ highSchool.name }}</h3>
          <hr />
          <el-form-item align="left">
            <el-button
              type="primary"
              @click="() => openTwelvethGradeCourseworkDialog(highSchoolIndex)"
            >
              ADD 12TH GRADE CLASSES
            </el-button>
          </el-form-item>
          <div
            :key="courseIndex"
            v-for="(course, courseIndex) in highSchool.twelvethCourses"
            class="course"
          >
            <div>
              <b>{{ course.className }}</b>&nbsp;&nbsp;&nbsp;&nbsp;
              {{ course.subjectName }}
            </div>
            <div>
              <span
                class="editAndDeleteCourseOfOtherHighSchool"
                @click="() => editGradeCourseworkOfHighSchool(highSchoolIndex, 12, courseIndex)"
              >
                EDIT
              </span>
              <span
                class="editAndDeleteCourseOfOtherHighSchool"
                @click="() => deleteGradeCourseworkOfHighSchool(highSchoolIndex, 12, courseIndex)"
              >
                DELETE
              </span>
            </div>
          </div>
        </div>
        <div>
          <strong>When you've finished adding all of your coursework, use the checkbox below in order to mark your 12th Grade Coursework section complete.</strong>
        </div>
        <el-form-item>
          <el-checkbox
            :value="counselingProfile.senior_complete"
            @input="(senior_complete) => setCounselingProfile({ senior_complete })"
          >
            I'm finished adding all the 12th grade classes I've taken or plan to take for every high school I've attended. (I can still edit my classes if I need to.)
          </el-checkbox>
        </el-form-item>
        <template v-if="counselingProfile.senior_complete && highSchoolsTwelvethCoursesCount < 5">
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
          >
            <template slot="label">
              You've entered fewer than five classes for 12th grade. Please add more classes or explain below.
            </template>
            <div class="ckeditor">
              <CounselingEditor
                height="200"
                :value="counselingProfile.senior_explain"
                @input="
                  senior_explain => setCounselingProfile({ senior_explain })
                "
              />
            </div>
          </CounselingFormItem>
        </template>
      </el-form>
    </el-collapse-item>
    <HighSchoolGradeCourseworkDialog
      :currentApplicationType="currentApplicationType"
      year="12"
      :visible="twelvethGradeCourseworkDialogVisible"
      @closeDialog="closeTwelvethGradeCourseworkDialog"
      @submitGradeCoursework="
        coursework => addGradeCourseworkOfOtherHighSchool(coursework)
      "
    />
    <HighSchoolGradeCourseworkDialog
      :currentApplicationType="currentApplicationType"
      v-if="editingCourseworkOfHighSchool"
      :visible="Boolean(editingCourseworkOfHighSchool)"
      :isEditing="true"
      :editingCoursework="editingCourseworkOfHighSchool"
      :year="editingCourseworkOfHighSchoolYear"
      @closeDialog="() => editingCourseworkOfHighSchool = null"
      @submitGradeCoursework="
        coursework => saveGradeCourseworkOfHighSchool(coursework)
      "
    />
    <el-collapse-item
      v-if="[thirdAppTypes.california_app].includes(thirdAppTypes[currentApplicationType])"
      title="Additional information"
      name="additionalInformation"
    >
      <el-form>
        <CounselingFormItem
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.california_app]"
        >
          <el-checkbox
            true-label="Y"
            false-label="N"
            :value="counselingProfile.has_no_courses"
            @input="(has_no_courses) => setCounselingProfile({ has_no_courses })"
          >
            I confirm that I have no courses to report.
          </el-checkbox>
          <template slot="belowPromptText">
            Please explain why under additional comments.
          </template>
        </CounselingFormItem>
        <CounselingFormItem
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.california_app]"
        >
          <template slot="label">
            <p>If there's anything else you want us to know about your academic history, you can do so in the space below. But remember, you should use this space only if you want to describe anything that you have not had the opportunity to include elsewhere in this section (for example: a break in attendance, poor grades in a particular course or year, specific information about your school environment or policies that affect your academic record or choices for classes).</p>
            International applicants: If you selected Other as your school's grading system, please explain the grading system here.
          </template>
          <el-input
            type="textarea"
            :rows="5"
            maxlength="550"
            show-word-limit
            :value="counselingProfile.additional_comments"
            @input="additional_comments => setCounselingProfile({ additional_comments })"
          />
        </CounselingFormItem>
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
    <el-collapse-item title="Colleges & Universities" name="colleges">
      <el-form>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
        >
          <template slot="label">
            Have you taken college courses or earned college credit?
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.has_college_attended"
            @input="(has_college_attended) => {
              setCounselingProfile({ has_college_attended })
              if (!has_college_attended) {
                setCounselingProfile({ colleges_length: 0 })
              }
            }"
          >
            <div><el-radio :label="true">Yes</el-radio></div>
            <div><el-radio :label="false">No</el-radio></div>
          </el-radio-group>
        </CounselingFormItem>
        <template v-if="counselingProfile.has_college_attended">
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
          >
            <template slot="label">
              Are you currently taking courses in college?
            </template>
            <el-radio-group
              style="width: 100%"
              :value="counselingProfile.in_college"
              @input="(in_college) => { setCounselingProfile({ in_college }) }"
            >
              <div><el-radio :label="true">Yes</el-radio></div>
              <div><el-radio :label="false">No</el-radio></div>
            </el-radio-group>
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
          >
            <template slot="label">
              How many college credits have you earned to date?
            </template>
            <el-input-number
              class="inputNumber"
              controls-position="right"
              :min="0"
              :max="999"
              :value="counselingProfile.college_credits"
              @input="(college_credits) => { setCounselingProfile({ college_credits }) }"
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
          >
            <template slot="label">
              How many credits were earned after high school?
            </template>
            <el-input-number
              class="inputNumber"
              controls-position="right"
              :min="0"
              :max="999"
              :value="counselingProfile.after_high_school_credits"
              @input="(after_high_school_credits) => { setCounselingProfile({ after_high_school_credits }) }"
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
          >
            <template slot="promptText">
              In light of natural disasters and emergency situations like the COVID-19 pandemic, many schools have made significant adjustments to their grading policies. If you wish, you may share information here regarding how your college or university responded to these events.
            </template>
            <template slot="label">
              Check all of the following that apply to you:
            </template>
            <div style="display: inline-block; width: 100%;">
              <el-checkbox-group
                :value="counselingProfile.disaster_impacts_transfer"
                @input="(disasterImpactsTransfer) => setDisasterImpactsTransfer(disasterImpactsTransfer)"
              >
                <div
                  :key="disaster.label"
                  v-for="disaster in disasters"
                >
                  <el-checkbox
                    :disabled="
                      didNotChange &&
                      (
                        disaster.label !== 'My school did not change its grading policy.' &&
                        disaster.label !== 'I would like to provide additional information.'
                      )
                    "
                    :label="disaster.value"
                  >
                    {{ disaster.label }}
                  </el-checkbox>
                </div>
              </el-checkbox-group>
            </div>
          </CounselingFormItem>
          <template v-if="wouldLikeToProvideDisasterInformation">
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
            >
              <template slot="label">
                Please provide any additional information you would like to share regarding how your college or university responded to these events. (300 words max)
              </template>
              <div class="ckeditor">
                <CounselingEditor
                  height="200"
                  :value="counselingProfile.other_disaster_impact_transfer_desc"
                  @input="
                    other_disaster_impact_transfer_desc =>
                      setCounselingProfile({ other_disaster_impact_transfer_desc })
                  "
                />
              </div>
            </CounselingFormItem>
          </template>
          <CounselingFormAlert
            :currentApplication="currentApplicationType"
            :applicationsNeedToVisible="[thirdAppTypes.common_app]"
          >
            <template slot="title">
              {{ $t('counselingProfile.commonAppJustCanFillThreeColleges') }}
            </template>
          </CounselingFormAlert>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              If you have ever taken coursework at a college or university, please indicate the number of colleges.
            </template>
            <el-radio-group
              style="width: 100%"
              :value="counselingProfile.colleges_length"
              @input="(colleges_length) => {
                setCounselingProfile({ colleges_length })
                initEditingCollegesStatus();
              }"
            >
              <div><el-radio :label="0">0</el-radio></div>
              <div :key="num" v-for="num in MAX_TAKEN_COLLEGE_COUNT">
                <el-radio :label="num">{{ num }}</el-radio>
              </div>
            </el-radio-group>
          </CounselingFormItem>
          <div
            :key="takenCollegeIndex"
            v-for="(takenCollegeNum, takenCollegeIndex) of counselingProfile.colleges_length"
          >
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
            >
              <template slot="label">
                {{ `College ${takenCollegeNum}` }}
              </template>
              <div v-if="!editingColleges[takenCollegeIndex]">
                {{ counselingProfile.colleges[takenCollegeIndex].name }}
                <span
                  class="editNameOfColleges"
                  @click="() => setEditingColleges(takenCollegeIndex, true)"
                >
                  EDIT
                </span>
              </div>
              <div v-else>
                <CollegeSelector
                  style="width: 100%"
                  :value="counselingProfile.colleges[takenCollegeIndex].value"
                  @change="({ value, title }) => setTakenCollege(takenCollegeIndex, { value, name: title })"
                />
              </div>
            </CounselingFormItem>
            <div v-if="!hasTakenCollegeInList(takenCollegeIndex)">
              <CounselingFormItem
                required
                :currentApplication="currentApplicationType"
                :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
              >
                <template slot="label">
                  Name of college/university
                </template>
                <el-input
                  :value="counselingProfile.colleges[takenCollegeIndex].name"
                  @input="
                    name => setTakenCollege(takenCollegeIndex, { name })
                  "
                />
              </CounselingFormItem>
              <AddressForm
                :currentApplicationType="currentApplicationType"
                :address="counselingProfile.colleges[takenCollegeIndex]"
                @setAddress="data => setTakenCollege(takenCollegeIndex, { ...data })"
              />
            </div>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app]"
            >
              <template slot="label">
                Course details
              </template>
              <div style="display: inline-block; width: 100%;">
                <el-checkbox-group
                  :value="counselingProfile.colleges[takenCollegeIndex].course_details"
                  @input="
                    course_details =>
                       setTakenCollege(takenCollegeIndex, { course_details })
                  "
                >
                  <div>
                    <el-checkbox :label="6">Dual enrollment with high school</el-checkbox>
                  </div>
                  <div>
                    <el-checkbox :label="7">Summer program</el-checkbox>
                  </div>
                  <div>
                    <el-checkbox :label="8">Credit awarded directly by college</el-checkbox>
                  </div>
                </el-checkbox-group>
              </div>
            </CounselingFormItem>
            <CounselingFormAlert
              :currentApplication="currentApplicationType"
              :applicationsNeedToVisible="[thirdAppTypes.common_app]"
            >
              <template slot="title">
                {{ $t('counselingProfile.commonAppJustCanSubmitFirstAttendanceDate') }}
              </template>
            </CounselingFormAlert>
            <div
              :key="attendDateIndex"
              v-for="(attendDate, attendDateIndex) in counselingProfile.colleges[takenCollegeIndex].attend_dates"
            >
              <CounselingFormItem
                required
                :currentApplication="currentApplicationType"
                :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
              >
                <template slot="label">
                  {{ `College ${takenCollegeNum} from date` }}
                </template>
                <el-date-picker
                  style="width: 100%"
                  value-format="MM/yyyy"
                  type="date"
                  :value="attendDate.from_month"
                  @input="
                    from_month =>
                      setAttendDateOfCollege(takenCollegeIndex, attendDateIndex, { from_month })
                  "
                />
              </CounselingFormItem>
              <CounselingFormItem
                required
                :currentApplication="currentApplicationType"
                :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
              >
                <template slot="label">
                  {{ `College ${takenCollegeNum} to date` }}
                </template>
                <el-date-picker
                  style="width: 100%"
                  value-format="MM/yyyy"
                  type="date"
                  :value="attendDate.to_month"
                  @input="
                    to_month =>
                      setAttendDateOfCollege(takenCollegeIndex, attendDateIndex, { to_month })
                  "
                /><br />
                <span
                  class="removeAttendDateOfCollege"
                  v-if="1 < counselingProfile.colleges[takenCollegeIndex].attend_dates.length"
                  @click="() => removeCollegeAttendDate(takenCollegeIndex, attendDateIndex)"
                >
                  REMOVE
                </span>
              </CounselingFormItem>
            </div>
            <div v-if="thirdAppTypes[currentApplicationType] === thirdAppTypes.california_app">
              <h5>During which grades and terms did you attend this college?</h5>
              <div
                :key="gradeIndex"
                v-for="(grade, gradeIndex) in counselingProfile.colleges[takenCollegeIndex].grades"
              >
                <CounselingFormItem
                  required
                  :currentApplication="currentApplicationType"
                  :applicationsNeedToFilling="[thirdAppTypes.california_app]"
                >
                  <template slot="label">
                    Grade
                  </template>
                  <el-select
                    style="width: 100%"
                    :value="grade.year_code"
                    @change="
                      year_code =>
                        setGradeOfCollege(takenCollegeIndex, gradeIndex, { year_code })
                    "
                  >
                    <el-option label="9th" value="09" />
                    <el-option label="10th" value="10" />
                    <el-option label="11th" value="11" />
                    <el-option label="12th" value="12" />
                  </el-select>
                </CounselingFormItem>
                <CounselingFormItem
                  required
                  :currentApplication="currentApplicationType"
                  :applicationsNeedToFilling="[thirdAppTypes.california_app]"
                >
                  <template slot="label">Fall</template>
                  <el-select
                    style="width: 100%"
                    :value="grade.fall_term_year"
                    @change="
                      fall_term_year =>
                        setGradeOfCollege(takenCollegeIndex, gradeIndex, { fall_term_year })
                    "
                  >
                    <el-option
                      v-for="year in counselingProfile.colleges[takenCollegeIndex].attended_years"
                      :key="year"
                      :label="year"
                      :value="year"
                    />
                  </el-select>
                </CounselingFormItem>
                <CounselingFormItem
                  required
                  :currentApplication="currentApplicationType"
                  :applicationsNeedToFilling="[thirdAppTypes.california_app]"
                >
                  <template slot="label">Winter</template>
                  <el-select
                    style="width: 100%"
                    :value="grade.winter_term_year"
                    @change="
                      winter_term_year =>
                        setGradeOfCollege(takenCollegeIndex, gradeIndex, { winter_term_year })
                    "
                  >
                    <el-option
                      v-for="year in counselingProfile.colleges[takenCollegeIndex].attended_years"
                      :key="year"
                      :label="year"
                      :value="year"
                    />
                  </el-select>
                </CounselingFormItem>
                <CounselingFormItem
                  required
                  :currentApplication="currentApplicationType"
                  :applicationsNeedToFilling="[thirdAppTypes.california_app]"
                >
                  <template slot="label">Spring</template>
                  <el-select
                    style="width: 100%"
                    :value="grade.spring_term_year"
                    @change="
                      spring_term_year =>
                        setGradeOfCollege(takenCollegeIndex, gradeIndex, { spring_term_year })
                    "
                  >
                    <el-option
                      v-for="year in counselingProfile.colleges[takenCollegeIndex].attended_years"
                      :key="year"
                      :label="year"
                      :value="year"
                    />
                  </el-select>
                </CounselingFormItem>
                <CounselingFormItem
                  required
                  :currentApplication="currentApplicationType"
                  :applicationsNeedToFilling="[thirdAppTypes.california_app]"
                >
                  <template slot="label">Summer</template>
                  <el-select
                    style="width: 100%"
                    :value="grade.summer_term_year"
                    @change="
                      summer_term_year =>
                        setGradeOfCollege(takenCollegeIndex, gradeIndex, { summer_term_year })
                    "
                  >
                    <el-option
                      v-for="year in counselingProfile.colleges[takenCollegeIndex].attended_years"
                      :key="year"
                      :label="year"
                      :value="year"
                    />
                  </el-select><br />
                  <span
                    class="removeAttendDateOfCollege"
                    v-if="1 < counselingProfile.colleges[takenCollegeIndex].grades.length"
                    @click="() => removeCollegeGrade(takenCollegeIndex, attendDateIndex)"
                  >
                    REMOVE
                  </span>
                </CounselingFormItem>
                <hr />
              </div>
            </div>
            <el-form-item align="left">
              <el-button
                type="primary"
                @click="() => addCollegeGrade(takenCollegeIndex)"
              >
                ADD MORE GRADES
              </el-button>
            </el-form-item>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.california_app]"
            >
              <template slot="label">
                What is this college's grading system?
              </template>
              <div style="display: inline-block; width: 100%;">
              <el-checkbox-group
                :value="counselingProfile.colleges[takenCollegeIndex].grading_systems"
                @input="
                  grading_systems =>
                    setTakenCollege(takenCollegeIndex, { grading_systems })
                "
              >
                <div><el-checkbox :label="1">A B C D F</el-checkbox></div>
                <div><el-checkbox :label="2">0-100 (U.S.)</el-checkbox></div>
                <div><el-checkbox :label="3">Other</el-checkbox></div>
              </el-checkbox-group>
              </div>
            </CounselingFormItem>
            <CounselingFormItem
              v-if="takenCollegeGradingSystemsIncludesOther(takenCollegeIndex)"
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.california_app]"
            >
              <template slot="label">
                Specify the grading system
              </template>
              <el-input
                style="width: 100%"
                :value="counselingProfile.colleges[takenCollegeIndex].grading_system_descr"
                @input="grading_system_descr => setTakenCollege(takenCollegeIndex, { grading_system_descr })"
              />
            </CounselingFormItem>
            <CounselingFormItem
              checkApplications
              :value="counselingProfile.colleges[takenCollegeIndex].degree"
              getterProp="collegeDegreeOptions"
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
            >
              <template slot="label">
                Degree earned
              </template>
              <el-radio-group
                style="width: 100%"
                :value="counselingProfile.colleges[takenCollegeIndex].degree"
                @input="degree => setTakenCollege(takenCollegeIndex, { degree })"
              >
                <div
                  :key="collegeDegree.id"
                  v-for="collegeDegree in collegeDegrees"
                >
                  <el-radio :label="collegeDegree.id">{{ collegeDegree.title }}</el-radio>
                </div>
              </el-radio-group>
            </CounselingFormItem>
          </div>
        </template>
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
    <el-collapse-item
      v-if="[thirdAppTypes.california_app, thirdAppTypes.coalition_app].includes(thirdAppTypes[currentApplicationType])"
      title="In Progress College Coursework"
      name="inProgressCollegeCoursework"
    >
      <el-form>
        <template v-if="counselingProfile.hasInProgressColleges">
          <div
            :key="collegeIndex"
            v-for="(collegeNum, collegeIndex) in counselingProfile.colleges_length"
          >
            <template v-if="counselingProfile.colleges[collegeIndex].isInProgress">
              <h3>{{ counselingProfile.colleges[collegeIndex].name }}</h3>
              <hr />
              <el-form-item align="left">
                <el-button
                  type="primary"
                  @click="() => openInProgressCollegeGradeCourseworkDialog(collegeIndex)"
                >
                  ADD CLASSES
                </el-button>
              </el-form-item>
              <div
                :key="year"
                v-for="year in counselingProfile.colleges[collegeIndex].takenInProgressCourseYears"
              >
                <h4>{{ `${year} - ${counselingProfile.colleges[collegeIndex].getInprogressCoursesByYear(year).length} course${counselingProfile.colleges[collegeIndex].getInprogressCoursesByYear(year).length > 1 ? 's' : ''}` }}</h4>
                <div
                  :key="courseIndex"
                  v-for="(course, courseIndex) in counselingProfile.colleges[collegeIndex].getInprogressCoursesByYear(year)"
                  class="course"
                >
                  <div>
                    <b>{{ course.name }}</b>&nbsp;&nbsp;&nbsp;&nbsp;
                    {{ course.number }}
                  </div>
                  <div>
                    <span
                      class="editAndDeleteCourseOfCollege"
                      @click="() => editInProgressGradeCourseworkOfCollege(collegeIndex, course)"
                    >
                      EDIT
                    </span>
                    <span
                      class="editAndDeleteCourseOfCollege"
                      @click="() => deleteInProgressGradeCourseworkOfCollege(collegeIndex, course)"
                    >
                      DELETE
                    </span>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </template>
        <el-alert
          v-else
          title="You indicated that you are not currently attending any colleges."
          type="info"
          show-icon
          :closable="false"
        />
        <div>
          <strong>When you've finished adding all of your coursework, use the checkbox below in order to mark your College Coursework section complete. </strong>
        </div>
        <CounselingFormItem
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            Degree earned
          </template>
          <el-checkbox
            :value="counselingProfile.in_progress_college_complete"
            @input="(in_progress_college_complete) => setCounselingProfile({ in_progress_college_complete })"
          >
            I'm finished adding all the college courses in which I'm currently enrolled or plan to take before enrolling at a new institution.
          </el-checkbox>
        </CounselingFormItem>
      </el-form>
    </el-collapse-item>
    <InProgressCollegeGradeCourseworkDialog
      :currentApplicationType="currentApplicationType"
      :visible="inProgressCollegeGradeCourseworkDialogVisible"
      @closeDialog="closeInProgressCollegeGradeCourseworkDialog"
      @submitGradeCoursework="
        coursework => addInProgressGradeCourseworkOfCollege(coursework)
      "
    />
    <InProgressCollegeGradeCourseworkDialog
      v-if="editingInProgressCourseworkOfCollege"
      :currentApplicationType="currentApplicationType"
      :visible="Boolean(editingInProgressCourseworkOfCollege)"
      :isEditing="true"
      :editingCoursework="editingInProgressCourseworkOfCollege"
      @closeDialog="() => editingInProgressCourseworkOfCollege = null"
      @submitGradeCoursework="
        coursework => saveInProgressGradeCourseworkOfCollege(coursework)
      "
    />
    <el-collapse-item
      v-if="[thirdAppTypes.california_app, thirdAppTypes.coalition_app].includes(thirdAppTypes[currentApplicationType])"
      title="Completed College Coursework"
      name="completedCollegeCoursework"
    >
      <el-form>
        <div
          :key="collegeIndex"
          v-for="(collegeNum, collegeIndex) in counselingProfile.colleges_length"
        >
          <h3>{{ counselingProfile.colleges[collegeIndex].name }}</h3>
          <hr />
          <el-form-item align="left">
            <el-button
              type="primary"
              @click="() => openCompletedCollegeGradeCourseworkDialog(collegeIndex)"
            >
              ADD CLASSES
            </el-button>
          </el-form-item>
          <div
            :key="year"
            v-for="year in counselingProfile.colleges[collegeIndex].takenCompletedCourseYears"
          >
            <h4>{{ `${year} - ${counselingProfile.colleges[collegeIndex].getCompletedCoursesByYear(year).length} course${counselingProfile.colleges[collegeIndex].getCompletedCoursesByYear(year).length > 1 ? 's' : ''}` }}</h4>
            <div
              :key="courseIndex"
              v-for="(course, courseIndex) in counselingProfile.colleges[collegeIndex].getCompletedCoursesByYear(year)"
              class="course"
            >
              <div>
                <b>{{ course.name }}</b>&nbsp;&nbsp;&nbsp;&nbsp;
                {{ course.number }}
              </div>
              <div>
                <span
                  class="editAndDeleteCourseOfCollege"
                  @click="() => editCompletedGradeCourseworkOfCollege(collegeIndex, course)"
                >
                  EDIT
                </span>
                <span
                  class="editAndDeleteCourseOfCollege"
                  @click="() => deleteCompletedGradeCourseworkOfCollege(collegeIndex, course)"
                >
                  DELETE
                </span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <strong>When you've finished adding all of your coursework, use the checkbox below in order to mark your Completed College Coursework section complete.</strong>
        </div>
        <CounselingFormItem
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
        >
          <el-checkbox
            :value="counselingProfile.completed_college_complete"
            @input="(completed_college_complete) => setCounselingProfile({ completed_college_complete })"
          >
            I'm finished adding all the college courses I've completed for every college I've attended. (I can still edit my classes if I need to.)
          </el-checkbox>
        </CounselingFormItem>
      </el-form>
    </el-collapse-item>
    <CompletedCollegeGradeCourseworkDialog
      :currentApplicationType="currentApplicationType"
      :visible="completedCollegeGradeCourseworkDialogVisible"
      @closeDialog="closeCompletedCollegeGradeCourseworkDialog"
      @submitGradeCoursework="
        coursework => addCompletedGradeCourseworkOfCollege(coursework)
      "
    />
    <CompletedCollegeGradeCourseworkDialog
      v-if="editingCompletedCourseworkOfCollege"
      :currentApplicationType="currentApplicationType"
      :visible="Boolean(editingCompletedCourseworkOfCollege)"
      :isEditing="true"
      :editingCoursework="editingCompletedCourseworkOfCollege"
      @closeDialog="() => editingCompletedCourseworkOfCollege = null"
      @submitGradeCoursework="
        coursework => saveCompletedGradeCourseworkOfCollege(coursework)
      "
    />
    <el-collapse-item
      v-if="thirdAppTypes[currentApplicationType] === thirdAppTypes.common_app"
      title="Current or Most Recent Year Courses"
      name="currentOrRecentYearCourses"
    >
      <el-form>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="promptText">
            Please list all courses you are taking this academic year. If you are not currently enrolled, please list courses from your most recent academic year.
          </template>
          <template slot="label">
            How many courses would you like to report?
          </template>
          <el-select
            style="width: 100%"
            :value="counselingProfile.courses_length"
            @change="(courses_length) => setCounselingProfile({ courses_length })"
          >
            <el-option
              :key="num"
              v-for="num in MAX_RECENT_COURSE_COUNT"
              :label="num"
              :value="num"
            />
          </el-select>
        </CounselingFormItem>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            Please select the course scheduling system your institution is using.
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.courses_scheduling_system"
            @input="
              courses_scheduling_system => {
                setCounselingProfile({ courses_scheduling_system });
                cleanAllRecentCourseSchedule();
              }
            "
          >
            <div
              :key="coursesSchedulingSystem"
              v-for="coursesSchedulingSystem in EnumCoursesSchedulingSystems"
            >
              <el-radio :label="coursesSchedulingSystem">
                {{ coursesSchedulingSystem }}
              </el-radio>
            </div>
          </el-radio-group>
        </CounselingFormItem>
        <div
          :key="displayRecentCourseIndex"
          v-for="(displayRecentCourseNum, displayRecentCourseIndex) of counselingProfile.courses_length"
        >
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          >
            <template slot="label">
              {{ `Course ${displayRecentCourseNum} title` }}
            </template>
            <el-input
              :value="counselingProfile.courses[displayRecentCourseIndex].title"
              @input="
                title => setRecentCourse(displayRecentCourseIndex, { title })
              "
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          >
            <template slot="label">
              {{ `Course ${displayRecentCourseNum} level, if applicable` }}
            </template>
            <CourseLevelSelector
              :value="counselingProfile.courses[displayRecentCourseIndex].level"
              @change="
                level =>
                  setRecentCourse(displayRecentCourseIndex, { level })
              "
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          >
            <template slot="label">
              {{ `Course ${displayRecentCourseNum} schedule` }}
            </template>
            <el-select
              style="width: 100%"
              multiple
              :value="counselingProfile.courses[displayRecentCourseIndex].schedule"
              @change="
                schedule =>
                  setRecentCourse(displayRecentCourseIndex, { schedule })
              "
            >
              <el-option value="0" label="Full Year" />
              <el-option value="2" label="First Trimester" />
              <el-option value="4" label="Second Trimester" />
              <el-option
                v-if="scheduleIsTrimester || scheduleIsQuarter"
                value="5"
                label="Third Trimester"
              />
              <el-option
                v-if="scheduleIsQuarter"
                value="9"
                label="Fourth Quarter"
              />
            </el-select>
          </CounselingFormItem>
        </div>
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
    <el-collapse-item
      title="Honors"
      name="honors"
      v-if="[thirdAppTypes.common_app, thirdAppTypes.coalition].includes(thirdAppTypes[currentApplicationType])"
    >
      <el-form>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            Do you wish to report any honors related to your academic achievements beginning with the ninth grade or international equivalent?
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.wish_to_report_honors"
            @input="wish_to_report_honors => setCounselingProfile({ wish_to_report_honors })"
          >
            <div><el-radio label="0">Yes</el-radio></div>
            <div><el-radio label="1">No</el-radio></div>
          </el-radio-group>
        </CounselingFormItem>
        <template v-if="counselingProfile.wish_to_report_honors === '0'">
          <div
            :key="displayHonorCountIndex"
            v-for="(displayHonorCountNum, displayHonorCountIndex) of displayHonorCount"
          >
            <el-card class="card">
              <div slot="header" class="cardHeader">
                <span>{{ `Honors ${displayHonorCountNum}` }}</span>
                <el-tooltip
                  content="Move Up"
                  placement="top"
                  popper-class="tooltipColor"
                  v-if="honorOrderIsLast(displayHonorCountNum)"
                >
                  <el-button
                    type="danger"
                    icon="el-icon-close"
                    circle
                    @click="removeLastHonor"
                  />
                </el-tooltip>
                <el-tooltip
                  content="Move Down"
                  placement="top"
                  popper-class="tooltipColor"
                  v-if="honorCountIsMultiple && !honorOrderIsLast(displayHonorCountNum)"
                >
                  <el-button
                    type="primary"
                    icon="el-icon-bottom"
                    circle
                    @click="() => moveHonorOrderDown(displayHonorCountIndex)"
                  />
                </el-tooltip>
                <el-tooltip
                  content="Move Up"
                  placement="top"
                  popper-class="tooltipColor"
                  v-if="honorCountIsMultiple && !honorOrderIsFirst(displayHonorCountNum)"
                >
                  <el-button
                    type="primary"
                    icon="el-icon-top"
                    circle
                    @click="() => moveHonorOrderUp(displayHonorCountIndex)"
                  />
                </el-tooltip>
              </div>
              <CounselingFormItem
                required
                :currentApplication="currentApplicationType"
                :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
              >
                <template slot="label">
                  {{ `Honors ${displayHonorCountNum} title` }}
                </template>
                <el-input
                  :value="counselingProfile.honors[displayHonorCountIndex].title"
                  @input="title => setHonor(displayHonorCountIndex, { title })"
                />
              </CounselingFormItem>
              <CounselingFormItem
                required
                checkApplications
                :value="counselingProfile.honors[displayHonorCountIndex].level"
                getterProp="gradeLevelOptions"
                :currentApplication="currentApplicationType"
                :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
              >
                <template slot="label">
                  Grade level
                </template>
                <div style="display: inline-block; width: 100%;">
                  <el-checkbox-group
                    :value="counselingProfile.honors[displayHonorCountIndex].level"
                    @input="
                      level =>
                        setHonor(displayHonorCountIndex, { level })
                    "
                  >
                    <div
                      :key="honorGradeLevel.id"
                      v-for="honorGradeLevel in honorGradeLevels"
                    >
                      <el-checkbox :label="honorGradeLevel.id">
                        {{ honorGradeLevel.title }}
                      </el-checkbox>
                    </div>
                  </el-checkbox-group>
                </div>
              </CounselingFormItem>
              <CounselingFormItem
                required
                checkApplications
                :value="counselingProfile.honors[displayHonorCountIndex].level_recognition"
                getterProp="honorRecognitionLevelOptions"
                :currentApplication="currentApplicationType"
                :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
              >
                <template slot="label">
                  Level(s) of recognition
                </template>
                <div style="display: inline-block; width: 100%;">
                  <el-checkbox-group
                    :value="counselingProfile.honors[displayHonorCountIndex].level_recognition"
                    @input="
                      level_recognition =>
                        setHonor(displayHonorCountIndex, { level_recognition })
                    "
                  >
                    <div
                      :key="honorRecognitionLevel.id"
                      v-for="honorRecognitionLevel in honorRecognitionLevels"
                    >
                      <el-checkbox :label="honorRecognitionLevel.id">
                        {{ honorRecognitionLevel.title }}
                      </el-checkbox>
                    </div>
                  </el-checkbox-group>
                </div>
              </CounselingFormItem>
            </el-card>
          </div>
          <el-form-item v-if="!displayHonorCountAlreadyMax" align="right">
            {{ `(${MAX_HONOR_COUNT - displayHonorCount} of ${MAX_HONOR_COUNT} available)` }}
            <el-button type="primary" @click="addDisplayHonorCount">
              Add another honors
            </el-button>
          </el-form-item>
        </template>
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
    <el-collapse-item
      title="Community-Based Organizations"
      name="communityBasedOrganizations"
    >
      <el-form>
        <CounselingFormAlert
          :currentApplication="currentApplicationType"
          :applicationsNeedToVisible="[thirdAppTypes.common_app]"
        >
          <template slot="title">
            {{ $t('counselingProfile.commonAppJustCanFillThreeOrganizations') }}
          </template>
        </CounselingFormAlert>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            Indicate the number of community programs or organizations that have provided you with free assistance in your application process.
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.community_based_organizations_length"
            @input="
              community_based_organizations_length =>
                setCounselingProfile({ community_based_organizations_length })
            "
          >
            <div><el-radio :label="0">0</el-radio></div>
            <div :key="num" v-for="num in MAX_ORGANIZATION_COUNT">
              <el-radio :label="num">{{ num }}</el-radio>
            </div>
          </el-radio-group>
        </CounselingFormItem>
        <div
          :key="organizationlIndex"
          v-for="(organizationlNum, organizationlIndex) of counselingProfile.community_based_organizations_length"
        >
          <CounselingFormItem
            required
            checkApplications
            :value="counselingProfile.community_based_organizations[organizationlIndex].value"
            getterProp="organizationOptions"
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
          >
            <template slot="label">
              Organization from which you received the most help
            </template>
            <OrganizationsSelector
              :value="counselingProfile.community_based_organizations[organizationlIndex].value"
              @change="
                organization =>
                  setOrganization(organizationlIndex, {
                    organization_name: organization.title,
                    value: organization.id
                  })
              "
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
          >
            <template slot="label">
              CBO City
            </template>
            <el-input
              :value="
                counselingProfile.community_based_organizations[
                  organizationlIndex
                ].city
              "
              @input="city => setOrganization(organizationlIndex, { city })"
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
          >
            <template slot="label">
              CBO State
            </template>
            <StateSelector
              :value="counselingProfile.community_based_organizations[organizationlIndex].state"
              @change="state => setOrganization(organizationlIndex, { state: state.id })"
            />
          </CounselingFormItem>
          <div v-if="organizationOrderIsFirst(organizationlNum)">
            <CounselingFormItem
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app]"
            >
              <template slot="promptText">
                <b>Counselor/Advisor/Mentor</b>
              </template>
              <template slot="label">
                Prefix
              </template>
              <el-radio-group
                style="width: 100%"
                :value="counselingProfile.community_based_organizations[organizationlIndex].mentor_prefix"
                @input="(mentor_prefix) => setOrganization(organizationlIndex, { mentor_prefix })"
              >
                <div>
                  <el-radio :label="0">Dr.</el-radio>
                </div>
                <div>
                  <el-radio :label="1">Mr.</el-radio>
                </div>
                <div>
                  <el-radio :label="2">Ms.</el-radio>
                </div>
                <div>
                  <el-radio :label="3">Mrs.</el-radio>
                </div>
              </el-radio-group>
            </CounselingFormItem>
            <CounselingFormItem
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app]"
            >
              <template slot="label">
                First name
              </template>
              <el-input
                :value="
                  counselingProfile.community_based_organizations[
                    organizationlIndex
                  ].mentor_first_name
                "
                @input="
                  mentor_first_name =>
                    setOrganization(organizationlIndex, { mentor_first_name })
                "
              />
            </CounselingFormItem>
            <CounselingFormItem
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app]"
            >
              <template slot="label">
                Last name
              </template>
              <el-input
                :value="
                  counselingProfile.community_based_organizations[
                    organizationlIndex
                  ].mentor_last_name
                "
                @input="
                  mentor_last_name =>
                    setOrganization(organizationlIndex, { mentor_last_name })
                "
              />
            </CounselingFormItem>
            <CounselingFormItem
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app]"
            >
              <template slot="label">
                Email
              </template>
              <el-input
                :value="
                  counselingProfile.community_based_organizations[
                    organizationlIndex
                  ].mentor_email
                "
                @input="
                  mentor_email =>
                    setOrganization(organizationlIndex, { mentor_email })
                "
              />
            </CounselingFormItem>
            <CounselingFormItem
              checkApplications
              :value="counselingProfile.community_based_organizations[organizationlIndex].mentor_phone_number.country_code"
              getterProp="mobileCountryCodeOptions"
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app]"
            >
              <template slot="label">
                Preferred phone number
              </template>
              <div style="display: inline-block; width: 100%;">
                <div style="display: flex">
                  <MobileCountryCodeSelector
                    :value="
                      counselingProfile.community_based_organizations[
                        organizationlIndex
                      ].mentor_phone_number.country_code
                    "
                    @change="
                      (country_code) =>
                        setPhoneOfOrganization(organizationlIndex, {
                          country_code
                        })
                    "
                    style="margin-right: 10px"
                  />
                  <el-input
                    :value="
                      counselingProfile.community_based_organizations[
                        organizationlIndex
                      ].mentor_phone_number.phone_number
                    "
                    @input="
                      phone_number =>
                        setPhoneOfOrganization(organizationlIndex, {
                          phone_number
                        })
                    "
                    style="width: 400px; margin-right: 10px"
                  />
                  <el-input
                    :value="
                      counselingProfile.community_based_organizations[
                        organizationlIndex
                      ].mentor_phone_number.extension
                    "
                    @input="
                      extension =>
                        setPhoneOfOrganization(organizationlIndex, {
                          extension
                        })
                    "
                    style="width: 200px"
                  />
                </div>
              </div>
            </CounselingFormItem>
          </div>
        </div>
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
    <el-collapse-item
      v-if="thirdAppTypes[currentApplicationType] === thirdAppTypes.coalition_app"
      title="Academic Interests"
      name="academicInterests"
    >
      <el-form>
        <div
          :key="academicInterestIndex"
          v-for="(academicInterest, academicInterestIndex) in counselingProfile.academic_interests"
        >
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
          >
            <template slot="label">
              {{ `Academic Interest ${academicInterestIndex + 1}` }}
            </template>
            <el-select
              style="width: 100%"
              :value="academicInterest"
              @change="
                academicInterest =>
                  setAcademicInterest(academicInterestIndex, academicInterest)
              "
            >
              <el-option
                v-for="academicInterest in academicInterests"
                :key="academicInterest.value"
                :label="academicInterest.label"
                :value="academicInterest.value"
              />
            </el-select><br />
            <span
              class="removeAcademicInterest"
              v-if="1 < counselingProfile.academic_interests.length"
              @click="() => removeAcademicInterest(academicInterestIndex)"
            >
              REMOVE
            </span>
          </CounselingFormItem>
        </div>
        <el-form-item v-if="academicInterestCountNotMaxYet" align="left">
          <el-button type="primary" @click="() => addAcademicInterest()">
            ADD ANOTHER ACADEMIC INTEREST
          </el-button>
        </el-form-item>
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
    <el-collapse-item
      v-if="thirdAppTypes[currentApplicationType] === thirdAppTypes.common_app"
      title="Future Plans"
      name="futurePlans"
    >
      <el-form>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            Career interest
          </template>
          <CareerSelector
            :value="counselingProfile.career_interest"
            @change="career_interest => setCounselingProfile({ career_interest })"
          />
        </CounselingFormItem>
        <CounselingFormItem
          v-if="careerInterestIsOther"
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            Other career interest
          </template>
          <el-input
            :value="counselingProfile.career_interest_descr"
            @input="career_interest_descr => setCounselingProfile({ career_interest_descr })"
          />
        </CounselingFormItem>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            Highest degree you intend to earn
          </template>
          <DegreeIntendToEarnSelector
            :value="counselingProfile.highest_degree_to_earn"
            @change="highest_degree_to_earn => setCounselingProfile({ highest_degree_to_earn })"
          />
        </CounselingFormItem>
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import { thirdAppTypeDict } from "@/class/ThirdApp";
import CounselingFormAlert from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/CounselingFormAlert";
import CounselingFormItem from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/CounselingFormItem";
import AddressForm from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/AddressForm";
import CollegeSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/CollegeSelector";
import HighSchoolAcadYear from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/EducationForm/HighSchoolAcadYear";
import MathCourse78thSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/EducationForm/MathCourse78thSelector";
import LanguageOfCourse78thSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/EducationForm/LanguageOfCourse78thSelector";
import LanguageOfInstructionCourse78thSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/EducationForm/LanguageOfInstructionCourse78thSelector";
import HighSchoolGradeCourseworkDialog from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/EducationForm/HighSchoolGradeCourseworkDialog";
import CompletedCollegeGradeCourseworkDialog from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/EducationForm/CompletedCollegeGradeCourseworkDialog";
import InProgressCollegeGradeCourseworkDialog from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/EducationForm/InProgressCollegeGradeCourseworkDialog";
import GradingScaleRadio from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/EducationForm/GradingScaleRadio";
import ClassesScheduledRadio from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/EducationForm/ClassesScheduledRadio";
import HighSchoolFilterStateSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/EducationForm/HighSchoolFilterStateSelector";
import HighSchoolFilterCountrySelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/EducationForm/HighSchoolFilterCountrySelector";
import HighSchoolSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/EducationForm/HighSchoolSelector";
import CourseLevelSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/EducationForm/CourseLevelSelector";
import OrganizationsSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/EducationForm/OrganizationsSelector";
import StateSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/StateSelector";
import CareerSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/EducationForm/CareerSelector";
import DegreeIntendToEarnSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/EducationForm/DegreeIntendToEarnSelector";
import MobileCountryCodeSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/MobileCountryCodeSelector";
import CounselingEditor from "@/components/ckeditor/CounselingEditor.vue";
import { EnumClassRankReportings, EnumCoursesSchedulingSystems } from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/Enum";

export default {
  props: {
    studentUserId: {
      type: Number,
      default: null
    },
    currentApplicationType: {
      type: String,
      default: ""
    },
    activePanel: {
      type: String,
      default: ""
    }
  },
  components: {
    CounselingFormAlert,
    CounselingFormItem,
    MathCourse78thSelector,
    LanguageOfCourse78thSelector,
    LanguageOfInstructionCourse78thSelector,
    CareerSelector,
    DegreeIntendToEarnSelector,
    MobileCountryCodeSelector,
    OrganizationsSelector,
    StateSelector,
    HighSchoolAcadYear,
    CourseLevelSelector,
    HighSchoolGradeCourseworkDialog,
    CompletedCollegeGradeCourseworkDialog,
    InProgressCollegeGradeCourseworkDialog,
    GradingScaleRadio,
    ClassesScheduledRadio,
    HighSchoolFilterStateSelector,
    HighSchoolFilterCountrySelector,
    HighSchoolSelector,
    CollegeSelector,
    AddressForm,
    CounselingEditor
  },
  watch: {
    counselingProfile() {
      this.displayHonorCount = this.counselingProfile.valid_honor_count;
      this.editingHighSchool = !this.counselingProfile.high_school_value;
      this.initEditingOtherHighSchoolsStatus();
      this.initEditingCollegesStatus();
    }
  },
  computed: {
    thirdAppTypes() {
      return thirdAppTypeDict;
    },
    EnumClassRankReportings() {
      return EnumClassRankReportings;
    },
    EnumCoursesSchedulingSystems() {
      return EnumCoursesSchedulingSystems;
    },
    course78thTermPeriods() {
      return [
        { value: "2", label: "Full year" },
        { value: "1", label: "Less than full year" }
      ];
    },
    course78thCourseCategories() {
      return [
        {
          label: "Yr 1 Lang Other Than English",
          value: "23"
        },
        {
          label: "Yr 2 Lang Other Than English",
          value: "24"
        },
        {
          label: "Yr 3 Lang Other Than English",
          value: "25"
        },
        {
          label: "Yr 4+ Lang Other Than English",
          value: "26"
        }
      ];
    },
    disasters() {
      return [
        { value: "{\"code\":\"GRADE_CHG\",\"value\":\"My school changed its grading policy for all students.\"}", label: "My school changed its grading policy for all students." },
        { value: "{\"code\":\"GRADE_CHOICE\",\"value\":\"My school allowed students to choose how their grades would be assigned (e.g. pass/fail, credit/no credit, letter grades).\"}", label: "My school allowed students to choose how their grades would be assigned (e.g. pass/fail, credit/no credit, letter grades)." },
        { value: "{\"code\":\"NA\",\"value\":\"My school did not change its grading policy.\"}", label: "My school did not change its grading policy." },
        { value: "{\"code\":\"MORE_INFO\",\"value\":\"I would like to provide additional information.\"}", label: "I would like to provide additional information." }
      ];
    },
    highSchoolTypes() {
      return [
        { value: "OFFICIAL", label: "US High School" },
        { value: "INTERNATIONAL", label: "Non-US Secondary / High School" },
        { value: "HOMESCHOOL", label: "Home School" }
      ];
    },
    highSchoolTypeCodes() {
      return [
        { value: "1", label: "Public" },
        { value: "2", label: "Private" },
        { value: "7", label: "Junior High" }
      ];
    },
    specializedCurriculums() {
      return [
        { value: "academy", label: "Career Pathway / Academy" },
        { value: "ib", label: "International Baccalaureate" },
        { value: "magnet", label: "Magnet" },
        { value: "other", label: "Other" }
      ];
    },
    highSchoolGradingSystems() {
      return [
        { value: 1, label: "A B C D F" },
        { value: 2, label: "0-100 (U.S.)" },
        { value: 3, label: "1-7 Int'l Bacc" },
        { value: 4, label: "Other" }
      ];
    },
    outsideUsaHighSchoolGradingSystems() {
      return [
        {
          label: "A B C D F",
          value: 1
        },
        {
          label: "0-100 (U.S.)",
          value: 2
        },
        {
          label: "0-100 (Canada - AB, SK)",
          value: 3
        },
        {
          label: "0-100 (Canada - BC)",
          value: 4
        },
        {
          label: "0-100 (Canada - MB, NB, ON)",
          value: 5
        },
        {
          label: "0-100 (Canada - NL, NS)",
          value: 6
        },
        {
          label: "0-100 (Canada - PE)",
          value: 7
        },
        {
          label: "0-100 (Canada - QC)",
          value: 8
        },
        {
          label: "0-100 (Canada - YT)",
          value: 9
        },
        {
          label: "0-100 (China)",
          value: 10
        },
        {
          label: "0-100 (India)",
          value: 11
        },
        {
          label: "0-100 (Korea)",
          value: 12
        },
        {
          label: "0-100 (Mexico)",
          value: 13
        },
        {
          label: "0-100 (Pakistan)",
          value: 14
        },
        {
          label: "0-100 (Philippines)",
          value: 15
        },
        {
          label: "0-100 (Saudi Arabia)",
          value: 16
        },
        {
          label: "0-100 (Taiwan)",
          value: 17
        },
        {
          label: "0-100 (Turkey)",
          value: 18
        },
        {
          label: "0-100 (United Arab Emirates)",
          value: 19
        },
        {
          label: "0-15 (Germany)",
          value: 20
        },
        {
          label: "0-20 (France)",
          value: 21
        },
        {
          label: "0.0-4.0 (United Arab Emirates)",
          value: 22
        },
        {
          label: "1-10 (Mexico)",
          value: 23
        },
        {
          label: "1-5 (Turkey)",
          value: 24
        },
        {
          label: "1-7 Int'l Bacc",
          value: 25
        },
        {
          label: "Sehr Gut, Gut... (Germany) ",
          value: 26
        },
        {
          label: "Su, Wu, Mi... (Korea)",
          value: 27
        },
        {
          label: "Other",
          value: 28
        }
      ];
    },
    highSchoolGradingSystemsIsOther() {
      if (this.counselingProfile.high_school_state_type === "outsideTheUs") {
        return this.counselingProfile.high_school_grading_systems.includes(28);
      } else {
        return this.counselingProfile.high_school_grading_systems.includes(4);
      }
    },
    highSchoolDegrees() {
      return [
        {
          label: "High/Secondary School Diploma",
          value: "A"
        },
        {
          label: "IB Diploma",
          value: "B"
        },
        {
          label: "GED",
          value: "C"
        },
        {
          label: "Certificate of Proficiency",
          value: "D"
        },
        {
          label: "Other",
          value: "O"
        }
      ];
    },
    highSchoolDegreeIsOther() {
      return this.counselingProfile.high_school_degree_code === "O";
    },
    gradeLevels() {
      return [
        { value: "{\"code\":\"9\",\"value\":\"9th\"}", label: "9th"},
        { value: "{\"code\":\"10\",\"value\":\"10th\"}", label: "10th"},
        { value: "{\"code\":\"11\",\"value\":\"11th\"}", label: "11th"},
        { value: "{\"code\":\"12\",\"value\":\"12th\"}", label: "12th"},
        { value: "{\"code\":\"13\",\"value\":\"Post-12th Year\"}", label: "Post-12th Year"},
        { value: "{\"code\":\"GR\",\"value\":\"Already Graduated\"}", label: "Already Graduated"}
      ];
    },
    counselorRelationships() {
      return [
        { value: "Counselor/Registrar", label: "Counselor/Registrar"},
        { value: "Recommender", label: "Recommender"},
        { value: "Parent/Guardian", label: "Parent/Guardian"},
        { value: "Teacher", label: "Teacher"},
        { value: "Friend", label: "Friend"},
        { value: "Mentor", label: "Mentor"}
      ];
    },
    gpaTypes() {
      return this.$store.getters["counselingProfile/gpaTypeOptions"];
    },
    gpaScales() {
      return this.$store.getters["counselingProfile/gpaScaleOptions"];
    },
    academicInterests() {
      return [
        { value: "{\"value\":\"Agriculture/Forestry\",\"code\":\"01\",\"label\":\"Agriculture/Forestry\"}", label: "Agriculture/Forestry"},
        { value: "{\"value\":\"Animal Sciences/Pre-Vet\",\"code\":\"0109\",\"label\":\"Animal Sciences/Pre-Vet\"}", label: "Animal Sciences/Pre-Vet"},
        { value: "{\"value\":\"Architecture and Planning\",\"code\":\"04\",\"label\":\"Architecture and Planning\"}", label: "Architecture and Planning"},
        { value: "{\"value\":\"Biological and Life Sciences\",\"code\":\"26\",\"label\":\"Biological and Life Sciences\"}", label: "Biological and Life Sciences"},
        { value: "{\"value\":\"Business and Economics\",\"code\":\"52\",\"label\":\"Business and Economics\"}", label: "Business and Economics"},
        { value: "{\"value\":\"Communications/Journalism\",\"code\":\"09\",\"label\":\"Communications/Journalism\"}", label: "Communications/Journalism"},
        { value: "{\"value\":\"Computer Sciences\",\"code\":\"11\",\"label\":\"Computer Sciences\"}", label: "Computer Sciences"},
        { value: "{\"value\":\"Education\",\"code\":\"13\",\"label\":\"Education\"}", label: "Education"},
        { value: "{\"value\":\"Engineering\",\"code\":\"14\",\"label\":\"Engineering\"}", label: "Engineering"},
        { value: "{\"value\":\"English\",\"code\":\"23\",\"label\":\"English\"}", label: "English"},
        { value: "{\"value\":\"Environmental/Natural Resources\",\"code\":\"03\",\"label\":\"Environmental/Natural Resources\"}", label: "Environmental/Natural Resources"},
        { value: "{\"value\":\"Health and Pre-Medical\",\"code\":\"51\",\"lab…Nursing, Pre-Pharmacy, and Pre-Physical Therapy\"}", label: "Health and Pre-Medical"},
        { value: "{\"value\":\"History\",\"code\":\"54\",\"label\":\"History\"}", label: "History"},
        { value: "{\"value\":\"Hospitality/Tourism/Recreation\",\"code\":\"31\",\"label\":\"Hospitality/Tourism/Recreation\"}", label: "Hospitality/Tourism/Recreation"},
        { value: "{\"value\":\"Languages and Global Studies\",\"code\":\"16\",\"label\":\"Languages and Global Studies\"}", label: "Languages and Global Studies"},
        { value: "{\"value\":\"Philosophy/Religion\",\"code\":\"38\",\"label\":\"Philosophy/Religion\"}", label: "Philosophy/Religion"},
        { value: "{\"value\":\"Pre-Law\",\"code\":\"22\",\"label\":\"Pre-Law\"}", label: "Pre-Law"},
        { value: "{\"value\":\"Psychology\",\"code\":\"42\",\"label\":\"Psychology\"}", label: "Psychology"},
        { value: "{\"value\":\"Sciences (Physical) and Math\",\"code\":\"40…ludes Chemistry, Phyics, Astronomy, and Geology\"}", label: "Sciences (Physical) and Math"},
        { value: "{\"value\":\"Social Sciences\",\"code\":\"45\",\"label\":\"So…ience, Sociology, Anthropology, and Criminology\"}", label: "Social Sciences"},
        { value: "{\"value\":\"Social Services/ Public Administration\",…\"label\":\"Social Services/ Public Administration\"}", label: "Social Services/ Public Administration"},
        { value: "{\"value\":\"Visual and Performing Arts\",\"code\":\"50\",\"label\":\"Visual and Performing Arts\"}", label: "Visual and Performing Arts"},
        { value: "{\"value\":\"Undecided\",\"code\":\"99\",\"label\":\"Undecided\"}", label: "Undecided"}
      ];
    },
    collegeDegrees() {
      return this.$store.getters["counselingProfile/collegeDegreeOptions"];
    },
    honorRecognitionLevels() {
      return this.$store.getters["counselingProfile/honorRecognitionLevelOptions"];
    },
    honorGradeLevels() {
      return this.$store.getters["counselingProfile/gradeLevelOptions"];
    },
    counselingProfile() {
      return this.$store.getters["counselingProfile/counselingProfile"];
    },
    counselingProfileHighSchool() {
      return {
        school_name: this.counselingProfile.high_school_name,
        ceeb_code: this.counselingProfile.high_school_ceeb_code,
        school_type_code: this.counselingProfile.high_school_school_type_code,
        city: this.counselingProfile.high_school_city,
        state: this.counselingProfile.high_school_state,
        state_value: this.counselingProfile.high_school_state_value,
        zip: this.counselingProfile.high_school_zip,
        country_code: this.counselingProfile.high_school_country_code,
        country_value: this.counselingProfile.high_school_country_value,
        address_line_1: this.counselingProfile.high_school_address_line_1,
        address_line_2: this.counselingProfile.high_school_address_line_2,
        address_line_3: this.counselingProfile.high_school_address_line_3
      };
    },
    applyLevelIsNotFreshman() {
      const [TRANSFER, OTHER] = ["2", "3"];
      return [TRANSFER, OTHER].includes(this.counselingProfile.apply_level);
    },
    choseOtherInHighSchool() {
      const didNotFind = "other";
      return this.counselingProfile.high_school_value === didNotFind;
    },
    specializedCurriculumIncludesOther() {
      return this.counselingProfile.high_school_specialized_curriculums.includes("other");
    },
    hasNoOtherHighSchool() {
      return this.counselingProfile.other_high_schools_length === 0;
    },
    editingCourseworkOfHighSchoolYear() {
      if (this.editingCourseworkOfHighSchool) {
        return String(this.editingCourseworkOfHighSchool.targetYear);
      } else {
        return "";
      }
    },
    didNotChange() {
      return this.counselingProfile.disaster_impacts_transfer.includes("{\"code\":\"NA\",\"value\":\"My school did not change its grading policy.\"}");
    },
    wouldLikeToProvideDisasterInformation() {
      return this.counselingProfile.disaster_impacts_transfer.includes("{\"code\":\"MORE_INFO\",\"value\":\"I would like to provide additional information.\"}");
    },
    hasTakenCollegeInList() {
      const didNotFind = "other";
      return takenCollegeIndex =>
        this.counselingProfile.colleges[takenCollegeIndex]
          .value !== didNotFind;
    },
    takenCollegeGradingSystemsIncludesOther() {
      return takenCollegeIndex =>
        this.counselingProfile.colleges[takenCollegeIndex]
          .grading_systems.includes(3);
    },
    hasBoardingSchool() {
      return this.counselingProfile.high_school_is_boarding_high_school === 0;
    },
    hasGraduatedFromThisShcool() {
      return this.counselingProfile.high_school_graduate_from_school === 0;
    },
    hasReceivedSchoolEquivalency() {
      return this.counselingProfile.high_school_receive_high_school_equivalency === 0;
    },
    hasAnyProgression() {
      return this.counselingProfile.high_school_progression.some(progression => {
        const [GRADUATE_EARLY, GRADUATE_LATE, TAKE_TIME_OFF, TAKE_GAP_YEAR] = ["1", "2", "4", "5"];
        const progressions = [GRADUATE_EARLY, GRADUATE_LATE, TAKE_TIME_OFF, TAKE_GAP_YEAR];
        return progressions.includes(progression);
      });
    },
    MAX_OTHER_HIGH_SCHOOL_COUNT() {
      return 5;
    },
    MAX_TAKEN_COLLEGE_COUNT() {
      return 5;
    },
    MAX_RECENT_COURSE_COUNT() {
      return 15;
    },
    MAX_HONOR_COUNT() {
      return 5;
    },
    MAX_ORGANIZATION_COUNT() {
      return 5;
    },
    otherHighSchoolCounsMoreThanZero() {
      return 0 < this.counselingProfile.other_high_schools_length;
    },
    gpaScaleReportingIsNone() {
      return this.counselingProfile.gpa_scale_reporting === 200;
    },
    gpaScaleIsOther() {
      return this.counselingProfile.gpa_scale_reporting === 125968;
    },
    scheduleIsTrimester() {
      return (
        this.counselingProfile.courses_scheduling_system ===
        this.EnumCoursesSchedulingSystems.Trimester
      );
    },
    scheduleIsQuarter() {
      return (
        this.counselingProfile.courses_scheduling_system ===
        this.EnumCoursesSchedulingSystems.Quarter
      );
    },
    displayHonorCountAlreadyMax() {
      return this.MAX_HONOR_COUNT <= this.displayHonorCount;
    },
    honorCountIsMultiple() {
      return 1 < this.displayHonorCount;
    },
    MAX_MATH_COURSE_COUNT() {
      return 7;
    },
    mathCourseCountNotMaxYet() {
      return this.counselingProfile.math_courses_78th.length < this.MAX_MATH_COURSE_COUNT;
    },
    MAX_LANGUAGE_COURSE_COUNT() {
      return 7;
    },
    languageCourseCountNotMaxYet() {
      return this.counselingProfile.language_courses_78th.length < this.MAX_LANGUAGE_COURSE_COUNT;
    },
    intlExpLangIsOther() {
      return this.counselingProfile.intl_exp_lang_code === "OT";
    },
    academicInterestCountNotMaxYet() {
      const MAX_ACADEMIC_INTEREST_COUNT = 3;
      return this.counselingProfile.academic_interests.length < MAX_ACADEMIC_INTEREST_COUNT;
    },
    careerInterestIsOther() {
      const OTHER = "44";
      return this.counselingProfile.career_interest === OTHER;
    },
    highSchoolTypeIsNonUs() {
      return this.counselingProfile.high_school_type === "INTERNATIONAL";
    },
    highSchoolGradingScaleIsOther() {
      return this.counselingProfile.high_school_grade_scale === "{\"code\":\"O\",\"label\":\"Other\",\"value\":\"Other\"}";
    },
    highSchoolGradingScaleIsNumbers() {
      return this.counselingProfile.high_school_grade_scale === "{\"code\":\"N\",\"label\":\"Numbers\",\"value\":\"Numbers\"}";
    },
    highSchoolsNinethCoursesCount() {
      const highSchoolCoursesCount = this.counselingProfile.high_schools
        .reduce((coursesCount, highSchool) => coursesCount + highSchool.ninethCourses.length, 0);
      return highSchoolCoursesCount;
    },
    highSchoolsTenthCoursesCount() {
      const highSchoolCoursesCount = this.counselingProfile.high_schools
        .reduce((coursesCount, highSchool) => coursesCount + highSchool.tenthCourses.length, 0);
      return highSchoolCoursesCount;
    },
    highSchoolsEleventhCoursesCount() {
      const highSchoolCoursesCount = this.counselingProfile.high_schools
        .reduce((coursesCount, highSchool) => coursesCount + highSchool.eleventhCourses.length, 0);
      return highSchoolCoursesCount;
    },
    highSchoolsTwelvethCoursesCount() {
      const highSchoolCoursesCount = this.counselingProfile.high_schools
        .reduce((coursesCount, highSchool) => coursesCount + highSchool.twelvethCourses.length, 0);
      return highSchoolCoursesCount;
    }
  },
  data() {
    return {
      displayRecentCourseCount: 0,
      ninethGradeCourseworkDialogVisible: false,
      tenthGradeCourseworkDialogVisible: false,
      eleventhGradeCourseworkDialogVisible: false,
      twelvethGradeCourseworkDialogVisible: false,
      completedCollegeGradeCourseworkDialogVisible: false,
      inProgressCollegeGradeCourseworkDialogVisible: false,
      displayHonorCount: 0,
      currentAddCouseworkHighSchoolIndex: null,
      currentAddCouseworkCollegeIndex: null,
      editingCourseworkOfHighSchool: null,
      editingCompletedCourseworkOfCollege: null,
      editingInProgressCourseworkOfCollege: null,
      editingHighSchool: false,
      editingOtherHighSchools: [],
      editingColleges: []
    };
  },
  async created() {
    this.displayHonorCount = this.counselingProfile.valid_honor_count;
    this.editingHighSchool = !this.counselingProfile.high_school_value;
    this.initEditingOtherHighSchoolsStatus();
    this.initEditingCollegesStatus();

    this.$store.dispatch(
      "counselingProfile/fetchCollegeDegreesOfCounselingProfile"
    );
    this.$store.dispatch(
      "counselingProfile/fetchHonorRecognitionLevelsOfCounselingProfile"
    );
    this.$store.dispatch(
      "counselingProfile/fetchGradeLevelsOfCounselingProfile"
    );
    this.$store.dispatch("counselingProfile/fetchGpaTypesOfCounselingProfile");
    this.$store.dispatch("counselingProfile/fetchGpaScalesOfCounselingProfile");
  },
  methods: {
    setCounselingProfile(data) {
      this.$store.commit("counselingProfile/setCounselingProfile", { ...data });
    },
    addMathCourse78th() {
      this.$store.dispatch("counselingProfile/addMathCourse78th");
    },
    setMathCourse78th(mathCourse78thIndex, mathCourse78th) {
      this.$store.dispatch("counselingProfile/setMathCourse78th", {
        mathCourse78thIndex,
        mathCourse78th
      });
    },
    removeMathCourse78th(mathCourse78thIndex) {
      this.$store.dispatch("counselingProfile/removeMathCourse78th", mathCourse78thIndex);
    },
    addLanguageCourse78th() {
      this.$store.dispatch("counselingProfile/addLanguageCourse78th");
    },
    setLanguageCourse78th(languageCourse78thIndex, languageCourse78th) {
      this.$store.dispatch("counselingProfile/setLanguageCourse78th", {
        languageCourse78thIndex,
        languageCourse78th
      });
    },
    removeLanguageCourse78th(languageCourse78thIndex) {
      this.$store.dispatch("counselingProfile/removeLanguageCourse78th", languageCourse78thIndex);
    },
    setHighSchool(data) {
      const parseToHightSchoolKey = data => {
        const highSchoolKeyMapping = {
          name: "high_school_name",
          ceeb_code: "high_school_ceeb_code",
          school_type_code: "high_school_school_type_code",
          city: "high_school_city",
          state: "high_school_state",
          state_value: "high_school_state_value",
          zip: "high_school_zip",
          country_code: "high_school_country_code",
          country_value: "high_school_country_value",
          address_line_1: "high_school_address_line_1",
          address_line_2: "high_school_address_line_2",
          address_line_3: "high_school_address_line_3"
        };
        let parsedHihgSchool = {};
        Object.keys(data).forEach(key => {
          parsedHihgSchool[highSchoolKeyMapping[key]] = data[key];
        });
        return parsedHihgSchool;
      };

      this.setCounselingProfile(parseToHightSchoolKey(data));
    },
    setEditingHighSchool(isEditing) {
      const highSchoolHasSubmitCoursework = 1 <= this.counselingProfile.high_school_courses.length;
      if (!highSchoolHasSubmitCoursework) {
        this.editingHighSchool = isEditing;
        this.setCounselingProfile({ high_school_value: "" });
      } else {
        this.alertRemoveCourseworkOfHighSchool();
      }
    },
    alertRemoveCourseworkOfHighSchool() {
      this.$message.warning(
        "This high school can't be removed because it has coursework associated with it. You must remove the courses before removing this school."
      );
    },
    setOtherHighSchool(otherHighSchoolIndex, data) {
      this.$store.commit("counselingProfile/setOtherHighSchool", { otherHighSchoolIndex, data });
    },
    initEditingOtherHighSchoolsStatus() {
      this.editingOtherHighSchools = Array.from(
        Array(this.counselingProfile.other_high_schools_length)
      ).map((_, index) => !this.counselingProfile.other_high_schools[index].value);
    },
    setEditingOtherHighSchools(otherHighSchoolIndex, isEditing) {
      const highSchoolHasSubmitCoursework = 1 <= this.counselingProfile.other_high_schools[otherHighSchoolIndex].courses.length;
      if (!highSchoolHasSubmitCoursework) {
        this.editingOtherHighSchools = this.editingOtherHighSchools.map((editingOtherHighSchool, index) => {
          if (index === otherHighSchoolIndex) {
            return isEditing;
          } else {
            return editingOtherHighSchool;
          }
        });
        this.setOtherHighSchool(otherHighSchoolIndex, { value: "" });
      } else {
        this.alertRemoveCourseworkOfHighSchool();
      }
    },
    setAttendDateOfOtherHighSchool(otherHighSchoolIndex, attendDateIndex, data) {
      this.$store.commit("counselingProfile/setAttendDateOfOtherHighSchool", {
        otherHighSchoolIndex,
        attendDateIndex,
        data
      });
    },
    removeOtherHighSchoolAttendDate(otherHighSchoolIndex, removeAttendDateIndex) {
      this.$store.dispatch("counselingProfile/removeOtherHighSchoolAttendDate", { otherHighSchoolIndex, removeAttendDateIndex });
    },
    setHighSchoolAcadYear(index, highSchoolAcadYear) {
      this.$store.dispatch("counselingProfile/setHighSchoolAcadYear", { index, highSchoolAcadYear });
    },
    addHighSchoolAnotherAcadYear() {
      this.$store.dispatch("counselingProfile/addHighSchoolAnotherAcadYear");
    },
    setOtherHighSchoolAcadYear(otherHighSchoolIndex, index, highSchoolAcadYear) {
      this.$store.dispatch("counselingProfile/setOtherHighSchoolAcadYear", { otherHighSchoolIndex, index, highSchoolAcadYear });
    },
    addOtherHighSchoolAnotherAcadYear(otherHighSchoolIndex) {
      this.$store.dispatch("counselingProfile/addOtherHighSchoolAnotherAcadYear", { otherHighSchoolIndex });
    },
    addOtherHighSchoolAttendDate(otherHighSchoolIndex) {
      this.$store.dispatch("counselingProfile/addOtherHighSchoolAttendDate", otherHighSchoolIndex);
    },
    setAttendDateOfCollege(takenCollegeIndex, attendDateIndex, data) {
      this.$store.commit("counselingProfile/setAttendDateOfCollege", {
        takenCollegeIndex,
        attendDateIndex,
        data
      });
    },
    removeCollegeAttendDate(takenCollegeIndex, removeAttendDateIndex) {
      this.$store.dispatch("counselingProfile/removeCollegeAttendDate", { takenCollegeIndex, removeAttendDateIndex });
    },
    addCollegeAttendDate(takenCollegeIndex) {
      this.$store.dispatch("counselingProfile/addCollegeAttendDate", takenCollegeIndex);
    },
    setGradeOfCollege(takenCollegeIndex, gradeIndex, data) {
      this.$store.commit("counselingProfile/setGradeOfCollege", {
        takenCollegeIndex,
        gradeIndex,
        data
      });
    },
    removeCollegeGrade(takenCollegeIndex, removeGradeIndex) {
      this.$store.dispatch("counselingProfile/removeCollegeGrade", { takenCollegeIndex, removeGradeIndex });
    },
    addCollegeGrade(takenCollegeIndex) {
      this.$store.dispatch("counselingProfile/addCollegeGrade", takenCollegeIndex);
    },
    addGradeCourseworkOfOtherHighSchool(gradeCoursework) {
      const isCurrentHighSchool = this.currentAddCouseworkHighSchoolIndex === 0;
      if (isCurrentHighSchool) {
        this.$store.dispatch("counselingProfile/addGradeCourseworkOfHighSchool",
          { gradeCoursework }
        );
      } else {
        this.$store.dispatch("counselingProfile/addGradeCourseworkOfOtherHighSchool",
          {
            otherHighSchoolIndex: this.currentAddCouseworkHighSchoolIndex - 1,
            gradeCoursework
          }
        );
      }
    },
    editGradeCourseworkOfHighSchool(highSchoolIndex, targetYear, courseIndex) {
      const getGradeCourseworkOfHighSchool = () => {
        const targetOtherHighSchool = this.counselingProfile.high_schools[highSchoolIndex];
        switch (targetYear) {
          case 9:
            return targetOtherHighSchool.ninethCourses[courseIndex];
          case 10:
            return targetOtherHighSchool.tenthCourses[courseIndex];
          case 11:
            return targetOtherHighSchool.eleventhCourses[courseIndex];
          case 12:
            return targetOtherHighSchool.twelvethCourses[courseIndex];
          default:
            return [];
        }
      };
      this.editingCourseworkOfHighSchool = {
        highSchoolIndex,
        targetYear,
        courseIndex,
        ...getGradeCourseworkOfHighSchool()
      };
    },
    saveGradeCourseworkOfHighSchool(coursework) {
      const { highSchoolIndex, targetYear, courseIndex } = coursework;
      const isCurrentHighSchool = highSchoolIndex === 0;
      if (isCurrentHighSchool) {
        this.$store.dispatch("counselingProfile/saveGradeCourseworkOfHighSchool",
          {
            targetYear,
            courseIndex,
            coursework
          }
        );
      } else {
        this.$store.dispatch("counselingProfile/saveGradeCourseworkOfOtherHighSchool",
          {
            otherHighSchoolIndex: highSchoolIndex - 1,
            targetYear,
            courseIndex,
            coursework
          }
        );
      }
    },
    deleteGradeCourseworkOfHighSchool(highSchoolIndex, targetYear, courseIndex) {
      const isCurrentHighSchool = highSchoolIndex === 0;
      if (isCurrentHighSchool) {
        this.$store.dispatch("counselingProfile/deleteGradeCourseworkOfHighSchool",
          {
            targetYear,
            courseIndex
          }
        );
      } else {
        this.$store.dispatch("counselingProfile/deleteGradeCourseworkOfOtherHighSchool",
          {
            otherHighSchoolIndex: highSchoolIndex - 1,
            targetYear,
            courseIndex
          }
        );
      }
    },
    initEditingCollegesStatus() {
      this.editingColleges = Array.from(
        Array(this.counselingProfile.colleges_length)
      ).map((_, index) => !this.counselingProfile.colleges[index].value);
    },
    setEditingColleges(takenCollegeIndex, isEditing) {
      const collegeHasSubmitCourse = 1 <= this.counselingProfile.colleges[takenCollegeIndex].courses.length || 0;
      if (!collegeHasSubmitCourse) {
        this.editingColleges = this.editingColleges.map((editingCollege, index) => {
          if (index === takenCollegeIndex) {
            return isEditing;
          } else {
            return editingCollege;
          }
        });
        this.setTakenCollege(takenCollegeIndex, { value: "" });
      } else {
        this.$message.warning(
          "This college can't be changed because it has completed coursework associated with it. You must delete these courses before changing this school."
        );
      }
    },
    editCompletedGradeCourseworkOfCollege(collegeIndex, coursework) {
      this.editingCompletedCourseworkOfCollege = {
        ...coursework,
        collegeIndex,
        originalCoursework: coursework
      };
    },
    addCompletedGradeCourseworkOfCollege(gradeCoursework) {
      this.$store.dispatch("counselingProfile/addCompletedGradeCourseworkOfCollege", {
        takenCollegeIndex: this.currentAddCouseworkCollegeIndex,
        gradeCoursework
      });
    },
    saveCompletedGradeCourseworkOfCollege(coursework) {
      const { collegeIndex, originalCoursework } = coursework;
      this.$store.dispatch("counselingProfile/saveCompletedGradeCourseworkOfCollege", {
        takenCollegeIndex: collegeIndex,
        originalCoursework,
        newCoursework: coursework
      });
    },
    deleteCompletedGradeCourseworkOfCollege(takenCollegeIndex, coursework) {
      this.$store.dispatch("counselingProfile/deleteCompletedGradeCourseworkOfCollege", {
        takenCollegeIndex,
        coursework
      });
    },
    editInProgressGradeCourseworkOfCollege(collegeIndex, coursework) {
      this.editingInProgressCourseworkOfCollege = {
        ...coursework,
        collegeIndex,
        originalCoursework: coursework
      };
    },
    addInProgressGradeCourseworkOfCollege(gradeCoursework) {
      this.$store.dispatch("counselingProfile/addInProgressGradeCourseworkOfCollege", {
        takenCollegeIndex: this.currentAddCouseworkCollegeIndex,
        gradeCoursework
      });
    },
    saveInProgressGradeCourseworkOfCollege(coursework) {
      const { collegeIndex, originalCoursework } = coursework;
      this.$store.dispatch("counselingProfile/saveInProgressGradeCourseworkOfCollege", {
        takenCollegeIndex: collegeIndex,
        originalCoursework,
        newCoursework: coursework
      });
    },
    deleteInProgressGradeCourseworkOfCollege(takenCollegeIndex, coursework) {
      this.$store.dispatch("counselingProfile/deleteInProgressGradeCourseworkOfCollege", {
        takenCollegeIndex,
        coursework
      });
    },
    setDisasterImpactsTransfer(disasterImpactsTransfer) {
      const didNotChange = disasterImpactsTransfer.includes("{\"code\":\"NA\",\"value\":\"My school did not change its grading policy.\"}");
      if (didNotChange) {
        this.setCounselingProfile({
          disaster_impacts_transfer: disasterImpactsTransfer.filter((disasterImpactTransfer) => (
            disasterImpactTransfer !== "{\"code\":\"GRADE_CHG\",\"value\":\"My school changed its grading policy for all students.\"}" &&
            disasterImpactTransfer !== "{\"code\":\"GRADE_CHOICE\",\"value\":\"My school allowed students to choose how their grades would be assigned (e.g. pass/fail, credit/no credit, letter grades).\"}"
          ))
        });
      } else {
        this.setCounselingProfile({ disaster_impacts_transfer: disasterImpactsTransfer });
      }
    },
    setAcademicInterest(academicInterestIndex, academicInterest) {
      this.$store.dispatch("counselingProfile/setAcademicInterest", {
        academicInterestIndex,
        academicInterest
      });
    },
    removeAcademicInterest(academicInterestIndex) {
      this.$store.dispatch("counselingProfile/removeAcademicInterest", academicInterestIndex);
    },
    addAcademicInterest() {
      this.$store.dispatch("counselingProfile/addAcademicInterest");
    },
    setTakenCollege(takenCollegeIndex, data) {
      this.$store.commit("counselingProfile/setTakenCollege", { takenCollegeIndex, data });
    },
    setRecentCourse(recentCourseIndex, data) {
      this.$store.commit("counselingProfile/setRecentCourse", { recentCourseIndex, data });
    },
    cleanAllRecentCourseSchedule() {
      this.$store.commit("counselingProfile/cleanAllRecentCourseSchedule");
    },
    setHonor(honorIndex, data) {
      this.$store.commit("counselingProfile/setHonor", { honorIndex, data });
    },
    moveHonorOrderUp(honorIndex) {
      this.$store.dispatch("counselingProfile/moveHonorOrderUp", { honorIndex });
    },
    moveHonorOrderDown(honorIndex) {
      this.$store.dispatch("counselingProfile/moveHonorOrderDown", { honorIndex });
    },
    removeLastHonor() {
      this.displayHonorCount -= 1;
      this.$store.dispatch("counselingProfile/removeLastHonor");
    },
    addDisplayHonorCount() {
      this.displayHonorCount += 1;
    },
    honorOrderIsFirst(honorOrder) {
      return honorOrder === 1;
    },
    honorOrderIsLast(honorOrder) {
      return honorOrder === this.displayHonorCount;
    },
    setOrganization(organizationIndex, data) {
      this.$store.commit("counselingProfile/setOrganization", { organizationIndex, data });
    },
    setPhoneOfOrganization(organizationIndex, data) {
      this.$store.commit("counselingProfile/setPhoneOfOrganization", { organizationIndex, data });
    },
    organizationOrderIsFirst(organizationOrder) {
      return organizationOrder === 1;
    },
    choseOtherInOtherHighSchool(otherHighSchoolIndex) {
      const didNotFind = "other";
      return this.counselingProfile.other_high_schools[otherHighSchoolIndex].value === didNotFind;
    },
    otherSchoolGradingSystemIsOther(otherHighSchoolIndex) {
      if (this.counselingProfile.other_high_schools[otherHighSchoolIndex].state_type === "outsideTheUs") {
        return this.counselingProfile.other_high_schools[otherHighSchoolIndex].grading_systems.includes(28);
      } else {
        return this.counselingProfile.other_high_schools[otherHighSchoolIndex].grading_systems.includes(4);
      }
    },
    otherHighSchoolGradingScaleIsOther(otherHighSchoolIndex) {
      return this.counselingProfile.other_high_schools[otherHighSchoolIndex].grade_scale === "{\"code\":\"O\",\"label\":\"Other\",\"value\":\"Other\"}";
    },
    otherHighSchoolGradingScaleIsNumbers(otherHighSchoolIndex) {
      return this.counselingProfile.other_high_schools[otherHighSchoolIndex].grade_scale === "{\"code\":\"N\",\"label\":\"Numbers\",\"value\":\"Numbers\"}";
    },
    otherHighSchoolTypeIsNonUs(otherHighSchoolIndex) {
      return this.counselingProfile.other_high_schools[otherHighSchoolIndex].type === "INTERNATIONAL";
    },
    openNinethGradeCourseworkDialog(otherHighSchoolIndex) {
      this.currentAddCouseworkHighSchoolIndex = otherHighSchoolIndex;
      this.ninethGradeCourseworkDialogVisible = true;
    },
    closeNinethGradeCourseworkDialog() {
      this.currentAddCouseworkHighSchoolIndex = null;
      this.ninethGradeCourseworkDialogVisible = false;
    },
    openTenthGradeCourseworkDialog(otherHighSchoolIndex) {
      this.currentAddCouseworkHighSchoolIndex = otherHighSchoolIndex;
      this.tenthGradeCourseworkDialogVisible = true;
    },
    closeTenthGradeCourseworkDialog() {
      this.currentAddCouseworkHighSchoolIndex = null;
      this.tenthGradeCourseworkDialogVisible = false;
    },
    openEleventhGradeCourseworkDialog(otherHighSchoolIndex) {
      this.currentAddCouseworkHighSchoolIndex = otherHighSchoolIndex;
      this.eleventhGradeCourseworkDialogVisible = true;
    },
    closeEleventhGradeCourseworkDialog() {
      this.currentAddCouseworkHighSchoolIndex = null;
      this.eleventhGradeCourseworkDialogVisible = false;
    },
    openTwelvethGradeCourseworkDialog(otherHighSchoolIndex) {
      this.currentAddCouseworkHighSchoolIndex = otherHighSchoolIndex;
      this.twelvethGradeCourseworkDialogVisible = true;
    },
    closeTwelvethGradeCourseworkDialog() {
      this.currentAddCouseworkHighSchoolIndex = null;
      this.twelvethGradeCourseworkDialogVisible = false;
    },
    openCompletedCollegeGradeCourseworkDialog(collegeIndex) {
      this.currentAddCouseworkCollegeIndex = collegeIndex;
      this.completedCollegeGradeCourseworkDialogVisible = true;
    },
    closeCompletedCollegeGradeCourseworkDialog() {
      this.currentAddCouseworkCollegeIndex = null;
      this.completedCollegeGradeCourseworkDialogVisible = false;
    },
    openInProgressCollegeGradeCourseworkDialog(collegeIndex) {
      this.currentAddCouseworkCollegeIndex = collegeIndex;
      this.inProgressCollegeGradeCourseworkDialogVisible = true;
    },
    closeInProgressCollegeGradeCourseworkDialog() {
      this.currentAddCouseworkCollegeIndex = null;
      this.inProgressCollegeGradeCourseworkDialogVisible = false;
    }
  }
};
</script>

<style scoped src="@/views/private/Counseling/ThirdApp/CounselingProfileForm/index.css"></style>
<style scoped>
.removeAttendDateOfOtherHighSchool {
  color: #42a16a;
  cursor: pointer;
}

.editAndDeleteCourseOfOtherHighSchool {
  color: #42a16a;
  cursor: pointer;
  margin-left: 12px;
}

.editAndDeleteCourseOfCollege {
  color: #42a16a;
  cursor: pointer;
  margin-left: 12px;
}

.removeAttendDateOfCollege {
  color: #42a16a;
  cursor: pointer;
}

.editNameOfOtherHighSchool {
  color: #42a16a;
  cursor: pointer;
  margin-left: 12px;
}

.editNameOfColleges {
  color: #42a16a;
  cursor: pointer;
  margin-left: 12px;
}

.removeAcademicInterest {
  color: #42a16a;
  cursor: pointer;
}

.course {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  padding: 16px 32px;
  margin: 10px 0px;
  background: #fafafa;
  border: 1px solid rgba(144, 144, 144, .15);
}
</style>
