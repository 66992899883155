<template>
  <div>
    <CounselingFormItem
      required
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.california_app]"
    >
      <template slot="label">
        Where did you work?
      </template>
      <template slot="promptText">
        Please tell us the name of the place where you worked.
      </template>
      <el-input
        :value="activity.work_exp_name"
        @input="(work_exp_name) => $emit('setActivity', { work_exp_name })"
      />
    </CounselingFormItem>
    <CounselingFormItem
      required
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.california_app]"
    >
      <template slot="label">
        Please briefly describe the company or organization where you worked.
      </template>
      <template slot="promptText">
        Consider describing the industry, the size of the company or organization, or its main focus.
      </template>
      <el-input
        type="textarea"
        :rows="5"
        maxlength="250"
        show-word-limit
        :value="activity.work_exp_desc"
        @input="(work_exp_desc) => $emit('setActivity', { work_exp_desc })"
      />
    </CounselingFormItem>
    <CounselingFormItem
      required
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.california_app]"
    >
      <template slot="label">
        What was your job title?
      </template>
      <el-input
        :value="activity.work_exp_pos_title"
        @input="(work_exp_pos_title) => $emit('setActivity', { work_exp_pos_title })"
      />
    </CounselingFormItem>
    <CounselingFormItem
      required
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.california_app]"
    >
      <template slot="label">
        What were your job responsibilities? 
      </template>
      <el-input
        type="textarea"
        :rows="5"
        maxlength="350"
        show-word-limit
        :value="activity.work_exp_pos_desc"
        @input="(work_exp_pos_desc) => $emit('setActivity', { work_exp_pos_desc })"
      />
    </CounselingFormItem>
    <CounselingFormItem
      required
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.california_app]"
    >
      <template slot="label">
        When did you work at this job?
      </template>
      <template slot="promptText">
        We'd like to know when you worked at this job and the number of hours per week you worked. It's ok to estimate, but try to be as accurate as possible. If you worked during the summer, select the grade year before that summer.
      </template>
      <div :key="grade.label" v-for="(grade, gradeIndex) in grades">
        <el-checkbox
          :value="activity.work_exp_grades.includes(grade.value)"
          @input="() => changeGrades(grade.value)"
        >
          {{ grade.label }}
        </el-checkbox>
        <div class="gradeQuestion" v-if="workExpYearsAfter(grade.value)">
          <CounselingFormItem
            hiddenTag
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.california_app]"
          >
            <el-checkbox
              true-label="Y"
              false-label="N"
              :value="activity[`is_work_exp_school${beginOfGrade + gradeIndex}`]"
              @input="
                value =>
                  $emit('setActivity', {
                    [`is_work_exp_school${beginOfGrade + gradeIndex}`]: value
                  })
              "
            >
              School year
            </el-checkbox>
            <el-input-number
              :min="0"
              :max="999.99"
              class="inputNumber"
              controls-position="right"
              :value="activity[`work_exp${beginOfGrade + gradeIndex}_school_hours_per_week`]"
              @input="
                value =>
                  $emit('setActivity', {
                    [`work_exp${beginOfGrade + gradeIndex}_school_hours_per_week`]: value
                  })
              "
            />
          </CounselingFormItem>
          <CounselingFormItem
            hiddenTag
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.california_app]"
          >
            <el-checkbox
              true-label="Y"
              false-label="N"
              :value="activity[`is_work_exp_summer${beginOfGrade + gradeIndex}`]"
              @input="
                value =>
                  $emit('setActivity', {
                    [`is_work_exp_summer${beginOfGrade + gradeIndex}`]: value
                  })
              "
            >
              Summer
            </el-checkbox>
            <el-input-number
              :min="0"
              :max="999.99"
              class="inputNumber"
              controls-position="right"
              :value="activity[`work_exp${beginOfGrade + gradeIndex}_summer_hours_per_week`]"
              @input="
                value =>
                  $emit('setActivity', {
                    [`work_exp${beginOfGrade + gradeIndex}_summer_hours_per_week`]: value
                  })
              "
            />
          </CounselingFormItem>
        </div>
      </div>
    </CounselingFormItem>
    <template v-if="workExpYearsAfter12">
      <b>How many months/years after 12th grade did you work at this job?</b>
      <CounselingFormItem
        required
        :currentApplication="currentApplicationType"
        :applicationsNeedToFilling="[thirdAppTypes.california_app]"
      >
        <template slot="label">
          Number of Years
        </template>
        <ActivityOrAwardSpendYearsSelector
          :value="activity.work_exp_years_after12"
          @change="(work_exp_years_after12) => $emit('setActivity', { work_exp_years_after12 })"
        />
      </CounselingFormItem>
      <CounselingFormItem
        required
        :currentApplication="currentApplicationType"
        :applicationsNeedToFilling="[thirdAppTypes.california_app]"
      >
        <template slot="label">
          Number of Months
        </template>
        <ActivityOrAwardSpendMonthsSelector
          :value="activity.work_exp_months_after12"
          @change="(work_exp_months_after12) => $emit('setActivity', { work_exp_months_after12 })"
        />
      </CounselingFormItem>
      <CounselingFormItem
        :currentApplication="currentApplicationType"
        :applicationsNeedToFilling="[thirdAppTypes.california_app]"
      >
        <el-checkbox
          true-label="Y"
          false-label="N"
          :value="activity.is_work_exp_school_after12"
          @input="(is_work_exp_school_after12) => $emit('setActivity', { is_work_exp_school_after12 })"
        >
          School year
        </el-checkbox>
        <el-input-number
          :min="0"
          :max="999.99"
          class="inputNumber"
          controls-position="right"
          :value="activity.work_exp_after12_school_hours_per_week"
          @input="(work_exp_after12_school_hours_per_week) => $emit('setActivity', { work_exp_after12_school_hours_per_week })"
        />
      </CounselingFormItem>
      <CounselingFormItem
        :currentApplication="currentApplicationType"
        :applicationsNeedToFilling="[thirdAppTypes.california_app]"
      >
        <el-checkbox
          true-label="Y"
          false-label="N"
          :value="activity.is_work_exp_summer_after12"
          @input="(is_work_exp_summer_after12) => $emit('setActivity', { is_work_exp_summer_after12 })"
        >
          Summer
        </el-checkbox>
        <el-input-number
          :min="0"
          :max="999.99"
          class="inputNumber"
          controls-position="right"
          :value="activity.work_exp_after12_summer_hours_per_week"
          @input="(work_exp_after12_summer_hours_per_week) => $emit('setActivity', { work_exp_after12_summer_hours_per_week })"
        />
      </CounselingFormItem>
    </template>
    <CounselingFormItem
      required
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.california_app]"
    >
      <template slot="label">
        Do you still work at this job?
      </template>
      <el-radio-group
        style="width: 100%"
        :value="activity.is_work_exp_curr_working"
        @input="(is_work_exp_curr_working) => $emit('setActivity', { is_work_exp_curr_working })"
      >
        <div>
          <el-radio label="Y">Yes</el-radio>
        </div>
        <div>
          <el-radio label="N">No</el-radio>
        </div>
      </el-radio-group>
    </CounselingFormItem>
    <template v-if="activity.is_work_exp_curr_working === 'Y'">
      <CounselingFormItem
        required
        :currentApplication="currentApplicationType"
        :applicationsNeedToFilling="[thirdAppTypes.california_app]"
      >
        <template slot="label">
          Start date:
        </template>
        <el-date-picker
          style="width: 100%"
          value-format="MM/yyyy"
          type="date"
          :value="activity.work_exp_start"
          @input="(work_exp_start) => $emit('setActivity', { work_exp_start })"
        />
      </CounselingFormItem>
      <CounselingFormItem
        required
        :currentApplication="currentApplicationType"
        :applicationsNeedToFilling="[thirdAppTypes.california_app]"
      >
        <template slot="label">
          End date:
        </template>
        <el-date-picker
          style="width: 100%"
          value-format="MM/yyyy"
          type="date"
          :value="activity.work_exp_end"
          @input="(work_exp_end) => $emit('setActivity', { work_exp_end })"
        />
      </CounselingFormItem>
    </template>
  </div>
</template>

<script>
import { thirdAppTypeDict } from "@/class/ThirdApp";
import CounselingFormItem from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/CounselingFormItem";
import ActivityOrAwardSpendYearsSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/ActivityForm/ActivityOrAwardDialog/Components/ActivityOrAwardSpendYearsSelector";
import ActivityOrAwardSpendMonthsSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/ActivityForm/ActivityOrAwardDialog/Components/ActivityOrAwardSpendMonthsSelector";

export default {
  name: "WorkExperienceForm",
  props: {
    currentApplicationType: {
      type: String,
      default: ""
    },
    activity: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    CounselingFormItem,
    ActivityOrAwardSpendYearsSelector,
    ActivityOrAwardSpendMonthsSelector
  },
  computed: {
    thirdAppTypes() {
      return thirdAppTypeDict;
    },
    grades() {
      return this.$store.getters["counselingProfile/awardGradeOptions"];
    },
    beginOfGrade() {
      return 9;
    },
    workExpYearsAfter12() {
      return this.activity.work_exp_grades.includes(5);
    }
  },
  methods: {
    workExpYearsAfter(grade) {
      return this.activity.work_exp_grades
        .filter((work_exp_grade) => work_exp_grade !== 5)
        .includes(grade);
    },
    changeGrades(grade) {
      let workingWorkExpGrades = [...this.activity.work_exp_grades];
      if (this.activity.work_exp_grades.includes(grade)) {
        workingWorkExpGrades = workingWorkExpGrades.filter((workingWorkExpGrade) => workingWorkExpGrade !== grade);
      } else {
        workingWorkExpGrades.push(grade);
      }
      this.$emit("setActivity", { work_exp_grades: workingWorkExpGrades });
    }
  }
};
</script>

<style scoped>
.gradeQuestion {
  margin-top: -20px;
  margin-left: 20px;
}
</style>