<template>
  <div>
    <h2>
      {{ pageTitle }}
      <span
        v-if="!accountEditable"
        class="action-icon"
        @click="openAccountEditable()"
      >
        <i class="fas fa-edit" />
      </span>
    </h2>
    <el-form label-width="160px">
      <el-form-item :label="$t('application.columns.appName')">
        {{ thirdAppName }}
      </el-form-item>
      <el-form-item :label="$t('application.columns.appUsername')">
        <el-input v-if="accountEditable" v-model="newThirdApp.account" />
        <template v-else>
          <span v-if="thirdApp.account">
            {{ thirdApp.account }}
          </span>
          <span v-else class="prompt">
            {{ $t('application.details.usernameNotSet') }}
          </span>
        </template>
      </el-form-item>
      <el-form-item :label="$t('application.columns.appPassword')">
        <el-input v-if="accountEditable" v-model="newThirdApp.password" />
        <template v-else>
          <span v-if="thirdApp.password">
            {{ thirdApp.password }}
          </span>
          <span v-else class="prompt">
            {{ $t('application.details.passwordNotSet') }}
          </span>
        </template>
      </el-form-item>
      <el-form-item v-if="accountEditable" align="right">
        <el-button type="danger" @click="closeAccountEditable()">
          {{ $t("button.cancel") }}
        </el-button>
        <el-button type="primary" @click="updateAccount()">
          {{ $t("button.save") }}
        </el-button>
      </el-form-item>
    </el-form>
    <CheckingAccountInfoDialog :isVisible="checkingAccountInfoDialogVisible" />
    <LoginFailedDialog
      :isVisible="accountInfoIsWrongDialogVisible"
      :thirdAppTypeName="thirdAppName"
      @onCancel="() => accountInfoIsWrongDialogVisible = false"
      @onInputAccountInfo="() => accountInfoIsWrongDialogVisible = false"
    />
  </div>
</template>

<script>
import CheckingAccountInfoDialog from "@/views/private/Counseling/ThirdApp/Dialog/CheckingAccountInfo";
import LoginFailedDialog from "@/views/private/Counseling/ThirdApp/Dialog/LoginFailed";
export default {
  components: {
    CheckingAccountInfoDialog,
    LoginFailedDialog
  },
  props: {
    pageTitle: {
      type: String,
      default: ""
    },
    thirdApp: {
      type: Object,
      default: () => ({})
    },
    studentUserId: {
      type: Number,
      default: null
    },
    thirdAppName: {
      type: String,
      default: ""
    },
    editMode: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    thirdApp() {
      if (this.accountEditable) {
        this.newThirdApp = { ...this.thirdApp };
      }
    }
  },
  data() {
    return {
      accountEditable: false,
      newThirdApp: {},
      checkingAccountInfoDialogVisible: false,
      accountInfoIsWrongDialogVisible: false
    };
  },
  async created() {
    this.openAccountEditable();
  },
  methods: {
    openAccountEditable() {
      this.newThirdApp = { ...this.thirdApp };
      this.accountEditable = true;
    },
    closeAccountEditable() {
      this.accountEditable = false;
    },
    updateAccount() {
      this.checkingAccountInfoDialogVisible = true;
      this.$store.dispatch(
        "thirdApp/storeThirdAccount",
        {
          studentUserId: this.studentUserId,
          thirdApp: this.newThirdApp,
          success: () => {
            this.$emit("fetchThirdAccount");
            this.accountEditable = false;
            this.checkingAccountInfoDialogVisible = false;
          },
          error: () => {
            this.checkingAccountInfoDialogVisible = false;
            this.accountInfoIsWrongDialogVisible = true;
          }
        }
      );
    }
  }
};
</script>

<style scoped>
</style>
