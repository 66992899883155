<template>
  <el-radio-group
    style="width: 100%"
    :value="value"
    @input="changeValue"
  >
    <div
      :key="item.id"
      v-for="item in data"
    >
      <el-radio :label="item.id">
        {{ item.title }}
      </el-radio>
    </div>
  </el-radio-group>
</template>

<script>
export default {
  name: "ClassesScheduledRadio",
  props: ["value"],
  computed: {
    data() {
      return this.$store.getters["counselingProfile/classScheduledOptions"];
    }
  },
  created() {
    this.$store.dispatch("counselingProfile/fetchClassesScheduledOfCounselingProfile");
  },
  methods: {
    changeValue(value) {
      this.$emit("input", value);
    }
  }
};
</script>
