<template>
  <el-collapse
    :value="activePanel"
    @change="(value) => {
      $emit('saveCounselingProfile');
      $emit('setActivePanel', value);
    }"
    accordion
  >
    <el-collapse-item title="Personal Information" name="personalInformation">
      <el-form>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
        >
          <template slot="label">
            First/given name
          </template>
          <el-input
            :value="counselingProfile.first_name"
            @input="(first_name) => setCounselingProfile({ first_name })"
          />
        </CounselingFormItem>
        <CounselingFormItem
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
        >
          <template slot="label">
            Middle name
          </template>
          <el-input
            :value="counselingProfile.middle_name"
            @input="(middle_name) => setCounselingProfile({ middle_name })"
          />
        </CounselingFormItem>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
        >
          <template slot="label">
            Last/Family/Surname
          </template>
          <el-input
            :value="counselingProfile.last_name"
            @input="(last_name) => setCounselingProfile({ last_name })"
          />
        </CounselingFormItem>
        <CounselingFormItem
          checkApplications
          :value="counselingProfile.suffix"
          getterProp="suffixOptions"
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
        >
          <template slot="label">
            Suffix
          </template>
          <SuffixSelector
            :value="counselingProfile.suffix"
            @change="(suffix) => setCounselingProfile({ suffix })"
          />
        </CounselingFormItem>
        <CounselingFormItem
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            Would you like to share a different first name that you go by?
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.would_you_like_to_share_different_first_name"
            @input="(would_you_like_to_share_different_first_name) => setCounselingProfile({ would_you_like_to_share_different_first_name })"
          >
            <div><el-radio :label="0">Yes</el-radio></div>
            <div><el-radio :label="1">No</el-radio></div>
          </el-radio-group>
        </CounselingFormItem>
        <CounselingFormItem
          required
          v-if="wantToShareDifferentFirstName"
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            My first name
          </template>
          <el-input
            :value="counselingProfile.different_first_name"
            @input="(different_first_name) => setCounselingProfile({ different_first_name })"
          />
        </CounselingFormItem>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            Pronouns
          </template>
          <div style="display: inline-block; width: 100%;">
            <el-checkbox-group
              :value="counselingProfile.pronouns"
              @input="(pronouns) => setCounselingProfile({ pronouns })"
            >
              <div
                :key="pronoun.label"
                v-for="pronoun in pronouns"
              >
                <el-checkbox :label="pronoun.value">
                  {{ pronoun.label }}
                </el-checkbox>
              </div>
            </el-checkbox-group>
          </div>
        </CounselingFormItem>
        <CounselingFormItem
          v-if="pronounsIsNotListed"
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            Please feel free to enter your pronouns.
          </template>
          <el-input
            :value="counselingProfile.other_pronoun_descr"
            @input="(other_pronoun_descr) => setCounselingProfile({ other_pronoun_descr })"
          />
        </CounselingFormItem>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            Preferred name (nickname)
          </template>
          <el-input
            :value="counselingProfile.preferred_name"
            @input="(preferred_name) => setCounselingProfile({ preferred_name })"
          />
        </CounselingFormItem>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.california_app]"
        >
          <template slot="label">
            Have you ever used any other names?
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.used_any_other_names"
            @input="(used_any_other_names) => setCounselingProfile({ used_any_other_names })"
          >
            <div><el-radio :label="0">Yes</el-radio></div>
            <div><el-radio :label="1">No</el-radio></div>
          </el-radio-group>
        </CounselingFormItem>
        <template v-if="hasOtherNames">
          <CounselingFormItem
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              Former first name
            </template>
            <el-input
              :value="counselingProfile.former_first_name"
              @input="(former_first_name) => setCounselingProfile({ former_first_name })"
            />
          </CounselingFormItem>
          <CounselingFormItem
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              Former middle name
            </template>
            <el-input
              :value="counselingProfile.former_middle_name"
              @input="(former_middle_name) => setCounselingProfile({ former_middle_name })"
            />
          </CounselingFormItem>
          <CounselingFormItem
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              Former last name
            </template>
            <el-input
              :value="counselingProfile.former_last_name"
              @input="(former_last_name) => setCounselingProfile({ former_last_name })"
            />
          </CounselingFormItem>
          <CounselingFormItem
            checkApplications
            :value="counselingProfile.former_suffix"
            getterProp="suffixOptions"
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.california_app]"
          >
            <template slot="label">
              Suffix
            </template>
            <SuffixSelector
              :value="counselingProfile.former_suffix"
              @change="(former_suffix) => setCounselingProfile({ former_suffix })"
            />
          </CounselingFormItem>
        </template>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            Sex
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.sex"
            @input="(sex) => setCounselingProfile({ sex })"
          >
            <div><el-radio :label="0">Male</el-radio></div>
            <div><el-radio :label="1">Female</el-radio></div>
          </el-radio-group>
        </CounselingFormItem>
        <CounselingFormItem
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            Gender Identity
          </template>
          <div style="display: inline-block; width: 100%;">
            <el-checkbox-group
              :value="counselingProfile.gender_identities"
              @input="(gender_identities) => setCounselingProfile({ gender_identities })"
            >
              <div
                :key="genderIdentity.label"
                v-for="genderIdentity in genderIdentities"
              >
                <el-checkbox :label="genderIdentity.value">
                  {{ genderIdentity.label }}
                </el-checkbox>
              </div>
            </el-checkbox-group>
          </div>
        </CounselingFormItem>
        <CounselingFormItem
          v-if="wantToDescrGenderIdentity"
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            If you would like the opportunity, we invite you to share more about your gender identity below
          </template>
          <el-input
            :value="counselingProfile.sex_descr"
            @input="(sex_descr) => setCounselingProfile({ sex_descr })"
          />
        </CounselingFormItem>
        <CounselingFormItem
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.california_app]"
        >
          <template slot="label">
            What do you consider yourself to be?
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.sexual_orientation"
            @input="(sexual_orientation) => setCounselingProfile({ sexual_orientation })"
          >
            <div
              :key="sexualOrientation.value"
              v-for="sexualOrientation in sexualOrientations"
            >
              <el-radio :label="sexualOrientation.value">
                {{ sexualOrientation.label }}
              </el-radio>
            </div>
          </el-radio-group>
        </CounselingFormItem>
        <CounselingFormItem
          v-if="sexualOrientationIsOther"
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.california_app]"
        >
          <template slot="label">
            Please specify your yourself to be.
          </template>
          <el-input
            :value="counselingProfile.other_sexual_orientation_descr"
            @input="(other_sexual_orientation_descr) => setCounselingProfile({ other_sexual_orientation_descr })"
          />
        </CounselingFormItem>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
        >
          <template slot="label">
            Date of birth
          </template>
          <el-date-picker
            style="width: 100%"
            value-format="yyyy-MM-dd"
            type="date"
            :value="counselingProfile.birth_date"
            @input="(birth_date) => setCounselingProfile({ birth_date })"
          />
        </CounselingFormItem>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[
            thirdAppTypes.common_app,
            thirdAppTypes.coalition_app,
            thirdAppTypes.california_app
          ]"
        >
          <template slot="label">
            Do you have a US Social Security Number?
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.has_ssn"
            @input="(has_ssn) => setCounselingProfile({ has_ssn })"
          >
            <div><el-radio :label="true">Yes</el-radio></div>
            <div><el-radio :label="false">No</el-radio></div>
          </el-radio-group>
        </CounselingFormItem>
        <CounselingFormItem
          v-if="counselingProfile.has_ssn"
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
        >
          <template slot="promptText">
            Providing your Social Security number enables colleges and universities to award federal financial aid and also to provide your family with a 1098T, which ensures that your family receives tax benefits associated with paying college expenses for dependents. Your Social Security number is not required to complete your college applications.
          </template>
          <template slot="label">
            Social Security Number?
          </template>
          <el-input
            :value="counselingProfile.ssn_number"
            @input="(ssn_number) => setCounselingProfile({ ssn_number })"
          />
          <div class="formatPromptText">Please use SSN format: ***-**-****</div>
        </CounselingFormItem>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            United States Armed Forces Status
          </template>
          <el-select
            style="width: 100%"
            :value="counselingProfile.us_armed_forces_status"
            @change="(us_armed_forces_status) => setCounselingProfile({ us_armed_forces_status })"
          >
            <el-option
              v-for="unitedStatesArmedForcesStatus in unitedStatesArmedForcesStatuses"
              :key="unitedStatesArmedForcesStatus.value"
              :label="unitedStatesArmedForcesStatus.label"
              :value="unitedStatesArmedForcesStatus.value"
            />
          </el-select>
        </CounselingFormItem>
        <template v-if="usArmedForcesStatusIsServingOrServed">
          <CounselingFormItem
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          >
            <template slot="label">
              Anticipated status at the time you enroll
            </template>
            <el-radio-group
              style="width: 100%"
              :value="counselingProfile.us_military_anticipated_status"
              @input="(us_military_anticipated_status) => setCounselingProfile({ us_military_anticipated_status })"
            >
              <div>
                <el-radio :label="0">On active duty U.S. military</el-radio>
              </div>
              <div>
                <el-radio :label="1">Veteran of U.S. Armed Forces</el-radio>
              </div>
              <div>
                <el-radio :label="2">U.S. Reserves or National Guard</el-radio>
              </div>
            </el-radio-group>
          </CounselingFormItem>
          <CounselingFormItem
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          >
            <template slot="label">
              Military experiences
            </template>
          </CounselingFormItem>
          <CounselingFormItem
            hiddenTag
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          >
            <div
              :key="displayMilitaryExperienceCountIndex"
              v-for="(displayMilitaryExperienceCountNum, displayMilitaryExperienceCountIndex) of displayMilitaryExperienceCount"
            >
              <el-card class="card">
                <div slot="header" class="cardHeader">
                  <span>{{
                    `Military experience ${displayMilitaryExperienceCountNum}`
                  }}</span>
                  <el-tooltip
                    content="Remove"
                    placement="top"
                    popper-class="tooltipColor"
                    v-if="militaryExperienceOrderIsLast(displayMilitaryExperienceCountNum)"
                  >
                    <el-button
                      type="danger"
                      icon="el-icon-close"
                      circle
                      @click="removeLastMilitaryExperience"
                    />
                  </el-tooltip>
                  <el-tooltip
                    content="Move Down"
                    placement="top"
                    popper-class="tooltipColor"
                    v-if="militaryExperienceCountIsMultiple && !militaryExperienceOrderIsLast(displayMilitaryExperienceCountNum)"
                  >
                    <el-button
                      type="primary"
                      icon="el-icon-bottom"
                      circle
                      @click="() => moveMilitaryExperienceOrderDown(displayMilitaryExperienceCountIndex)"
                    />
                  </el-tooltip>
                  <el-tooltip
                    content="Move Up"
                    placement="top"
                    popper-class="tooltipColor"
                    v-if="militaryExperienceCountIsMultiple && !militaryExperienceOrderIsFirst(displayMilitaryExperienceCountNum)"
                  >
                    <el-button
                      type="primary"
                      icon="el-icon-top"
                      circle
                      @click="() => moveMilitaryExperienceOrderUp(displayMilitaryExperienceCountIndex)"
                    />
                  </el-tooltip>
                </div>
                <el-form-item
                  required
                  label="Branch"
                >
                  <el-radio-group
                    style="width: 100%"
                    :value="counselingProfile.us_military_experiences[displayMilitaryExperienceCountIndex].branch"
                    @input="branch => setMilitaryExperience(displayMilitaryExperienceCountIndex, { branch })"
                  >
                    <div
                      :key="usMilitaryExperiencesBranch.value"
                      v-for="usMilitaryExperiencesBranch in usMilitaryExperiencesBranchs"
                    >
                      <el-radio :label="usMilitaryExperiencesBranch.value">
                        {{ usMilitaryExperiencesBranch.label }}
                      </el-radio>
                    </div>
                  </el-radio-group>
                </el-form-item>
                <el-form-item required label="Service start date">
                  <el-date-picker
                    style="width: 100%"
                    value-format="MM/yyyy"
                    type="date"
                    :value="counselingProfile.us_military_experiences[displayMilitaryExperienceCountIndex].from_month"
                    @input="from_month => setMilitaryExperience(displayMilitaryExperienceCountIndex, { from_month })"
                  />
                </el-form-item>
                <el-form-item required label="Actual or Projected Service end date">
                  <el-date-picker
                    style="width: 100%"
                    value-format="MM/yyyy"
                    type="date"
                    :value="counselingProfile.us_military_experiences[displayMilitaryExperienceCountIndex].to_month"
                    @input="to_month => setMilitaryExperience(displayMilitaryExperienceCountIndex, { to_month })"
                  />
                </el-form-item>
              </el-card>
            </div>
            <el-form-item v-if="!displayMilitaryExperienceCountAlreadyMax" align="right">
              {{ `(${MAX_MILITARY_EXPERIENCE_COUNT - displayMilitaryExperienceCount} of ${MAX_MILITARY_EXPERIENCE_COUNT} available)` }}
              <el-button type="primary" @click="addDisplayMilitaryExperienceCount">
                Add another military experience
              </el-button>
            </el-form-item>
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          >
            <template slot="label">
              Did you ever receive an Other Than Honorable Discharge, Bad Conduct Discharge, or Dishonorable Discharge?
            </template>
            <el-radio-group
              style="width: 100%"
              :value="counselingProfile.ever_received_discharge"
              @input="(ever_received_discharge) => setCounselingProfile({ ever_received_discharge })"
            >
              <div>
                <el-radio :label="0">Yes</el-radio>
              </div>
              <div>
                <el-radio :label="1">No</el-radio>
              </div>
            </el-radio-group>
          </CounselingFormItem>
          <template v-if="counselingProfile.ever_received_discharge === 0">
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app]"
            >
              <template slot="label">
                Please describe the circumstances and details of your U.S. Armed Forces discharge.
              </template>
              <div class="ckeditor">
                <CounselingEditor
                  height="200"
                  :value="counselingProfile.discharge_descr"
                  @input="discharge_descr => setCounselingProfile({ discharge_descr })"
                />
              </div>
            </CounselingFormItem>
          </template>
        </template>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            What is your current enrollment status?
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.current_enrollment_status"
            @input="(current_enrollment_status) => setCounselingProfile({ current_enrollment_status })"
          >
            <div :key="currentEnrollmentStatus.value" v-for="currentEnrollmentStatus in currentEnrollmentStatuses">
              <el-radio :label="currentEnrollmentStatus.value">
                {{ currentEnrollmentStatus.label }}
              </el-radio>
            </div>
          </el-radio-group>
        </CounselingFormItem>
        <CounselingFormItem
          v-if="notCurrentlyEnrolledInSchool"
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            Have you graduated from high school or received high school equivalency?
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.has_graduated_from_high_school"
            @input="(has_graduated_from_high_school) => setCounselingProfile({ has_graduated_from_high_school })"
          >
            <div><el-radio :label="true">Yes</el-radio></div>
            <div><el-radio :label="false">No</el-radio></div>
          </el-radio-group>
        </CounselingFormItem>
        <CounselingFormItem
          v-if="(currentlyEnrolledInHighSchool || (notCurrentlyEnrolledInSchool && !counselingProfile.has_graduated_from_high_school))"
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            When will you graduate from high school or receive high school equivalency?
          </template>
          <el-date-picker
            style="width: 100%"
            value-format="dd/MM/yyyy"
            type="date"
            :value="counselingProfile.high_school_graduation_month"
            @input="(high_school_graduation_month) => setCounselingProfile({ high_school_graduation_month })"
          />
        </CounselingFormItem>
        <CounselingFormItem
          v-if="(currentlyEnrolledInCollege || (notCurrentlyEnrolledInSchool && counselingProfile.has_graduated_from_high_school))"
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            When did you graduate from high school or receive high school equivalency?
          </template>
          <el-date-picker
            style="width: 100%"
            value-format="dd/MM/yyyy"
            type="date"
            :value="counselingProfile.high_school_graduation_month"
            @input="(high_school_graduation_month) => setCounselingProfile({ high_school_graduation_month })"
          />
        </CounselingFormItem>
        <CounselingFormItem
          v-if="notCurrentlyEnrolledInSchool && counselingProfile.has_graduated_from_high_school"
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            Have you taken college courses post-high school graduation?
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.has_taken_college_course"
            @input="(has_taken_college_course) => setCounselingProfile({ has_taken_college_course })"
          >
            <div><el-radio :label="true">Yes</el-radio></div>
            <div><el-radio :label="false">No</el-radio></div>
          </el-radio-group>
        </CounselingFormItem>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            What year do you plan to begin studying at your next college
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.plan_to_begin_studying_year"
            @input="(plan_to_begin_studying_year) => setCounselingProfile({ plan_to_begin_studying_year })"
          >
            <!--顯示近五年-->
            <div :key="addYear" v-for="addYear in 5">
              <el-radio :label="String(previousYear + addYear)">
                {{ String(previousYear + addYear) }}
              </el-radio>
            </div>
          </el-radio-group>
        </CounselingFormItem>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            Do you intend to apply for need-based financial aid?
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.apply_for_need_based_financial_aid"
            @input="(apply_for_need_based_financial_aid) => setCounselingProfile({ apply_for_need_based_financial_aid })"
          >
            <div><el-radio :label="true">Yes</el-radio></div>
            <div><el-radio :label="false">No</el-radio></div>
          </el-radio-group>
        </CounselingFormItem>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
        >
          <template slot="promptText">
            Natural disasters and emergency situations like the COVID-19 pandemic have impacted the lives of many students, both academically and personally. While entirely optional, you may share information here regarding how any of these events have affected you.
          </template>
          <template slot="label">
            Check all of the following that apply to you:
          </template>
          <div style="display: inline-block; width: 100%;">
            <el-checkbox-group
              :value="counselingProfile.disaster_impacts"
              @input="(disaster_impacts) => setDisasterImpacts(disaster_impacts)"
            >
              <div
                :key="disaster.label"
                v-for="disaster in disasters"
              >
                <el-checkbox
                  :disabled="
                    noneApply &&
                    disaster.label !== 'None of these apply to me.' &&
                    disaster.label !== 'I would like to provide additional information about personal and/or academic impacts.'
                  "
                  :label="disaster.value"
                >
                  {{ disaster.label }}
                </el-checkbox>
              </div>
            </el-checkbox-group>
          </div>
        </CounselingFormItem>
        <CounselingFormItem
          v-if="wouldLikeToProvideDisaster"
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            Please provide any additional information you would like to share regarding how these events have affected you. (300 words max) 
          </template>
          <div class="ckeditor">
            <CounselingEditor
              height="200"
              :value="counselingProfile.covid_descr"
              @input="
                covid_descr =>
                  setCounselingProfile({ covid_descr })
              "
            />
          </div>
        </CounselingFormItem>
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
    <el-collapse-item title="Address" name="address">
      <el-form>
        <AddressForm
          :currentApplicationType="currentApplicationType"
          :address="counselingProfile.address"
          @setAddress="setAddress"
        />
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
        >
          <template slot="label">
            Alternate mailing address (e.g. if you attend a boarding school or use a different mailing address)
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.used_mailing_address"
            @input="(used_mailing_address) => setCounselingProfile({ used_mailing_address })"
          >
            <div><el-radio :label="0">No alternate address</el-radio></div>
            <div><el-radio :label="1">Send mail to a temporary or alternate address</el-radio></div>
          </el-radio-group>
        </CounselingFormItem>
        <div v-if="hasOtherMailAddress">
          <AddressForm
            :currentApplicationType="currentApplicationType"
            :address="counselingProfile.mailing_address"
            @setAddress="setMailingAddress"
          />
        </div>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            Is this a temporary address?
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.is_temporary_mailing_address"
            @input="(is_temporary_mailing_address) => setCounselingProfile({ is_temporary_mailing_address })"
          >
            <div><el-radio :label="0">Yes</el-radio></div>
            <div><el-radio :label="1">No</el-radio></div>
          </el-radio-group>
        </CounselingFormItem>
        <div v-if="isTemporaryMailingAddress">
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          >
            <template slot="label">
              From date
            </template>
            <el-date-picker
              style="width: 100%"
              value-format="dd/MM/yyyy"
              type="date"
              :value="counselingProfile.temporary_mailing_address_from_date"
              @input="(temporary_mailing_address_from_date) => setCounselingProfile({ temporary_mailing_address_from_date })"
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          >
            <template slot="label">
              To date
            </template>
            <el-date-picker
              style="width: 100%"
              value-format="dd/MM/yyyy"
              type="date"
              :value="counselingProfile.temporary_mailing_address_to_date"
              @input="(temporary_mailing_address_to_date) => setCounselingProfile({ temporary_mailing_address_to_date })"
            />
          </CounselingFormItem>
        </div>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            State of Residency
          </template>
          <StateOfResidencySelector
            :value="counselingProfile.residency"
            @change="(residency) => setCounselingProfile({ residency })"
          />
        </CounselingFormItem>
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
    <el-collapse-item title="Contact Details" name="contactDetails">
      <el-form>
        <CounselingFormItem
          required
          checkApplications
          :value="counselingProfile.preferred_phone"
          getterProp="phoneTypeOptions"
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
        >
          <template slot="label">
            Preferred phone
          </template>
          <PhoneTypeSelector
            :value="counselingProfile.preferred_phone"
            @change="(preferred_phone) => setCounselingProfile({ preferred_phone })"
          />
        </CounselingFormItem>
        <CounselingFormItem
          required
          checkApplications
          :value="counselingProfile.preferred_phone_number.country_code"
          getterProp="mobileCountryCodeOptions"
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
        >
          <template slot="label">
            Preferred phone number
          </template>
          <div style="display: inline-block; width: 100%;">
            <div style="display: flex">
              <MobileCountryCodeSelector
                :value="counselingProfile.preferred_phone_number.country_code"
                @change="(country_code) => setPreferredPhone({ country_code })"
                style="margin-right: 10px"
              />
              <el-input
                :value="counselingProfile.preferred_phone_number.phone_number"
                @input="(phone_number) => setPreferredPhone({ phone_number })"
              />
            </div>
          </div>
        </CounselingFormItem>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.california_app]"
        >
          <template slot="label">
            Alternate phone
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.used_alternate_phone"
            @input="(used_alternate_phone) => setCounselingProfile({ used_alternate_phone })"
          >
            <div><el-radio :label="0">No other telephone</el-radio></div>
            <div><el-radio :label="1">Home</el-radio></div>
            <div><el-radio :label="2">Mobile</el-radio></div>
          </el-radio-group>
        </CounselingFormItem>
        <CounselingFormItem
          v-if="hasAlternatePhone"
          required
          checkApplications
          :value="counselingProfile.alternate_phone_number.country_code"
          getterProp="mobileCountryCodeOptions"
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.california_app]"
        >
          <template slot="label">
            Alternate phone number
          </template>
          <div style="display: inline-block; width: 100%;">
            <div style="display: flex">
              <MobileCountryCodeSelector
                :value="counselingProfile.alternate_phone_number.country_code"
                @change="(country_code) => setAlternatePhone({ country_code })"
                style="margin-right: 10px"
              />
              <el-input
                :value="counselingProfile.alternate_phone_number.phone_number"
                @input="(phone_number) => setAlternatePhone({ phone_number })"
              />
            </div>
          </div>
        </CounselingFormItem>
        <CounselingFormItem
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.california_app]"
        >
          <el-checkbox
            :value="counselingProfile.is_alt_text_auth"
            @input="(is_alt_text_auth) => setCounselingProfile({ is_alt_text_auth })"
          >
            I authorize text messages (for example, reminders about application deadlines) to be sent to the above phone number and accept responsibility for any charges incurred.
          </el-checkbox>
        </CounselingFormItem>
        <CounselingFormItem
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.california_app]"
        >
          <template slot="promptText">
            If you check this option, you may receive information about admissions tips, updates and reminders from UC campuses (even ones you haven't applied to) and outreach programs. They may contact you before and/or after you've submitted the application.
          </template>
          <template slot="label">
            Can we share your contact information with UC campuses and programs?
          </template>
          <el-checkbox
            :value="counselingProfile.can_release_contact_info"
            @input="(can_release_contact_info) => setCounselingProfile({ can_release_contact_info })"
          >
            I authorize the University of California to share my contact information.
          </el-checkbox>
        </CounselingFormItem>
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
    <el-collapse-item
      v-if="[thirdAppTypes.common_app, thirdAppTypes.california_app].includes(thirdAppTypes[currentApplicationType])"
      title="Geography"
      name="geography"
    >
      <el-form>
        <CounselingFormItem
          checkApplications
          :value="counselingProfile.birth_country"
          getterProp="countryOptions"
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.california_app]"
        >
          <template slot="label">
            Birth country/region/territory
          </template>
          <CountrySelector
            style="width: 100%"
            :value="counselingProfile.birth_country"
            @change="(birth_country) => setCounselingProfile({ birth_country })"
          />
        </CounselingFormItem>
        <CounselingFormItem
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.california_app]"
        >
          <template slot="label">
            City of birth
          </template>
          <el-input
            :value="counselingProfile.birth_city"
            @input="(birth_city) => setCounselingProfile({ birth_city })"
          />
        </CounselingFormItem>
        <CounselingFormItem
          v-if="birthCountryHasStateOrProvince"
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            State/Province of birth
          </template>
          <StateSelector
            :value="counselingProfile.birth_state"
            @change="({ code }) => setCounselingProfile({ birth_state: code })"
          />
        </CounselingFormItem>
        <CounselingFormItem
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            Number of years you have lived in the United States
          </template>
          <el-select
            style="width: 100%"
            :value="counselingProfile.lived_in_us_yr"
            @change="(lived_in_us_yr) => setCounselingProfile({ lived_in_us_yr })"
          >
            <el-option
              v-for="liveNumber in liveInOfNumbers"
              :key="liveNumber.value"
              :label="liveNumber.label"
              :value="liveNumber.value"
            />
          </el-select>
        </CounselingFormItem>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            Number of years you have lived outside the United States
          </template>
          <el-select
            style="width: 100%"
            :value="counselingProfile.lived_outside_us_yr"
            @change="(lived_outside_us_yr) => setCounselingProfile({ lived_outside_us_yr })"
          >
            <el-option
              v-for="liveNumber in liveInOfNumbers"
              :key="liveNumber.value"
              :label="liveNumber.label"
              :value="liveNumber.value"
            />
          </el-select>
        </CounselingFormItem>
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
    <el-collapse-item title="Demographic Information" name="demographicInformation">
      <el-form>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            Religious preference
          </template>
          <ReligiousSelector
            :value="counselingProfile.religion"
            @change="(religion) => setCounselingProfile({ religion })"
            style="width: 100%"
          />
        </CounselingFormItem>
        <CounselingFormItem
          v-if="religionIsOther"
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            Specify other religion or denomination
          </template>
          <el-input
            :value="counselingProfile.religion_descr"
            @input="(religion_descr) => setCounselingProfile({ religion_descr })"
          />
        </CounselingFormItem>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
        >
          <template slot="label">
            Are you Hispanic or Latino?
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.is_hispanic_or_latino"
            @input="(is_hispanic_or_latino) => setCounselingProfile({ is_hispanic_or_latino })"
          >
            <div>
              <el-radio :label="0">Yes</el-radio>
            </div>
            <div>
              <el-radio :label="1">No</el-radio>
            </div>
          </el-radio-group>
        </CounselingFormItem>
        <template v-if="counselingProfile.is_hispanic_or_latino === 0">
          <CounselingFormItem
            checkApplications
            :value="counselingProfile.hispanic_or_latino_identities"
            getterProp="hispanicsOptions"
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              Choose the Option(s) That Best Describe(s) Your Hispanic or Latino Background
            </template>
            <div style="display: inline-block; width: 100%;">
              <el-checkbox-group
                :value="counselingProfile.hispanic_or_latino_identities"
                @input="(hispanic_or_latino_identities) => setCounselingProfile({ hispanic_or_latino_identities })"
              >
                <div
                  :key="hispanic_background.id"
                  v-for="hispanic_background in hispanicBackgrounds"
                >
                  <el-checkbox :label="hispanic_background.id">
                    {{ hispanic_background.title }}
                  </el-checkbox>
                </div>
              </el-checkbox-group>
            </div>
          </CounselingFormItem>
          <CounselingFormItem
            v-if="needToSpecifyOtherHispanicBackground"
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              Please Specify Your Other Background
            </template>
            <el-input
              :value="counselingProfile.hispanic_or_latino_descr"
              @input="(hispanic_or_latino_descr) => setCounselingProfile({ hispanic_or_latino_descr })"
            />
          </CounselingFormItem>
        </template>
        <CounselingFormItem
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
        >
          <template slot="label">
            Select one or more of the following
          </template>
          <div style="display: inline-block; width: 100%;">
            <el-checkbox-group
              :value="counselingProfile.other_identities"
              @input="(other_identities) => setCounselingProfile({ other_identities })"
            >
              <div :key="race.label" v-for="race in races">
                <el-checkbox :label="race.value">
                  {{ race.label }}
                </el-checkbox>
              </div>
            </el-checkbox-group>
          </div>
        </CounselingFormItem>
        <template v-if="raceIncludeAmerican">
          <CounselingFormItem
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
          >
            <template slot="label">
              Please tell us about your tribal identity or affiliation
            </template>
            <el-input
              :value="counselingProfile.tribal_identity"
              @input="(tribal_identity) => setCounselingProfile({ tribal_identity })"
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
          >
            <template slot="label">
              Are you enrolled in a federally recognized tribe?
            </template>
            <el-radio-group
              style="width: 100%"
              :value="counselingProfile.is_federally_recognized_tribe"
              @input="(is_federally_recognized_tribe) => setCounselingProfile({ is_federally_recognized_tribe })"
            >
              <div>
                <el-radio :label="0">Yes</el-radio>
              </div>
              <div>
                <el-radio :label="1">No</el-radio>
              </div>
            </el-radio-group>
          </CounselingFormItem>
          <template v-if="counselingProfile.is_federally_recognized_tribe === 0">
            <CounselingFormItem
              checkApplications
              :value="counselingProfile.amerind_background[0]"
              getterProp="tribalAffiliationOptions"
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.californai_app]"
            >
              <template slot="promptText">
                Start typing to search federally-recognized tribes. If your tribe is not listed, select 'Other'.
              </template>
              <template slot="label">
                Please select your tribal affiliation.
              </template>
              <TribalAffiliationSelector
                :value="counselingProfile.amerind_background[0]"
                @change="(amerind_background) => setCounselingProfile({ amerind_background: [amerind_background] })"
                style="width: 100%"
              />
            </CounselingFormItem>
            <CounselingFormItem
              v-if="amerindBackgroundIncludeOther"
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
            >
              <template slot="label">
                Please specify your tribal affiliation.
              </template>
              <el-input
                :value="counselingProfile.amerind_addl"
                @input="(amerind_addl) => setCounselingProfile({ amerind_addl })"
              />
            </CounselingFormItem>
            <CounselingFormItem
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
            >
              <template slot="label">
                Please Enter Your Tribal Enrollment Number
              </template>
              <el-input
                :value="counselingProfile.tribal_enrollment_number"
                @input="(tribal_enrollment_number) => setCounselingProfile({ tribal_enrollment_number })"
              />
            </CounselingFormItem>
          </template>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.california_app]"
          >
            <template slot="label">
              Please select your primary American Indian / Alaska Native affiliation
            </template>
            <el-radio-group
              style="width: 100%"
              :value="counselingProfile.ucai_tribe_status"
              @input="(ucai_tribe_status) => setCounselingProfile({ ucai_tribe_status })"
            >
              <div
                :key="ucaiTribeStatus.value"
                v-for="ucaiTribeStatus in ucaiTribeStatuses"
              >
                <el-radio :label="ucaiTribeStatus.value">
                  {{ ucaiTribeStatus.label }}
                </el-radio>
              </div>
            </el-radio-group>
          </CounselingFormItem>
          <CounselingFormItem
            v-if="ucaiTribeStatusIsLineal"
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.california_app]"
          >
            <div style="display: inline-block; width: 100%;">
              <el-checkbox
                true-label="Y"
                false-label="N"
                :value="counselingProfile.is_ucai_want_support_info"
                @input="
                  is_ucai_want_support_info =>
                    setCounselingProfile({ is_ucai_want_support_info })
                "
              >
                I want to receive information about American Indian support services at the University of California.  If you want to get more information online
                <a href="https://apply.universityofcalifornia.edu/docs/aicra.pdf">click here</a>.
                [<a href="https://apply.universityofcalifornia.edu/docs/aicra.pdf">pdf</a>]
              </el-checkbox>
            </div>
          </CounselingFormItem>
        </template>
        <template v-if="raceIncludeBlack">
          <CounselingFormItem
            checkApplications
            :value="counselingProfile.african_american_backgrounds"
            getterProp="blackBackgroundOptions"
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              Choose the Option(s) That Best Describe(s) Your Black or African American Background
            </template>
            <div style="display: inline-block; width: 100%;">
              <el-checkbox-group
                :value="counselingProfile.african_american_backgrounds"
                @input="(african_american_backgrounds) => setCounselingProfile({ african_american_backgrounds })"
              >
                <div :key="blackBackground.id" v-for="blackBackground in blackBackgrounds">
                  <el-checkbox :label="blackBackground.id">
                    {{ blackBackground.title }}
                  </el-checkbox>
                </div>
              </el-checkbox-group>
            </div>
          </CounselingFormItem>
          <CounselingFormItem
            v-if="blackBackgroundIncludeOther"
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
          >
            <template slot="label">
              Please Specify Your Other Background
            </template>
            <el-input
              :value="counselingProfile.african_american_descr"
              @input="(african_american_descr) => setCounselingProfile({ african_american_descr })"
            />
          </CounselingFormItem>
        </template>
        <template v-if="raceIncludeAsian">
          <CounselingFormItem
            checkApplications
            :value="counselingProfile.asian_backgrounds"
            getterProp="asianBackgroundOptions"
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              Choose the Option(s) That Best Describe(s) Your Asian Background
            </template>
            <div style="display: inline-block; width: 100%;">
              <el-checkbox-group
                :value="counselingProfile.asian_backgrounds"
                @input="(asian_backgrounds) => setCounselingProfile({ asian_backgrounds })"
              >
                <div :key="asianBackground.id" v-for="asianBackground in asianBackgrounds">
                  <el-checkbox :label="asianBackground.id">
                    {{ asianBackground.title }}
                  </el-checkbox>
                </div>
              </el-checkbox-group>
            </div>
          </CounselingFormItem>
          <CounselingFormItem
            v-if="asianBackgroundIsEast || asianBackgroundIsSouth || asianBackgroundIsSoutheast"
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              Please Specify Your Other Background
            </template>
            <el-input
              :value="counselingProfile.asian_descr"
              @input="(asian_descr) => setCounselingProfile({ asian_descr })"
            />
          </CounselingFormItem>
          <CounselingFormItem
            v-if="asianBackgroundIsEast"
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          >
            <template slot="label">
              Specify other East Asian background
            </template>
            <el-input
              :value="counselingProfile.east_asian_descr"
              @input="(east_asian_descr) => setCounselingProfile({ east_asian_descr })"
            />
          </CounselingFormItem>
          <CounselingFormItem
            v-if="asianBackgroundIsSouth"
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          >
            <template slot="label">
              Specify other South Asian background
            </template>
            <el-input
              :value="counselingProfile.south_asian_descr"
              @input="(south_asian_descr) => setCounselingProfile({ south_asian_descr })"
            />
          </CounselingFormItem>
          <CounselingFormItem
            v-if="asianBackgroundIsSoutheast"
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          >
            <template slot="label">
              Specify other Southeast Asian background
            </template>
            <el-input
              :value="counselingProfile.southeast_asian_descr"
              @input="(southeast_asian_descr) => setCounselingProfile({ southeast_asian_descr })"
            />
          </CounselingFormItem>
        </template>
        <template v-if="raceIncludeIsland">
          <CounselingFormItem
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              Choose the Option(s) That Best Describe(s) Your Native Hawaiian or Other Pacific Islander Background
            </template>
            <div style="display: inline-block; width: 100%;">
              <el-checkbox-group
                :value="counselingProfile.native_hawaiian_or_other_pacific_islander_backgrounds"
                @input="(native_hawaiian_or_other_pacific_islander_backgrounds) => setCounselingProfile({ native_hawaiian_or_other_pacific_islander_backgrounds })"
              >
                <div :key="islandBackground.label" v-for="islandBackground in islandBackgrounds">
                  <el-checkbox :label="islandBackground.value">
                    {{ islandBackground.label }}
                  </el-checkbox>
                </div>
              </el-checkbox-group>
            </div>
          </CounselingFormItem>
          <CounselingFormItem
            v-if="islandBackgroundIncludeOther"
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              Please Specify Your Other Background
            </template>
            <el-input
              :value="counselingProfile.native_hawaiian_or_other_pacific_islander_descr"
              @input="(native_hawaiian_or_other_pacific_islander_descr) => setCounselingProfile({ native_hawaiian_or_other_pacific_islander_descr })"
            />
          </CounselingFormItem>
        </template>
        <template v-if="raceIncludeWhite">
          <CounselingFormItem
            checkApplications
            :value="counselingProfile.white_backgrounds"
            getterProp="whiteBackgroundOptions"
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              Choose the Option(s) That Best Describe(s) Your White Background
            </template>
            <div style="display: inline-block; width: 100%;">
              <el-checkbox-group
                :value="counselingProfile.white_backgrounds"
                @input="(white_backgrounds) => setCounselingProfile({ white_backgrounds })"
              >
                <div :key="whiteBackground.id" v-for="whiteBackground in whiteBackgrounds">
                  <el-checkbox :label="whiteBackground.id">
                    {{ whiteBackground.title }}
                  </el-checkbox>
                </div>
              </el-checkbox-group>
            </div>
          </CounselingFormItem>
          <CounselingFormItem
            v-if="whiteBackgroundIncludeOther"
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              Please Specify Your Other Background
            </template>
            <el-input
              :value="counselingProfile.white_descr"
              @input="(white_descr) => setCounselingProfile({ white_descr })"
            />
          </CounselingFormItem>
        </template>
        <CounselingFormItem
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.california_app]"
        >
          <template slot="label">
            Southwest Asian and North African
          </template>
          <el-select
            style="width: 100%"
            multiple
            :value="counselingProfile.southwest_asian_and_north_africans_background"
            @change="southwest_asian_and_north_africans_background => setCounselingProfile({ southwest_asian_and_north_africans_background })"
          >
            <el-option
              v-for="southwestAsianAndNorthAfricanBackground in southwestAsianAndNorthAfricanBackgrounds"
              :key="southwestAsianAndNorthAfricanBackground.value"
              :value="southwestAsianAndNorthAfricanBackground.value"
              :label="southwestAsianAndNorthAfricanBackground.label"
            />
          </el-select>
        </CounselingFormItem>
        <CounselingFormItem
          v-if="southwestAsianAndNorthAfricanBackgroundIncludeOtherSouthwest"
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.california_app]"
        >
          <template slot="label">
            Please Specify Your Other Southwest Asian
          </template>
          <el-input
            :value="counselingProfile.other_southwest_asian_descr"
            @input="(other_southwest_asian_descr) => setCounselingProfile({ other_southwest_asian_descr })"
          />
        </CounselingFormItem>
        <CounselingFormItem
          v-if="southwestAsianAndNorthAfricanBackgroundIncludeOtherNorth"
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.california_app]"
        >
          <template slot="label">
            Please Specify Your Other North African
          </template>
          <el-input
            :value="counselingProfile.other_north_asian_descr"
            @input="(other_north_asian_descr) => setCounselingProfile({ other_north_asian_descr })"
          />
        </CounselingFormItem>
      </el-form>
    </el-collapse-item>
    <el-collapse-item
      v-if="[thirdAppTypes.california_app].includes(thirdAppTypes[currentApplicationType])"
      title="Your background"
      name="yourBackground"
    >
      <el-form>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.california_app]"
        >
          <template slot="label">
            What language did you learn to speak first?
          </template>
          <el-select
            style="width: 100%"
            :value="counselingProfile.lang_first_code"
            @change="(lang_first_code) => setCounselingProfile({ lang_first_code })"
          >
            <el-option value="EN" label="English only" />
            <el-option value="E2" label="English and another language" />
            <el-option value="OT" label="Other language" />
          </el-select>
        </CounselingFormItem>
        <CounselingFormItem
          v-if="firstLangIsNotOnlyEnglish"
          required
          checkApplications
          :value="counselingProfile.lang_second_code"
          getterProp="languageOptions"
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.california_app]"
        >
          <template slot="label">
            What was the other language?
          </template>
          <LanguageSelector
            :value="counselingProfile.lang_second_code"
            @change="(lang_second_code) => setCounselingProfile({ lang_second_code })"
            style="width: 100%"
          />
        </CounselingFormItem>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.california_app]"
        >
          <template slot="label">
            Check all that apply to you
          </template>
          <div style="display: inline-block; width: 100%;">
            <el-checkbox-group
              :value="counselingProfile.backgrounds"
              @input="(backgrounds) => setBackgrounds(backgrounds)"
            >
              <div
                :key="backgroundStatus.value"
                v-for="backgroundStatus in backgroundStatuses"
              >
                <el-checkbox
                  :disabled="
                    noneBackgroundStatus && backgroundStatus.value !== 5
                  "
                  :label="backgroundStatus.value"
                >
                  {{ backgroundStatus.label }}
                </el-checkbox>
              </div>
            </el-checkbox-group>
          </div>
        </CounselingFormItem>
        <template v-if="willBeMemberOfTheUsMilitary">
          <CounselingFormItem
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.california_app]"
          >
            <el-radio-group
              style="width: 100%"
              :value="counselingProfile.military_service"
              @input="(military_service) => setCounselingProfile({ military_service })"
            >
              <div :key="usMilitary.value" v-for="usMilitary in usMilitaries">
                <el-radio :label="usMilitary.value">
                  {{ usMilitary.label }}
                </el-radio>
              </div>
            </el-radio-group>
          </CounselingFormItem>
          <CounselingFormItem
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.california_app]"
          >
            <el-checkbox
              true-label="Y"
              false-label="N"
              :value="counselingProfile.has_military_transcript"
              @input="(has_military_transcript) => setCounselingProfile({ has_military_transcript })"
            >
              Check if you plan to submit an official U.S. military transcript (AARTS, SMART, JST or CGI)
            </el-checkbox>
          </CounselingFormItem>
        </template>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.california_app]"
        >
          <template slot="label">
            Are you a dependent of a U.S. military veteran or service member?
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.is_military_dependent"
            @input="(is_military_dependent) => setCounselingProfile({ is_military_dependent })"
          >
            <div><el-radio label="Y">Yes</el-radio></div>
            <div><el-radio label="N">No</el-radio></div>
          </el-radio-group>
        </CounselingFormItem>
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
    <el-collapse-item
      title="Language"
      name="language"
      v-if="[thirdAppTypes.common_app, thirdAppTypes.coalition_app].includes(thirdAppTypes[currentApplicationType])"
    >
      <el-form>
        <CounselingFormAlert
          :currentApplication="currentApplicationType"
          :applicationsNeedToVisible="[thirdAppTypes.coalition_app]"
        >
          <template slot="title">
            {{ $t('counselingProfile.coalitionAppJustCanFillThreeLanguages') }}
          </template>
        </CounselingFormAlert>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            Number of languages you are proficient in
          </template>
          <div style="display: inline-block; width: 100%;">
            <el-radio-group
              style="width: 100%"
              :value="counselingProfile.languages_length"
              @input="(languages_length) => setCounselingProfile({ languages_length })"
            >
              <div
                :key="index + 1"
                v-for="(num, index) in 5"
              >
                <el-radio :label="index + 1">{{ index + 1 }}</el-radio>
              </div>
            </el-radio-group>
          </div>
        </CounselingFormItem>
        <div
          :key="index"
          v-for="(value, index) in counselingProfile.languages_length"
        >
          <CounselingFormItem
            required
            checkApplications
            :value="counselingProfile.languages[index].lang"
            getterProp="languageOptions"
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
          >
            <template slot="label">
              Select language
            </template>
            <LanguageSelector
              :value="counselingProfile.languages[index].lang"
              @change="(lang) => setLanguage(index, { lang })"
              style="width: 100%"
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          >
            <template slot="label">
              Language proficiency
            </template>
            <div style="display: inline-block; width: 100%;">
              <el-checkbox-group
                :value="counselingProfile.languages[index].proficiency"
                @input="(proficiency) => setLanguage(index, { proficiency })"
              >
                <div
                  :key="languageProficiency.value"
                  v-for="languageProficiency in languageProficiencys"
                >
                  <el-checkbox :label="languageProficiency.value">
                    {{ languageProficiency.label }}
                  </el-checkbox>
                </div>
              </el-checkbox-group>
            </div>
          </CounselingFormItem>
        </div>
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
    <el-collapse-item title="Citizenship" name="citizenship">
      <el-form>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
        >
          <template slot="promptText">
            This is your citizenship status on the date you submit your application
          </template>
          <template slot="label">
            Select your citizenship status
          </template>
          <el-select
            style="width: 100%"
            :value="counselingProfile.citizenship_status"
            @change="(citizenship_status) => setCitizenshipStatus(citizenship_status)"
          >
            <el-option
              v-for="citizenshipStatus in citizenshipStatuses"
              :key="citizenshipStatus.value"
              :label="citizenshipStatus.label"
              :value="citizenshipStatus.value"
            />
          </el-select>
        </CounselingFormItem>
        <CounselingFormItem
          required
          v-if="citizenshipIsUSCitizenOrNational"
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.california_app]"
        >
          <template slot="label">
            Are you an enrolled member of an American Indian or Alaska Native tribe (U.S.)?
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.is_tribal_member"
            @input="(is_tribal_member) => setCounselingProfile({ is_tribal_member })"
          >
            <div><el-radio label="Y">Yes</el-radio></div>
            <div><el-radio label="N">No</el-radio></div>
          </el-radio-group>
        </CounselingFormItem>
        <template v-if="isTribalMember">
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.california_app]"
          >
            <template slot="label">
              Please select the category that best reflects your tribal enrollment. If you are an enrolled member of more than one tribe, select your primary tribal affiliation from the list of recognized tribes.
            </template>
            <el-radio-group
              style="width: 100%"
              :value="counselingProfile.tribal_membership_type"
              @input="(tribal_membership_type) => setCounselingProfile({ tribal_membership_type })"
            >
              <div
                :key="tribalMembershipType.value"
                v-for="tribalMembershipType in tribalMembershipTypes"
              >
                <el-radio :label="tribalMembershipType.value">
                  {{ tribalMembershipType.label }}
                </el-radio>
              </div>
            </el-radio-group>
          </CounselingFormItem>
          <template v-if="counselingProfile.tribal_membership_type === 'FR'">
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.california_app]"
            >
              <template slot="label">
                Select your primary tribal affiliation
              </template>
              <el-input
                :value="counselingProfile.tribal_affiliation_fr"
                @input="(tribal_affiliation_fr) => setCounselingProfile({ tribal_affiliation_fr })"
              />
            </CounselingFormItem>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.california_app]"
            >
              <template slot="promptText">
                Your enrollment in an American Indian or Alaska Native tribe may be considered during the comprehensive review of your UC application for admission.
              </template>
              <template slot="label">
                Please provide your tribal citizenship or enrollment number.
              </template>
              <el-input
                :value="counselingProfile.tribal_enrollment_number"
                @input="(tribal_enrollment_number) => setCounselingProfile({ tribal_enrollment_number })"
              />
            </CounselingFormItem>
            <CounselingFormItem
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.california_app]"
            >
              <template slot="promptText">
                concerning my UC application for admission. I understand that this information is subject to verification.
              </template>
              <el-checkbox
                :value="counselingProfile.is_agree_tribe_use_verification"
                @input="(is_agree_tribe_use_verification) => setCounselingProfile({ is_agree_tribe_use_verification })"
              >
                I consent to the use of my tribal citizenship and enrollment status for purposes
              </el-checkbox>
            </CounselingFormItem>
          </template>
        </template>
        <template v-if="counselingProfile.tribal_membership_type === 'SR'">
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.california_app]"
          >
            <template slot="label">
              Select your primary tribal affiliation
            </template>
            <el-input
              :value="counselingProfile.tribal_affiliation_sr"
              @input="(tribal_affiliation_sr) => setCounselingProfile({ tribal_affiliation_sr })"
            />
          </CounselingFormItem>
        </template>
        <template v-if="counselingProfile.tribal_membership_type === 'CN'">
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.california_app]"
          >
            <template slot="label">
              Select your primary tribal affiliation
            </template>
            <el-input
              :value="counselingProfile.tribal_affiliation_cn"
              @input="(tribal_affiliation_cn) => setCounselingProfile({ tribal_affiliation_cn })"
            />
          </CounselingFormItem>
        </template>
        <CounselingFormItem
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            Social Security Number, required if applying for financial aid via FAFSA
          </template>
          <el-input
            :value="counselingProfile.ssn_number"
            @input="(ssn_number) => setCounselingProfile({ ssn_number })"
          />
        </CounselingFormItem>
        <CounselingFormItem
          v-if="!citizenshipIsUSCitizenOrNational"
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            Green Card Upload
          </template>
          <el-upload
            :action="imageAction"
            :headers="token"
            :data="{ type: 'counselingProfile' }"
            :on-success="handleUploadSuccess"
            :on-remove="handleUploadRemove"
            :file-list="fileList"
            name="document"
          >
            <el-button size="small" type="primary">
              {{ $t("teacherDetail.upload") }}
            </el-button>
          </el-upload>
          <div v-if="counselingProfile.green_card_file">
            Uploaded photo:
            <div
              class="greenCard"
              :style="`background-image: url(${counselingProfile.green_card_file})`"
            />
          </div>
        </CounselingFormItem>
        <div v-if="citizenshipIsUSPermanentResidentOrRefugee">
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
          >
            <template slot="label">
              Alien Registration Number
            </template>
            <el-input
              :value="counselingProfile.arn_number"
              @input="(arn_number) => setCounselingProfile({ arn_number })"
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
          >
            <template slot="label">
              Alien Registration Number Expiration Date
            </template>
            <el-date-picker
              style="width: 100%"
              value-format="yyyy-MM-dd"
              type="date"
              :value="counselingProfile.arn_expiration_date"
              @input="(arn_expiration_date) => setCounselingProfile({ arn_expiration_date })"
            />
          </CounselingFormItem>
        </div>
        <div v-if="counselingProfile.citizenship_status && !citizenshipIsUSCitizenOrNational">
          <CounselingFormAlert
            :currentApplication="currentApplicationType"
            :applicationsNeedToVisible="[thirdAppTypes.coalition_app]"
          >
            <template slot="title">
              {{ $t('counselingProfile.coalitionAppJustCanFillTwoCitizenship') }}
            </template>
          </CounselingFormAlert>
          <CounselingFormItem
            required
            checkApplications
            :value="counselingProfile.list_citizenship"
            getterProp="citizenshipOptions"
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
          >
            <template slot="label">
              List citizenship(s)
            </template>
            <CitizenshipSelector
              style="width: 100%"
              :value="counselingProfile.list_citizenship"
              @change="(list_citizenship) => setCounselingProfile({ list_citizenship })"
            />
          </CounselingFormItem>
        </div>
        <div v-if="isCitizenshipNonUs">
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
          >
            <template slot="label">
              Do you currently hold a valid U.S. Visa?
            </template>
            <el-radio-group
              style="width: 100%"
              :value="counselingProfile.hold_us_visa"
              @input="(hold_us_visa) => setCounselingProfile({ hold_us_visa })"
            >
              <div><el-radio :label="0">Yes</el-radio></div>
              <div><el-radio :label="1">No</el-radio></div>
            </el-radio-group>
          </CounselingFormItem>
          <div v-if="isHoldValidVisa">
            <CounselingFormItem
              required
              checkApplications
              :value="counselingProfile.held_visa"
              getterProp="visaTypeOptions"
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
            >
              <template slot="label">
                Currently held United States visa
              </template>
              <el-select
                style="width: 100%"
                :value="counselingProfile.held_visa"
                @change="(held_visa) => setCounselingProfile({ held_visa })"
              >
                <el-option
                  v-for="visaType in visaTypes"
                  :key="visaType.id"
                  :label="visaType.title"
                  :value="visaType.id"
                />
              </el-select>
            </CounselingFormItem>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.common_app]"
            >
              <template slot="label">
                Visa number
              </template>
              <el-input
                :value="counselingProfile.visa_number"
                @input="(visa_number) => setCounselingProfile({ visa_number })"
              />
            </CounselingFormItem>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
            >
              <template slot="label">
                Date issued
              </template>
              <el-date-picker
                style="width: 100%"
                value-format="yyyy-MM-dd"
                type="date"
                :value="counselingProfile.visa_date_issued"
                @input="(visa_date_issued) => setCounselingProfile({ visa_date_issued })"
              />
            </CounselingFormItem>
          </div>
          <div v-else>
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
            >
              <template slot="label">
                Have You Been Approved to Receive DACA Status?
              </template>
              <el-radio-group
                style="width: 100%"
                :value="counselingProfile.daca_approved"
                @input="(daca_approved) => setCounselingProfile({ daca_approved })"
              >
                <div><el-radio :label="true">Yes</el-radio></div>
                <div><el-radio :label="false">No</el-radio></div>
              </el-radio-group>
            </CounselingFormItem>
            <CounselingFormItem
              required
              v-if="counselingProfile.daca_approved"
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
            >
              <template slot="label">
                Expiration Date of DACA Status
              </template>
              <el-date-picker
                style="width: 100%"
                value-format="yyyy-MM-dd"
                type="date"
                :value="counselingProfile.daca_expiry"
                @input="(daca_expiry) => setCounselingProfile({ daca_expiry })"
              />
            </CounselingFormItem>
          </div>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.california_app]"
          >
            <template slot="label">
              By the time you attend UC, will you have a different visa?
            </template>
            <el-radio-group
              style="width: 100%"
              :value="counselingProfile.planned_visa"
              @input="(planned_visa) => setCounselingProfile({ planned_visa })"
            >
              <div><el-radio label="Y">Yes</el-radio></div>
              <div><el-radio label="N">No</el-radio></div>
            </el-radio-group>
          </CounselingFormItem>
          <div v-if="isPlannedVisa">
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.california_app]"
            >
              <template slot="label">
                Planned visa type
              </template>
              <el-select
                style="width: 100%"
                :value="counselingProfile.hold_planned_visa"
                @change="(hold_planned_visa) => setCounselingProfile({ hold_planned_visa })"
              >
                <el-option
                  v-for="visaType in visaTypes"
                  :key="visaType.id"
                  :label="visaType.title"
                  :value="visaType.id"
                />
              </el-select>
            </CounselingFormItem>
          </div>
        </div>
        <div v-if="!isCitizenshipNonUs">
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.california_app]"
          >
            <template slot="label">
              By the time you attend UC, will you have graduated from a California high school?
            </template>
            <el-radio-group
              style="width: 100%"
              :value="counselingProfile.has_graduated_from_cahs"
              @input="(has_graduated_from_cahs) => setCounselingProfile({ has_graduated_from_cahs })"
            >
              <div><el-radio label="Y">Yes</el-radio></div>
              <div><el-radio label="N">No</el-radio></div>
            </el-radio-group>
          </CounselingFormItem>
          <CounselingFormItem
             v-if="hasGraduatedFromCahs"
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.california_app]"
          >
            <template slot="label">
              By the time you attend UC, will you have attended a California school for three or more years during grades K through 12?
            </template>
            <el-radio-group
              style="width: 100%"
              :value="counselingProfile.has_attended_cahs"
              @input="(has_attended_cahs) => setCounselingProfile({ has_attended_cahs })"
            >
              <div><el-radio label="Y">Yes</el-radio></div>
              <div><el-radio label="N">No</el-radio></div>
            </el-radio-group>
          </CounselingFormItem>
          <template v-if="!hasGraduatedFromCahs || (hasGraduatedFromCahs && willNotAttendedMoreThreeYear)">
            <CounselingFormItem
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.california_app]"
            >
              <template slot="label">
                Are you currently living in California?
              </template>
              <el-radio-group
                style="width: 100%"
                :value="counselingProfile.is_living_in_ca"
                @input="(is_living_in_ca) => setCounselingProfile({ is_living_in_ca })"
              >
                <div><el-radio label="Y">Yes</el-radio></div>
                <div><el-radio label="N">No</el-radio></div>
              </el-radio-group>
            </CounselingFormItem>
            <template v-if="isLivingInCa">
              <CounselingFormItem
                required
                :currentApplication="currentApplicationType"
                :applicationsNeedToFilling="[thirdAppTypes.california_app]"
              >
                <template slot="label">
                  Have you lived in California your entire life? 
                </template>
                <el-radio-group
                  style="width: 100%"
                  :value="counselingProfile.has_lived_in_ca_when"
                  @input="(has_lived_in_ca_when) => setCounselingProfile({ has_lived_in_ca_when })"
                >
                  <div><el-radio label="L">Yes</el-radio></div>
                  <div><el-radio label="S">No</el-radio></div>
                </el-radio-group>
              </CounselingFormItem>
              <CounselingFormItem
                v-if="noLongerLiveInCa"
                required
                :currentApplication="currentApplicationType"
                :applicationsNeedToFilling="[thirdAppTypes.california_app]"
              >
                <template slot="label">
                  I have lived here since
                </template>
                <el-date-picker
                  style="width: 100%"
                  value-format="MM/yyyy"
                  type="date"
                  :value="counselingProfile.has_lived_in_ca_since"
                  @input="
                    has_lived_in_ca_since =>
                      setCounselingProfile({ has_lived_in_ca_since })
                  "
                />
              </CounselingFormItem>
            </template>
            <CounselingFormItem
              v-else
              required
              :currentApplication="currentApplicationType"
              :applicationsNeedToFilling="[thirdAppTypes.california_app]"
            >
              <template slot="label">
                Is your spouse or registered domestic partner an employee of the University of California or a UC-affiliated national laboratory (Los Alamos National Security LLC or Lawrence Livermore National Security LLC)?
              </template>
              <el-radio-group
                style="width: 100%"
                :value="counselingProfile.is_parent_working_at_uc"
                @input="(is_parent_working_at_uc) => setCounselingProfile({ is_parent_working_at_uc })"
              >
                <div><el-radio label="Y">Yes</el-radio></div>
                <div><el-radio label="N">No</el-radio></div>
              </el-radio-group>
            </CounselingFormItem>
          </template>
        </div>
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
    <el-collapse-item title="Fee Waiver" name="feeWaiver">
      <el-form>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            <span>Our member colleges want to make sure that application fees do not pose a barrier for any student who wishes to apply for admission. Do you feel that your financial circumstances might qualify you for an application fee waiver? <a href="https://appsupport.commonapp.org/applicantsupport/s/article/What-do-I-need-to-know-about-the-Common-App-fee-waiver" target="_blank">Learn more</a></span>
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.apply_fee_waiver"
            @input="(apply_fee_waiver) => setCounselingProfile({ apply_fee_waiver })"
          >
            <div><el-radio :label="0">Yes</el-radio></div>
            <div><el-radio :label="1">No</el-radio></div>
          </el-radio-group>
        </CounselingFormItem>
        <div v-if="isApplyFeeWaiver">
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          >
            <template slot="label">
              You must meet at least one of the following indicators of economic need to qualify for an application fee waiver. Select all that apply:
            </template>
            <el-checkbox-group
              :value="counselingProfile.common_app_fee_waiver_indicators"
              @input="(common_app_fee_waiver_indicators) => setCounselingProfile({ common_app_fee_waiver_indicators })"
            >
              <div
                :key="feeWaiverIndicator.value"
                v-for="feeWaiverIndicator in commonAppFeeWaiverIndicators"
              >
                <el-checkbox :label="feeWaiverIndicator.value">
                  {{ feeWaiverIndicator.label }}
                </el-checkbox>
              </div>
            </el-checkbox-group>
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          >
            <template slot="promptText">
              I certify that I understand and meet the eligibility requirements to request an admission application fee waiver. I also understand if I am a first year student my counselor will be asked to verify my eligibility.
            </template>
            <template slot="label">
              Fee Waiver signature
            </template>
            <el-input
              :value="counselingProfile.fee_waiver_signature"
              @input="(fee_waiver_signature) => setCounselingProfile({ fee_waiver_signature })"
            />
          </CounselingFormItem>
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          >
            <template slot="promptText">
              Common App has partnered with <a href="https://appsupport.commonapp.org/applicantsupport/s/article/What-is-Strive-For-College" target="_blank">Strive for College</a> to connect U.S. resident students with free virtual mentoring to provide additional support in the admission and financial aid application process. Students interested in connecting with a mentor, please indicate below.
            </template>
            <template slot="label">
              I would like to receive information from Strive for College
            </template>
            <el-radio-group
              style="width: 100%"
              :value="counselingProfile.want_info_from_strive_for_college"
              @input="(want_info_from_strive_for_college) => setCounselingProfile({ want_info_from_strive_for_college })"
            >
              <div><el-radio :label="0">Yes</el-radio></div>
              <div><el-radio :label="1">No</el-radio></div>
            </el-radio-group>
          </CounselingFormItem>
        </div>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            Check all of the following that apply to you:
          </template>
          <el-checkbox-group
            :value="counselingProfile.coalition_app_fee_waiver_indicators"
            @input="(coalition_app_fee_waiver_indicators) => setCoalitionAppFeeWaiverIndicators(coalition_app_fee_waiver_indicators)"
          >
            <div
              :key="feeWaiverIndicator.value"
              v-for="feeWaiverIndicator in coalitionAppFeeWaiverIndicators"
            >
              <el-checkbox
                :disabled="
                  noneApplyFeeWaiver && feeWaiverIndicator.label !== 'notEligibleYN'
                "
                :label="feeWaiverIndicator.value"
              >
                {{ feeWaiverIndicator.label }}
              </el-checkbox>
            </div>
          </el-checkbox-group>
        </CounselingFormItem>
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import JwtService from "@/common/jwt.service";
import { thirdAppTypeDict } from "@/class/ThirdApp";
import CounselingFormAlert from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/CounselingFormAlert";
import CounselingFormItem from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/CounselingFormItem";
import AddressForm from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/AddressForm";
import SuffixSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/SuffixSelector";
import MobileCountryCodeSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/MobileCountryCodeSelector";
import CountrySelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/CountrySelector";
import StateSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/StateSelector";
import StateOfResidencySelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/ProfileForm/StateOfResidencySelector";
import PhoneTypeSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/ProfileForm/PhoneTypeSelector";
import TribalAffiliationSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/ProfileForm/TribalAffiliationSelector";
import ReligiousSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/ProfileForm/ReligiousSelector";
import LanguageSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/LanguageSelector";
import CitizenshipSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/CitizenshipSelector";
import CounselingEditor from "@/components/ckeditor/CounselingEditor.vue";

export default {
  props: {
    currentApplicationType: {
      type: String,
      default: ""
    },
    activePanel: {
      type: String,
      default: ""
    }
  },
  components: {
    CounselingFormAlert,
    CounselingFormItem,
    AddressForm,
    SuffixSelector,
    MobileCountryCodeSelector,
    CountrySelector,
    StateSelector,
    StateOfResidencySelector,
    PhoneTypeSelector,
    TribalAffiliationSelector,
    ReligiousSelector,
    LanguageSelector,
    CitizenshipSelector,
    CounselingEditor
  },
  watch: {
    counselingProfile() {
      if (this.counselingProfile.valid_military_experience_count === 0) {
        this.displayMilitaryExperienceCount = 1;
      } else {
        this.displayMilitaryExperienceCount = this.counselingProfile.valid_military_experience_count;
      }
    }
  },
  computed: {
    thirdAppTypes() {
      return thirdAppTypeDict;
    },
    imageAction() {
      return `${process.env.VUE_APP_BACKEND_URL}document`;
    },
    token() {
      return { Authorization: `Bearer ${JwtService.getToken()}` };
    },
    pronouns() {
      return [
        { value: "{\"code\":\"THEY\",\"value\":\"They/Them/Theirs\"}", label: "They/Them/Theirs" },
        { value: "{\"code\":\"SHE\",\"value\":\"She/Her/Hers\"}", label: "She/Her/Hers" },
        { value: "{\"code\":\"HE\",\"value\":\"He/Him/His\"}", label: "He/Him/His" },
        { value: "{\"code\":\"OTH\",\"value\":\"My pronouns are not listed.\"}", label: "My pronouns are not listed." }
      ];
    },
    genderIdentities() {
      return [
        { value: "{\"code\":\"F\",\"value\":\"Woman\"}", label: "Woman" },
        { value: "{\"code\":\"M\",\"value\":\"Man\"}", label: "Man" },
        { value: "{\"code\":\"NB\",\"value\":\"Nonbinary\"}", label: "Nonbinary" },
        { value: "{\"code\":\"OTH\",\"value\":\"Additional Gender Category/Identity\"}", label: "Additional Gender Category/Identity" },
        { value: "{\"code\":\"NA\",\"value\":\"Prefer Not to Disclose\"}", label: "Prefer Not to Disclose" }
      ];
    },
    sexualOrientations() {
      return [
        { value: "HS", label: "Heterosexual or straight" },
        { value: "GL", label: "Gay or lesbian" },
        { value: "BI", label: "Bisexual" },
        { value: "OT", label: "Not listed above" }
      ];
    },
    liveInOfNumbers() {
      return [
        { value: 0, label: "0" },
        { value: 1, label: "< 1 Year" },
        { value: 2, label: "2" },
        { value: 3, label: "3" },
        { value: 4, label: "4" },
        { value: 5, label: "5" },
        { value: 6, label: "6" },
        { value: 7, label: "7" },
        { value: 8, label: "8" },
        { value: 9, label: "9" },
        { value: 10, label: "10" },
        { value: 11, label: "11" },
        { value: 12, label: "12" },
        { value: 13, label: "13" },
        { value: 14, label: "14" },
        { value: 15, label: "15" },
        { value: 16, label: "16" },
        { value: 17, label: "17" },
        { value: 18, label: "18" },
        { value: 19, label: "19" },
        { value: 20, label: "20" },
        { value: 21, label: "> 20 Years" }
      ];
    },
    languageProficiencys() {
      return [
        { value: "3", label: "First Language" },
        { value: "0", label: "Speak" },
        { value: "1", label: "Read" },
        { value: "2", label: "Write" },
        { value: "4", label: "Spoken at Home" }
      ];
    },
    usMilitaryExperiencesBranchs() {
      return [
        {
          label: "Air Force",
          value: 3
        },
        {
          label: "Army",
          value: 0
        },
        {
          label: "Coast Guard",
          value: 4
        },
        {
          label: "Marine Corps",
          value: 1
        },
        {
          label: "Navy",
          value: 2
        }
      ];
    },
    citizenshipStatuses() {
      return [
        { value: 0, label: "U.S. Citizen or U.S. National" },
        { value: 1, label: "U.S. Dual Citizen" },
        { value: 5, label: "U.S. Permanent Resident" },
        { value: 4, label: "U.S. Refugee or Asylee" },
        { value: 3, label: "Other (Non-US)" }
      ];
    },
    tribalMembershipTypes() {
      return [
        { value: "FR", label: "Federally recognized tribe" },
        { value: "SR", label: "State recognized tribe (not in California)" },
        { value: "CN", label: "California non-federally recognized tribe" }
      ];
    },
    visaTypes() {
      return this.$store.getters["counselingProfile/visaTypeOptions"];
    },
    commonAppFeeWaiverIndicators() {
      return [
        { value: "0", label: "I have received or am eligible to receive an ACT or SAT testing fee waiver" },
        { value: "1", label: "I am enrolled in or am eligible to participate in the Federal Free or Reduced Price Lunch program (FRPL)" },
        { value: "2", label: "My annual family income falls within the Income Eligibility Guidelines set by the USDA Food and Nutrition Service" },
        { value: "3", label: "I am enrolled in a federal, state, or local program that aids students from low-income families (e.g., TRIO programs such as Upward Bound)" },
        { value: "4", label: "My family receives public assistance" },
        { value: "5", label: "I live in federally subsidized public housing, a foster home or am homeless" },
        { value: "6", label: "I am a ward of the state or an orphan" },
        { value: "7", label: "I can provide a supporting statement from a school official, college access counselor, financial aid officer, or community leader" }
      ];
    },
    coalitionAppFeeWaiverIndicators() {
      return [
        { value: "freeLunchYN", label: "My annual family income falls within the Income Eligibility Guidelines* for the Federal Free and Reduced Lunch Program." },
        { value: "cbWaiverYN", label: "I have received a College Board fee waiver." },
        { value: "actWaiverYN", label: "I have received an ACT fee waiver." },
        { value: "nacacWaiverYN", label: "I have received a NACAC fee waiver." },
        { value: "pellGrantYN", label: "I am eligible for a Pell Grant." },
        { value: "participateTrioYN", label: "I have participated (or am participating) in TRIO programs (such as Upward Bound)." },
        { value: "notEligibleYN", label: "None of these apply to me." }
      ];
    },
    unitedStatesArmedForcesStatuses() {
      return [
        { value: 0, label: "None" },
        { value: 1, label: "Currently Serving" },
        { value: 2, label: "Currently Dependent" },
        { value: 3, label: "Previously Served" }
      ];
    },
    currentEnrollmentStatuses() {
      return [
        { value: "{\"code\":\"MID\",\"value\":\"Middle School / Junior High\"}", label: "Middle School / Junior High" },
        { value: "{\"code\":\"HIGH\",\"value\":\"High School / Secondary School\"}", label: "High School / Secondary School" },
        { value: "{\"code\":\"COMM\",\"value\":\"2-year College (after high school graduation)\"}", label: "2-year College (after high school graduation)" },
        { value: "{\"code\":\"4YEAR\",\"value\":\"Previously Served\"}", label: "4-year College (after high school graduation)" },
        { value: "{\"code\":\"NONE\",\"value\":\"Not Currently Enrolled in School\"}", label: "Not Currently Enrolled in School" }
      ];
    },
    disasters() {
      return [
        { value: "{\"code\":\"NO_INTERNET\",\"value\":\"I had inconsistent or unreliable access to the Internet and/or a computer, laptop, or tablet.\"}", label: "I had inconsistent or unreliable access to the Internet and/or a computer, laptop, or tablet." },
        { value: "{\"code\":\"LOST_JOB\",\"value\":\"I lost my job or was no longer able to work.\"}", label: "I lost my job or was no longer able to work." },
        { value: "{\"code\":\"PG_LOST_JOB\",\"value\":\"At least one parent/guardian lost their job or was unable to work.\"}", label: "At least one parent/guardian lost their job or was unable to work." },
        { value: "{\"code\":\"ESSENTIAL\",\"value\":\"I was considered an essential worker (e.g. healthcare worker, grocery store employee, public transportation driver, first responder, sanitation worker, etc.) and was required to work.\"}", label: "I was considered an essential worker (e.g. healthcare worker, grocery store employee, public transportation driver, first responder, sanitation worker, etc.) and was required to work." },
        { value: "{\"code\":\"PG_ESSENTIAL\",\"value\":\"At least one parent/guardian was considered an essential worker.\"}", label: "At least one parent/guardian was considered an essential worker." },
        { value: "{\"code\":\"CURFEW\",\"value\":\"My community had a curfew affecting the hours I could travel, use electricity, or access the Internet.\"}", label: "My community had a curfew affecting the hours I could travel, use electricity, or access the Internet." },
        { value: "{\"code\":\"HOME_RESP\",\"value\":\"My home responsibilities (e.g. childcare, elder care, etc.) substantially increased or changed.\"}", label: "My home responsibilities (e.g. childcare, elder care, etc.) substantially increased or changed." },
        { value: "{\"code\":\"HEALTH\",\"value\":\"My health was affected.\"}", label: "My health was affected." },
        { value: "{\"code\":\"FAM_HEALTH\",\"value\":\"A member of my household’s health was affected.\"}", label: "A member of my household’s health was affected." },
        { value: "{\"code\":\"NA\",\"value\":\"None of these apply to me.\"}", label: "None of these apply to me." },
        { value: "{\"code\":\"MORE_INFO\",\"value\":\"I would like to provide additional information about personal and/or academic impacts.\"}", label: "I would like to provide additional information about personal and/or academic impacts." }
      ];
    },
    hispanicBackgrounds() {
      return this.$store.getters["counselingProfile/hispanicsOptions"];
    },
    races() {
      return [
        { value: 0, label: "American Indian or Alaska Native" },
        { value: 1, label: "Asian" },
        { value: 2, label: "Black or African American" },
        { value: 3, label: "Native Hawaiian or Other Pacific Islander" },
        { value: 4, label: "White" }
      ];
    },
    ucaiTribeStatuses() {
      return [
        { value: "F", label: "Member of a Federally recognized tribe / Alaska Native Village" },
        { value: "S", label: "Member of a State recognized tribe (not in California)" },
        { value: "C", label: "Member of a California non-federally recognized tribe" },
        { value: "U", label: "Member of a non-recognized tribe" },
        { value: "L", label: "Lineal descendent" }
      ];
    },
    blackBackgrounds() {
      return this.$store.getters["counselingProfile/blackBackgroundOptions"];
    },
    whiteBackgrounds() {
      return this.$store.getters["counselingProfile/whiteBackgroundOptions"];
    },
    asianBackgrounds() {
      return this.$store.getters["counselingProfile/asianBackgroundOptions"];
    },
    islandBackgrounds() {
      return [
        { value: 0, label: "Guam" },
        { value: 1, label: "Hawaii" },
        { value: 2, label: "Samoa" },
        { value: 3, label: "Other Pacific Islands" }
      ];
    },
    southwestAsianAndNorthAfricanBackgrounds() {
      return [
        { value: "afghan", label: "Afghan" },
        { value: "algerian", label: "Algerian" },
        { value: "armenian", label: "Armenian" },
        { value: "assyrian", label: "Assyrian / Chaldean" },
        { value: "azerbaijani", label: "Azerbaijani" },
        { value: "bahraini", label: "Bahraini" },
        { value: "berber", label: "Berber" },
        { value: "Circassian", label: "Circassian" },
        { value: "djiboutian", label: "Djiboutian" },
        { value: "egyptian", label: "Egyptian" },
        { value: "emirati", label: "Emirati" },
        { value: "georgian", label: "Georgian" },
        { value: "iranian", label: "Iranian" },
        { value: "iraqi", label: "Iraqi" },
        { value: "israeli", label: "Israeli" },
        { value: "jordanian", label: "Jordanian" },
        { value: "other-southwest-asian", label: "Other Southwest Asian" },
        { value: "kurdish", label: "Kurdish" },
        { value: "kuwaiti", label: "Kuwaiti" },
        { value: "lebanese", label: "Lebanese" },
        { value: "libyan", label: "Libyan" },
        { value: "mauritanian", label: "Mauritanian" },
        { value: "moroccan", label: "Moroccan" },
        { value: "omani", label: "Omani" },
        { value: "palestinian", label: "Palestinian" },
        { value: "qatari", label: "Qatari" },
        { value: "saudi-arabian", label: "Saudi Arabian" },
        { value: "somali", label: "Somali" },
        { value: "sudanese", label: "Sudanese" },
        { value: "syrian", label: "Syrian" },
        { value: "tunisian", label: "Tunisian" },
        { value: "turkish", label: "Turkish" },
        { value: "yemeni", label: "Yemeni" },
        { value: "other-north-african", label: "Other North African" }
      ];
    },
    backgroundStatuses() {
      return [
        { value: 1, label: "I am married or in a civil union/registered domestic partnership" },
        { value: 2, label: "I have children" },
        { value: 3, label: "I've been in foster care or am a ward of the court in the United States?" },
        { value: 4, label: "When I attend UC, I will be a current or former member of the U.S. military" },
        { value: 5, label: "None of the above apply to me" }
      ];
    },
    usMilitaries() {
      return [
        { value: "AD", label: "On active duty" },
        { value: "RV", label: "Reservist (not including ROTC)" },
        { value: "NG", label: "National Guard member" },
        { value: "DV", label: "Military veteran who served on active duty" },
        { value: "RO", label: "ROTC cadet or NROTC midshipman" }
      ];
    },
    MAX_MILITARY_EXPERIENCE_COUNT() {
      return 3;
    },
    counselingProfile() {
      return this.$store.getters["counselingProfile/counselingProfile"];
    },
    wantToShareDifferentFirstName() {
      return this.counselingProfile.would_you_like_to_share_different_first_name === 0;
    },
    hasOtherNames() {
      return this.counselingProfile.used_any_other_names === 0;
    },
    hasOtherMailAddress() {
      return this.counselingProfile.used_mailing_address === 1;
    },
    hasAlternatePhone() {
      return this.counselingProfile.used_alternate_phone !== 0;
    },
    isTemporaryMailingAddress() {
      return this.counselingProfile.is_temporary_mailing_address === 0;
    },
    usArmedForcesStatusIsServingOrServed() {
      return [1, 2].includes(this.counselingProfile.us_armed_forces_status);
    },
    militaryExperienceCountIsMultiple() {
      return 1 < this.displayMilitaryExperienceCount;
    },
    displayMilitaryExperienceCountAlreadyMax() {
      return this.MAX_MILITARY_EXPERIENCE_COUNT <= this.displayMilitaryExperienceCount;
    },
    citizenshipIsUSCitizenOrNational() {
      const US_CITIZEN_OR_NATIONAL = 0;
      return this.counselingProfile.citizenship_status === US_CITIZEN_OR_NATIONAL;
    },
    citizenshipIsUSPermanentResidentOrRefugee() {
      const US_PERMANENT_RESIDENT = 5;
      const US_REFUGEE_OR_ASYLEE = 4;
      return [US_PERMANENT_RESIDENT, US_REFUGEE_OR_ASYLEE].includes(
        this.counselingProfile.citizenship_status
      );
    },
    isCitizenshipNonUs() {
      const NON_US = 3;
      return this.counselingProfile.citizenship_status === NON_US;
    },
    isTribalMember() {
      return this.counselingProfile.is_tribal_member === "Y";
    },
    isApplyFeeWaiver() {
      return this.counselingProfile.apply_fee_waiver === 0;
    },
    isHoldValidVisa() {
      return this.counselingProfile.hold_us_visa === 0;
    },
    isPlannedVisa() {
      return this.counselingProfile.planned_visa === "Y";
    },
    hasGraduatedFromCahs() {
      return this.counselingProfile.has_graduated_from_cahs === "Y";
    },
    willNotAttendedMoreThreeYear() {
      return this.counselingProfile.has_attended_cahs === "N";
    },
    isLivingInCa() {
      return this.counselingProfile.is_living_in_ca === "Y";
    },
    noLongerLiveInCa() {
      return this.counselingProfile.has_lived_in_ca_when === "S";
    },
    birthCountryHasStateOrProvince() {
      const [USA, CANADA] = ["0", "1"];
      return this.counselingProfile.birth_country === USA || this.counselingProfile.birth_country === CANADA;
    },
    pronounsIsNotListed() {
      return this.counselingProfile.pronouns.includes(
        "{\"code\":\"OTH\",\"value\":\"My pronouns are not listed.\"}"
      );
    },
    wantToDescrGenderIdentity() {
      return this.counselingProfile.gender_identities.includes(
        "{\"code\":\"OTH\",\"value\":\"Additional Gender Category/Identity\"}"
      );
    },
    sexualOrientationIsOther() {
      return this.counselingProfile.sexual_orientation === "OT";
    },
    currentlyEnrolledInHighSchool() {
      const current_enrollment_status = [
        "{\"code\":\"MID\",\"value\":\"Middle School / Junior High\"}",
        "{\"code\":\"HIGH\",\"value\":\"High School / Secondary School\"}"
      ];
      return current_enrollment_status.includes(this.counselingProfile.current_enrollment_status);
    },
    currentlyEnrolledInCollege() {
      const current_enrollment_status = [
        "{\"code\":\"COMM\",\"value\":\"2-year College (after high school graduation)\"}",
        "{\"code\":\"4YEAR\",\"value\":\"Previously Served\"}"
      ];
      return current_enrollment_status.includes(this.counselingProfile.current_enrollment_status);
    },
    notCurrentlyEnrolledInSchool() {
      return this.counselingProfile.current_enrollment_status === "{\"code\":\"NONE\",\"value\":\"Not Currently Enrolled in School\"}";
    },
    noneApply() {
      return this.counselingProfile.disaster_impacts.includes("{\"code\":\"NA\",\"value\":\"None of these apply to me.\"}");
    },
    noneApplyFeeWaiver() {
      return this.counselingProfile.coalition_app_fee_waiver_indicators.includes("notEligibleYN");
    },
    wouldLikeToProvideDisaster() {
      return this.counselingProfile.disaster_impacts.includes("{\"code\":\"MORE_INFO\",\"value\":\"I would like to provide additional information about personal and/or academic impacts.\"}");
    },
    religionIsOther() {
      const OTHER_RELIGION = [35, 36];
      return OTHER_RELIGION.includes(this.counselingProfile.religion);
    },
    needToSpecifyOtherHispanicBackground() {
      return (
        this.counselingProfile.hispanic_or_latino_identities.includes(1394) ||
        this.counselingProfile.hispanic_or_latino_identities.includes(1393) ||
        this.counselingProfile.hispanic_or_latino_identities.includes(1392)
      );
    },
    ucaiTribeStatusIsLineal() {
      return this.counselingProfile.ucai_tribe_status === "L";
    },
    raceIncludeAmerican() {
      return this.counselingProfile.other_identities.includes(0);
    },
    raceIncludeAsian() {
      return this.counselingProfile.other_identities.includes(1);
    },
    raceIncludeBlack() {
      return this.counselingProfile.other_identities.includes(2);
    },
    raceIncludeIsland() {
      return this.counselingProfile.other_identities.includes(3);
    },
    raceIncludeWhite() {
      return this.counselingProfile.other_identities.includes(4);
    },
    amerindBackgroundIncludeOther() {
      return this.counselingProfile.amerind_background.includes("{\"value\":\"Other\",\"code\":\"IOTH\",\"label\":\"Other\",\"name\":\"\"}");
    },
    asianBackgroundIsEast() {
      const EAST_ASIA = 1409;
      return this.counselingProfile.asian_backgrounds.includes(EAST_ASIA);
    },
    asianBackgroundIsSouth() {
      const SOUTH_ASIA = 1410;
      return this.counselingProfile.asian_backgrounds.includes(SOUTH_ASIA);
    },
    asianBackgroundIsSoutheast() {
      const SOUTHEAST_ASIA = 1411;
      return this.counselingProfile.asian_backgrounds.includes(SOUTHEAST_ASIA);
    },
    blackBackgroundIncludeOther() {
      return this.counselingProfile.african_american_backgrounds.includes(125940);
    },
    islandBackgroundIncludeOther() {
      return this.counselingProfile.native_hawaiian_or_other_pacific_islander_backgrounds.includes(3);
    },
    whiteBackgroundIncludeOther() {
      return this.counselingProfile.white_backgrounds.includes(125944);
    },
    southwestAsianAndNorthAfricanBackgroundIncludeOtherSouthwest() {
      return this.counselingProfile.southwest_asian_and_north_africans_background.includes("other-southwest-asian");
    },
    southwestAsianAndNorthAfricanBackgroundIncludeOtherNorth() {
      return this.counselingProfile.southwest_asian_and_north_africans_background.includes("other-north-african");
    },
    firstLangIsNotOnlyEnglish() {
      return (
        this.counselingProfile.lang_first_code === "E2" ||
        this.counselingProfile.lang_first_code === "OT"
      );
    },
    noneBackgroundStatus() {
      return this.counselingProfile.backgrounds.includes(5);
    },
    willBeMemberOfTheUsMilitary() {
      return this.counselingProfile.backgrounds.includes(4);
    },
    previousYear() {
      return new Date().getFullYear() - 1;
    }
  },
  data() {
    return {
      displayMilitaryExperienceCount: 1,
      fileList: [],
      uploadFileId: ""
    };
  },
  created() {
    this.$store.dispatch(
      "counselingProfile/fetchVisaTypesOfCounselingProfile"
    );
    this.$store.dispatch(
      "counselingProfile/fetchHispanicsOfCounselingProfile"
    );
    this.$store.dispatch(
      "counselingProfile/fetchBlackBackgroundsOfCounselingProfile"
    );
    this.$store.dispatch(
      "counselingProfile/fetchWhiteBackgroundsOfCounselingProfile"
    );
    this.$store.dispatch(
      "counselingProfile/fetchAsianBackgroundsOfCounselingProfile"
    );
  },
  methods: {
    setCounselingProfile(data) {
      this.$store.commit("counselingProfile/setCounselingProfile", { ...data });
    },
    setDisasterImpacts(disasterImpacts) {
      const noneApply = disasterImpacts.includes("{\"code\":\"NA\" \"value\":\"None of these apply to me.\"}");
      if (noneApply) {
        this.setCounselingProfile({
          disaster_impacts: disasterImpacts.filter((disasterImpact) => (
            disasterImpact === "{\"code\":\"NA\",\"value\":\"None of these apply to me.\"}" ||
            disasterImpact === "{\"code\":\"MORE_INFO\",\"value\":\"I would like to provide additional information about personal and/or academic impacts.\"}"
          ))
        });
      } else {
        this.setCounselingProfile({ disaster_impacts: disasterImpacts });
      }
    },
    setCoalitionAppFeeWaiverIndicators(coalitionAppFeeWaiverIndicators) {
      const noneApplyFeeWaiver = coalitionAppFeeWaiverIndicators.includes("notEligibleYN");
      if (noneApplyFeeWaiver) {
        this.setCounselingProfile({
          coalition_app_fee_waiver_indicators: coalitionAppFeeWaiverIndicators.filter((coalitionAppFeeWaiverIndicator) => (
            coalitionAppFeeWaiverIndicator === "notEligibleYN"
          ))
        });
      } else {
        this.setCounselingProfile({
          coalition_app_fee_waiver_indicators: coalitionAppFeeWaiverIndicators
        });
      }
    },
    setBackgrounds(backgrounds) {
      const noneBackground = backgrounds.includes(5);
      if (noneBackground) {
        this.setCounselingProfile({
          backgrounds: backgrounds.filter((background) => (
            background === 5
          ))
        });
      } else {
        this.setCounselingProfile({
          backgrounds
        });
      }
    },
    setAddress(data) {
      this.$store.commit("counselingProfile/setAddress", { ...data });
    },
    setMailingAddress(data) {
      this.$store.commit("counselingProfile/setMailingAddress", { ...data });
    },
    setPreferredPhone(data) {
      this.$store.commit("counselingProfile/setPreferredPhone", { ...data });
    },
    setAlternatePhone(data) {
      this.$store.commit("counselingProfile/setAlternatePhone", { ...data });
    },
    setLanguage(languageIndex, data) {
      this.$store.commit(
        "counselingProfile/setLanguage",
        { languageIndex, data: { ...data }}
      );
    },
    setMilitaryExperience(militaryExperienceIndex, data) {
      this.$store.commit("counselingProfile/setMilitaryExperience", {
        militaryExperienceIndex,
        data
      });
    },
    setCitizenshipStatus(citizenship_status) {
      this.setCounselingProfile({
        citizenship_status
      });
    },
    militaryExperienceOrderIsLast(militaryExperienceOrder) {
      return militaryExperienceOrder === this.displayMilitaryExperienceCount;
    },
    militaryExperienceOrderIsFirst(militaryExperienceOrder) {
      return militaryExperienceOrder === 1;
    },
    moveMilitaryExperienceOrderUp(militaryExperienceCountIndex) {
      this.$store.dispatch("counselingProfile/moveMilitaryExperienceOrderUp", { militaryExperienceCountIndex });
    },
    moveMilitaryExperienceOrderDown(militaryExperienceCountIndex) {
      this.$store.dispatch("counselingProfile/moveMilitaryExperienceOrderDown", { militaryExperienceCountIndex });
    },
    addDisplayMilitaryExperienceCount() {
      this.displayMilitaryExperienceCount += 1;
    },
    removeLastMilitaryExperience() {
      this.displayMilitaryExperienceCount -= 1;
      this.$store.dispatch("counselingProfile/removeLastHonor");
    },
    handleUploadSuccess(response, file, fileList) {
      if (this.uploadFileId) {
        this.handleUploadRemove(file, fileList);
      } else {
        this.uploadFileId = response.document.id;
        this.fileList = fileList;
      }
      this.setCounselingProfile({
        green_card_file: response.document.url
      });
    },
    async handleUploadRemove(file, fileList) {
      await this.$store.dispatch("bill/deleteUploadFile", this.uploadFileId);
      if (fileList.length > 1) {
        this.fileList = fileList.slice(1, 2);
      } else {
        this.fileList = [];
        this.uploadFileId = "";
        this.setCounselingProfile({
          green_card_file: null
        });
      }
    }
  }
};
</script>

<style scoped src="@/views/private/Counseling/ThirdApp/CounselingProfileForm/index.css"></style>
<style scoped>
.greenCard {
  width: 680px;
  height: 400px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}
</style>