<template>
  <el-select
    style="width: 100%"
    filterable
    :value="value"
    @change="changeValue"
    placeholder
  >
    <el-option
      v-for="item in data"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    />
  </el-select>
</template>

<script>
export default {
  name: "CollegeSubjects",
  props: ["value"],
  computed: {
    data() {
      return [
        {
          label: "Civics/American Government",
          value: "3"
        },
        {
          label: "U.S. History",
          value: "2"
        },
        {
          label: "World History/Cultures/Hist. Geography",
          value: "1"
        },
        {
          label: "English",
          value: "4"
        },
        {
          label: "English-ESL/ELD",
          value: "5"
        },
        {
          label: "Algebra I",
          value: "6"
        },
        {
          label: "Geometry",
          value: "7"
        },
        {
          label: "Algebra II",
          value: "8"
        },
        {
          label: "Mathematics I (Integrated)",
          value: "10"
        },
        {
          label: "Mathematics II (Integrated)",
          value: "11"
        },
        {
          label: "Mathematics III (Integrated)",
          value: "12"
        },
        {
          label: "Algebra II/Trigonometry",
          value: "9"
        },
        {
          label: "Statistics",
          value: "15"
        },
        {
          label: "Calculus",
          value: "14"
        },
        {
          label: "Advanced Mathematics",
          value: "13"
        },
        {
          label: "Computer Science",
          value: "16"
        },
        {
          label: "Biology/Life Sciences",
          value: "17"
        },
        {
          label: "Biology/Earth &amp; Space Sciences",
          value: "43"
        },
        {
          label: "Chemistry",
          value: "18"
        },
        {
          label: "Chemistry/Earth &amp; Space Sciences",
          value: "44"
        },
        {
          label: "Physics",
          value: "19"
        },
        {
          label: "Physics/Earth &amp; Space Sciences",
          value: "45"
        },
        {
          label: "Integrated Science 1",
          value: "46"
        },
        {
          label: "Integrated Science 2",
          value: "47"
        },
        {
          label: "Integrated Science 3",
          value: "48"
        },
        {
          label: "Applied Science",
          value: "49"
        },
        {
          label: "Computer Science",
          value: "50"
        },
        {
          label: "Earth and Space Sciences",
          value: "22"
        },
        {
          label: "Engineering",
          value: "51"
        },
        {
          label: "Interdisciplinary Sciences",
          value: "20"
        },
        {
          label: "Yr 1 Lang Other Than English",
          value: "23"
        },
        {
          label: "Yr 2 Lang Other Than English",
          value: "24"
        },
        {
          label: "Yr 3 Lang Other Than English",
          value: "25"
        },
        {
          label: "Yr 4+ Lang Other Than English",
          value: "26"
        },
        {
          label: "Dance",
          value: "27"
        },
        {
          label: "Music",
          value: "28"
        },
        {
          label: "Theater",
          value: "29"
        },
        {
          label: "Visual Arts",
          value: "30"
        },
        {
          label: "Interdisciplinary Arts",
          value: "31"
        },
        {
          label: "Elective -English",
          value: "33"
        },
        {
          label: "Elective -English - ESL/ELD",
          value: "34"
        },
        {
          label: "Elective -History/Social Science",
          value: "32"
        },
        {
          label: "Elective -Interdisciplinary",
          value: "41"
        },
        {
          label: "Elective -Language Other Than English ",
          value: "39"
        },
        {
          label: "Elective -Mathematics",
          value: "35"
        },
        {
          label: "Elective -Math -Computer Science",
          value: "42"
        },
        {
          label: "Elective -Science-Biology/Life Sciences",
          value: "36"
        },
        {
          label: "Elective -Science-Integrated Science",
          value: "38"
        },
        {
          label: "Elective -Science-Physical Sciences",
          value: "37"
        },
        {
          label: "Elective -Visual &amp; Performing Arts",
          value: "40"
        }
      ];
    }
  },
  methods: {
    changeValue(value) {
      this.$emit("change", value);
    }
  }
};
</script>
