<template>
  <div>
    <CounselingFormItem
      required
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.california_app]"
    >
      <template slot="label">
        What's the name of the organization, program, school or group you volunteered for? 
      </template>
      <el-input
        :value="activity.volunteer_org"
        @input="(volunteer_org) => $emit('setActivity', { volunteer_org })"
      />
    </CounselingFormItem>
    <CounselingFormItem
      required
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.california_app]"
    >
      <template slot="label">
        Please describe the organization, program, school or group.
      </template>
      <template slot="promptText">
        Consider what kind of work the organization does: What’s the reason the organization exists today? How does it help a certain community or population?
      </template>
      <el-input
        type="textarea"
        :rows="5"
        maxlength="250"
        show-word-limit
        :value="activity.volunteer_org_description"
        @input="(volunteer_org_description) => $emit('setActivity', { volunteer_org_description })"
      />
    </CounselingFormItem>
    <CounselingFormItem
      required
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.california_app]"
    >
      <template slot="label">
        What did you do?
      </template>
      <template slot="promptText">
        Think about your experience, and what you accomplished and learned while volunteering. We’d also like to know if you’ve held a leadership role, which can mean more than just a title — it can mean being a mentor to others, acting as a point-person in charge of a specific task, or taking a lead role in organizing an event or project.
      </template>
      <el-input
        type="textarea"
        :rows="5"
        maxlength="350"
        show-word-limit
        :value="activity.volunteer_description"
        @input="(volunteer_description) => $emit('setActivity', { volunteer_description })"
      />
    </CounselingFormItem>
    <CounselingFormItem
      required
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.california_app]"
    >
      <template slot="label">
        When did you participate in this activity? 
      </template>
      <template slot="promptText">
        If you participated during the summer, select the grade you were in before that summer.
      </template>
      <ActivityOrAwardGradesCheckbox
        :value="activity.volunteer_grades"
        @input="(volunteer_grades) => $emit('setActivity', { volunteer_grades })"
      />
    </CounselingFormItem>
    <template v-if="volunteerYearsAfter12">
      <b>How many months/years after 12th grade did you spend on this program?</b>
      <CounselingFormItem
        required
        :currentApplication="currentApplicationType"
        :applicationsNeedToFilling="[thirdAppTypes.california_app]"
      >
        <template slot="label">
          Number of Years
        </template>
        <ActivityOrAwardSpendYearsSelector
          :value="activity.volunteer_years_after12"
          @change="(volunteer_years_after12) => $emit('setActivity', { volunteer_years_after12 })"
        />
      </CounselingFormItem>
      <CounselingFormItem
        required
        :currentApplication="currentApplicationType"
        :applicationsNeedToFilling="[thirdAppTypes.california_app]"
      >
        <template slot="label">
          Number of Months
        </template>
        <ActivityOrAwardSpendMonthsSelector
          :value="activity.volunteer_months_after12"
          @change="(volunteer_months_after12) => $emit('setActivity', { volunteer_months_after12 })"
        />
      </CounselingFormItem>
    </template>
    <b>How much time did you spend in the program?</b>
    <CounselingFormItem
      required
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.california_app]"
    >
      <template slot="label">
        Hours spent per week
      </template>
      <el-input-number
        :min="0"
        :max="999.99"
        class="inputNumber"
        controls-position="right"
        :value="activity.volunteer_hours_per_week"
        @input="(volunteer_hours_per_week) => $emit('setActivity', { volunteer_hours_per_week })"
      />
    </CounselingFormItem>
    <CounselingFormItem
      required
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.california_app]"
    >
      <template slot="label">
        Weeks spent per year
      </template>
      <el-input-number
        :min="0"
        :max="99.99"
        class="inputNumber"
        controls-position="right"
        :value="activity.volunteer_weeks_per_year"
        @input="(volunteer_weeks_per_year) => $emit('setActivity', { volunteer_weeks_per_year })"
      />
    </CounselingFormItem>
  </div>
</template>

<script>
import { thirdAppTypeDict } from "@/class/ThirdApp";
import CounselingFormItem from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/CounselingFormItem";
import ActivityOrAwardGradesCheckbox from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/ActivityForm/ActivityOrAwardDialog/Components/ActivityOrAwardGradesCheckbox";
import ActivityOrAwardSpendYearsSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/ActivityForm/ActivityOrAwardDialog/Components/ActivityOrAwardSpendYearsSelector";
import ActivityOrAwardSpendMonthsSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/ActivityForm/ActivityOrAwardDialog/Components/ActivityOrAwardSpendMonthsSelector";

export default {
  name: "VolunteerForm",
  props: {
    currentApplicationType: {
      type: String,
      default: ""
    },
    activity: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    CounselingFormItem,
    ActivityOrAwardGradesCheckbox,
    ActivityOrAwardSpendYearsSelector,
    ActivityOrAwardSpendMonthsSelector
  },
  computed: {
    thirdAppTypes() {
      return thirdAppTypeDict;
    },
    volunteerYearsAfter12() {
      return this.activity.volunteer_grades.includes(5);
    }
  }
};
</script>

