<template>
  <el-select
    style="width: 100%"
    filterable
    :value="value"
    @change="changeValue"
    placeholder
  >
    <el-option
      v-for="item in data"
      :key="item.id"
      :label="item.title"
      :value="item.id"
    />
  </el-select>
</template>

<script>
export default {
  name: "TribalAffiliationSelector",
  props: ["value"],
  computed: {
    data() {
      return this.$store.getters["counselingProfile/tribalAffiliationOptions"];
    }
  },
  created() {
    this.$store.dispatch(
      "counselingProfile/fetchTribalAffiliationOfCounselingProfile"
    );
  },
  methods: {
    changeValue(value) {
      this.$emit("change", value);
    }
  }
};
</script>
