<template>
  <div>
    <CounselingFormItem
      required
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.california_app]"
    >
      <template slot="label">
        What was the program name?
      </template>
      <el-select
        style="width: 100%" 
        :value="activity.ed_prep_id"
        @input="(ed_prep_id) => $emit('setActivity', { ed_prep_id })"
      >
        <el-option
          v-for="educationalPrepProgram in educationalPrepPrograms"
          :key="educationalPrepProgram.value"
          :label="educationalPrepProgram.label"
          :value="educationalPrepProgram.value"
        />
      </el-select>
    </CounselingFormItem>
    <CounselingFormItem
      v-if="educationalPrepProgramIsOther"
      required
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.california_app]"
    >
      <template slot="label">
        Program Name
      </template>
      <el-input
        :value="activity.ed_prep_other_name"
        @input="(ed_prep_other_name) => $emit('setActivity', { ed_prep_other_name })"
      />
    </CounselingFormItem>
    <CounselingFormItem
      required
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.california_app]"
    >
      <template slot="label">
        Briefly describe the program.
      </template>
      <template slot="promptText">
        Think about the program's main focus, your experience, and what you accomplished and learned while participating in the program.
      </template>
      <el-input
        type="textarea"
        :rows="5"
        maxlength="350"
        show-word-limit
        :value="activity.ed_prep_description"
        @input="(ed_prep_description) => $emit('setActivity', { ed_prep_description })"
      />
    </CounselingFormItem>
    <CounselingFormItem
      required
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.california_app]"
    >
      <template slot="label">
        When did you participate in the program?
      </template>
      <template slot="promptText">
        If you participated in the program during the summer, select the grade you were in before that summer.
      </template>
      <ActivityOrAwardGradesCheckbox
        :value="activity.ed_prep_grades"
        @input="(ed_prep_grades) => $emit('setActivity', { ed_prep_grades })"
      />
    </CounselingFormItem>
    <template v-if="educationalPrepYearsAfter12">
      <b>How many months/years after 12th grade did you spend on this program?</b>
      <CounselingFormItem
        required
        :currentApplication="currentApplicationType"
        :applicationsNeedToFilling="[thirdAppTypes.california_app]"
      >
        <template slot="label">
          Number of Years
        </template>
        <ActivityOrAwardSpendYearsSelector
          :value="activity.ed_prep_years_after12"
          @change="(ed_prep_years_after12) => $emit('setActivity', { ed_prep_years_after12 })"
        />
      </CounselingFormItem>
      <CounselingFormItem
        required
        :currentApplication="currentApplicationType"
        :applicationsNeedToFilling="[thirdAppTypes.california_app]"
      >
        <template slot="label">
          Number of Months
        </template>
        <ActivityOrAwardSpendMonthsSelector
          :value="activity.ed_prep_months_after12"
          @change="(ed_prep_months_after12) => $emit('setActivity', { ed_prep_months_after12 })"
        />
      </CounselingFormItem>
    </template>
    <b>How much time did you spend in the program?</b>
    <CounselingFormItem
      required
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.california_app]"
    >
      <template slot="label">
        Hours spent per week
      </template>
      <el-input-number
        :min="0"
        :max="999.99"
        class="inputNumber"
        controls-position="right"
        :value="activity.ed_prep_hours_per_week"
        @input="(ed_prep_hours_per_week) => $emit('setActivity', { ed_prep_hours_per_week })"
      />
    </CounselingFormItem>
    <CounselingFormItem
      required
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.california_app]"
    >
      <template slot="label">
        Weeks spent per year
      </template>
      <el-input-number
        :min="0"
        :max="99.99"
        class="inputNumber"
        controls-position="right"
        :value="activity.ed_prep_weeks_per_year"
        @input="(ed_prep_weeks_per_year) => $emit('setActivity', { ed_prep_weeks_per_year })"
      />
    </CounselingFormItem>
  </div>
</template>

<script>
import { thirdAppTypeDict } from "@/class/ThirdApp";
import CounselingFormItem from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/CounselingFormItem";
import ActivityOrAwardGradesCheckbox from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/ActivityForm/ActivityOrAwardDialog/Components/ActivityOrAwardGradesCheckbox";
import ActivityOrAwardSpendYearsSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/ActivityForm/ActivityOrAwardDialog/Components/ActivityOrAwardSpendYearsSelector";
import ActivityOrAwardSpendMonthsSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/ActivityForm/ActivityOrAwardDialog/Components/ActivityOrAwardSpendMonthsSelector";

export default {
  name: "EducationalPrepProgramForm",
  props: {
    currentApplicationType: {
      type: String,
      default: ""
    },
    activity: {
      type: Object,
      default: () => ({})
    },
    educationalPrepPrograms: {
      type: Array,
      default: () => ([])
    }
  },
  components: {
    CounselingFormItem,
    ActivityOrAwardGradesCheckbox,
    ActivityOrAwardSpendYearsSelector,
    ActivityOrAwardSpendMonthsSelector
  },
  computed: {
    thirdAppTypes() {
      return thirdAppTypeDict;
    },
    educationalPrepProgramIsOther() {
      return this.activity.ed_prep_id === "18";
    },
    educationalPrepYearsAfter12() {
      return this.activity.ed_prep_grades.includes(5);
    }
  }
};
</script>

