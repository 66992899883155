<template>
  <el-collapse
    :value="activePanel"
    @change="(value) => {
      $emit('saveCounselingProfile');
      $emit('setActivePanel', value);
    }"
    accordion
  >
    <el-collapse-item
      title="Common App Essay"
      name="commonAppEssay"
      v-if="thirdAppTypes[currentApplicationType] === thirdAppTypes.common_app"
    >
      <el-form>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            Some colleges require submission of the personal essay with your Common App. You may submit a personal essay to any college, even if it is not required by that college. If not required by a college, you will be given the option during submission to include your essay or not for that college.
          </template>
          <div style="display: inline-block; width: 100%;">
            <el-checkbox
              :true-label="1"
              :false-label="0"
              :value="counselingProfile.understand_to_submit_essay"
              @input="
                understand_to_submit_essay =>
                  setCounselingProfile({ understand_to_submit_essay })
              "
            >
              I understand
            </el-checkbox>
          </div>
        </CounselingFormItem>
        <el-form-item
          required
          label="Here is the current list of colleges on your Dashboard and whether they require the Personal Essay."
        />
        <div class="requireEssay">
          <div>
            <b>Required</b>
            <ul>
              <li>
                Agnes Scott College
              </li>
            </ul>
          </div>
          <div><b>Not Required</b></div>
        </div>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            The essay demonstrates your ability to write clearly and concisely on a selected topic and helps you distinguish yourself in your own voice. What do you want the readers of your application to know about you apart from courses, grades, and test scores? Choose the option that best helps you answer that question and write an essay of no more than 650 words, using the prompt to inspire and structure your response. Remember: 650 words is your limit, not your goal. Use the full range if you need it, but don't feel obligated to do so. (The application won't accept a response shorter than 250 words.)
          </template>
          <el-radio-group
            :value="counselingProfile.common_app_essay_topic"
            @input="(common_app_essay_topic) => setCounselingProfile({ common_app_essay_topic })"
          >
            <el-radio
              :key="commonAppEssayPrompt.id"
              v-for="commonAppEssayPrompt in commonAppEssayPrompts"
              :label="commonAppEssayPrompt.id"
            >
              {{ commonAppEssayPrompt.prompt }}
            </el-radio>
          </el-radio-group>
        </CounselingFormItem>
        <template v-if="selectedCommonAppEssayPrompt">
          <template v-if="selectedCommonAppEssayPrompt.student_essay">
            <EssayPreviewer
              :essay="selectedCommonAppEssayPrompt"
              @setEssayContent="(common_app_essay_content) => setCounselingProfile({ common_app_essay_content })"
            />
          </template>
          <h4 v-else>
            {{ $t("counselingProfile.pleaseGoTo") }}
            <router-link
              :to="
                classId
                  ? {
                      name: 'counselingEssays',
                      params: { id: classId }
                    }
                  : {
                      name: 'counselingUserEssays',
                      params: { username: $route.params.username }
                    }
              "
            >
              {{ $t("counselingProfile.myEssays") }}
            </router-link>
            {{ $t("counselingProfile.toDraftYourEssay") }}
          </h4>
        </template>
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
    <el-collapse-item
      title="Coalition App Essay"
      name="coalitionAppEssay"
      v-if="thirdAppTypes[currentApplicationType] === thirdAppTypes.coalition_app"
    >
      <el-form>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            Please select which prompt you will use for your Coalition Essay:
          </template>
          <el-radio-group
            :value="Number(counselingProfile.coalition_app_essay_topic)"
            @input="(coalition_app_essay_topic) => setCounselingProfile({ coalition_app_essay_topic })"
          >
            <el-radio
              :key="coalitionAppEssayPrompt.id"
              v-for="coalitionAppEssayPrompt in coalitionAppEssayPrompts"
              :label="coalitionAppEssayPrompt.id"
            >
              {{ coalitionAppEssayPrompt.prompt }}
            </el-radio>
          </el-radio-group>
        </CounselingFormItem>
        <el-form-item>
          <template slot="label">
            <div>
              Please write an essay on the topic selected. You can type directly into the box, or you can paste text from another source.
              <br />
              While there is no perfect length for an essay, we recommend that you aim for 500 to 650 words.
            </div>
          </template>
        </el-form-item>
        <template v-if="selectedCoalitionAppEssayPrompt">
          <template v-if="selectedCoalitionAppEssayPrompt.student_essay">
            <EssayPreviewer
              :essay="selectedCoalitionAppEssayPrompt"
              @setEssayContent="(coalition_app_essay_content) => setCounselingProfile({ coalition_app_essay_content })"
            />
          </template>
          <h4 v-else>
            {{ $t("counselingProfile.pleaseGoTo") }}
            <router-link
              :to="
                classId
                  ? {
                      name: 'counselingEssays',
                      params: { id: classId }
                    }
                  : {
                      name: 'counselingUserEssays',
                      params: { username: $route.params.username }
                    }
              "
            >
              {{ $t("counselingProfile.myEssays") }}
            </router-link>
            {{ $t("counselingProfile.toDraftYourEssay") }}
          </h4>
        </template>
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
    <el-collapse-item
      title="UC App Essay"
      name="UcAppEssay"
      v-if="thirdAppTypes[currentApplicationType] === thirdAppTypes.california_app"
    >
      <el-form>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.california_app]"
        >
          <template slot="promptText">
            <p>Please respond to any 4 of the 8 questions below.</p>
            <p>We realize that not all questions apply to all applicants, so be sure to select the 4 questions that you believe give us the best information about you.</p>
            <p>All 8 questions are given equal consideration in the application review process. Responses to each question should be between 250-350 words.</p>
          </template>
          <template slot="label">
            Please answer any 4 questions below
          </template>
          <div style="display: inline-block; width: 100%;">
            <el-checkbox-group
              :value="counselingProfile.california_app_essay_topics"
              @input="(california_app_essay_topics) => {
                if (california_app_essay_topics.length <= 4) {
                  setCounselingProfile({ california_app_essay_topics })
                }
              }"
            >
              <div 
                :key="californiaAppEssayPrompt.id"
                v-for="californiaAppEssayPrompt in californiaAppEssayPrompts"
              >
                <el-checkbox :label="californiaAppEssayPrompt.id">
                  {{ californiaAppEssayPrompt.prompt }}
                </el-checkbox>
              </div>
            </el-checkbox-group>
          </div>
        </CounselingFormItem>
        <div
          :key="selectedCaliforniaAppEssayPrompt.id"
          v-for="(selectedCaliforniaAppEssayPrompt, essayPromptIndex) in selectedCaliforniaAppEssayPrompts"
        >
          <hr />
          <h5>{{ `Essay Prompt${essayPromptIndex + 1}: ${selectedCaliforniaAppEssayPrompt.prompt}` }}</h5>
          <br />
          <template v-if="selectedCaliforniaAppEssayPrompt.student_essay">
            <EssayPreviewer
              :essay="selectedCaliforniaAppEssayPrompt"
              @setEssayContent="
                california_app_essay_content =>
                  setCaliforniaAppEssayContent(essayPromptIndex, essayContent)
              "
            />
          </template>
          <h4 v-else>
            {{ $t("counselingProfile.pleaseGoTo") }}
            <router-link
              :to="
                classId
                  ? {
                      name: 'counselingEssays',
                      params: { id: classId }
                    }
                  : {
                      name: 'counselingUserEssays',
                      params: { username: $route.params.username }
                    }
              "
            >
              {{ $t("counselingProfile.myEssays") }}
            </router-link>
            {{ $t("counselingProfile.toDraftYourEssay") }}
          </h4>
        </div>
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
    <el-collapse-item
      title="Disciplinary History"
      name="disciplinaryHistory"
      v-if="thirdAppTypes[currentApplicationType] === thirdAppTypes.common_app"
    >
      <el-form>
        <el-form-item>
          <template slot="label">
            <b>Please Note:</b>
            <div>
              Common App member colleges carefully consider all parts of your application. Information provided below will be considered in the context of the rest of your application and does not necessarily prevent you from being admitted to college. For more information on whether specific colleges choose to receive this information or how it may be considered, please see the My Colleges tab.
            </div>
          </template>
        </el-form-item>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            Have you ever been found responsible for a disciplinary violation at any educational institution you have attended from the 9th grade (or the international equivalent) forward, whether related to academic misconduct or behavioral misconduct, that resulted in a disciplinary action? These actions could include, but are not limited to: probation, suspension, removal, dismissal, or expulsion from the institution.
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.received_disciplinary_action"
            @input="(received_disciplinary_action) => setCounselingProfile({ received_disciplinary_action })"
          >
            <div>
              <el-radio :label="0">Yes</el-radio>
            </div>
            <div>
              <el-radio :label="1">No</el-radio>
            </div>
          </el-radio-group>
        </CounselingFormItem>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            Please give the approximate date(s) of each incident, explain the circumstances and reflect on what you learned from the experience.
          </template>
          <div class="ckeditor">
            <CounselingEditor
              height="200"
              :value="counselingProfile.disciplinary_history_descr"
              @input="disciplinary_history_descr => setCounselingProfile({ disciplinary_history_descr })"
            />
          </div>
        </CounselingFormItem>
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
    <el-collapse-item
      title="Additional Information"
      name="additionalInformation"
    >
      <el-form>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          :hiddenTag="true"
        >
          <template slot="label">
            Community disruptions such as COVID-19 and natural disasters can have deep and long-lasting impacts. If you need it, this space is yours to describe those impacts. Colleges care about the effects on your health and well-being, safety, family circumstances, future plans, and education, including access to reliable technology and quiet study spaces. For more information, check out our <a target="_blank" href="https://appsupport.commonapp.org/applicantsupport/s/article/Where-can-I-explain-how-COVID-19-had-an-impact-on-me">COVID-19 FAQ</a>.
          </template>
        </CounselingFormItem>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            Do you wish to share anything on this topic?
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.wish_to_share_about_covid"
            @input="(wish_to_share_about_covid) => setCounselingProfile({ wish_to_share_about_covid })"
          >
            <div>
              <el-radio :label="0">Yes</el-radio>
            </div>
            <div>
              <el-radio :label="1">No</el-radio>
            </div>
          </el-radio-group>
        </CounselingFormItem>
        <div v-if="counselingProfile.wish_to_share_about_covid === 0">
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          >
            <template slot="label">
              Please use this space to describe how these events have impacted you.
            </template>
            <div class="ckeditor">
              <CounselingEditor
                height="200"
                :value="counselingProfile.covid_descr"
                @input="covid_descr => setCounselingProfile({ covid_descr })"
              />
            </div>
          </CounselingFormItem>
        </div>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app]"
        >
          <template slot="label">
            Do you wish to provide details of circumstances or qualifications not reflected in the application?
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.wish_to_share_not_reflected_by_covid"
            @input="(wish_to_share_not_reflected_by_covid) => setCounselingProfile({ wish_to_share_not_reflected_by_covid })"
          >
            <div>
              <el-radio :label="0">Yes</el-radio>
            </div>
            <div>
              <el-radio :label="1">No</el-radio>
            </div>
          </el-radio-group>
        </CounselingFormItem>
        <div v-if="counselingProfile.wish_to_share_not_reflected_by_covid === 0">
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.common_app]"
          >
            <template slot="label">
              You may use the space below to provide any additional information you wish to share.
            </template>
            <div class="ckeditor">
              <CounselingEditor
                height="200"
                :value="counselingProfile.not_reflected_by_covid_descr"
                @input="not_reflected_by_covid_descr => setCounselingProfile({ not_reflected_by_covid_descr })"
              />
            </div>
          </CounselingFormItem>
        </div>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
          :hiddenTag="true"
        >
          <template slot="label">
            If there are details that you haven’t had the opportunity to share elsewhere in your application, please feel free to share them here.
          </template>
        </CounselingFormItem>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            Do you have any additional information you would like to share?
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.any_additional_information"
            @input="(any_additional_information) => setCounselingProfile({ any_additional_information })"
          >
            <div>
              <el-radio :label="true">Yes</el-radio>
            </div>
            <div>
              <el-radio :label="false">No</el-radio>
            </div>
          </el-radio-group>
        </CounselingFormItem>
        <div v-if="counselingProfile.any_additional_information">
          <CounselingFormItem
            required
            :currentApplication="currentApplicationType"
            :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
          >
            <template slot="label">
              Please share any additional information you would like to provide.
            </template>
            <div class="ckeditor">
              <CounselingEditor
                height="200"
                :value="counselingProfile.provide_additional_information"
                @input="provide_additional_information => setCounselingProfile({ provide_additional_information })"
              />
            </div>
          </CounselingFormItem>
        </div>
        <CounselingFormItem
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.california_app]"
        >
          <template slot="label">
            If there's anything else you want us to know about you, now's your chance. But remember, you should use this space only if you want to describe anything that you have not had the opportunity to include elsewhere in the application. This shouldn't be an essay, but rather a place to explain unusual personal or family circumstances, or anything that may be unclear in other parts of the application.
          </template>
          <el-input
            type="textarea"
            :rows="5"
            maxlength="550"
            show-word-limit
            :value="counselingProfile.uc_additional_comments"
            @input="uc_additional_comments => setCounselingProfile({ uc_additional_comments })"
          />
        </CounselingFormItem>
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import { thirdAppTypeDict } from "@/class/ThirdApp";
import CounselingFormItem from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/CounselingFormItem";
import EssayPreviewer from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/WritingForm/EssayPreviewer";
import CounselingEditor from "@/components/ckeditor/CounselingEditor.vue";

export default {
  props: {
    currentApplicationType: {
      type: String,
      default: ""
    },
    classId: {
      type: String,
      default: ""
    },
    activePanel: {
      type: String,
      default: ""
    },
    essayPrompts: {
      type: Array,
      default: () => ([])
    }
  },
  components: {
    CounselingFormItem,
    EssayPreviewer,
    CounselingEditor
  },
  computed: {
    thirdAppTypes() {
      return thirdAppTypeDict;
    },
    counselingProfile() {
      return this.$store.getters["counselingProfile/counselingProfile"];
    },
    selectedCommonAppEssayPrompt() {
      if (this.counselingProfile.common_app_essay_topic) {
        const selectedCommonAppEssayPrompt = this.getEssayPrompt(
          this.counselingProfile.common_app_essay_topic
        );
        return selectedCommonAppEssayPrompt;
      } else {
        return null;
      }
    },
    commonAppEssayPrompts() {
      return this.essayPrompts.filter(({ is_common_app }) => is_common_app);
    },
    selectedCoalitionAppEssayPrompt() {
      if (this.counselingProfile.coalition_app_essay_topic) {
        const selectedCoalitionAppEssayPrompt = this.getEssayPrompt(
          this.counselingProfile.coalition_app_essay_topic
        );
        return selectedCoalitionAppEssayPrompt;
      } else {
        return null;
      }
    },
    coalitionAppEssayPrompts() {
      return this.essayPrompts.filter(({ is_coalition_app }) => is_coalition_app);
    },
    selectedCaliforniaAppEssayPrompts() {
      return this.counselingProfile.california_app_essay_topics.map((california_app_essay_topic) => (
        this.getEssayPrompt(california_app_essay_topic)
      ));
    },
    californiaAppEssayPrompts() {
      return this.essayPrompts.filter(({ is_california_app }) => is_california_app);
    }
  },
  data() {
    return {};
  },
  methods: {
    setCounselingProfile(data) {
      this.$store.commit("counselingProfile/setCounselingProfile", { ...data });
    },
    setCaliforniaAppEssayContent(essayPromptIndex, essayContent) {
      this.$store.commit("counselingProfile/setCaliforniaAppEssayContent", { essayPromptIndex, essayContent });
    },
    getEssayPrompt(selectedEssayPromptId) {
      return this.essayPrompts.find(
        essayPrompt => essayPrompt.id === selectedEssayPromptId
      );
    }
  }
};
</script>

<style scoped src="@/views/private/Counseling/ThirdApp/CounselingProfileForm/index.css"></style>
<style scoped>
.requireEssay {
  width: 100%;
  display: flex;
  flex-grow: 1 1;
  gap: 8px;
}

.requireEssay > div {
  width: 100%;
  border-radius: 2px;
  border: 1px solid #b7b7b7;
  background: #f6f6f6;
  box-sizing: border-box;
  padding: 8px;
}
</style>
