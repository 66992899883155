<template>
  <div v-if="applicationNeedToFilling">
    <slot name="promptText" />
    <el-form-item :required="required">
      <template slot="label">
        <slot name="label" />
        <template v-if="!hiddenTag">
          <CommonAppTag v-if="commonAppNeedToFilling" />
          <CoalitionAppTag v-if="coalitionAppNeedToFilling" />
          <CaliforniaAppTag v-if="californiaAppNeedToFilling" />
        </template>
      </template>
      <slot />
    </el-form-item>
    <div v-if="$slots.belowPromptText" class="belowPromptText">
      <slot name="belowPromptText" />
    </div>
    <el-alert
      v-if="checkApplications && valuesIsNotUsingApplication.length > 0"
      type="warning"
      :closable="false"
      show-icon
      class="applicationAlert"
    >
      <template slot="title">
        <span v-if="valuesIsNotUsingApplication.length === 1">
          {{ composeUselessApplicationAlertText(valuesIsNotUsingApplication[0]) }}
        </span>
        <ul v-else>
          <li
            :key="index"
            v-for="(valueIsNotUsingApplication,
            index) in valuesIsNotUsingApplication"
          >
            {{ composeUselessApplicationAlertText(valueIsNotUsingApplication) }}
          </li>
        </ul>
      </template>
    </el-alert>
  </div>
</template>

<script>
import { thirdAppTypeDict } from "@/class/ThirdApp";
import { CommonAppTag, CoalitionAppTag, CaliforniaAppTag } from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/ThirdAppTags";

export default {
  props: {
    currentApplication: {
      type: String,
      default: ""
    },
    applicationsNeedToFilling: {
      type: Array,
      default: () => ([])
    },
    required: {
      type: Boolean,
      default: false
    },
    hiddenTag: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number, Array],
      default: null
    },
    getterProp: {
      type: String,
      default: ""
    },
    checkApplications: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CommonAppTag,
    CoalitionAppTag,
    CaliforniaAppTag
  },
  computed: {
    thirdAppTypeDict() {
      return thirdAppTypeDict;
    },
    applicationNeedToFilling() {
      return (
        this.currentApplication === "all" ||
        this.applicationsNeedToFilling.includes(
          thirdAppTypeDict[this.currentApplication]
        )
      );
    },
    options() {
      return this.$store.getters[`counselingProfile/${this.getterProp}`];
    },
    commonAppNeedToFilling() {
      return this.applicationsNeedToFilling.includes(thirdAppTypeDict.common_app);
    },
    coalitionAppNeedToFilling() {
      return this.applicationsNeedToFilling.includes(thirdAppTypeDict.coalition_app);
    },
    californiaAppNeedToFilling() {
      return this.applicationsNeedToFilling.includes(thirdAppTypeDict.california_app);
    }
  },
  data() {
    return {
      valuesIsNotUsingApplication: []
    };
  },
  watch: {
    options() {
      this.checkIfApplicationsIsUsing();
    },
    value() {
      this.checkIfApplicationsIsUsing();
    }
  },
  created() {
    this.checkIfApplicationsIsUsing();
  },
  methods: {
    valueIsEmpty() {
      return this.value === "" || this.value === null || this.value.length === 0;
    },
    checkIfApplicationsIsUsing() {
      let valuesIsNotUsingApplication = [];
      if (this.checkApplications && !this.valueIsEmpty()) {
        const options = this.options || [];
        let selectedOptions = [];
        if (typeof this.value === "number") {
          selectedOptions.push(options.find(option => option.id === this.value));
        } else if (Array.isArray(this.value)) {
          this.value.forEach((itemValue) => {
            selectedOptions.push(options.find(option => option.id === itemValue));
          });
        }
        selectedOptions
          .filter(selectedOption => selectedOption)
          .forEach((selectedOption) => {
            let uselessApplicationSet = new Set();
            if (!selectedOption.services.includes(this.currentApplication)) {
              Object.keys(this.thirdAppTypeDict)
                .filter((thirdAppType) => (
                  !selectedOption.services.includes(thirdAppType)
                ))
                .forEach((thirdAppType) => {
                  uselessApplicationSet.add(this.thirdAppTypeDict[thirdAppType]);
                });
              valuesIsNotUsingApplication = [
                ...valuesIsNotUsingApplication, {
                  option: selectedOption.title,
                  uselessApplications: Array.from(uselessApplicationSet)
                }
              ];
            };
          });
        this.valuesIsNotUsingApplication = valuesIsNotUsingApplication
          .filter(({ uselessApplications }) => uselessApplications.length > 0);
      } else {
        this.valuesIsNotUsingApplication = [];
      };
    },
    composeUselessApplicationAlertText(valueIsNotUsingApplication) {
      return `所選的選項 ${valueIsNotUsingApplication.option} 在 ${valueIsNotUsingApplication.uselessApplications.join("、")} 申請表沒有沒有這個資料，請在上傳後重新填寫此答案！`;
    }
  }
};
</script>

<style scoped>
.belowPromptText {
  margin-top: -20px;
  margin-bottom: 20px;
  color: #4D4D4D;
}

.applicationAlert {
  margin-top: -16px !important;
}
</style>