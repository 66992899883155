<template>
  <el-select
    filterable
    :value="value"
    @change="changeValue"
    clearable
    placeholder
    remote
    :remote-method="fetchColleges"
  >
    <el-option
      v-for="item in data"
      :key="item.id"
      :label="item.title"
      :value="item.id"
    />
    <el-option label="Other" value="other" />
  </el-select>
</template>

<script>
export default {
  name: "College",
  props: ["value"],
  computed: {
    data() {
      return this.$store.getters["counselingProfile/collegeOptions"];
    }
  },
  methods: {
    fetchColleges(searchText) {
      this.$store.dispatch(
        "counselingProfile/fetchCollegesOfCounselingProfile", searchText
      );
    },
    changeValue(value) {
      if (value === "other") {
        this.$emit("change", { value: "other" });
      } else {
        const targetCollege = this.data.find(college => college.id === value);
        this.$emit("change", { ...targetCollege, value: targetCollege.id });
      }
    }
  }
};
</script>
