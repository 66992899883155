<template>
  <el-collapse
    :value="activePanel"
    @change="(value) => {
      $emit('saveCounselingProfile');
      $emit('setActivePanel', value);
    }"
    accordion
  >
    <el-collapse-item
      title="Activities"
      name="activities"
      v-if="[thirdAppTypes.common_app, thirdAppTypes.coalition_app].includes(thirdAppTypes[currentApplicationType])"
    >
      <el-form>
        <CounselingFormItem
          required
          :currentApplication="currentApplicationType"
          :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
        >
          <template slot="label">
            Do you have any activities that you wish to report?
          </template>
          <el-radio-group
            style="width: 100%"
            :value="counselingProfile.wish_to_report_activities"
            @input="
              wish_to_report_activities =>
                setCounselingProfile({ wish_to_report_activities })
            "
          >
            <div><el-radio :label="1">Yes</el-radio></div>
            <div><el-radio :label="0">No</el-radio></div>
          </el-radio-group>
        </CounselingFormItem>
        <template v-if="counselingProfile.wish_to_report_activities">
          <div
            :key="displayActivityCountIndex"
            v-for="(displayActivityCountNum, displayActivityCountIndex) of displayActivityCount"
          >
            <el-card class="card">
              <div slot="header" class="cardHeader">
                <span>{{ `Activity ${displayActivityCountNum}` }}</span>
                <el-tooltip
                  content="Move Up"
                  placement="top"
                  popper-class="tooltipColor"
                  v-if="activityOrderIsLast(displayActivityCountNum)"
                >
                  <el-button
                    type="danger"
                    icon="el-icon-close"
                    circle
                    @click="removeLastActivity"
                  />
                </el-tooltip>
                <el-tooltip
                  content="Move Down"
                  placement="top"
                  popper-class="tooltipColor"
                  v-if="activityCountIsMultiple && !activityOrderIsLast(displayActivityCountNum)"
                >
                  <el-button
                    type="primary"
                    icon="el-icon-bottom"
                    circle
                    @click="() => moveActivityOrderDown(displayActivityCountIndex)"
                  />
                </el-tooltip>
                <el-tooltip
                  content="Move Up"
                  placement="top"
                  popper-class="tooltipColor"
                  v-if="activityCountIsMultiple && !activityOrderIsFirst(displayActivityCountNum)"
                >
                  <el-button
                    type="primary"
                    icon="el-icon-top"
                    circle
                    @click="() => moveActivityOrderUp(displayActivityCountIndex)"
                  />
                </el-tooltip>
              </div>
              <CounselingFormItem
                required
                checkApplications
                :value="counselingProfile.activities[displayActivityCountIndex].type"
                getterProp="activityTypeOptions"
                :currentApplication="currentApplicationType"
                :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
              >
                <template slot="label">
                  Activity type
                </template>
                <ActivitySelector
                  :value="counselingProfile.activities[displayActivityCountIndex].type"
                  @change="type => setActivity(displayActivityCountIndex, { type })"
                />
              </CounselingFormItem>
              <CounselingFormItem
                v-if="activityTypeIsAthletics(displayActivityCountIndex)"
                required
                :currentApplication="currentApplicationType"
                :applicationsNeedToFilling="[thirdAppTypes.common_app]"
              >
                <template slot="label">
                  port/team
                </template>
                <SportSelector
                  :value="counselingProfile.activities[displayActivityCountIndex].sport"
                  @change="sport => setActivity(displayActivityCountIndex, { sport })"
                />
              </CounselingFormItem>
              <CounselingFormItem
                required
                :currentApplication="currentApplicationType"
                :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
              >
                <template slot="label">
                  Activity/Experience Name
                </template>
                <el-input
                  :value="counselingProfile.activities[displayActivityCountIndex].name"
                  @input="name => setActivity(displayActivityCountIndex, { name })"
                />
              </CounselingFormItem>
              <CounselingFormItem
                required
                :currentApplication="currentApplicationType"
                :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
              >
                <template slot="label">
                  Please provide a description of your experience.
                </template>
                <el-input
                  type="textarea"
                  maxlength="255"
                  show-word-limit
                  :value="counselingProfile.activities[displayActivityCountIndex].experience_descr"
                  @input="experience_descr => setActivity(displayActivityCountIndex, { experience_descr })"
                />
              </CounselingFormItem>
              <CounselingFormItem
                required
                :currentApplication="currentApplicationType"
                :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
              >
                <template slot="label">
                  Are you involved in a leadership role?
                </template>
                <el-radio-group
                  style="width: 100%"
                  :value="counselingProfile.activities[displayActivityCountIndex].leader"
                  @input="leader => setActivity(displayActivityCountIndex, { leader })"
                >
                  <div>
                    <el-radio :label="true">Yes</el-radio>
                  </div>
                  <div>
                    <el-radio :label="false">No</el-radio>
                  </div>
                </el-radio-group>
              </CounselingFormItem>
              <CounselingFormItem
                v-if="counselingProfile.activities[displayActivityCountIndex].leader"
                required
                :currentApplication="currentApplicationType"
                :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
              >
                <template slot="label">
                  Leadership Title?
                </template>
                <el-input
                  :value="counselingProfile.activities[displayActivityCountIndex].leadership_title"
                  @input="leadership_title => setActivity(displayActivityCountIndex, { leadership_title })"
                />
              </CounselingFormItem>
              <CounselingFormItem
                v-if="counselingProfile.activities[displayActivityCountIndex].leader"
                required
                :currentApplication="currentApplicationType"
                :applicationsNeedToFilling="[thirdAppTypes.common_app]"
              >
                <template slot="label">
                  Position/Leadership description
                </template>
                <el-input
                  type="textarea"
                  maxlength="50"
                  show-word-limit
                  :value="counselingProfile.activities[displayActivityCountIndex].position_descr"
                  @input="position_descr => setActivity(displayActivityCountIndex, { position_descr })"
                />
              </CounselingFormItem>
              <CounselingFormItem
                required
                :currentApplication="currentApplicationType"
                :applicationsNeedToFilling="[thirdAppTypes.common_app]"
              >
                <template slot="label">
                  Organization Name
                </template>
                <el-input
                  type="textarea"
                  maxlength="100"
                  show-word-limit
                  :value="counselingProfile.activities[displayActivityCountIndex].organization_name"
                  @input="organization_name => setActivity(displayActivityCountIndex, { organization_name })"
                />
              </CounselingFormItem>
              <CounselingFormItem
                required
                :currentApplication="currentApplicationType"
                :applicationsNeedToFilling="[thirdAppTypes.common_app]"
              >
                <template slot="label">
                  Please describe this activity, including what you accomplished and any recognition you received, etc.
                </template>
                <el-input
                  type="textarea"
                  maxlength="150"
                  show-word-limit
                  :value="counselingProfile.activities[displayActivityCountIndex].descr"
                  @input="descr => setActivity(displayActivityCountIndex, { descr })"
                />
              </CounselingFormItem>
              <CounselingFormItem
                required
                :currentApplication="currentApplicationType"
                :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
              >
                <template slot="label">
                  List any individual distinctions you earned in this activity or experience.
                </template>
                <el-input
                  type="textarea"
                  maxlength="255"
                  show-word-limit
                  :value="counselingProfile.activities[displayActivityCountIndex].descr"
                  @input="descr => setActivity(displayActivityCountIndex, { descr })"
                />
              </CounselingFormItem>
              <CounselingFormItem
                required
                checkApplications
                :value="counselingProfile.activities[displayActivityCountIndex].grade_levels"
                getterProp="gradeLevelOptions"
                :currentApplication="currentApplicationType"
                :applicationsNeedToFilling="[thirdAppTypes.common_app, thirdAppTypes.coalition_app]"
              >
                <template slot="label">
                  Participation grade levels
                </template>
                <el-checkbox-group
                  :value="counselingProfile.activities[displayActivityCountIndex].grade_levels"
                  @input="
                    grade_levels =>
                      setActivity(displayActivityCountIndex, { grade_levels })
                  "
                >
                  <div :key="gradeLevel.id" v-for="gradeLevel in gradeLevels">
                    <el-checkbox :label="gradeLevel.id">
                      {{ gradeLevel.title }}
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
              </CounselingFormItem>
              <CounselingFormItem
                required
                :currentApplication="currentApplicationType"
                :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
              >
                <template slot="label">
                  Are you currently participating in this activity or experience? 
                </template>
                <el-radio-group
                  style="width: 100%"
                  :value="counselingProfile.activities[displayActivityCountIndex].current"
                  @input="current => setActivity(displayActivityCountIndex, { current })"
                >
                  <div>
                    <el-radio :label="true">Yes</el-radio>
                  </div>
                  <div>
                    <el-radio :label="false">No</el-radio>
                  </div>
                </el-radio-group>
              </CounselingFormItem>
              <CounselingFormItem
                required
                :currentApplication="currentApplicationType"
                :applicationsNeedToFilling="[thirdAppTypes.common_app]"
              >
                <template slot="label">
                  Timing of participation
                </template>
                <div style="display: inline-block; width: 100%;">
                  <el-checkbox-group
                    :value="counselingProfile.activities[displayActivityCountIndex].participation_time"
                    @input="
                      participation_time =>
                        setActivity(displayActivityCountIndex, { participation_time })
                    "
                  >
                    <div>
                      <el-checkbox label="0">During school year</el-checkbox>
                    </div>
                    <div>
                      <el-checkbox label="1">During school break</el-checkbox>
                    </div>
                    <div>
                      <el-checkbox label="2">All year</el-checkbox>
                    </div>
                  </el-checkbox-group>
                </div>
              </CounselingFormItem>
              <CounselingFormItem
                required
                :currentApplication="currentApplicationType"
                :applicationsNeedToFilling="[thirdAppTypes.common_app]"
              >
                <template slot="label">
                  Hours spent per week
                </template>
                <el-input-number
                  class="inputNumber"
                  controls-position="right"
                  :min="1"
                  :max="168"
                  :value="counselingProfile.activities[displayActivityCountIndex].hours_spent_per_week"
                  @change="
                    hours_spent_per_week =>
                      setActivity(displayActivityCountIndex, { hours_spent_per_week })
                  "
                />
              </CounselingFormItem>
              <CounselingFormItem
                required
                :currentApplication="currentApplicationType"
                :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
              >
                <template slot="label">
                  Hours per week on the high end
                </template>
                <el-input-number
                  class="inputNumber"
                  controls-position="right"
                  :min="1"
                  :max="168"
                  :value="counselingProfile.activities[displayActivityCountIndex].high_hours_spent_per_week"
                  @change="
                    high_hours_spent_per_week =>
                      setActivity(displayActivityCountIndex, { high_hours_spent_per_week })
                  "
                />
              </CounselingFormItem>
              <CounselingFormItem
                required
                :currentApplication="currentApplicationType"
                :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
              >
                <template slot="label">
                  Hours per week on the low end
                </template>
                <el-input-number
                  class="inputNumber"
                  controls-position="right"
                  :min="1"
                  :max="168"
                  :value="counselingProfile.activities[displayActivityCountIndex].low_hours_spent_per_week"
                  @change="
                    low_hours_spent_per_week =>
                      setActivity(displayActivityCountIndex, { low_hours_spent_per_week })
                  "
                />
              </CounselingFormItem>
              <CounselingFormItem
                required
                :currentApplication="currentApplicationType"
                :applicationsNeedToFilling="[thirdAppTypes.common_app]"
              >
                <template slot="label">
                  Weeks spent per year
                </template>
                <el-input-number
                  class="inputNumber"
                  controls-position="right"
                  :min="1"
                  :max="52"
                  :value="counselingProfile.activities[displayActivityCountIndex].weeks_spent_per_yr"
                  @change="
                    weeks_spent_per_yr =>
                      setActivity(displayActivityCountIndex, { weeks_spent_per_yr })
                  "
                />
              </CounselingFormItem>
              <CounselingFormItem
                required
                :currentApplication="currentApplicationType"
                :applicationsNeedToFilling="[thirdAppTypes.common_app]"
              >
                <template slot="label">
                  I intend to participate in a similar activity in college.
                </template>
                <el-radio-group
                  style="width: 100%"
                  :value="counselingProfile.activities[displayActivityCountIndex].intend_to_participate_similar_in_college"
                  @input="intend_to_participate_similar_in_college => setActivity(displayActivityCountIndex, { intend_to_participate_similar_in_college })"
                >
                  <div>
                    <el-radio :label="0">Yes</el-radio>
                  </div>
                  <div>
                    <el-radio :label="1">No</el-radio>
                  </div>
                </el-radio-group>
              </CounselingFormItem>
            </el-card>
          </div>
          <el-form-item v-if="!displayActivityCountAlreadyMax" align="right">
            {{ `(${MAX_ACTIVITY_COUNT - displayActivityCount} of ${MAX_ACTIVITY_COUNT} available)` }}
            <el-button type="primary" @click="addDisplayActivityCount">
              Add another activity
            </el-button>
          </el-form-item>
        </template>
        <el-form-item align="right">
          <el-button type="primary" @click="$emit('saveCounselingProfile')">
            Save
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
    <el-collapse-item
      title="Activities & awards"
      name="activitiesAndAwards"
      v-if="[thirdAppTypes.california_app].includes(thirdAppTypes[currentApplicationType])"
    >
      <div>
        <p>You can enter your activities and awards one at a time. First, select a category from the dropdown menu below. Once you've selected a category, you'll be able to tell us more about the activity or award in the follow-up questions.</p>
        <h5>Description of categories</h5>
        <p>
          <strong>Award or honor: </strong>We want to know about the awards and honors you've received that mean the most to you.<br >
          <strong>Educational preparation programs: </strong>Any programs or activities that have enriched your academic experiences or helped you prepare for college.<br >
          <strong>Extracurricular activity: </strong>These could include hobbies, clubs, sports or anything else you haven't had the chance to tell us about.<br >
          <strong>Other coursework: </strong>These are courses other than those required for UC admission (courses that do not fit in UC’s "a-g" subject areas).<br >
          <strong>Volunteering / Community service: </strong>These are activities you've donated time and effort to without getting paid.<br >
          <strong>Work experience: </strong>This is for telling us about any paid jobs or paid internships you've had.
        </p>
      </div>
      <hr />
      <div 
        :key="activityOrAwardIndex"
        v-for="(activityOrAwardNum, activityOrAwardIndex) of displayActivityOrAwardCount"
      >
        <div class="panelTitle" @click="() => toggleActivityOrAwardOpenStatus(activityOrAwardIndex)">
          <span v-if="activityOrAwardOpenStatues[activityOrAwardIndex]" key="open">
            <i class="fas fa-chevron-down" />
          </span>
          <span v-else key="close">
            <i class="fas fa-chevron-right" />
          </span>
          <div class="activityOrAwardTitle">
            <ActivityOrAwardTitle
              :hideActivityOrAwardTitle="activityOrAwardOpenStatues[activityOrAwardIndex]"
              :categories="categories"
              :educationalPrepPrograms="educationalPrepPrograms"
              :activitiesOrAward="counselingProfile.activities_or_awards[activityOrAwardIndex]"
            />
          </div>
          <div>
            <span
              class="editAndDeleteActivitiesOrAward"
              @click="() => editActivitiesOrAward(activityOrAwardIndex)"
            >
              EDIT
            </span>
            <span
              class="editAndDeleteActivitiesOrAward"
              @click="() => removeActivitiesOrAward(counselingProfile.activities_or_awards[activityOrAwardIndex])"
            >
              DELETE
            </span>
          </div>
        </div>
        <div
          class="panelContent"
          v-if="activityOrAwardOpenStatues[activityOrAwardIndex]"
        >
          <ActivityOrAwardContent
            :categories="categories"
            :educationalPrepPrograms="educationalPrepPrograms"
            :activitiesOrAward="counselingProfile.activities_or_awards[activityOrAwardIndex]"
          />
        </div>
        <hr />
      </div>
      <el-form>
        <el-form-item v-if="!displayActivityOrAwardCountAlreadyMax" align="right">
          {{ `(${MAX_ACTIVITY_OR_AWARD_COUNT - displayActivityOrAwardCount} of ${MAX_ACTIVITY_OR_AWARD_COUNT} available)` }}
          <el-button type="primary" @click="openActivityOrAwardDialog">
            Add another activity or award
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
    <ActivityOrAwardDialog
      :currentApplicationType="currentApplicationType"
      :visible="activityOrAwardDialogVisible"
      :categories="categories"
      :educationalPrepPrograms="educationalPrepPrograms"
      @closeDialog="closeActivityOrAwardDialog"
      @saveActivityOrAward="activityOrAward => addActivityOrAward(activityOrAward)"
    />
    <ActivityOrAwardDialog
      :currentApplicationType="currentApplicationType"
      v-if="editingActivityOrAward"
      :visible="Boolean(editingActivityOrAward)"
      :categories="categories"
      :educationalPrepPrograms="educationalPrepPrograms"
      :isEditing="true"
      :editingActivityOrAward="editingActivityOrAward"
      @closeDialog="() => {
        editingActivityOrAward = null;
        editingActivityOrAwardIndex = null;
      }"
      @saveActivityOrAward="
        activityOrAward => saveActivityOrAward(activityOrAward)
      "
    />
  </el-collapse>
</template>

<script>
import { thirdAppTypeDict } from "@/class/ThirdApp";
import CounselingFormItem from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/CounselingFormItem";
import ActivityOrAwardDialog from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/ActivityForm/ActivityOrAwardDialog";
import ActivityOrAwardTitle from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/ActivityForm/ActivityOrAwardTitle";
import ActivityOrAwardContent from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/ActivityForm/ActivityOrAwardContent";
import ActivitySelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/ActivityForm/ActivitySelector";
import SportSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/ActivityForm/SportSelector";

export default {
  props: {
    currentApplicationType: {
      type: String,
      default: ""
    },
    activePanel: {
      type: String,
      default: ""
    }
  },
  components: {
    CounselingFormItem,
    ActivityOrAwardTitle,
    ActivityOrAwardContent,
    ActivityOrAwardDialog,
    ActivitySelector,
    SportSelector
  },
  computed: {
    thirdAppTypes() {
      return thirdAppTypeDict;
    },
    gradeLevels() {
      return this.$store.getters["counselingProfile/gradeLevelOptions"];
    },
    counselingProfile() {
      return this.$store.getters["counselingProfile/counselingProfile"];
    },
    MAX_ACTIVITY_COUNT() {
      return 10;
    },
    MAX_ACTIVITY_OR_AWARD_COUNT() {
      return 20;
    },
    displayActivityCountAlreadyMax() {
      return this.MAX_ACTIVITY_COUNT <= this.displayActivityCount;
    },
    displayActivityOrAwardCountAlreadyMax() {
      return this.MAX_ACTIVITY_OR_AWARD_COUNT <= this.displayActivityOrAwardCount;
    },
    activityCountIsMultiple() {
      return 1 < this.displayActivityCount;
    },
    categories() {
      return [
        {
          label: "Award or honor",
          value: "Award"
        },
        {
          label: "Educational preparation program",
          value: "EdPrep"
        },
        {
          label: "Extracurricular activity",
          value: "ExtraCurr"
        },
        {
          label: "Other coursework",
          value: "OtherCourse"
        },
        {
          label: "Volunteer / Community service",
          value: "Volunteer"
        },
        {
          label: "Work experience",
          value: "WorkExp"
        }
      ];
    },
    educationalPrepPrograms() {
      return [
        {
          label: "ATDP (Academic Talent Development Program)",
          value: "1"
        },
        {
          label: "Cal-SOAP",
          value: "2"
        },
        {
          label: "CAMP (California Alliance for Minority Participation in Science)",
          value: "3"
        },
        {
          label: "CBOP (Career Based Outreach Program)",
          value: "4"
        },
        {
          label: "COSMOS",
          value: "5"
        },
        {
          label: "C-STEM",
          value: "63"
        },
        {
          label: "Early Academic Outreach Program (EAOP)",
          value: "6"
        },
        {
          label: "Educational Guidance Center (EGC)",
          value: "7"
        },
        {
          label: "Educational Talent Search",
          value: "8"
        },
        {
          label: "GEAR UP",
          value: "10"
        },
        {
          label: "MESA (Mathematics, Engineering, Science Achievement) including MESA Schools Programs, SUCCESS Through Collaboration and California Community College Program",
          value: "11"
        },
        {
          label: "PDP (Professional Development Program)",
          value: "12"
        },
        {
          label: "Puente Program",
          value: "13"
        },
        {
          label: "UC Merced Transfer Initiative Programs",
          value: "65"
        },
        {
          label: "UC Scholars",
          value: "64"
        },
        {
          label: "UCLA Community College/SITE",
          value: "61"
        },
        {
          label: "UCLA VIP Scholars Program",
          value: "62"
        },
        {
          label: "UCSC SAGE (Students Acquiring \"A-G\" Expectations)",
          value: "15"
        },
        {
          label: "UCSF Internships",
          value: "9"
        },
        {
          label: "Upward Bound",
          value: "16"
        },
        {
          label: "Young Entrepreneurs at Haas",
          value: "17"
        },
        {
          label: "Other",
          value: "18"
        }
      ];
    }
  },
  watch: {
    counselingProfile() {
      this.displayActivityCount = this.counselingProfile.valid_activity_count;
      this.displayActivityOrAwardCount = this.counselingProfile.valid_activity_or_award_count;
    }
  },
  data() {
    return {
      displayActivityCount: 0,
      displayActivityOrAwardCount: 0,
      activityOrAwardDialogVisible: false,
      editingActivityOrAward: null,
      editingActivityOrAwardIndex: null,
      activityOrAwardOpenStatues: []
    };
  },
  async created() {
    this.displayActivityCount = this.counselingProfile.valid_activity_count;
    this.displayActivityOrAwardCount = this.counselingProfile.valid_activity_or_award_count;
    this.$store.dispatch(
      "counselingProfile/fetchGradeLevelsOfCounselingProfile"
    );
  },
  methods: {
    setCounselingProfile(data) {
      this.$store.commit("counselingProfile/setCounselingProfile", { ...data });
    },
    setActivity(activityIndex, data) {
      this.$store.commit("counselingProfile/setActivity", { activityIndex, data });
    },
    moveActivityOrderUp(activityIndex) {
      this.$store.dispatch("counselingProfile/moveActivityOrderUp", { activityIndex });
    },
    moveActivityOrderDown(activityIndex) {
      this.$store.dispatch("counselingProfile/moveActivityOrderDown", { activityIndex });
    },
    removeLastActivity() {
      this.displayActivityCount -= 1;
      this.$store.dispatch("counselingProfile/removeLastActivity");
    },
    addDisplayActivityCount() {
      this.displayActivityCount += 1;
    },
    addDisplayActivityOrAwardCount() {
      this.displayActivityOrAwardCount += 1;
    },
    toggleActivityOrAwardOpenStatus(activityIndex) {
      this.activityOrAwardOpenStatues[activityIndex] = !this.activityOrAwardOpenStatues[activityIndex];
      this.activityOrAwardOpenStatues = [...this.activityOrAwardOpenStatues];
    },
    activityOrderIsFirst(activityOrder) {
      return activityOrder === 1;
    },
    activityOrderIsLast(activityOrder) {
      return activityOrder === this.displayActivityCount;
    },
    activityOrAwardOrderIsFirst(activityOrAwardOrder) {
      return activityOrAwardOrder === 1;
    },
    activityOrAwardOrderIsLast(activityOrAwardOrder) {
      return activityOrAwardOrder === this.displayActivityOrAwardCount;
    },
    openActivityOrAwardDialog() {
      this.activityOrAwardDialogVisible = true;
    },
    closeActivityOrAwardDialog() {
      this.activityOrAwardDialogVisible = false;
    },
    editActivitiesOrAward(activityOrAwardIndex) {
      this.editingActivityOrAward = this.counselingProfile.activities_or_awards[activityOrAwardIndex];
      this.editingActivityOrAwardIndex = activityOrAwardIndex;
    },
    removeActivitiesOrAward(activitiesOrAward) {
      this.$store.dispatch("counselingProfile/removeActivitiesOrAward", activitiesOrAward);
    },
    addActivityOrAward(activityOrAward) {
      this.$store.dispatch("counselingProfile/addActivityOrAward", activityOrAward);
    },
    saveActivityOrAward(activityOrAward) {
      this.$store.dispatch("counselingProfile/saveActivityOrAward", {
        editingActivityOrAwardIndex: this.editingActivityOrAwardIndex,
        newActivityOrAward: activityOrAward
      });
    },
    activityTypeIsAthletics(activityIndex) {
      const targetType = this.counselingProfile.activities[activityIndex].type;
      const ATHLETICS_CLUB = 125911;
      const ATHLETICS_JV_VARSITY = 125912;
      return (
        targetType === ATHLETICS_CLUB || targetType === ATHLETICS_JV_VARSITY
      );
    },
    getCategoryTextByValue(categoryValue) {
      return this.categories.find((category) => (
        category.value === categoryValue
      )).label;
    },
    getEducationalPrepName(educationalPrep) {
      const OTHER = "18";
      if (educationalPrep.ed_prep_id === OTHER) {
        return educationalPrep.ed_prep_other_name;
      } else {
        return this.educationalPrepPrograms.find((educationalPrepProgram) => (
          educationalPrepProgram.value === educationalPrep.ed_prep_id
        )).label;
      }
    }
  }
};
</script>

<style scoped src="@/views/private/Counseling/ThirdApp/CounselingProfileForm/index.css"></style>
<style scoped>
.activityOrAwardTitle {
  box-sizing: border-box;
  padding-left: 16px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  font-size: 16px;
}

.editAndDeleteActivitiesOrAward {
  color: #42a16a;
  cursor: pointer;
  margin-right: 12px;
}
</style>
