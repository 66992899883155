<template>
  <el-row :gutter="24">
    <el-col :span="5">
      <el-menu
        :default-active="currentProfileClass"
        @select="setCurrentProfileClass"
      >
        <div
          :index="profile.value"
          :key="profile.value"
          v-for="profile in profileClassification"
        >
          <el-menu-item
            :index="profile.value"
            v-if="
              $route.params.thirdAppType === 'all' ||
                profile.applications.includes(
                  thirdAppTypes[$route.params.thirdAppType]
                )
            "
          >
            <span slot="title">{{ profile.label }}</span>
          </el-menu-item>
        </div>
      </el-menu>
    </el-col>
    <el-col :span="15">
      <ProfileForm
        v-if="currentProfileClass === 'profile'"
        :currentApplicationType="$route.params.thirdAppType"
        :activePanel="activePanel"
        @setActivePanel="setActivePanel"
        @saveCounselingProfile="saveCounselingProfile"
      />
      <FamilyForm
        v-if="currentProfileClass === 'family'"
        :currentApplicationType="$route.params.thirdAppType"
        :activePanel="activePanel"
        @setActivePanel="setActivePanel"
        @saveCounselingProfile="saveCounselingProfile"
      />
      <CampusesMajorsForm
        v-if="currentProfileClass === 'campusesMajors'"
        :currentApplicationType="$route.params.thirdAppType"
        :activePanel="activePanel"
        @setActivePanel="setActivePanel"
        @saveCounselingProfile="saveCounselingProfile"
      />
      <EducationForm
        v-if="currentProfileClass === 'education'"
        :studentUserId="studentUserId"
        :currentApplicationType="$route.params.thirdAppType"
        :activePanel="activePanel"
        @setActivePanel="setActivePanel"
        @saveCounselingProfile="saveCounselingProfile"
        @sendCounselorInvitation="sendCounselorInvitation"
      />
      <TestForm
        v-if="currentProfileClass === 'testing'"
        :currentApplicationType="$route.params.thirdAppType"
        :activePanel="activePanel"
        @setActivePanel="setActivePanel"
        @saveCounselingProfile="saveCounselingProfile"
      />
      <ActivityForm
        v-if="currentProfileClass === 'activities'"
        :currentApplicationType="$route.params.thirdAppType"
        :activePanel="activePanel"
        @setActivePanel="setActivePanel"
        @saveCounselingProfile="saveCounselingProfile"
      />
      <ScholarshipsProgramsForm
        v-if="currentProfileClass === 'scholarshipsPrograms'"
        :currentApplicationType="$route.params.thirdAppType"
        :activePanel="activePanel"
        @setActivePanel="setActivePanel"
        @saveCounselingProfile="saveCounselingProfile"
      />
      <WritingForm
        v-if="currentProfileClass === 'writing'"
        :currentApplicationType="$route.params.thirdAppType"
        :classId="$route.params.id"
        :activePanel="activePanel"
        :essayPrompts="essayPrompts"
        @setActivePanel="setActivePanel"
        @saveCounselingProfile="saveCounselingProfile"
      />
    </el-col>
  </el-row>
</template>

<script>
import { thirdAppTypeDict } from "@/class/ThirdApp";
import essayApi from "@/apis/essay";
import ProfileForm from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/ProfileForm";
import FamilyForm from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/FamilyForm";
import CampusesMajorsForm from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/CampusesMajorsForm";
import EducationForm from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/EducationForm";
import TestForm from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/TestForm";
import ActivityForm from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/ActivityForm";
import ScholarshipsProgramsForm from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/ScholarshipsProgramsForm";
import WritingForm from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/WritingForm";

export default {
  components: {
    ProfileForm,
    FamilyForm,
    CampusesMajorsForm,
    EducationForm,
    TestForm,
    ActivityForm,
    ScholarshipsProgramsForm,
    WritingForm
  },
  props: {
    studentUserId: {
      default: null,
      type: Number
    }
  },
  computed: {
    thirdAppTypes() {
      return thirdAppTypeDict;
    },
    profileClassification() {
      return [
        {
          value: "profile",
          label: "Profile",
          applications: [
            this.thirdAppTypes.common_app,
            this.thirdAppTypes.coalition_app,
            this.thirdAppTypes.california_app
          ]
        },
        {
          value: "family",
          label: "Family",
          applications: [
            this.thirdAppTypes.common_app,
            this.thirdAppTypes.coalition_app,
            this.thirdAppTypes.california_app
          ]
        },
        {
          value: "campusesMajors",
          label: "Campuses & majors",
          applications: [this.thirdAppTypes.california_app]
        },
        {
          value: "education",
          label: "Education",
          applications: [
            this.thirdAppTypes.common_app,
            this.thirdAppTypes.coalition_app,
            this.thirdAppTypes.california_app
          ]
        },
        {
          value: "testing",
          label: "Testing",
          applications: [
            this.thirdAppTypes.common_app,
            this.thirdAppTypes.coalition_app,
            this.thirdAppTypes.california_app
          ]
        },
        {
          value: "activities",
          label: "Activities",
          applications: [
            this.thirdAppTypes.common_app,
            this.thirdAppTypes.coalition_app,
            this.thirdAppTypes.california_app
          ]
        },
        {
          value: "scholarshipsPrograms",
          label: "Scholarships & programs",
          applications: [this.thirdAppTypes.california_app]
        },
        {
          value: "writing",
          label: "Writing",
          applications: [
            this.thirdAppTypes.common_app,
            this.thirdAppTypes.coalition_app,
            this.thirdAppTypes.california_app
          ]
        }
      ];
    },
    counselingProfile() {
      return this.$store.getters["counselingProfile/counselingProfile"];
    },
    applyLevelIsNotFreshman() {
      const [TRANSFER, OTHER] = ["2", "3"];
      return [TRANSFER, OTHER].includes(this.counselingProfile.apply_level);
    }
  },
  data() {
    return {
      currentProfileClass: "profile",
      activePanel: "personalInformation",
      essayPrompts: []
    };
  },
  watch: {
    async studentUserId() {
      try {
        await this.$store.dispatch(
          "counselingProfile/fetchCounselingProfile",
          this.studentUserId
        );
      } catch (e) {
        console.warn(e);
        await this.$store.commit("counselingProfile/setCounselingProfile", {});
      }
      if (this.currentProfileClass === "writing") {
        this.fetchEssayPrompts();
      }
    }
  },
  async created() {
    if (this.$route.query.currentProfileClass) {
      this.currentProfileClass = this.$route.query.currentProfileClass;
    }

    if (this.$route.query.activePanel) {
      this.activePanel = this.$route.query.activePanel;
    }

    this.$store.commit(
      "counselingProfile/setCurrentApplicationType",
      this.$route.params.thirdAppType
    );
  },
  methods: {
    async fetchEssayPrompts() {
      const {
        essay_prompts: essayPrompts
      } = await essayApi.getThirdApplicationEssayPrompts(this.studentUserId);
      this.essayPrompts = essayPrompts;
    },
    setCurrentProfileClass(currentProfileClass) {
      this.$route.query.currentProfileClass = currentProfileClass;
      this.$router.push({
        path: this.$route.path,
        query: {
          currentProfileClass,
          activePanel: this.getFirstPanelName(currentProfileClass)
        }
      });
    },
    setActivePanel(activePanel) {
      if (activePanel !== "") {
        this.$router.push({
          path: this.$route.path,
          query: {
            currentProfileClass: this.currentProfileClass,
            activePanel
          }
        });
      }
    },
    getFirstPanelName(currentProfileClass) {
      switch (currentProfileClass) {
        case "profile":
          return "personalInformation";
        case "family":
          return "household";
        case "education":
          if (this.applyLevelIsNotFreshman) {
            return "transferAdmissionPlanner";
          } else {
            return "highSchool";
          }
        case "campusesMajors":
          return "ucValues";
        case "testing":
          return "testsTaken";
        case "activities":
          return "activities";
        case "scholarshipsPrograms":
          return "applyForScholarships";
        case "writing":
          return "commonAppEssay";
        default:
          return "";
      }
    },
    async saveCounselingProfile() {
      try {
        switch (this.currentProfileClass) {
          case "profile":
            await this.$store.dispatch(
              "counselingProfile/saveCounselingProfile",
              this.studentUserId
            );
            break;
          case "family":
            await this.$store.dispatch(
              "counselingProfile/saveCounselingFamily",
              this.studentUserId
            );
            break;
          case "campusesMajors":
            await this.$store.dispatch(
              "counselingProfile/saveCounselingCampusesMajors",
              this.studentUserId
            );
            break;
          case "education":
            await this.$store.dispatch(
              "counselingProfile/saveCounselingEducation",
              this.studentUserId
            );
            break;
          case "testing":
            await this.$store.dispatch(
              "counselingProfile/saveCounselingTesting",
              this.studentUserId
            );
            break;
          case "activities":
            await this.$store.dispatch(
              "counselingProfile/saveCounselingActivities",
              this.studentUserId
            );
            break;
          case "scholarshipsPrograms":
            await this.$store.dispatch(
              "counselingProfile/saveCounselingScholarshipsPrograms",
              this.studentUserId
            );
            break;
          case "writing":
            await this.$store.dispatch(
              "counselingProfile/saveCounselingWriting",
              this.studentUserId
            );
            break;
          default:
        }
        this.$message.success(this.$t("message.update_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    async sendCounselorInvitation() {
      await this.saveCounselingProfile();
      this.$store.dispatch("thirdApp/sendCounselorInvitationToThirdApp", {
        thirdServiceType: this.$route.params.thirdAppType,
        studentUserId: this.studentUserId
      });
    }
  }
};
</script>

<style scoped>
::v-deep .el-collapse-item__header {
  padding-left: 8px;
  margin-bottom: 4px;
  background: #ededed;
  border: 1px solid #b7b7b7;
  border-radius: 4px;
}

::v-deep .el-collapse-item__header.is-active {
  color: #fff;
  background: #cdcdcd;;
}

::v-deep .el-input__count {
  bottom: -32px;
  background: transparent;
}
</style>
