<template>
  <el-select
    style="width: 100%"
    filterable
    :value="value"
    @change="changeValue"
    placeholder
  >
    <el-option
      v-for="item in data"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    />
  </el-select>
</template>

<script>
export default {
  name: "Career",
  props: ["value"],
  computed: {
    data() {
      return [
        { value: 0, label: "Accountant or actuary" },
        { value: 1, label: "Actor or entertainer" },
        { value: 2, label: "Architect or urban planner" },
        { value: 3, label: "Artist" },
        { value: 4, label: "Business (clerical)" },
        { value: 5, label: "Business executive (management, administrator)" },
        { value: 6, label: "Business owner or proprietor" },
        { value: 7, label: "Business salesperson or buyer" },
        { value: 46, label: "Chef" },
        { value: 8, label: "Clergy (minister, priest)" },
        { value: 9, label: "Clergy (other religious)" },
        { value: 10, label: "Clinical psychologist" },
        { value: 11, label: "College administrator/staff" },
        { value: 12, label: "College teacher" },
        { value: 13, label: "Computer programmer or analyst" },
        { value: 14, label: "Conservationist or forester" },
        { value: 15, label: "Dentist (including orthodontist)" },
        { value: 16, label: "Dietitian or nutritionist" },
        { value: 17, label: "Engineer" },
        { value: 18, label: "Farmer or rancher" },
        { value: 19, label: "Foreign service worker (including diplomat)" },
        { value: 20, label: "Homemaker (full-time)" },
        { value: 47, label: "Hospitality Management (Hotels, Restaurant)" },
        { value: 21, label: "Interior decorator (including designer)" },
        { value: 22, label: "Lab technician or hygienist" },
        { value: 42, label: "Laborer" },
        { value: 23, label: "Law enforcement officer" },
        { value: 24, label: "Lawyer (attorney) or judge" },
        { value: 25, label: "Military service (career)" },
        { value: 26, label: "Musician (performer, composer)" },
        { value: 27, label: "Nurse" },
        { value: 28, label: "Optometrist" },
        { value: 29, label: "Pharmacist" },
        { value: 30, label: "Physician" },
        { value: 31, label: "Policymaker/Government" },
        { value: 32, label: "School counselor" },
        { value: 33, label: "School principal or superintendent" },
        { value: 34, label: "Scientific researcher" },
        { value: 41, label: "Skilled trades" },
        { value: 35, label: "Social, welfare, or recreation worker" },
        { value: 37, label: "Teacher or administrator (elementary)" },
        { value: 38, label: "Teacher or administrator (secondary)" },
        { value: 36, label: "Therapist (physical, occupational, speech)" },
        { value: 39, label: "Veterinarian" },
        { value: 40, label: "Writer or journalist" },
        { value: 44, label: "Other" },
        { value: 45, label: "Undecided" }
      ];
    }
  },
  methods: {
    changeValue(value) {
      this.$emit("change", value);
    }
  }
};
</script>
