<template>
  <el-select
    style="width: 100%"
    filterable
    :value="value"
    @change="changeValue"
    :size="size"
    placeholder
  >
    <el-option
      v-for="item in data"
      :key="item.id"
      :label="item.title"
      :value="item.id"
    />
  </el-select>
</template>

<script>
export default {
  name: "Orgnization",
  props: {
    value: {
      type: Number,
      default: null
    },
    size: {
      type: String,
      default: ""
    }
  },
  computed: {
    data() {
      return this.$store.getters["counselingProfile/organizationOptions"];
    }
  },
  created() {
    this.$store.dispatch(
      "counselingProfile/fetchOrganizationsOfCounselingProfile"
    );
  },
  methods: {
    changeValue(value) {
      const organization = this.data.find(({ id }) => id === value);
      this.$emit("change", organization);
    }
  }
};
</script>
