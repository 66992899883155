<template>
  <div>
    <div>
      <el-radio-group v-model="collectType" @input="fetchCollectType">
        <el-radio-button :label="null">{{
          $t("enrollment.list.all")
        }}</el-radio-button>
        <el-radio-button
          :key="collectType.name"
          v-for="collectType in collectTypes"
          :label="collectType.name"
          :class="getClassName(collectType.name)"
        >
          {{ $t(`schools.star.${collectType.i18nKey}`) }}
        </el-radio-button>
      </el-radio-group>
    </div>
    <hr class="separate-line" />
    <el-table :data="displayCollectedCounselingSchools">
      <el-table-column label="#" type="index" />
      <el-table-column width="320px" label="School">
        <template slot-scope="scope">
          <router-link
            :to="{
              name: 'School',
              params: { schoolId: scope.row.school.slug, type: 'overview' }
            }"
          >
            <span v-if="$i18n.locale === 'en'">{{
              scope.row.school.name[$i18n.locale]
            }}</span>
            <span v-else
              >{{ scope.row.school.name[$i18n.locale] }}&nbsp;({{
                scope.row.school.name["en"]
              }})</span
            >
          </router-link>
        </template>
      </el-table-column>
      <el-table-column
        width="200px"
        :label="$t('collectedSchool.form.category')"
      >
        <template slot-scope="scope">
          <CollectCollegeTypeTag :type="scope.row.type" />
        </template>
      </el-table-column>
      <el-table-column
        width="110px"
        :label="$t('collectedSchool.form.applying')"
      >
        <template slot-scope="scope">
          <span>
            {{ scope.row.is_applying ? $t('collectedSchool.form.yes') : $t('collectedSchool.form.no') }}
          </span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('collectedSchool.form.applicationType')" prop="application_type" />
      <el-table-column :label="$t('collectedSchool.form.applicationSubmissionDate')">
        <template slot-scope="scope">
          <SelectApplicationTypeInfoIcon
            v-if="shortageOfApplicationType(scope.row.application_type)"
          />
          <span v-else>
            <span v-if="scope.row.deadline">
              {{ moment(scope.row.deadline).format("MM-DD") }}
            </span>
            <span v-else>-</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('collectedSchool.form.financialAidSubmissionDate')">
        <template slot-scope="scope">
          <SelectApplicationTypeInfoIcon
            v-if="shortageOfApplicationType(scope.row.application_type)"
          />
          <span v-else>
            <span v-if="scope.row.finaid_app_final_deadline">
              {{ moment(scope.row.finaid_app_final_deadline).format("MM-DD") }}
            </span>
            <span v-else>-</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column width="120px" label="Action">
        <template slot-scope="scope">
          <template v-if="counselingId">
            <router-link
              class="action-icon"
              :to="{
                name: 'counselingCollectedColleges',
                params: { counselingId, collectedCollegeId: scope.row.id }
              }"
            >
              <i class="fa fa-eye" />
            </router-link>
            <router-link
              class="action-icon"
              :to="{
                name: 'editCounselingCollectedColleges',
                params: { counselingId, collectedCollegeId: scope.row.id }
              }"
            >
              <i class="fa fa-edit" />
            </router-link>
          </template>
          <template v-else-if="username">
            <router-link
              class="action-icon"
              :to="{
                name: 'userCollectedSchool',
                params: { username, collectedCollegeId: scope.row.id }
              }"
            >
              <i class="fa fa-eye" />
            </router-link>
            <router-link
              class="action-icon"
              :to="{
                name: 'editCounselingUserSchool',
                params: { username, collectedCollegeId: scope.row.id }
              }"
            >
              <i class="fa fa-edit" />
            </router-link>
          </template>
          <span
            class="action-icon warning-icon"
            @click="
              () => {
                removeSchoolFromFavorite(scope.row.id);
              }
            "
          >
            <i class="fa fa-trash" />
          </span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import moment from "moment";
import CollectCollegeTypeTag from "@/components/school/CollectCollegeTypeTag";
import SelectApplicationTypeInfoIcon from "@/components/school/SelectApplicationTypeInfoIcon";

export default {
  name: "CollectCollegeTable",
  components: {
    CollectCollegeTypeTag,
    SelectApplicationTypeInfoIcon
  },
  props: {
    counselingId: {
      type: Number,
      default: null
    },
    username: {
      type: String,
      default: ""
    },
    collectedCounselingSchools: {
      type: Array,
      default: () => []
    },
    enableFilterBtns: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      collectType: null
    };
  },
  computed: {
    moment() {
      return moment;
    },
    collectTypes() {
      return [
        { name: "DREAM", i18nKey: "Dream school", en: "Dream school" },
        { name: "IDEA", i18nKey: "Reach school", en: "Reach school" },
        { name: "NORMAL", i18nKey: "Fit school", en: "Fit school" },
        { name: "SAFETY", i18nKey: "Safety school", en: "Safety school" },
        { name: "DEFAULT", i18nKey: "Uncategorized", en: "Uncategorized" }
      ];
    },
    displayCollectedCounselingSchools() {
      if (this.collectType) {
        return this.collectedCounselingSchools.filter(
          ({ type }) => type === this.collectType
        );
      } else {
        return this.collectedCounselingSchools;
      }
    }
  },
  methods: {
    shortageOfApplicationType(applicationType) {
      return applicationType === null;
    },
    fetchCollectType(collectType) {
      this.$emit("fetchCollectedCounselingSchools", collectType);
    },
    getClassName(collectType) {
      if (collectType === this.collectType) {
        return "";
      }
      return collectType.toLowerCase();
    },
    async removeSchoolFromFavorite(relatedSchoolId) {
      try {
        await this.$confirm(
          this.$t("message.areYouSure"),
          this.$t("message.notice"),
          {
            confirmButtonText: this.$t("message.continue"),
            cancelButtonText: this.$t("message.cancel"),
            type: "warning"
          }
        );
      } catch (e) {
        return false;
      }

      this.$emit("removeSchoolFromFavorite", relatedSchoolId);
    }
  }
};
</script>

<style scoped>
.dream ::v-deep .el-radio-button__inner {
  background-color: #fbeeed;
  border-color: #f7dddc;
  color: #d9534f;
}

.idea ::v-deep .el-radio-button__inner {
  background-color: #fef7ed;
  border-color: #fcefdc;
  color: #f0ad4e;
}

.normal ::v-deep .el-radio-button__inner {
  background-color: #ecf6f0;
  border-color: #d9ece1;
  color: #42a16a;
}

.safety ::v-deep .el-radio-button__inner {
  background-color: #ecf5ff;
  border-color: #d9ecff;
  color: #409eff;
}

.default ::v-deep .el-radio-button__inner {
  background-color: #f3f3f3;
  border-color: #e6e6e6;
  color: #838383;
}
</style>