<template>
  <div v-if="applicationNeedToVisible">
    <el-alert type="info" show-icon :closable="false">
      <slot name="title" />
    </el-alert>
  </div>
</template>

<script>
import { thirdAppTypeDict } from "@/class/ThirdApp";

export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    currentApplication: {
      type: String,
      default: ""
    },
    applicationsNeedToVisible: {
      type: Array,
      default: () => ([])
    }
  },
  computed: {
    thirdAppTypeDict() {
      return thirdAppTypeDict;
    },
    applicationNeedToVisible() {
      return (
        this.currentApplication === "all" ||
        this.applicationsNeedToVisible.includes(
          thirdAppTypeDict[this.currentApplication]
        )
      );
    },
    commonAppNeedToFilling() {
      return this.applicationsNeedToVisible.includes(thirdAppTypeDict.common_app);
    },
    coalitionAppNeedToFilling() {
      return this.applicationsNeedToVisible.includes(thirdAppTypeDict.coalition_app);
    },
    californiaAppNeedToFilling() {
      return this.applicationsNeedToVisible.includes(thirdAppTypeDict.california_app);
    }
  }
};
</script>
