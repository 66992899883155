<template>
  <el-dialog
    title="ADD ACTIVITY OR AWARD"
    :visible.sync="visible"
    :before-close="() => $emit('closeDialog')"
  >
    <el-form>
      <CounselingFormItem
        required
        :currentApplication="currentApplicationType"
        :applicationsNeedToFilling="[thirdAppTypes.california_app]"
      >
        <template slot="label">
          Category
        </template>
        <el-select style="width: 100%" v-model="category">
          <el-option
            v-for="category in categories"
            :key="category.value"
            :label="category.label"
            :value="category.value"
          />
        </el-select>
      </CounselingFormItem>
      <AwardForm
        v-if="activity.category === 'Award'"
        :currentApplicationType="currentApplicationType"
        :activity="activity"
        @setActivity="setActivity"
      />
      <EducationalPrepProgramForm
        v-if="activity.category === 'EdPrep'"
        :currentApplicationType="currentApplicationType"
        :educationalPrepPrograms="educationalPrepPrograms"
        :activity="activity"
        @setActivity="setActivity"
      />
      <ExtracurricularActivityForm
        v-if="activity.category === 'ExtraCurr'"
        :currentApplicationType="currentApplicationType"
        :activity="activity"
        @setActivity="setActivity"
      />
      <OtherCourseworkForm
        v-if="activity.category === 'OtherCourse'"
        :currentApplicationType="currentApplicationType"
        :activity="activity"
        @setActivity="setActivity"
      />
      <VolunteerForm
        v-if="activity.category === 'Volunteer'"
        :currentApplicationType="currentApplicationType"
        :activity="activity"
        @setActivity="setActivity"
      />
      <WorkExperienceForm
        v-if="activity.category === 'WorkExp'"
        :currentApplicationType="currentApplicationType"
        :activity="activity"
        @setActivity="setActivity"
      />
      <el-form-item required align="right">
        <el-button @click="closeDialig">CANCEL</el-button>
        <el-button type="primary" @click="saveActivityOrAwardAndClose">
          SAVE ACTIVITY OR AWARD
        </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { ActivityOrAwardFactory } from "@/class/counselingProfile";
import { thirdAppTypeDict } from "@/class/ThirdApp";
import CounselingFormItem from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/CounselingFormItem";
import AwardForm from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/ActivityForm/ActivityOrAwardDialog/AwardForm";
import EducationalPrepProgramForm from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/ActivityForm/ActivityOrAwardDialog/EducationalPrepProgramForm";
import ExtracurricularActivityForm from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/ActivityForm/ActivityOrAwardDialog/ExtracurricularActivityForm";
import OtherCourseworkForm from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/ActivityForm/ActivityOrAwardDialog/OtherCourseworkForm";
import VolunteerForm from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/ActivityForm/ActivityOrAwardDialog/VolunteerForm";
import WorkExperienceForm from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/ActivityForm/ActivityOrAwardDialog/WorkExperienceForm";

export default {
  name: "HighSchoolGradeCourseworkDialog",
  props: {
    currentApplicationType: {
      type: String,
      default: ""
    },
    visible: {
      type: Boolean,
      default: false
    },
    categories: {
      type: Array,
      default: () => ([])
    },
    educationalPrepPrograms: {
      type: Array,
      default: () => ([])
    },
    isEditing: {
      type: Boolean,
      default: false
    },
    editingActivityOrAward: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    CounselingFormItem,
    AwardForm,
    EducationalPrepProgramForm,
    ExtracurricularActivityForm,
    OtherCourseworkForm,
    VolunteerForm,
    WorkExperienceForm
  },
  computed: {
    thirdAppTypes() {
      return thirdAppTypeDict;
    }
  },
  watch: {
    category() {
      this.activity = ActivityOrAwardFactory.createActivityOrAward({
        ...this.activity,
        category: this.category
      });
    }
  },
  data() {
    return {
      category: "",
      activity: {}
    };
  },
  created() {
    if (this.isEditing) {
      this.activity = ActivityOrAwardFactory.createActivityOrAward(this.editingActivityOrAward);
      this.category = this.activity.category;
    } else {
      this.activity = ActivityOrAwardFactory.createActivityOrAward(this.activity);
    }
  },
  methods: {
    initialState() {
      this.activity = {};
      this.category = "";
    },
    closeDialig() {
      this.$emit("closeDialog");
      this.initialState();
    },
    saveActivityOrAwardAndClose() {
      this.$emit("saveActivityOrAward", this.activity);
      this.closeDialig();
    },
    setActivity(activity) {
      this.activity = ActivityOrAwardFactory.createActivityOrAward({
        ...this.activity,
        ...activity
      });
    }
  }
};
</script>

