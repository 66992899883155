import { render, staticRenderFns } from "./index.vue?vue&type=template&id=61fa4533&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/index.css?vue&type=style&index=0&id=61fa4533&prod&scoped=true&lang=css&external"
import style1 from "./index.vue?vue&type=style&index=1&id=61fa4533&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61fa4533",
  null
  
)

export default component.exports