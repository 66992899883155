<template>
  <div>
    <CounselingFormItem
      required
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.california_app]"
    >
      <template slot="label">
        What was the course name?
      </template>
      <el-input
        :value="activity.other_course_name"
        @input="(other_course_name) => $emit('setActivity', { other_course_name })"
      />
    </CounselingFormItem>
    <CounselingFormItem
      required
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.california_app]"
    >
      <template slot="label">
        Briefly describe the course.
      </template>
      <template slot="promptText">
        What program or school offered the course? Also, think about describing the major themes or topics the course covered, as well as what knowledge or skills you learned.
      </template>
      <el-input
        type="textarea"
        :rows="5"
        maxlength="350"
        show-word-limit
        :value="activity.other_course_description"
        @input="(other_course_description) => $emit('setActivity', { other_course_description })"
      />
    </CounselingFormItem>
    <CounselingFormItem
      required
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.california_app]"
    >
      <template slot="label">
        When did you participate in this activity? 
      </template>
      <template slot="promptText">
        If you participated during the summer, select the grade you were in before that summer.
      </template>
      <ActivityOrAwardGradesCheckbox
        :value="activity.other_course_grades"
        @input="(other_course_grades) => $emit('setActivity', { other_course_grades })"
      />
    </CounselingFormItem>
    <template v-if="otherCourseYearsAfter12">
      <b>How many months/years after 12th grade did you spend on this program?</b>
      <CounselingFormItem
        required
        :currentApplication="currentApplicationType"
        :applicationsNeedToFilling="[thirdAppTypes.california_app]"
      >
        <template slot="label">
          Number of Years
        </template>
        <ActivityOrAwardSpendYearsSelector
          :value="activity.other_course_years_after12"
          @change="(other_course_years_after12) => $emit('setActivity', { other_course_years_after12 })"
        />
      </CounselingFormItem>
      <CounselingFormItem
        required
        :currentApplication="currentApplicationType"
        :applicationsNeedToFilling="[thirdAppTypes.california_app]"
      >
        <template slot="label">
          Number of Months
        </template>
        <ActivityOrAwardSpendMonthsSelector
          :value="activity.other_course_months_after12"
          @change="(other_course_months_after12) => $emit('setActivity', { other_course_months_after12 })"
        />
      </CounselingFormItem>
    </template>
    <b>How much time did you spend in the program?</b>
    <CounselingFormItem
      required
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.california_app]"
    >
      <template slot="label">
        Hours spent per week
      </template>
      <el-input-number
        :min="0"
        :max="999.99"
        class="inputNumber"
        controls-position="right"
        :value="activity.other_course_hours_per_week"
        @input="(other_course_hours_per_week) => $emit('setActivity', { other_course_hours_per_week })"
      />
    </CounselingFormItem>
    <CounselingFormItem
      required
      :currentApplication="currentApplicationType"
      :applicationsNeedToFilling="[thirdAppTypes.california_app]"
    >
      <template slot="label">
        Weeks spent per year
      </template>
      <el-input-number
        :min="0"
        :max="99.99"
        class="inputNumber"
        controls-position="right"
        :value="activity.other_course_weeks_per_year"
        @input="(other_course_weeks_per_year) => $emit('setActivity', { other_course_weeks_per_year })"
      />
    </CounselingFormItem>
  </div>
</template>

<script>
import { thirdAppTypeDict } from "@/class/ThirdApp";
import CounselingFormItem from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/CounselingFormItem";
import ActivityOrAwardGradesCheckbox from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/ActivityForm/ActivityOrAwardDialog/Components/ActivityOrAwardGradesCheckbox";
import ActivityOrAwardSpendYearsSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/ActivityForm/ActivityOrAwardDialog/Components/ActivityOrAwardSpendYearsSelector";
import ActivityOrAwardSpendMonthsSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/ActivityForm/ActivityOrAwardDialog/Components/ActivityOrAwardSpendMonthsSelector";

export default {
  name: "OtherCourseworkForm",
  props: {
    currentApplicationType: {
      type: String,
      default: ""
    },
    activity: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    CounselingFormItem,
    ActivityOrAwardGradesCheckbox,
    ActivityOrAwardSpendYearsSelector,
    ActivityOrAwardSpendMonthsSelector
  },
  computed: {
    thirdAppTypes() {
      return thirdAppTypeDict;
    },
    otherCourseYearsAfter12() {
      return this.activity.other_course_grades.includes(5);
    }
  }
};
</script>

