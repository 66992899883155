<template>
  <el-dialog
    :title="`ADD COLLEGE GRADE CLASSES`"
    :visible.sync="visible"
    :before-close="() => $emit('closeDialog')"
  >
    <el-form>
      <CounselingFormItem
        required
        :currentApplication="currentApplicationType"
        :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
      >
        <template slot="label">
          Schedule System
        </template>
        <el-radio-group style="width: 100%" v-model="termSystem">
          <div :key="termSystem.value" v-for="termSystem in termSystems">
            <el-radio :label="termSystem.value">
              {{ termSystem.label }}
            </el-radio>
          </div>
        </el-radio-group>
      </CounselingFormItem>
      <CounselingFormItem
        required
        :currentApplication="currentApplicationType"
        :applicationsNeedToFilling="[thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
      >
        <template slot="label">
          Academic year
        </template>
        <el-select style="width: 100%" v-model="year">
          <el-option
            v-for="year in years"
            :key="year"
            :label="year"
            :value="year"
          />
        </el-select>
      </CounselingFormItem>
      <CounselingFormItem
        required
        :currentApplication="currentApplicationType"
        :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
      >
        <template slot="label">
          Schedule System
        </template>
        <el-radio-group style="width: 100%" v-model="term">
          <div :key="term.value" v-for="term in terms">
            <el-radio :label="term.value">
              {{ term.label }}
            </el-radio>
          </div>
        </el-radio-group>
      </CounselingFormItem>
      <CounselingFormItem
        required
        :currentApplication="currentApplicationType"
        :applicationsNeedToFilling="[thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
      >
        <template slot="label">
          Subject/Rubric/Prefix
        </template>
        <el-input v-model="subject" />
      </CounselingFormItem>
      <CounselingFormItem
        required
        :currentApplication="currentApplicationType"
        :applicationsNeedToFilling="[thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
      >
        <template slot="label">
          Course Name
        </template>
        <el-input v-model="name" />
      </CounselingFormItem>
      <CounselingFormItem
        required
        :currentApplication="currentApplicationType"
        :applicationsNeedToFilling="[thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
      >
        <template slot="label">
          Course Number
        </template>
        <el-input v-model="number" />
      </CounselingFormItem>
      <CounselingFormItem
        required
        :currentApplication="currentApplicationType"
        :applicationsNeedToFilling="[thirdAppTypes.coalition_app, thirdAppTypes.california_app]"
      >
        <template slot="label">
          Credits/hours
        </template>
        <el-input-number
          class="inputNumber"
          controls-position="right"
          v-model="credits"
        />
      </CounselingFormItem>
      <CounselingFormItem
        required
        :currentApplication="currentApplicationType"
        :applicationsNeedToFilling="[thirdAppTypes.california_app]"
      >
        <template slot="label">
          Honors Status
        </template>
        <el-select style="width: 100%" v-model="honorStatusCode">
          <el-option label="College level" value="CL" />
          <el-option label="Not honors" value="NH" />
        </el-select>
      </CounselingFormItem>
      <CounselingFormItem
        required
        :currentApplication="currentApplicationType"
        :applicationsNeedToFilling="[thirdAppTypes.california_app]"
      >
        <template slot="label">
          Subject Area
        </template>
        <CollegeSubjectsSelector
          :value="subjectArea"
          @change="newSubjectArea => subjectArea = newSubjectArea"
        />
      </CounselingFormItem>
      <CounselingFormItem
        required
        :currentApplication="currentApplicationType"
        :applicationsNeedToFilling="[thirdAppTypes.coalition_app]"
      >
        <template slot="label">
          When will you take this course?
        </template>
        <el-radio-group style="width: 100%" v-model="takingCourses">
          <div
            :key="takingCourseOption.value"
            v-for="takingCourseOption in takingCourseOptions"
          >
            <el-radio :label="takingCourseOption.value">
              {{ takingCourseOption.label }}
            </el-radio>
          </div>
        </el-radio-group>
      </CounselingFormItem>
      <el-form-item required align="right">
        <el-button @click="() => $emit('closeDialog')">CANCEL</el-button>
        <el-button type="primary" @click="saveClassAndClose">
          SAVE CLASS & CLOSE
        </el-button>
        <el-button v-if="!isEditing" type="primary" @click="saveClassAndInitState">
          SAVE & ADD ANOTHER CLASS
        </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { thirdAppTypeDict } from "@/class/ThirdApp";
import CounselingFormItem from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/CounselingFormItem";
import CollegeSubjectsSelector from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/EducationForm/CollegeSubjectsSelector";

export default {
  name: "InProgressCollegeGradeCourseworkDialog",
  props: {
    currentApplicationType: {
      type: String,
      default: ""
    },
    visible: {
      type: Boolean,
      default: false
    },
    isEditing: {
      type: Boolean,
      default: false
    },
    editingCoursework: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    CounselingFormItem,
    CollegeSubjectsSelector
  },
  computed: {
    thirdAppTypes() {
      return thirdAppTypeDict;
    },
    termSystems() {
      return [
        { value: "{\"code\":\"S\",\"value\":\"Semester\",\"label\":\"Semester\"}", label: "Semester"},
        { value: "{\"code\":\"Q\",\"value\":\"Quarter\",\"label\":\"Semester\"}", label: "Quarter"}
      ];
    },
    years() {
      const currentYear = new Date().getFullYear();
      const startYear = currentYear + 1;
      const endYear = currentYear - 1;
      const years = [];
      for (let year = startYear; year >= endYear; year--) {
        years.push(String(year));
      }
      return years;
    },
    terms() {
      return [
        { value: "{\"code\":\"FALL\",\"value\":\"Fall\"}", label: "Fall"},
        { value: "{\"code\":\"WINTER\",\"value\":\"Winter\"}", label: "Winter"},
        { value: "{\"code\":\"SPR\",\"value\":\"Spring\"}", label: "Spring"},
        { value: "{\"code\":\"SUM\",\"value\":\"Summer\"}", label: "Summer"}
      ];
    },
    takingCourseOptions() {
      return [
        { value: "{\"code\":\"CTC\",\"value\":\"I am currently taking this course.\"}", label: "I am currently taking this course."},
        { value: "{\"code\":\"PTC\",\"value\":\"I plan to take this course.\"}", label: "I plan to take this course."}
      ];
    }
  },
  data() {
    return {
      termSystem: "",
      year: "",
      term: "",
      subjectArea: "",
      subject: "",
      name: "",
      number: "",
      takingCourses: "",
      credits: null,
      honorStatusCode: ""
    };
  },
  created() {
    if (this.isEditing) {
      this.fillEditingCourseworkToState();
    }
  },
  methods: {
    initState() {
      this.termSystem = "";
      this.year = "";
      this.term = "";
      this.subjectArea = "";
      this.subject = "";
      this.name = "";
      this.number = "";
      this.takingCourses = "";
      this.credits = null;
      this.honorStatusCode = "";
    },
    fillEditingCourseworkToState() {
      this.termSystem = this.editingCoursework.term_system;
      this.year = this.editingCoursework.year;
      this.term = this.editingCoursework.term;
      this.subjectArea = this.editingCoursework.subject_area;
      this.subject = this.editingCoursework.subject;
      this.name = this.editingCoursework.name;
      this.number = this.editingCoursework.number;
      this.takingCourses = this.editingCoursework.taking_courses;
      this.credits = this.editingCoursework.credits;
      this.honorStatusCode = this.editingCoursework.honor_status_code;
    },
    saveClass() {
      const coursework = {
        term_system: this.termSystem,
        year: this.year,
        term: this.term,
        subject_area: this.subjectArea,
        subject: this.subject,
        name: this.name,
        number: this.number,
        taking_courses: this.takingCourses,
        credits: this.credits,
        honor_status_code: this.honorStatusCode
      };
      
      if (this.isEditing) {
        coursework.collegeIndex = this.editingCoursework.collegeIndex;
        coursework.originalCoursework = this.editingCoursework.originalCoursework;
      }

      this.$emit("submitGradeCoursework", coursework);
    },
    saveClassAndClose() {
      this.saveClass();
      this.initState();
      this.$emit("closeDialog");
    },
    saveClassAndInitState() {
      this.saveClass();
      this.initState();
    }
  }
};
</script>
