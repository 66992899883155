<template>
  <ThirdAppTag>UC App</ThirdAppTag>
</template>

<script>
import ThirdAppTag from "@/views/private/Counseling/ThirdApp/CounselingProfileForm/ThirdAppTags/ThirdAppTag";

export default {
  name: "CaliforniaApp",
  components: {
    ThirdAppTag
  }
};
</script>
