<template>
  <el-dialog
    :title="$t('message.notice')"
    :visible.sync="isVisible"
    width="600px"
  >
    <strong>
      <span class="warning-icon">
        <i class="fas fa-times" />
      </span>
      {{ $t('application.dialogs.usernamePasswordWrong') }}
    </strong>
    <span slot="footer" class="accountInfoWrongDialogFooter">
      <el-button @click="$emit('onCancel')">
        {{ $t("button.cancel") }}
      </el-button>
      <a
        target="_blank"
        :href="forgotPasswordUrl"
      >
        <el-button>{{ $t('application.dialogs.forgotPassword') }}</el-button>
      </a>
      <el-button type="primary" @click="$emit('onInputAccountInfo')">
        {{ $t('application.dialogs.reenterPassword') }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>

export default {
  props: {
    thirdAppTypeName: {
      type: String,
      default: ""
    },
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    forgotPasswordUrl() {
      switch(this.thirdAppTypeName) {
        case "Coalition App":
          return "https://applyweb.collegenet.com/account/support/accountRecovery?filegroup=coalition&origin=https://www.mycoalition.org/app";
        case "Common App":
          return "https://apply.commonapp.org/login/reset";
        default:
          return "";
      }
    }
  }
};
</script>

<style scoped src="@/views/private/Counseling/ThirdApp/Dialog/index.css"></style>
<style scoped>
.accountInfoWrongDialogFooter > button {
  margin: 0px 8px;
}
</style>>