<template>
  <el-select
    style="width: 100%"
    filterable
    :value="value"
    @change="changeValue"
    placeholder
  >
    <el-option
      v-for="item in data"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    />
  </el-select>
</template>

<script>
export default {
  name: "ALevelScoreSelector",
  props: {
    value: {
      type: String,
      default: ""
    }
  },
  computed: {
    data() {
      return [
        { value: "{\"code\":\"ASTAR\",\"label\":\"A*\",\"value\":\"A*\"}", label: "A*"},
        { value: "{\"code\":\"A\",\"label\":\"A\",\"value\":\"A\"}", label: "A"},
        { value: "{\"code\":\"B\",\"label\":\"B\",\"value\":\"B\"}", label: "B"},
        { value: "{\"code\":\"C\",\"label\":\"C\",\"value\":\"C\"}", label: "C"},
        { value: "{\"code\":\"D\",\"label\":\"D\",\"value\":\"D\"}", label: "D"},
        { value: "{\"code\":\"E\",\"label\":\"E\",\"value\":\"E\"}", label: "E"}
      ];
    }
  },
  methods: {
    changeValue(value) {
      this.$emit("change", value);
    }
  }
};
</script>
