<template>
  <div class="tag">
    <slot />
  </div>
</template>

<script>
export default {
  name: "ThirdAppTag"
};
</script>

<style scoped>
.tag {
  display: inline;
  width: max-content;
  font-size: 12px;
  color: #4d769d;
  background: #e3ecf7;
  border-radius: 4px;
  padding: .4em .5em;
  margin: 4px;
  line-height: 1em;
  white-space: nowrap;
}
</style>
